import { FlightResult } from './flight-result';
import { PaymentInstrumentDetails } from './payment-instrument-details';
import { TravelerDetails } from './traveler-details';
import { BookingResponseError } from './booking-response-error';

export class BookingResponse {

  status: string = 'ERROR';
  bookingId: string;
  nextCallAfter: number;
  bookingStatusId: any = null;
  transactionId: string;
  tripSessionId:string;
  eventIdAndOptions: Map<string, string>;
  ticketId: string;
  tripId: string;
  limitedUserCreated:boolean;
  ticketNumber: string;
  errors: BookingResponseError[];
  bookedFlights: { flights: FlightResult[] };
  changedFlights: { flights: FlightResult[] };
  paymentDetails: PaymentInstrumentDetails;
  travellerDetails: TravelerDetails;
  multiBookingResponse:Array<any>;
  airlineNames: any = {};
  airports: any = {};
  alreadyHandledResponse: string;

}
