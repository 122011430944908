import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { SearchActionType } from './enum/search-action.type';
import { Constants } from './util/constants';
import { DateUtils } from './util/date-utils';
import { FilterService } from './filter.service';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { UserAccountService } from './user-account.service';
import { FlightLegResult } from './entity/flight-leg-result';
import { HotelSearchResponse } from './entity/hotel-search-response.';
import { HotelQueryParam } from './entity/hotel-query-param';
import { HotelSearchRequest } from './entity/hotel-search-request';
import { HotelResult, priceChange } from './entity/hotel-result';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { ImageRequest } from './entity/image-request';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { SearchService } from './search.service';

@Injectable()
export class HotelSearchService {
  public hotelChainFilterOptions: any[];
  previousSearch: HotelQueryParam;
  selectedHotel:any;
  roomPriceChange: Array<priceChange> = [];
  hotelCodeHasNoRoom = [];
  selectTypeFrom = 'list';
  selectedHotelFromMap: any;
  priceLineValues = { fee: '', resortFee: '', tax: '' };
  constructor(
    private http: GallopHttpClient,
    public filterService: FilterService,
    private searchService: SearchService,
    private userAccountService: UserAccountService,
    private gallopLocalStorage: GallopLocalStorageService,
  ) { }

  hotelSearchResponse: HotelSearchResponse;

  public lastBroadcastedSearchResponse: HotelSearchResponse;
  public hotelResponseSubject = new BehaviorSubject<any>(null);
  hotelResponse$ = this.hotelResponseSubject.asObservable();

  private hotelRequestSubject = new BehaviorSubject<any>(null);
  hotelRequest$ = this.hotelRequestSubject.asObservable();


  searchType: SearchActionType;


  broadCastHotelResponse(hotelSearchResponse: HotelSearchResponse) {
    this.lastBroadcastedSearchResponse = hotelSearchResponse;
    this.hotelResponseSubject.next(hotelSearchResponse);

  }

  broadCastHotelRequest(hotelSearchQueryParam: HotelQueryParam) {
    this.hotelRequestSubject.next(hotelSearchQueryParam);
  }
 EmptyHotelRequest() {
    this.hotelRequestSubject.next(null);
  }

  // setPreviousSearch(flightSearchQueryParam:FlightSearchQueryParam) {
  //   this.previousSearch = flightSearchQueryParam;
  // }

  // setAlgoType(algoSwitchType: any){
  //   if(algoSwitchType){
  //     this.algoTypeSwitchSubject.next(algoSwitchType)
  //   }

  // }


  // getPreviousSearch() {
  //   return this.previousSearch ? this.previousSearch : null;
  // }

  // outgoingSelectedFlight : FlightResult;
  // setOutgoingSelectedFlight(flight : FlightResult){
  //   this.outgoingSelectedFlight = flight;
  //   if(!flight.flightId || !this.returnFlightSearchResponse) return;
  //   let flightSearchResponse:FlightSearchResponse = this.filterService.filterAssociatedReturnFlights(flight.flightId,this.returnFlightSearchResponse);
  //   this.updateAirlineOptions(flightSearchResponse);
  //   this.filterService.setOriginalFlightSearchResults(flightSearchResponse);
  //   this.sortResultsAsRecommended(flightSearchResponse);
  // }

  // resetOutgoingSelectedFlight(){
  //   this.outgoingSelectedFlight = undefined;
  //   this.updateAirlineOptions(this.flightSearchResponse);
  //   this.filterService.setOriginalFlightSearchResults(this.flightSearchResponse);
  //   // this.broadCastFlightResponse(this.flightSearchResponse);
  //   this.sortResultsAsRecommended(this.flightSearchResponse);
  // }
  // getOutgoingSelectedFlight(){
  //   return this.outgoingSelectedFlight;
  // }

  setHotelSearchResponse(hotelSearchResponse: HotelSearchResponse, overrideOriginalSearchRes: boolean) {
    if (overrideOriginalSearchRes && hotelSearchResponse && (hotelSearchResponse.hotelsList &&
      hotelSearchResponse.hotelsList.length > 0)) {
      this.hotelSearchResponse = this.processHotelSearchResults(hotelSearchResponse);
      this.filterService.setOriginalHotelSearchResults(this.hotelSearchResponse);
      // this.gallopLocalStorage.setItem('airports',JSON.stringify(this.flightSearchResponse.airports));
      // this.gallopLocalStorage.setItem('airlineNames',JSON.stringify(this.flightSearchResponse.airlineNames));
      this.sortResultsAsRecommended(this.hotelSearchResponse);
      // this.broadCastHotelResponse(hotelSearchResponse);
    } else {
      this.broadCastHotelResponse(hotelSearchResponse);
    }
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          console.log(err);
          reject(err);
        });
    });

  }
  processHotelSearchResults(hotelSearchResponse: HotelSearchResponse) {
    let index = 0;
    for (let hotelResultItem of hotelSearchResponse.hotelsList) {
      hotelResultItem.recommendedIdx = index;
      index++;
    }
    return hotelSearchResponse;
  }

  private generateLegFlightId(handler: string, price: number, leg: FlightLegResult) {
    return leg.flightHops[0].from + '_' + leg.flightHops[0].to + '_' + leg.flightHops[0].carrier + '_' + leg.flightHops[0].starts + '_' + leg.flightHops[leg.flightHops.length - 1].ends;
  }

  setPreviousSearch(hotelSearchQueryParam: HotelQueryParam) {
    this.previousSearch = hotelSearchQueryParam;
  }

  getPreviousSearch() {
    return this.previousSearch;
  }

  searchHotel(hotelSearchQueryParam: HotelQueryParam, searchActionType: SearchActionType): Observable<any> {
    this.gallopLocalStorage.setItem('failedRates', '{}');

    this.setPreviousSearch(hotelSearchQueryParam);
    // this.searchService.searchHeaderCliked =true;
    let searchRequest: HotelSearchRequest = this.getSearchRequest(hotelSearchQueryParam);
    this.broadCastHotelRequest(hotelSearchQueryParam);
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    let queryParam: string = '';
    queryParam = queryParam + '&start_date_time=' + searchRequest.checkInDate;
    queryParam = queryParam + '&return_date_time=' + searchRequest.checkOutDate;
    queryParam = queryParam + '&destination=' + encodeURIComponent(searchRequest.destination);
    queryParam = queryParam + '&currentLocation=' + searchRequest.currentLocation;
    queryParam = queryParam + '&totalTravellerCount=' + searchRequest.totalTravellers;
    queryParam = queryParam + '&hotelChain=' + searchRequest.hotelChain;
    queryParam = queryParam + '&travellerData=' + encodeURIComponent(JSON.stringify(searchRequest.travellerRooms));
    queryParam = queryParam + '&travellerEmail=' + searchRequest.travellerEmail;
    return this.http.get(environment.apiSearchHotelUrl + '?'
      + 'action=' + searchActionType
      + queryParam);
  }
hotelSelectFromDropDown =false;
searchHotelFromDrodown(hotel: any): Observable<any> {
  let hotelSearchQueryParam: HotelQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
    let searchQuery: HotelSearchRequest = this.getSearchRequest(hotelSearchQueryParam);
    let data = { hotelSearchRequest: searchQuery, hotel: hotel };
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
   
    let travellerEmail;
    for(let i=0;i<this.searchService.employeeEmail.length;i++){
      if(travellerEmail ){
         travellerEmail = travellerEmail +','+ this.searchService.employeeEmail[i].email;
      }else{
        travellerEmail = this.searchService.employeeEmail[i].email;
      }
    }
    return this.http.post(environment.apiForFindingMoreHotelRevalidation+'?selectedHotelCode='+hotel.hotelCode +'&start_date_time='+searchQuery.checkInDate+'&return_date_time=' +searchQuery.checkOutDate+'&destination='+encodeURIComponent(JSON.stringify(this.destination.address))+'&totalTravellerCount='+encodeURIComponent(JSON.stringify(this.searchService.employeeEmail.length))+'&travellerData=' +encodeURIComponent(JSON.stringify(searchQuery.travellerRooms)) +'&travellerEmail='+travellerEmail+'&userid='+userId, {  });

}
  revalidateHotel(hotel: HotelResult): Observable<any> {
    let hotelSearchQueryParam: HotelQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
    let searchQuery: HotelSearchRequest = this.getSearchRequest(hotelSearchQueryParam);
    let data = { hotelSearchRequest: searchQuery, hotel: hotel };
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      .set('rate_id', hotel.rateId)
      .set('price', '' + hotel.price)
      // .set('hotel_room_code','')
      .set('hotel_code', hotel.hotelCode)
      .set('hotel_chain', hotel.hotelChainCode)
      .set('check_in_date', searchQuery.checkInDate)
      .set('check_out_date', searchQuery.checkOutDate)
      .set('currency', hotel.currency)
      .set('travellerData', JSON.stringify(searchQuery.travellerRooms))
      .set('optimumPrice', this.filterService.originalHotelSearchResponse.optimumPrice)
      .set('inPolicy', '' + hotel.withinPolicy)
      ;
    return this.http.post(environment.apiRevalidateHotelURL, data, { params });
  }

  fetchRoomRulesAndRateDesc(hotel: HotelResult, ratePlayType: string, hotelcode, hotelchain,is_corporate_rate, price, policyStatus): Observable<any> {
    let hotelSearchQueryParam: HotelQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
    let searchQuery: HotelSearchRequest = this.getSearchRequest(hotelSearchQueryParam);
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('rate_plan_type', ratePlayType)
      .set('price', '' + price)
      .set('is_within_policy', policyStatus)
      .set('hotel_code', hotelcode)
      .set('hotel_chain', hotelchain)
      .set('is_corporate_rate',is_corporate_rate)
      .set('check_in_date', searchQuery.checkInDate)
      .set('check_out_date', searchQuery.checkOutDate)
      .set('currency', hotel.currency);
    return this.http.get(environment.apiroomRulesAndRateDescURL, { params });
  }
  destination:any;
  searchMoreHotel(hotelname): Observable<any> {
    let hotelSearchQueryParam: HotelQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
    let searchQuery: HotelSearchRequest = this.getSearchRequest(hotelSearchQueryParam);
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    let travellerEmail;
    for(let i=0;i<this.searchService.employeeEmail.length;i++){
      if(travellerEmail ){
         travellerEmail = travellerEmail +','+ this.searchService.employeeEmail[i].email;
      }else{
        travellerEmail = this.searchService.employeeEmail[i].email;
      }
    }
    let destinationObject={};
    destinationObject['destination'] = this.destination;
    return this.http.get(environment.apiForFindingMoreHotel +'?query='+hotelname+'&destination='+encodeURIComponent(JSON.stringify(this.destination))+'&travellerEmail='+travellerEmail +'&userid='+userId);
  }
  getSearchRequest(hotelSearchQueryParam: HotelQueryParam): HotelSearchRequest {
    let searchRequest: HotelSearchRequest = new HotelSearchRequest();
    searchRequest.destination = hotelSearchQueryParam.hotelAddress;
    searchRequest.currentLocation = hotelSearchQueryParam.currentLocation;
    searchRequest.totalTravellers = ''+hotelSearchQueryParam.travellerEmail.length;
    if (hotelSearchQueryParam.travellerEmail && hotelSearchQueryParam.travellerEmail.length > 0) {
      searchRequest.travellerEmail = hotelSearchQueryParam.travellerEmail;
    }
    if (hotelSearchQueryParam.travellerEmail && hotelSearchQueryParam.travellerEmail.length > 0) {
      if (!this.searchService.multiTripBooking && this.searchService.employeeEmail.length !== hotelSearchQueryParam.travellerEmail.length) {
        this.searchService.employeeEmail = [];
          this.searchService.employeeEmail = [];
          for (let i = 0; i < hotelSearchQueryParam.travellerEmail.length; i++) {
            let employee1 = { Name: '', Id: -1, Type: '' };
            employee1['email'] = hotelSearchQueryParam.travellerEmail[i];
            this.searchService.employeeEmail.push(employee1);
          }
        
       
      } else if (this.searchService.employeeEmail.length === 0) {
        this.searchService.employeeEmail = [];
        let employee1 = { Name: '', Id: -1, Type: '' };
        employee1['email'] = hotelSearchQueryParam.travellerEmail;
        this.searchService.employeeEmail.push(employee1)
      }
    }
    searchRequest.hotelChain = hotelSearchQueryParam.hotelChain;
    searchRequest.checkInDate = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(hotelSearchQueryParam.checkinDate, 'yyyy-MM-dd');
    searchRequest.checkOutDate = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(hotelSearchQueryParam.checkoutDate, 'yyyy-MM-dd');
    searchRequest.travellerRooms = [];
    let totalRoomCount = Number.parseInt(hotelSearchQueryParam.roomCount);
    let totalTravellerCount = Number.parseInt(hotelSearchQueryParam.personCount);
    for (let i = 1; i <= totalRoomCount; i++) {
      if (i == 1 && totalTravellerCount > totalRoomCount) {
        searchRequest.travellerRooms.push({ 'numberOfChildren': 0, 'numberOfTraveller': (totalTravellerCount - totalRoomCount + 1), 'childrenAges': [], 'room': i });
      } else {
        searchRequest.travellerRooms.push({ 'numberOfChildren': 0, 'numberOfTraveller': 1, 'childrenAges': [], 'room': i });
      }

    }
    return searchRequest;
  }


  getDateString(searchDateObj: any): string {
    return searchDateObj.year + "-" + (searchDateObj.month) + "-" + searchDateObj.day + "T0";
  }
  sortResultsByPrice(flightSearchResponse: HotelSearchResponse) {
    flightSearchResponse.hotelsList = flightSearchResponse.hotelsList.sort(function (a, b) {
      let resortFee = a.hotelInfo.hotelRateList[0].resortFee ? Number.parseFloat(a.hotelInfo.hotelRateList[0].resortFee.substring(3)) : null;
      let resortFee1 = b.hotelInfo.hotelRateList[0].resortFee ? Number.parseFloat(b.hotelInfo.hotelRateList[0].resortFee.substring(3)) : null;
      let aPrice = (a.price + resortFee);
      let bPrice = (b.price + resortFee1);
      if (aPrice < bPrice) return -1;
      else if (aPrice > bPrice) return 1;
      else return 0;
    });
    this.setHotelSearchResponse(flightSearchResponse, false);
  }

  // sortResultsByDeparture(flightSearchResponse:FlightSearchResponse){
  //   flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function(a,b){
  //       if (a.legs[0].flightHops[0].starts < b.legs[0].flightHops[0].starts) return -1;
  //       else if (a.legs[0].flightHops[0].starts > b.legs[0].flightHops[0].starts) return 1;
  //       else return 0;
  //   });
  // this.setFlightSearchResponse(flightSearchResponse, false);
  // }

  // sortResultsByArival(flightSearchResponse:FlightSearchResponse){
  //   flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function(a,b){
  //       if (a.legs[0].flightHops[a.legs[0].flightHops.length - 1].ends < b.legs[0].flightHops[b.legs[0].flightHops.length - 1].ends) return -1;
  //       else if (a.legs[0].flightHops[a.legs[0].flightHops.length - 1].ends > b.legs[0].flightHops[b.legs[0].flightHops.length - 1].ends) return 1;
  //       else return 0;
  //   });
  // this.setFlightSearchResponse(flightSearchResponse, false);
  // }

  sortResultsByDuration(hotelSearchResponse: HotelSearchResponse) {
    hotelSearchResponse.hotelsList = hotelSearchResponse.hotelsList.sort(function (a, b) {
      if (a.distanceMile < b.distanceMile) return -1;
      else if (a.distanceMile > b.distanceMile) return 1;
      else return 0;
    });
    this.setHotelSearchResponse(hotelSearchResponse, false);
  }

  public applySorting(hotelSearchResponse, currentSortOptionId) {
    setTimeout(() => {
      if (currentSortOptionId === 'price') {
        this.sortResultsByPrice(hotelSearchResponse);
      } else if (currentSortOptionId === 'distance') {
        this.sortResultsByDuration(hotelSearchResponse);
      } else if (currentSortOptionId === 'recommended') {
        this.sortResultsAsRecommended(hotelSearchResponse);
      }
    }, 200);

  }

  sortResultsAsRecommended(hotelSearchResponse: HotelSearchResponse) {
    hotelSearchResponse.hotelsList = hotelSearchResponse.hotelsList.sort(function (a, b) {
      // if (a.scores.overAll < b.scores.overAll) return -1;
      // else if (a.scores.overAll > b.scores.overAll) return 1;
      // else {
      if (a.recommendedIdx < b.recommendedIdx) return -1;
      else if (a.recommendedIdx > b.recommendedIdx) return 1;
      else return 0;
      // }
    });
    this.setHotelSearchResponse(hotelSearchResponse, false);
  }

  updateHotelChainFilterOptions(hotelSearchResponse: HotelSearchResponse) {
    if (hotelSearchResponse && hotelSearchResponse.hotelsList.length > 0) {
      this.hotelChainFilterOptions = [];
      let tempHotelChainCodes = [];
      for (let hotel of hotelSearchResponse.hotelsList) {
        if (tempHotelChainCodes.indexOf(hotel.hotelChainCode) == -1) {
          let hotelChainObj = { 'id': '', 'value': '' };
          hotelChainObj.id = '' + hotel.hotelChainCode;
          hotelChainObj.value = '' + hotel.hotelChainName;
          tempHotelChainCodes.push(hotel.hotelChainCode);
          this.hotelChainFilterOptions.push(hotelChainObj);
        }
      }
    }
  }

  requestHotelImage(hotelResult: HotelResult): Observable<any> {
    const params = new HttpParams()
      .set('hotel_code', hotelResult.hotelCode)
      .set('hotel_chain', hotelResult.hotelChainCode)
      // .set('revalidateTestCase',"PRICE_CHANGED")
      ;
    return this.http.get(environment.hotelImageAPI, { params });
  }

  requestAllHotelImage(imageResuestObj: ImageRequest[]): Observable<any> {

    const params = new HttpParams();
    return this.http.post(environment.hotelImageAPI, imageResuestObj, { params });
  }
}
