<!-- <ng-template #hotelModal let-modal>     -->
<div *ngIf="!njoySpecificBuild">
    <div #hotelModal class="modal-body" [ngStyle]="{'height':this.searchService1.searchPopMsg && this.searchService1.searchPopMsg.length > 0 ? '250px':'150px'}">
       
        <div class="row" class="DetailsBoxLoader" style="position: relative;margin-top: 20px;">
          <app-loader
             style="text-align:center;margin-left:  auto !important;margin-right: auto !important;"
             [spinnerStyle]="true"></app-loader>
       </div>
      
       <div  style="text-align: center;margin:auto;margin-top: 30px;">
          {{'result.FetchingyourCarspleasewait' | translate}}
         </div>
         <div *ngIf="this.searchService1.searchPopMsg && this.searchService1.searchPopMsg.length > 0" style="text-align: center;margin:auto;margin-top: 20px;">
          <span class="popMsg"> {{this.searchService1.currentMessage}}</span>
         </div>
       </div>

</div>
<!-- </ng-template>
        
        <div class="modal-button">
            <button (click)="showModal(hotelModal)">Launch Modal</button>
        </div> -->
<div *ngIf="njoySpecificBuild">
    <div #hotelModal class="modal-body" [ngStyle]="{'height':this.searchService1.searchPopMsg && this.searchService1.searchPopMsg.length > 0 ? '250px':'150px'}">
       
        <div class="row" class="DetailsBoxLoader" style="position: relative;">
          <app-loader
             style="text-align:center;margin-left:  auto !important;margin-right: auto !important;"
             [spinnerStyle]="true"></app-loader>
       </div>
      
       <div  style="text-align: center;margin:auto;margin-top: 30px;">
          {{'result.FetchingyourCarspleasewait' | translate}}
         </div>
         <div *ngIf="this.searchService1.searchPopMsg && this.searchService1.searchPopMsg.length > 0" style="text-align: center;margin:auto;margin-top: 20px;">
          <span class="popMsg"> {{this.searchService1.currentMessage}}</span>
         </div>
       </div>

</div>