import { JsonObject } from '../util/ta-json/src/decorators/json-object';
import { FlightQueryParam } from './flight-query-param';
import { AddressType } from '../enum/address.type';

@JsonObject()
export class FlightSearchQueryParam {

  source: string;
  destination: string;
  constraint: string;
  departureDate: Date;
  arrivalDate: Date;
  durationDate:any;
  constraintTime: string;
  passengers: any;
  combo:boolean;
  travellerEmails: Array<any>;
  travellerAgeGroup:Array<any>;
  adults: string;
  children: string;
  infants: string;
  class: string;
  flights: FlightQueryParam[];
  trip: string;
  timeTypeFilter: string;
  timeTypeFilter1: string;
  constraintTime1: string;
  classTypeSwitch: string;
  originType: AddressType;
  destinationType: AddressType;
  eventid: any;
}
