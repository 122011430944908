import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators, FormControl, Form, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Constants } from '../util/constants';
import { countries } from '../util/countries';
import { AdminPanelService, Department, CompanyDomain } from '../admin-panel.service';
import { UserInfo } from '../entity/user-info';
import { EmployeeInfo } from '../entity/employee-info';
import { CommonUtils } from '../util/common-utils';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { AddEmployeeDTO } from '../entity/add-employee-dto';
import { UserAccountService } from '../user-account.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { SearchService } from '../search.service';
import { DeviceDetailsService } from '../device-details.service';
import { UserDetails } from '../entity/userDetails';
import { ToastrService } from 'ngx-toastr';
import { add } from 'ngx-bootstrap/chronos/public_api';
import { threadId } from 'worker_threads';
import { TruncateCharactersPipe } from 'ng2-truncate';
import { TranslateService } from '@ngx-translate/core';
declare var setFocusOnInputField: any;
@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit, OnDestroy {
  @Input() mode: string;
  isMobile: boolean;
  showSpinner = false;
  domainWrong = false;
  search = 'Type to search';
  dropDownopen = false;
  dropDownopen1=false;
  showRefreshButton = false;
  show = true;
  multipleSelectedApprover = [];
  approveerSavedAlready = [];
  emailNotificationList=[];
  bookingEmailsSentTo=[];
  emailDropdown= Constants.emailDropdown;
  switchDrodpdown=Constants.switchDropdown;
  disabledInput = false;
  bsModalRef: BsModalRef;
  bsApproveModelRef: BsModalRef;
  employeeForm: FormGroup;
  maxDoBDate: Date = new Date();
  countries = [];
  employeeHasChangedPolicy = false;
  employeeHasOwnPolicy = 0;
  showPolicyRefreshButton = false;
  domainOptions = [];
  appproverList = [];
  departmentOptions = [{ value: 'Select', id: '', name: "employee.Select" }];
  policyOptions = [{ value: 'Select', id: '', name: "employee.Select" }];
  managerOptions = [{ value: 'Select', id: '', }];

  addEmployeeResponseSubscription: Subscription;
  responseErrorSubscription: Subscription;
  deviceSubscription: Subscription;
  checkEmployeeDetailResponseSubscription: Subscription;
  listEmployeesResponseSubscription: Subscription;

  emailForm: FormGroup;
  toUpdateManagerInfo: UserInfo;
  approverDetailText: string;
  userRoleOptions = [{ value: 'EMPLOYEE', id: 'EMPLOYEE', name: 'Traveler' },
  { value: 'TRAVEL_MANAGER', id: 'TRAVEL_MANAGER', name: 'Travel Manager' },
  { value: 'ADMIN', id: 'ADMIN', name: 'Admin' }
  ];
  dummyModel: any;
  constructor(private modalService: BsModalService,
    private adminPanelService: AdminPanelService,
    private userAccountService: UserAccountService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private searchService: SearchService,
    private bsModelLoginRef: BsModalRef,
    private toastr: ToastrService,
    private deviceDetailsService: DeviceDetailsService, public translateService: TranslateService) {
    this.countries = countries;
  }
  changeBookForOther(item){
    if(this.employeeForm.controls['userRole'].value === 'TRAVEL_MANAGER' || this.employeeForm.controls['userRole'].value==='ADMIN'){
      this.toastr.error(this.translateService.instant("employee.Bookforotherscannotbedisabledfortravelmanagerandadminusers"));
        return
    }
  this.employeeForm.controls['allowBookForOthers'].setValue(item);
}
disbaleSwicth(){
  if(this.employeeForm.controls['userRole'].value === 'TRAVEL_MANAGER' || this.employeeForm.controls['userRole'].value==='ADMIN'){
  //  this.toastr.error(this.translateService.instant("employee.Bookforotherscannotbedisabledfortravelmanagerandadminusers"));
      return true;
  }else{
    return false;
  }
}
  departmentSelected(target) {
    this.policyOptions = [];
    //this.getFormControl('policy').setValue(-1);
    this.setDepartmentPolicyList(target.id);

    if (this.mode === 'add') {
      this.multipleSelectedApprover = this.getApproverDetail();
      let departmentId = this.getFormControl('department').value;
      if (departmentId && departmentId > 0) {
        let departmentObj: Department = this.adminPanelService.getDepartments().find(obj => obj.departmentId == departmentId);
        if (departmentObj && departmentObj.defaultPolicyId && departmentObj.defaultPolicyId > 0) {
          let defaultPolicyId = '' + departmentObj.defaultPolicyId;
          this.employeeForm.controls['policy'].setValue(defaultPolicyId);
        }
        if (departmentObj && departmentObj.defaultAgentRole === 'BOOK_FOR_OTHERS') {
          this.employeeForm.controls['allowBookForOthers'].setValue(true);
        } else {
          this.employeeForm.controls['allowBookForOthers'].setValue(false);
        }
      }
      // this.employeeForm.controls['managerEmail'].setValue(this.approverDetailText);
    } else {
      if (this.approveerSavedAlready.length > 0) {
        this.showRefreshButton = true;
      } else {
        this.multipleSelectedApprover = this.getApproverDetail();
      }
      if (this.employeeHasOwnPolicy !== 0) {
        this.showPolicyRefreshButton = true;
        this.getFormControl('policy').setValue(this.employeeHasOwnPolicy);
        console.log("policies ", this.employeeForm.controls['policy'].value, this.policyOptions)
      } else {
        let departmentId = this.getFormControl('department').value;
        if (departmentId && departmentId > 0) {
          let departmentObj: Department = this.adminPanelService.getDepartments().find(obj => obj.departmentId == departmentId);
          if (departmentObj && departmentObj.defaultPolicyId && departmentObj.defaultPolicyId > 0) {
            let defaultPolicyId = '' + departmentObj.defaultPolicyId;
            this.employeeForm.controls['policy'].setValue(defaultPolicyId);
          }
        }
      }
    }
  }
  setDepartmentPolicyList(departmentId) {

    // this.adminPanelService.getDepartmentPolicies(selectedDepartment.id).subscribe(resp => {
    //   if (resp.success === true && resp.data) {
    this.policyOptions = [];
    // let departmentPolicyList = JSON.parse(JSON.stringify(resp.data));
    let departmentPolicyList = this.adminPanelService.getDepartmentPolicyList(departmentId);
    for (let policyOption of departmentPolicyList) {
      this.policyOptions.push({ value: policyOption.policyName, id: '' + policyOption.policyId, name: policyOption.policyName });
    }
    //   }
    // });      
  }
  getSelectedPolicyName() {
    let policy = this.getFormControl('policy').value;
    if (!policy || policy == -1 || policy == 0) {
      let departmentId = this.getFormControl('department').value;
      if (departmentId && departmentId > 0) {
        let departmentObj: Department = this.adminPanelService.getDepartments().find(obj => obj.departmentId == departmentId);
        if (departmentObj && departmentObj.defaultPolicyId && departmentObj.defaultPolicyId > 0) {
          return this.adminPanelService.getDepartmentPolicyName(departmentObj.defaultPolicyId);
        } else {
          return this.adminPanelService.getDefaultPolicyName();
        }
      } else {
        return this.adminPanelService.getDefaultPolicyName();
      }
    } else {
      return this.adminPanelService.getDepartmentPolicyName(policy);
    }
  }
  closeDropdown() {
    this.dropDownopen = false;
  }
  closeDropdown1() {
    this.dropDownopen1 = false;
  }
  filterSelectedlist(item) {
    for (let option of item) {
      this.appproverList = this.appproverList.filter(item2 => item2.email !== option);
    }
    for (let option of item) {
      let originalList;
      if (this.adminPanelService.employeeList && this.adminPanelService.employeeList.length > 0) {
        let employeeFilterList = this.adminPanelService.employeeList;
        originalList = this.sortList(employeeFilterList);
      }
      if (originalList && originalList.length > 0) {
        let approver = originalList.find(item3 => item3.email === option)
        this.appproverList.unshift(approver);
      if(this.appproverList.length > 0){
        this.appproverList = this.appproverList.filter(item=> item.employeeInfo.limitedUser!==true)
      }
      }
    }
  }
  onPolicyChangeClicked() {
    this.employeeHasChangedPolicy = true;
  }
  refreshApprover() {
    this.approveerSavedAlready = [];
    this.multipleSelectedApprover = this.getApproverDetail();
    this.showRefreshButton = false;
  }
  refreshpolicy() {
    this.showPolicyRefreshButton = false;
    this.employeeHasOwnPolicy = 0;
    let departmentId = this.getFormControl('department').value;
    if (departmentId && departmentId > 0) {
      let departmentObj: Department = this.adminPanelService.getDepartments().find(obj => obj.departmentId == departmentId);
      if (departmentObj && departmentObj.defaultPolicyId && departmentObj.defaultPolicyId > 0) {
        let defaultPolicyId = '' + departmentObj.defaultPolicyId;
        this.employeeForm.controls['policy'].setValue(defaultPolicyId);
      }
    }
  }
  getApproverDetail() {
    let toAddEmployeeUserInfo: UserInfo = this.adminPanelService.getAddEmployeeUserInfo();
    let departmentId = this.getFormControl('department').value;
    let apiDepartmentList: Array<Department> = this.adminPanelService.getDepartments();
    //  if(this.getFormControl('managerEmail').value!==''){

    if (!(departmentId && departmentId >= 0)) {
      departmentId = -1;
    }
    let managerName = '';
    if (toAddEmployeeUserInfo.employeeInfo && toAddEmployeeUserInfo.employeeInfo.managerName) {
      managerName = ""
    }
    return CommonUtils.getApproverDetail(toAddEmployeeUserInfo, this.adminPanelService, departmentId,
      '', managerName);

    // let managerEmail = this.getFormControl('managerEmail').value;
    // let employeeInfo : EmployeeInfo = toAddEmployeeUserInfo.employeeInfo;
    // if(employeeInfo && managerEmail && managerEmail.trim().length > 0 ){
    //   if (managerEmail !== toAddEmployeeUserInfo.email){
    //     return employeeInfo.managerName + " (" + managerEmail + ")";
    //   }
    // }else{
    //   let departmentId = this.getFormControl('department').value;
    //   if(departmentId && departmentId >= 0){
    //     let defaultManager : UserDetails = this.adminPanelService.getDefaultAdminDetail(departmentId);
    //     if (defaultManager.emailId !== toAddEmployeeUserInfo.email){
    //       return defaultManager.firstName +" "+ defaultManager.lastName + " (" + defaultManager.emailId + ")";
    //     }
    //   }
    // }      
    // let defaultManager : UserDetails = this.adminPanelService.getDefaultAdminDetail(-1);
    // return defaultManager.firstName +" "+ defaultManager.lastName + " (" + defaultManager.emailId + ")";
  }

  ngOnInit() {
    this.subscribeEvents();
    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    // this.getDomainOptions();
    this.domainOptions = [];
    let apiDomainList: Array<CompanyDomain> = this.adminPanelService.getDomains();
    for (let domainItem of apiDomainList) {
      this.domainOptions.push({ value: domainItem.domain, id: '' + domainItem.domainId });
    }
    this.departmentOptions = [{ value: 'Select', id: '', name: 'employee.Select' }];
    let apiDepartmentList: Array<Department> = this.adminPanelService.getDepartments();
    for (let department of apiDepartmentList) {
      this.departmentOptions.push({ value: department.name, id: '' + department.departmentId, name: department.name });
    }

    this.managerOptions = [{ value: 'Select', id: '' }];
    let managerList = this.adminPanelService.getManagersList();
    for (let manager of managerList) {
      let managerValue = manager.firstName + " " + manager.lastName;
      this.managerOptions.push({ value: managerValue, id: manager.email });
    }

    let toAddEmployeeUserInfo: UserInfo = this.adminPanelService.getAddEmployeeUserInfo();
    let employeeInfo: EmployeeInfo = toAddEmployeeUserInfo.employeeInfo;
    let departmentSelect = (this.adminPanelService.departmentSelect !== '') ? '' + this.adminPanelService.departmentSelect : '-1';
    if (departmentSelect === '-1') {
      departmentSelect = (employeeInfo && employeeInfo.departmentId) ? '' + employeeInfo.departmentId : '-1';
    }
    var getDept = this.departmentOptions.find(item => item.id === departmentSelect)
    if (departmentSelect !== '-1' && !getDept) {
      departmentSelect = '-1';
    }
    if (departmentSelect === '-1' && this.departmentOptions.length === 2) {
      departmentSelect = this.departmentOptions[1].id;
    }
    let userEmailName = toAddEmployeeUserInfo.email ? toAddEmployeeUserInfo.email : '';
    this.employeeForm = this.fb.group({
      firstName: [toAddEmployeeUserInfo.firstName ? toAddEmployeeUserInfo.firstName.trim() : '', [Validators.pattern(Constants.RGEX_ONLY_ALPHA_AND_SPACE)]],
      middleName: [toAddEmployeeUserInfo.middleName ? toAddEmployeeUserInfo.middleName.trim() : ''],
      lastName: [toAddEmployeeUserInfo.lastName ? toAddEmployeeUserInfo.lastName.trim() : '', [Validators.pattern(Constants.RGEX_ONLY_ALPHA_AND_SPACE)]],
      gender: [toAddEmployeeUserInfo.gender ? toAddEmployeeUserInfo.gender : null],
      dateOfBirth: [toAddEmployeeUserInfo.dob && CommonUtils.isValidDOBDate(toAddEmployeeUserInfo.dob) ? toAddEmployeeUserInfo.dob : null],
      email: [userEmailName, Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_EMAIL)])],
      employeeIdentifier: [employeeInfo && employeeInfo.employeeIdentifier         ? employeeInfo.employeeIdentifier : null, [Validators.pattern(Constants.RGEX_POLICYNAME)]],
      phoneNumber: [toAddEmployeeUserInfo.phoneNumber && toAddEmployeeUserInfo.phoneNumber.number ? toAddEmployeeUserInfo.phoneNumber.number.trim() : ""],
      jobTitle: [employeeInfo && employeeInfo.jobTitle ? employeeInfo.jobTitle.trim() : ''],
      // managerName: [employeeInfo && employeeInfo.managerName ? employeeInfo.managerName.trim() : ''],
      department: [(departmentSelect !== '-1') ? '' + departmentSelect : '', Validators.compose([Validators.required])],
      policy: [employeeInfo && employeeInfo.policyId ? '' + employeeInfo.policyId : '-1'],
      dialCode: ['+1'],
      showGovtRates: [employeeInfo && employeeInfo.showGovtRates ? employeeInfo.showGovtRates : false],
      showAAARates: [employeeInfo && employeeInfo.showAAARates ? employeeInfo.showAAARates : false],
      limitedUser: [employeeInfo && employeeInfo.limitedUser ? employeeInfo.limitedUser : false],
      companyCardAccess: [employeeInfo && employeeInfo.companyCardAccess === false ? false : true],
      allowedCardAccess: [employeeInfo ? employeeInfo.allowReimburse : this.allowedReimburse()],
      allowBookForOthers: [employeeInfo && employeeInfo.agentRole !== "BOOK_FOR_OTHERS" ? false : true],
      userRole: [employeeInfo ? employeeInfo.role : 'EMPLOYEE'],
      guestUserDepartmentId:[(employeeInfo && employeeInfo.userSetting.guestUserDepartmentId ? employeeInfo.userSetting.guestUserDepartmentId: null)],
      bookingEmailConfig:[(employeeInfo && employeeInfo.userSetting.bookingEmailConfig ? employeeInfo.userSetting.bookingEmailConfig: 'DEFAULT')],
      minorBookingEnabled:[(employeeInfo && employeeInfo.userSetting.minorBookingEnabled ? employeeInfo.userSetting.minorBookingEnabled:'DEFAULT')],
      saveGuestUsers:[(employeeInfo && employeeInfo.userSetting.saveGuestUsers ? employeeInfo.userSetting.saveGuestUsers:'DEFAULT')],
      notificationEmail: [null, Validators.compose([Validators.pattern(Constants.RGEX_EMAIL)])]
    });
    //    this.employeeForm.controls['userRole'].setValue(employeeInfo?employeeInfo.role:'');
    if (employeeInfo && employeeInfo.userSetting.bookingEmailCustomId && employeeInfo.userSetting.bookingEmailCustomId.length > 0) {
      this.emailNotificationList = employeeInfo.userSetting.bookingEmailCustomId.split(',');

    }else{
      this.emailNotificationList=[];
    }
    if (employeeInfo && employeeInfo.userSetting.bookingEmailConfig && employeeInfo.userSetting.bookingEmailConfig[0] !=='DEFAULT') {
      this.bookingEmailsSentTo = employeeInfo.userSetting.bookingEmailConfig;

    }else{
      this.bookingEmailsSentTo=[];
    }
    if(this.emailNotificationList.length===0 && this.isCustomSelectedInEmailSendNotification()){
      this.employeeForm.controls['notificationEmail'].setValidators([Validators.required,Validators.pattern(Constants.RGEX_EMAIL)]);
      this.employeeForm.controls['notificationEmail'].updateValueAndValidity(); 
    }
    if (employeeInfo && employeeInfo.approvers && employeeInfo.approvers.length > 0) {
      this.approveerSavedAlready = [...employeeInfo.approvers];
      this.multipleSelectedApprover = [...employeeInfo.approvers];
    } else {
      this.multipleSelectedApprover = this.getApproverDetail();
    }
    if (employeeInfo && employeeInfo.policyId !== -1) {
      this.employeeHasOwnPolicy = employeeInfo.policyId;
    } else {
      let departmentId = this.getFormControl('department').value;
      if (departmentId && departmentId > 0) {
        let departmentObj: Department = this.adminPanelService.getDepartments().find(obj => obj.departmentId == departmentId);
        if (departmentObj && departmentObj.defaultPolicyId && departmentObj.defaultPolicyId > 0) {
          let defaultPolicyId = '' + departmentObj.defaultPolicyId;
          this.employeeForm.controls['policy'].setValue(defaultPolicyId);
        }
        if (this.mode === 'add') {
          if (departmentObj && departmentObj.defaultAgentRole === 'BOOK_FOR_OTHERS') {
            this.employeeForm.controls['allowBookForOthers'].setValue(true);
          } else {
            this.employeeForm.controls['allowBookForOthers'].setValue(false);
          }
        }
      }
    }
    if (departmentSelect !== '-1') {
      this.setDepartmentPolicyList(departmentSelect);
    } else {
      this.setDepartmentPolicyList('-1');
    }
    if (this.checkboxDisable()) {
      this.userRoleOptions = [{ value: 'ADMIN', id: 'ADMIN', name: 'Admin' }];
    }
  }
  setFocusOnInput(id){
    setFocusOnInputField(id, '');
  }
  ngOnDestroy() {
    this.unSubscribeEvents()
  }
  onEmailSendNotificationChangeClicked(item, event) {
    if (event.target.checked) {
      if (this.bookingEmailsSentTo.indexOf(item) === -1) {
        this.bookingEmailsSentTo.push(item);
      }

    } else {
      this.bookingEmailsSentTo = this.bookingEmailsSentTo.filter(item1 => item1 !== item);
      this.isApproverChecked(item);
    }
    if(this.isCustomSelectedInEmailSendNotification()){
      this.employeeForm.controls['notificationEmail'].setValidators([Validators.required,Validators.pattern(Constants.RGEX_EMAIL)]);
      this.employeeForm.controls['notificationEmail'].updateValueAndValidity(); 
    }else{
      this.emailNotificationList=[];
      this.employeeForm.controls['notificationEmail'].clearValidators();
      this.employeeForm.controls['notificationEmail'].setValidators(Validators.pattern(Constants.RGEX_EMAIL)); 
      this.employeeForm.controls['notificationEmail'].updateValueAndValidity();
this.employeeForm.controls['notificationEmail'].setValue(null);
    }
  }
  getEmailNotificationLabel(item1){
    let label = this.emailDropdown.filter(item => item.id===item1);
    if(label && label[0]){
      return label[0].value;
    }

  }
  inputEmailValue(value){
    let val=  value.replace(',', '');
    this.employeeForm.controls['notificationEmail'].setValue(val);
   }
  isEmailSendNotificationChecked(item) {
    return this.bookingEmailsSentTo.indexOf(item) > -1;
  }
  setBFO(event){
    if(!event){
      this.emailNotificationList=[];
      this.employeeForm.controls['bookingEmailConfig'].setValue(['DEFAULT']);
      this.employeeForm.controls['saveGuestUsers'].setValue('DEFAULT');
      this.employeeForm.controls['guestUserDepartmentId'].setValue(null);
      this.employeeForm.controls['minorBookingEnabled'].setValue('DEFAULT');
    }
  }
  addEmailList(){
    if(this.employeeForm.controls['notificationEmail'].value !==null && this.employeeForm.controls['notificationEmail'].value !=='' && this.employeeForm.controls['notificationEmail'].valid){
this.emailNotificationList.push(this.employeeForm.controls['notificationEmail'].value);
this.employeeForm.controls['notificationEmail'].clearValidators();
this.employeeForm.controls['notificationEmail'].setValidators(Validators.pattern(Constants.RGEX_EMAIL));
this.employeeForm.controls['notificationEmail'].updateValueAndValidity();
this.employeeForm.controls['notificationEmail'].setValue(null);
    }
  }
  unselectedEmployee(index) {
   
    this.emailNotificationList.splice(index, 1);
  }
  isCustomSelectedInEmailSendNotification(){
   return this.bookingEmailsSentTo.indexOf('CUSTOM') > -1
  }
  allowedReimburse() {
    if (this.adminPanelService.companySettings && this.adminPanelService.companySettings.company.allowReimburse) {
      return true;
    } else {
      return false;
    }
  }
  disableEnterKey(event: KeyboardEvent) {
    event.preventDefault();  // This prevents the form submission or any default behavior.
  }
  allowbookForOthers(employeeInfo) {
    let departmentId = this.getFormControl('department').value;
    if (this.mode === 'add') {
      if (departmentId && departmentId > 0) {
        let departmentObj: Department = this.adminPanelService.getDepartments().find(obj => obj.departmentId == departmentId);
        if (departmentObj && departmentObj.defaultAgentRole === 'BOOK_FOR_OTHERS') {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (employeeInfo.agentRole && employeeInfo.agentRole === 'BOOK_FOR_OTHERS') {
        return true;
      } else {
        return false;
      }
    }
  }
  subscribeEvents() {
    this.responseErrorSubscription = this.adminPanelService.responseErrorSubject$.subscribe(error => {
      if (error) {
        // window.alert(error);
        this.addEmployeeReqInProgress = false;
      }
    });

    this.addEmployeeResponseSubscription = this.adminPanelService.employeeAddResponseObservable$.subscribe(response => {
      this.addEmployeeReqInProgress = false;
      if (response) {
        if (this.bsModelLoginRef) this.bsModelLoginRef.hide();
        this.adminPanelService.fetchEmployeesList(this.userAccountService.getUserCompanyId());
      }
    });
    this.listEmployeesResponseSubscription = this.adminPanelService.employeeListResponseObservable$.subscribe(response => {
      if (response) {
        if (this.bsModalRef) this.bsModalRef.hide();
        if (this.adminPanelService.employeeList && this.adminPanelService.employeeList.length > 0) {
          let employeeFilterList = this.adminPanelService.employeeList;
          this.appproverList = JSON.parse(JSON.stringify(this.sortList(employeeFilterList)));
      if(this.appproverList.length > 0){
        this.appproverList = this.appproverList.filter(item=> item.employeeInfo.limitedUser!==true)
      }
        }
      }
    });
    this.checkEmployeeDetailResponseSubscription = this.adminPanelService.employeeCheckDetailResponseObservable$.subscribe(response => {
      if (response && response.flowType) {
        if (response.flowType === 'changeApprover') {
          this.toUpdateManagerInfo = response.userInfo;
          if (this.toUpdateManagerInfo.firstName) {
            this.emailForm.controls['firstName'].setValue(this.toUpdateManagerInfo.firstName);
          } else {
            this.emailForm.controls['firstName'].setValue('');
          }
          if (this.toUpdateManagerInfo.lastName) {
            this.emailForm.controls['lastName'].setValue(this.toUpdateManagerInfo.lastName);
            this.disabledInput = true;
          } else {
            this.emailForm.controls['lastName'].setValue('');
          }

        }
      }
    });
  }
  changeStyle(item) {
    if (item === 'edit') {
      return { 'color': 'gray', 'border-color': 'rgb(231, 230, 228)' };
    } else {
      return { 'color': '#000000', 'border-color': '#8A27F7' }
    }
  }
  sortList(data) {
    data.sort(function (a, b) {
      // console.log("employe name",a.firstName,b.firstName);
      if (a.firstName === "" || !a.firstName) {
        return 1;
      } else if (b.firstName === "" || !b.firstName) {
        return -1;
      } else if (a.firstName < b.firstName) { return -1; }
      else if (a.firstName > b.firstName) { return 1; }
      return 0;
    })
    return data;
  }
  isDisable() {
    if ((this.emailForm.controls['firstName'].value === 'FirstName' || this.emailForm.controls['firstName'].value === '') && (this.emailForm.controls['lastName'].value === 'LastName' || this.emailForm.controls['lastName'].value === '')) {
      return null;
    } else if ((this.emailForm.controls['firstName'].value !== '' && this.emailForm.controls['lastName'].value !== '') && this.disabledInput) {
      return true;
    }
  }
  unSubscribeEvents() {
    this.responseErrorSubscription.unsubscribe();
    this.addEmployeeResponseSubscription.unsubscribe();
    this.listEmployeesResponseSubscription.unsubscribe();
  }
  searchByApproverNameAndEmailChanged(term: string, item: any) {
    term = term.toLowerCase();
    return (item.firstName && item.firstName.toLowerCase().indexOf(term) > -1) ||
      (item.lastName && item.lastName.toLowerCase().indexOf(term) > -1) || item.email.toLowerCase().indexOf(term) > -1;
  }
  onModelCancel() {
    this.bsModelLoginRef.hide();
  }
  checkboxDisable() {
    if (this.mode === 'edit') {
      if (this.userAccountService.getUserEmail() === this.employeeForm.controls['email'].value) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  private conditionalValidators(condition, validators: ValidatorFn[]) {
    if (condition) {
      return Validators.compose(validators);
    }
    return null;
  }
  setGender(value: string) {
    this.employeeForm.get('gender').setValue(value);
  }

  isSelectedGender(value: string): boolean {
    let val = this.employeeForm.get('gender').value;
    return (val == value);
  }
  preventEntry(event) {
  }
  onHidePicker() {
    this.searchService.calendarOpenEventListsner.next(null);
  }
  onShowPicker(event, picker) {
    this.searchService.calendarOpenEventListsner.next(picker);

    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = (hoverEvent) => {
      const { cell, isHovered } = hoverEvent;

      if ((isHovered &&
        !!navigator.platform &&
        /iPad|iPhone|iPod/.test(navigator.platform)) &&
        'ontouchstart' in window
      ) {
        (picker as any)._datepickerRef.instance.daySelectHandler(cell);
      }

      return dayHoverHandler(hoverEvent);
    };
    event.dayHoverHandler = hoverWrapper;
  }
  getFormControl(name: string) {
    return this.employeeForm.controls[name];
  }
  selectedCountryCode = '';
  onCountrySelected(item) {
    if (item) {
      this.selectedCountryCode = item.code;
    } else {
      this.selectedCountryCode = '';
    }

  }
  searchByNameOrCode(term: string, item) {
    return CommonUtils.searchByNameOrCode(term, item);
  }
  getPhoneNumberMask(inputDIalCode) {
    return CommonUtils.getPhoneNumberMask(inputDIalCode);
  }
  getPhoneNumberPaceHolder(inputDIalCode) {
    return CommonUtils.getPhoneNumberPaceHolder(inputDIalCode);
  }
  getCountryCode(code, phoneNumber) {
    return CommonUtils.getCountryCode(code, phoneNumber, this.selectedCountryCode);
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  addEmployeeReqInProgress = false;
  checkEmailDomain(value) {
    if (value.search('@') !== -1) {
      let [email, domain] = this.employeeForm.controls['email'].value.split('@');
      let findDomain = this.domainOptions.find(item => item.value === domain);
      if (!findDomain) {
        this.domainWrong = false;
      } else {
        this.domainWrong = false;
      }

    }
  }
  onApproverChangeClicked(item, event) {
    console.log("email ", item, event);
    this.adminPanelService.userChangedApprover = true;
    if (event.target.checked) {

      if (this.multipleSelectedApprover.indexOf(item) === -1) {
        this.multipleSelectedApprover.push(item);
      }

    } else {
      this.multipleSelectedApprover = this.multipleSelectedApprover.filter(item1 => item1 !== item);
      this.isApproverChecked(item);
    }
  }
  isApproverChecked(item) {
    return this.multipleSelectedApprover.indexOf(item) > -1;
  }
  addUserAsEmployee() {
    if (this.employeeForm.valid) {
      if (this.domainWrong) {
        this.employeeForm.markAllAsTouched();
        return
      }
      if (!this.employeeForm.controls['allowedCardAccess'].value && !this.employeeForm.controls['companyCardAccess'].value) {
        this.toastr.error("Atleast select one payment method");
        return;
      }
      //if(this.getFormControl('managerEmail').value===''){
      //  let departmentId = this.getFormControl('department').value
      // let apiDepartmentList : Array<Department> = this.adminPanelService.getDepartments();
      // let adminDetail  = apiDepartmentList.find(item=> item.departmentId===(parseInt(departmentId)));
      // if((adminDetail.approver && (Object.keys((adminDetail.approver)).length === 3)) && adminDetail.approver.emailId !==this.getFormControl('email').value){
      // this.employeeForm.controls['managerEmail'].setValue(adminDetail.approver.emailId);
      //   }
      //  }
      if (!this.employeeHasChangedPolicy && this.employeeHasOwnPolicy === 0) {
        this.employeeForm.controls['policy'].setValue('-1');
      }
      let addEmployeeDTO: AddEmployeeDTO = deserialize(this.employeeForm.value, AddEmployeeDTO);
      let toAddEmployeeUserInfo: UserInfo = this.adminPanelService.getAddEmployeeUserInfo();
      if(this.bookingEmailsSentTo && this.bookingEmailsSentTo.length > 0){
        addEmployeeDTO.bookingEmailConfig = this.bookingEmailsSentTo;
      }else{
        addEmployeeDTO.bookingEmailConfig =['DEFAULT']
      }
      if(this.emailNotificationList && this.emailNotificationList.length > 0){
        addEmployeeDTO.bookingEmailCustomId = this.emailNotificationList.toString();
      }else{
        addEmployeeDTO.bookingEmailCustomId =null;
      }
      addEmployeeDTO.saveGuestUsers= this.employeeForm.controls['saveGuestUsers'].value;
      addEmployeeDTO.guestUserDepartmentId= this.employeeForm.controls['guestUserDepartmentId'].value;
      addEmployeeDTO.minorBookingEnabled= this.employeeForm.controls['minorBookingEnabled'].value;
      if (toAddEmployeeUserInfo.employeeInfo && toAddEmployeeUserInfo.employeeInfo.managerName) addEmployeeDTO.managerName = toAddEmployeeUserInfo.employeeInfo.managerName;
      addEmployeeDTO.dateOfBirth = addEmployeeDTO.dateOfBirth ? this.datePipe.transform(new Date(addEmployeeDTO.dateOfBirth), 'MM/dd/yyyy') : null;
      // addEmployeeDTO.managerEmail =  this.datePipe.transform(new Date(addEmployeeDTO.dateOfBirth),'MM/dd/yyyy');
      if (this.adminPanelService.userChangedApprover || this.approveerSavedAlready.length > 0) {
        if (this.multipleSelectedApprover.length === 1 && this.multipleSelectedApprover[0] === this.getValidFieldValue('email').toLowerCase()) {
          this.toastr.error("Approver cannot be the same as employee. Please choose a different approver.");
          return;
        }
        addEmployeeDTO.approvers = [...this.multipleSelectedApprover];
      } else {
        addEmployeeDTO.approvers = [];
      }
      this.addEmployeeReqInProgress = true;
      this.adminPanelService.addEmployee = true;
      this.adminPanelService.processAddEmployee(this.userAccountService.getUserCompanyId(), addEmployeeDTO, this.mode);



    } else {
      this.validateAllFormFields(this.employeeForm);
    }
  }

  isValidField(controlName: string) {
    let controlValue = this.getValidFieldValue(controlName);
    return controlValue && controlValue.trim().length > 0 ? true : false;
  }


  getValidFieldValue(controlName: string) {
    let controlValue = '';
    let toAddEmployeeUserInfo: UserInfo = this.adminPanelService.getAddEmployeeUserInfo();
    if (controlName === 'firstName') {
      controlValue = toAddEmployeeUserInfo.firstName;
    } else if (controlName === 'middleName') {
      controlValue = toAddEmployeeUserInfo.middleName;
    } else if (controlName === 'lastName') {
      controlValue = toAddEmployeeUserInfo.lastName;
    } else if (controlName === 'gender') {
      controlValue = toAddEmployeeUserInfo.gender;
    } else if (controlName === 'dateOfBirth') {
      controlValue = CommonUtils.isValidDOBDate(toAddEmployeeUserInfo.dob) ? toAddEmployeeUserInfo.dob : '';
    } else if (controlName === 'email') {
      controlValue = toAddEmployeeUserInfo.email;
    } else if (controlName === 'phoneNumber') {
      controlValue = toAddEmployeeUserInfo.phoneNumber && toAddEmployeeUserInfo.phoneNumber.number ? toAddEmployeeUserInfo.phoneNumber.number.trim() : "";
    }
    return controlValue ? controlValue.trim() : '';
  }
  getLabelValue() {
    if (this.dropDownopen) {
      return 'fuild.Typetosearch';
    }
    return '';
  }
  getGenderValue(code: string) {
    if (code === 'M') {
      return 'Male';
    } else if (code === 'F') {
      return 'Female';
    } else if (code === 'O') {
      return 'Other';
    } else {
      return '';
    }

  }

  showLookUpModal(modal) {
    this.disabledInput = false;
    this.emailForm = this.fb.group({
      emailUserName: ['', Validators.compose([Validators.required])],
      domain: [(this.domainOptions && this.domainOptions.length > 0) ? this.domainOptions[0].value : '', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_EMAIL)])],
      firstName: ['FirstName', Validators.compose([Validators.required])],
      lastName: ['LastName', Validators.compose([Validators.required])],
    });
    this.bsApproveModelRef = this.modalService.show(modal);
  }
  onApproveModelCancel() {
    this.showSpinner = false;
    this.bsApproveModelRef.hide();
    this.toUpdateManagerInfo = null;
  }

  getDomainOptions() {
    let domainOptions = [];
    let apiDomainList: Array<CompanyDomain> = this.adminPanelService.getDomains();
    for (let domainItem of apiDomainList) {
      domainOptions.push({ value: domainItem.domain, id: '' + domainItem.domainId });
    }
    return domainOptions;
  }

  checkManagerDetail() {
    if (this.emailForm.valid) {
      this.showSpinner = true;
      let approverEmail = this.emailForm.controls['email'].value;
      if (this.getValidFieldValue('email').toLowerCase() == approverEmail.toLowerCase()) {
        this.showSpinner = false;
        this.toastr.error("Approver cannot be the same as employee. Please choose a different approver. ");
        return;
      }
      this.adminPanelService.processCheckEmployeeDetail(approverEmail,
        this.userAccountService.getUserCompanyId(), 'changeApprover');
      // this.bsApproveModelRef.hide();
    } else {
      this.emailForm.controls['emailUserName'].markAsTouched();
      this.emailForm.controls['email'].markAsTouched();
      this.emailForm.controls['emailUserName'].updateValueAndValidity();
      this.emailForm.controls['email'].updateValueAndValidity();
    }
  }

  emailUserNameChange(userName) {
    let domain = this.emailForm.controls['domain'].value;
    this.emailForm.controls['email'].setValue(userName.trim() + '@' + domain);
  }
  emailDomainChange(domain) {
    let userName = this.emailForm.controls['emailUserName'].value;
    this.emailForm.controls['email'].setValue(userName.trim() + '@' + domain.value);
  }

  showToUpdateManagerDetail() {
    if (this.toUpdateManagerInfo) {
      this.showSpinner = false;
    }
    return this.toUpdateManagerInfo;
  }

  updateManager() {
    if (this.emailForm.valid) {
      let approverEmail = this.emailForm.controls['email'].value;
      //  this.employeeForm.controls['managerEmail'].setValue(this.toUpdateManagerInfo.email);

      let approverName = this.emailForm.controls['firstName'].value + " " + this.emailForm.controls['lastName'].value;
      let toAddEmployeeUserInfo: UserInfo = this.adminPanelService.getAddEmployeeUserInfo();
      if (!toAddEmployeeUserInfo.employeeInfo) {
        toAddEmployeeUserInfo.employeeInfo = new EmployeeInfo();
      }
      let employeeInfo: EmployeeInfo = toAddEmployeeUserInfo.employeeInfo;
      employeeInfo.managerEmail = approverEmail;
      employeeInfo.managerName = approverName;
      this.adminPanelService.setEditEmployeeUserInfo(toAddEmployeeUserInfo);
      this.bsApproveModelRef.hide();
      this.toUpdateManagerInfo = null;
      this.approverDetailText = this.getApproverDetail();
    } else {
      this.emailForm.controls['firstName'].markAsTouched();
      this.emailForm.controls['email'].markAsTouched();
      this.emailForm.controls['lastName'].markAsTouched();
      this.emailForm.controls['email'].updateValueAndValidity();
    }
  }
  roleChange(event) {
    this.employeeForm.controls['userRole'].setValue(event.value);
    if(event.value === 'TRAVEL_MANAGER' || event.value==='ADMIN'){
  this.employeeForm.controls['allowBookForOthers'].setValue(true);

    }
  }
}
