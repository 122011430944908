<header>
    <div class="container">
        <div style="width:100%;">

            <!-- <div *ngIf="isMobile && isLoggedIn()" class="header-right">
                <div>{{userName}}</div>
            </div> -->
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="header-inner">
                    <div class="header-left">
                        <div *ngIf="this.searchService.logoIcon" class="logo">
                            <a *ngIf="!this.bookingService.bookRequestProgress" (click)="goToSearch(content)"
                                style="cursor: pointer !important;">
                                <span class="logo-icon"
                                    [ngStyle]="{background: 'url('+ this.searchService.logoIcon + ')'}"></span>
                            </a>
                            <a *ngIf="this.bookingService.bookRequestProgress" style="cursor: pointer !important;">
                                <span class="logo-icon"></span>
                            </a>
                        </div>
                        <div *ngIf="isAmnetRelease()" style="display:none;color:#8A27F7; font-weight:bold">
                            <ng-container *ngIf="isMobile">
                                <span>{{'header.Call' | translate}} </span><a class="call-amnet-link"
                                    href="tel:1-833-OK-AMNET">1-833-OK-AMNET</a><span> {{'header.forprivatefares' |
                                    translate}}</span>
                            </ng-container>
                            <ng-container *ngIf="!isMobile">
                                {{'header.Call' | translate}} 1-833-OK-AMNET {{'header.forprivatefares' | translate}}
                            </ng-container>
                        </div>
                    </div>

                    <div class="header-right">

                        <div style="display:none;" *ngIf="isLoggedIn()" class="admin">
                            <div class="admin-profile-container" onclick="toggleAdminList(this);">

                                <div class="admin-img">
                                    <img src="{{profileImage}}" alt="" />
                                </div>

                                <div class="admin-name-img">
                                    <div class="admin-name">{{currentUrl}}</div>
                                    <div class="admin-name">{{userName}}</div>
                                    <div *ngIf="isNotProfilePage()" class="user-email">{{userEmail}}</div>
                                    <div *ngIf="isNotProfilePage()" class="gallop-phone">{{userPhoneNumber}}</div>
                                    <div *ngIf="isNotProfilePage() && gallopCash > 0" class="gallop-cash"><span
                                            class="gallop-cash-amount">{{gallopCash | currency}}</span>GallopCash</div>
                                </div>

                            </div>

                            <div class="admin-list">
                                <ul>
                                    <li *ngIf="isNotProfilePage()">
                                        <a data-tab="1" href="/profile" (click)="goToProfile()">
                                            {{'header.Profile' | translate}}
                                        </a>
                                    </li>
                                    <li *ngIf="!isNotProfilePage() || !isNotManageTripsPage()">
                                        <a data-tab="2" href="/search" (click)="goToSearch()">
                                            {{'header.BookTravel' | translate}}
                                        </a>
                                    </li>
                                    <li *ngIf="isNotManageTripsPage()">
                                        <a href="/bookingHistory?type=list" (click)="goToTrips()">{{'header.ManageTrips'
                                            | translate}}</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" (click)="signOut()">
                                            {{'header.Logout' | translate}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div *ngIf="((isNotBookingDetailsPage() || isLoggedIn()) && !this.bookingService.bookRequestProgress)"
                            class="signout">
                            <div class="user-profile-box-container">
                                <div *ngIf="this.searchService.showApprovalaInMenu && !this.isMobile && isNotCompanyApprovalPage()"
                                    class="user-profile-name" style="text-transform: uppercase;font-family: 'apercu-b'"
                                    (click)="navigateToApprovalListCheck(navigateToApprovalList)">
                                    {{'search.PendingApprovals' | translate}}
                                </div>
                                <div *ngIf="isLoggedIn()" class="user-profile-name" attr.data-track="ProfileMenu"
                                    (click)="openDropdown(content)"><span class="userBefore"
                                        [ngStyle]="{'top' : this.userAccountService.showLanguageOptions ?'5px':'5px'}"></span>{{userName}}<span
                                        class="userAfter"
                                        [ngStyle]="{'top' : this.userAccountService.showLanguageOptions ?'5px':'5px'}"></span>
                                </div>
                                <div class="user-profile-box-div" *ngIf="isShownProfile"
                                    (click)="closeHeaderDroddown()">
                                    <div class="user-profile-box">
                                        <div class="box-close" (click)="closeHeaderDroddown()"><i
                                                class="material-icons">close</i></div>
                                        <ul>
                                            <li *ngIf="isNotProfilePage()">
                                                <a class="user-profile-link" data-tab="1"
                                                    attr.data-track="ProfileMenuItem" attr.data-params="item=profile"
                                                    href="/profile" (click)="goToProfile()">
                                                    {{'header.Profile' | translate}}
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="!isNavigationBarVisible() && isUserCompanyManager() && (isNotCompanyAdminPage() && this.userAccountService.initResponseReceived)">
                                                <a class="user-profile-link" data-tab="2" href="{{dashboardUrl()}}"
                                                    attr.data-track="ProfileMenuItem" attr.data-params="item=dashboard"
                                                    (click)="goToCompanyPanel1()">
                                                    {{'header.CompanyDashboard' | translate}}
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="!isNotCardsPage() || !isNotCompanyAdminPage() || !isNotManageTripsPage()|| !isNotProfilePage() || !isNotCompanyApprovalPage()">
                                                <a class="user-profile-link" data-tab="3" href="/search"
                                                    attr.data-track="ProfileMenuItem" attr.data-params="item=book"
                                                    (click)="goToSearch()">
                                                    {{'header.BookTravel' | translate}}
                                                </a>
                                            </li>
                                            <li
                                                *ngIf="this.userAccountService.eventList && this.userAccountService.eventList.length > 0">
                                                <a class="user-profile-link" href="javascript:void(0);"
                                                    (click)="$event.stopPropagation();openEvents()">
                                                    {{'setting.MyInvites' | translate}}
                                                    <svg class="down-arrow" width="15" height="9" viewBox="0 0 15 9"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                                                            fill="#8936F3" />
                                                    </svg>
                                                </a>
                                                <div  class="eventList"  style="display: none;"
                                                    *ngFor="let item of this.userAccountService.eventList">
                                                    <div class="events" (click)="goToEvent(item)">
                                                        <span>{{item.event.groupTravelEventName}}
                                                        </span>
                                                    </div>

                                                </div>
                                            </li>
                                            <li *ngIf="isNotCardsPage() && isuserHascard()">
                                                <a class="user-profile-link" href="/cards" (click)="goToCards()">
                                                    {{'header.MyCards' | translate}}
                                                </a>
                                            </li>
                                            <li *ngIf="isNotManageTripsPage()">
                                                <a class="user-profile-link" href="/bookingHistory?type=list"
                                                    attr.data-track="ProfileMenuItem" attr.data-params="item=trips"
                                                    (click)="goToTrips()">{{'header.ManageTrips' | translate}}
                                                </a>
                                            </li>
                                            <li *ngIf="isNotApprovalRequestPage() && showApprovalRequest()">
                                                <a class="user-profile-link" 
                                                    attr.data-track="ProfileMenuItem" attr.data-params="item=expense-approvals"
                                                    (click)="goToApprovalRequestPage()">Approval Requests
                                                    <i class="fa fa-star" aria-hidden="true" style="padding-left: 20px;color: #cd201f;"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="user-profile-link" attr.data-track="ProfileMenuItem"
                                                    attr.data-params="item=logout" (click)="signOut()">
                                                    {{'header.Logout' | translate}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isLoggedIn()" class="user-profile-img">
                                <img src="{{profileImage}}" style="display:none; width: 100%; height: 100%;" />
                            </div>
                            <ng-container *ngIf="isMobile">
                                <div *ngIf="!isLoggingIn() && !isLoggedIn()" (click)="signin()"><span
                                        class="user-profile-name">{{'header.SignIn' | translate}}</span></div>
                            </ng-container>
                            <ng-container *ngIf="!isMobile">
                                <div *ngIf="!isLoggingIn() && !isLoggedIn()" (click)="signin()"
                                    class="user-profile-name">{{'header.SignIn' | translate}}</div>
                            </ng-container>
                            <div class="inlineblock_m" *ngIf="isLoggingIn() && !isLoggedIn()">{{'header.SigningIn' |
                                translate}}</div>
                            <div class="inlineblock_m" *ngIf="isLoggingIn() && !isLoggedIn()">
                                <loader-dots style="position: relative;left: 2px;"></loader-dots>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="!isNavigationBarVisible() && this.searchService.showApprovalaInMenu && this.isMobile && isNotCompanyApprovalPage()"
                        class="user-profile-name" style="text-transform: uppercase;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    color: #315EF6;
                    cursor: pointer;
                    font-family: 'apercu-b';
                    padding-right: 10px" (click)="navigateToApprovalListCheck(navigateToApprovalList)">
                        {{'search.PendingApprovals' | translate}}
                    </div>
                </div>
                <div *ngIf="this.searchService.selectedEventForBooking && this.searchService.selectedEventForBooking.length > 0" class="eventDetails">
                        <div class="subheading"  *ngIf="this.searchService.selectedEventForBooking[0].inviteDetails.invitedBy">
                               {{this.searchService.selectedEventForBooking[0].inviteDetails.invitedBy}} <span >{{'setting.hasinvitedyoutojoin' | translate}}:</span>
                             </div>
                      <div class="heading" style="margin-bottom: 10px;">
                         <h2>{{this.searchService.selectedEventForBooking[0].event.groupTravelEventName}} </h2> 
                      </div>
                      <div style="margin-bottom: 5px;">
                         <span style="margin-right:5px;"><i class="fa fa-map-marker" aria-hidden="true"></i></span>   {{this.searchService.selectedEventForBooking[0].event.location}} 
                         </div>
                         <div class="eventtimings">
                          <div style="max-width: 200px;min-width: 200px;"> <span class="eventStart">{{'setting.Eventstarts' | translate}}:</span>     {{getDisplayDate(this.searchService.selectedEventForBooking[0].event.start) | date:'MMM dd, yyyy'}} </div>
                         <div *ngIf="this.searchService.selectedEventForBooking[0].event && this.searchService.selectedEventForBooking[0].event.items && this.searchService.selectedEventForBooking[0].event.items.length >0 && this.searchService.selectedEventForBooking[0].event.items[0].suggestedStart" > 
                              <span 
                               class="eventStarttime" >{{'setting.Scheduleflighttoarrive' | translate}}:</span>
                                 <span *ngIf="this.searchService.selectedEventForBooking[0].event && this.searchService.selectedEventForBooking[0].event.items && this.searchService.selectedEventForBooking[0].event.items.length >0 && this.searchService.selectedEventForBooking[0].event.items[0].suggestedStart" >   {{getDisplayTime(this.searchService.selectedEventForBooking[0].event.items[0].suggestedStart)}}  <span *ngIf="isDateSame(this.searchService.selectedEventForBooking[0].event.items[0].suggestedStart,this.searchService.selectedEventForBooking[0].event.start)" > {{'setting.on' | translate}} {{getDisplayDate(this.searchService.selectedEventForBooking[0].event.items[0].suggestedStart) | date:' MMM dd'}}</span></span>
                                </div>
                             </div>
                             <div class="eventtimings1">
                                 <div style="max-width: 200px;min-width: 200px;">   <span class="eventStart">{{'setting.Eventends' | translate}}:</span>     {{getDisplayDate(this.searchService.selectedEventForBooking[0].event.end) | date:'MMM dd, yyyy'}}</div>
                                   <div *ngIf="this.searchService.selectedEventForBooking[0].event && this.searchService.selectedEventForBooking[0].event.items && this.searchService.selectedEventForBooking[0].event.items.length >0 && this.searchService.selectedEventForBooking[0].event.items[0].suggestedEnd" > 
                                       <span 
                                        class="eventStarttime" >{{'setting.Scheduleflighttodepartafter' | translate}}:</span>   <span *ngIf="this.searchService.selectedEventForBooking[0].event && this.searchService.selectedEventForBooking[0].event.items && this.searchService.selectedEventForBooking[0].event.items.length >0 && this.searchService.selectedEventForBooking[0].event.items[0].suggestedEnd" > {{getDisplayTime(this.searchService.selectedEventForBooking[0].event.items[0].suggestedEnd)}}
                                              <span *ngIf="isDateSame(this.searchService.selectedEventForBooking[0].event.items[0].suggestedEnd,this.searchService.selectedEventForBooking[0].event.end)" > {{'setting.on' | translate}}   {{getDisplayDate(this.searchService.selectedEventForBooking[0].event.items[0].suggestedEnd) | date:' MMM dd'}}  </span></span>
                                        </div>
                                      </div>
                                      <hr>
                                      <div *ngIf="!isNotSearchPage()" class="filter-row">
                                    <span> <button *ngIf="!addEvent1Progress" class="text-button link-primary"
                                        (click)="cancelEvent(this.searchService.selectedEventForBooking[0].event.id,'INIVITATION_DECLINED')">{{'setting.Declined' | translate}}</button></span>
                                        
                                            <div style="display: inline-block;padding-left: 20px;width: auto;">
                                                    <span *ngIf="addEvent1Progress" class="loaderCarRental">
                                                            <loader-dots class="loaderAlign"></loader-dots>
                                                    </span></div>
                                        <span> <button *ngIf="!addEvent2Progress" class="text-button link-primary"
                                            (click)="cancelEvent(this.searchService.selectedEventForBooking[0].event.id,'BOOKED_ALTERNATE')">{{'setting.Imdonebooking' | translate}}</button></span>
                                           
                                                <div style="display: inline-block;padding-left: 20px;width: auto;">
                                                        <span *ngIf="addEvent2Progress" class="loaderCarRental">
                                                                <loader-dots class="loaderAlign"></loader-dots>
                                                        </span>
                                                        </div>
                                      </div>

                </div>
                <div *ngIf="this.searchService.multiTripBooking && !isNotSelectionPage()" class="multibookingDetails">
                    <div class="" (click)="backTobooking()" style="padding-top: 3px;">
                        <button class="btn primary-button">Exit adding a {{this.searchService.tripType}}</button>
                    </div>
                </div>
                <div *ngIf="this.bookingService.previousTransactionId" class="rebookingDetails">
                    <div style="padding-left: 5px;padding-bottom: 5px;">

                        <div
                            *ngIf="this.bookingService.previousBooking.type =='flight' && this.bookingService.pageMode!=='WebSearch'">

                            <label style="float: left;padding-left: 12px;margin-top:10px;">
                                {{'bookingHistory.Rebooking' | translate}}
                                <span>
                                    {{getSourceAndDestination(this.bookingService.previousBooking.option.flight_option)}}
                                </span> <span
                                    style="text-transform: lowercase;margin-right: 2px;margin-left: 2px;">{{getTripType(this.bookingService.previousBooking.option.flight_option)}}</span>
                                <span
                                    *ngIf="this.bookingService.previousBooking.option.flight_option.legs.length===1">{{'bookingHistory.flightfor' | translate}}</span>
                                <span
                                    *ngIf="this.bookingService.previousBooking.option.flight_option.legs.length >1">{{'bookingHistory.flightsfor' | translate}}</span>
                                {{this.bookingService.rebookingTravllername}}



                            </label>

                        </div>
                        <div
                            *ngIf="this.bookingService.previousBooking.type =='flight' && this.bookingService.pageMode==='WebSearch'">
                            {{'option.Reviewyouroldandnewitinerary' | translate}}
                        </div>
                        <div *ngIf="this.bookingService.previousBooking.type =='hotel'">
                            <label class="font-bold">
                                {{this.bookingService.previousBooking.option.hotel_option.hotelName}},{{this.bookingService.previousBooking.option.hotel_option.address}}
                                {{'bookingHistory.hotel' | translate}}, {{'bookingHistory.hotelfor' | translate}}
                                {{this.bookingService.rebookingTravllername}}
                            </label>

                        </div>
                        <div *ngIf="this.bookingService.previousBooking.type =='cars'">
                            <label class="font-bold">
                                {{this.bookingService.previousBooking.option.car_option.description}},{{this.bookingService.previousBooking.option.car_option.pickUpLocation}}
                                {{'bookingHistory.carfor' | translate}} {{this.bookingService.rebookingTravllername}}
                            </label>

                        </div>
                    </div>
                    <div class="" (click)="closeRebooking()" style="padding-top: 3px;">
                        <button class="btn primary-button">{{'bookingHistory.ExitRebooking'
                                | translate}}</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</header>
<ng-template #content let-modal>
    <div class="modal-body" [ngStyle]="{'height' : this.isMobile ? '200px':'150px'}">
        <div style="text-align: center;margin:auto;margin-top: 0px;" *ngIf="!this.searchService.selectedEventID">
            <!-- multi trip booking Modal titel-->
            {{'option.YouwilllosethistripifyoumoveawayAreyousureyourwanttoexitthistripbooking?' | translate}}
        </div>
        <div style="text-align: center;margin:auto;margin-top: 0px;" *ngIf="this.searchService.selectedEventID">
                <!-- multi trip booking Modal titel-->
                {{'setting.YouwilllosethistripifyoumoveawayAreyousureyourwanttoexitthisEventbooking?' | translate}}
            </div>
        
        <button (click)="exitMultiAndReBooking()" class="btn btn-normal">
            <span class="add1">{{ 'option.OK' | translate}}</span>
        </button>
        <button (click)="onModelCancel()" class="btn btn-secondary">
            <span class="add">{{ 'employee.Cancel' | translate}}</span>
        </button>

    </div>
</ng-template>

<ng-template #navigateToApprovalList let-modal>
    <div class="modal-body" [ngStyle]="{'height' : this.isMobile ? '200px':'150px'}">

        <div style="text-align: center;margin:auto;margin-top: 0px;" *ngIf="this.searchService.multiTripBooking">
            <!-- multi trip booking Modal titel-->
            {{'option.YouwilllosethistripifyoumoveawayAreyousureyourwanttoexitthistripbooking?' | translate}}
        </div>
        <div style="text-align: center;margin:auto;margin-top: 0px;" *ngIf="this.bookingService.previousTransactionId">
            <!-- rebooking Modal titel-->
            {{'option.YouwilllosethistripifyoumoveawayAreyousureyourwanttoexitthistripbooking?' | translate}}
        </div>
        <div style="text-align: center;margin:auto;margin-top: 0px;" *ngIf="this.searchService.selectedEventID">
                <!-- multi trip booking Modal titel-->
                {{'setting.YouwilllosethistripifyoumoveawayAreyousureyourwanttoexitthisEventbooking?' | translate}}
            </div>
        <button (click)="goToApproval()" class="btn btn-normal">
            <span class="add1">{{ 'option.OK' | translate}}</span>
        </button>
        <button (click)="onModelCancel()" class="btn btn-secondary">
            <span class="add">{{ 'employee.Cancel' | translate}}</span>
        </button>
    </div>
</ng-template>