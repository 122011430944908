import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef, HostListener } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators, FormControl, Form, ValidationErrors } from '@angular/forms';
import { SearchService } from '../search.service';
import { NavigationExtras, Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../util/constants';
import { DateUtils } from '../util/date-utils';
import { FlightSearchQueryParam } from '../entity/flight-search-query-param';
import { sourceDestinationValidator } from '../shared/address-validator.directive';
import { GooglePlacesService } from '../google-places.service';
import { IntervalType } from '../enum/interval.type';
import { FlightQueryParam } from '../entity/flight-query-param';
import { FlightTripType } from '../enum/trip1.type';
import { FlightClassType } from '../enum/flight-class.type';
import { FlightTimeType } from '../enum/time.type';
import { NoOfStops } from '../enum/no-of-stops.type';
import { AirlinesType } from '../enum/airlines.type';
import { DeviceDetailsService } from '../device-details.service';
import { Subscription, Observable, Subject, range } from 'rxjs';
import { TravelersInfo } from '../entity/travelers-info';
import { UserProfile } from '../entity/user-profile';
import { UserAccountService } from '../user-account.service';
import { UserAccountInfo } from '../entity/user-account-info';
import { AlgoTypes } from '../enum/algo-types';
import { Role } from '../entity/employee-info';
import { searchModalBasic } from '../search-modal/search-modal.component';
import { HotelSearchService } from '../hotel-search.service';
import { DatePipe, LocationStrategy, PlatformLocation } from '@angular/common';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { HotelQueryParam } from '../entity/hotel-query-param';
import { AddressType } from '../enum/address.type';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { TranslateOptionsPipe } from '../util/translate-option';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { CommonUtils } from '../util/common-utils';
import { NavigationUtil } from '../util/navigation-util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupComponent } from '../popup/popup.component';
import { GeoLocationService } from '../geo-location.service';
import { CarBookingService } from '../car-booking.service';
import { FlightResult } from '../entity/flight-result';
import { BookingService } from '../booking.service';
import { HelpPopupComponent } from '../help-popup/help-popup.component';
import { invalid } from 'moment';
import { ConnectionService } from 'ng-connection-service';
import { ClientConfiguration } from '../client-config.service';
import { Title } from '@angular/platform-browser';
import { LoginService } from '../login.service';
import { AdminPanelService } from '../admin-panel.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { find, first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FilterComponent } from '../filter/filter.component';
import { idLocale } from 'ngx-bootstrap/chronos/i18n/id';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { Item } from 'vscroll/dist/typings/classes/item';
import { SeeDetailsComponent } from '../see-details/see-details.component';
import * as moment from 'moment';
//import * as http from "http";
declare var setNgxSmartModalOpen: any;
declare var setInputOnReadonly: any;
declare var setNgxSmartModalOpenStateClosed: any;
declare var setFocusOnInputField: any;
declare var unsetsetFocusOnInputField: any;
declare var setFocusOnHotelInputField: any;
declare var addNavigationComponentPadding: any;
declare var removeNavigationComponentPadding: any;
declare var focusAndOpenKeyboardIos: any;
declare var addingHeader: any;
@Component({
  selector: 'flight-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() componentType: string;
  @Input() showFilter: boolean = false;
  @Output() collapseToggle = new EventEmitter<boolean>();
  @Output() searchRequest = new EventEmitter();
  @Output() searchAgainWithFilter = new EventEmitter();
  @Output() changeSelectedFlight = new EventEmitter();
  changingValue: Subject<boolean> = new Subject();
  lastAirportQueryTime: Date = new Date();
  searchPopClicked = true;
  inputId = '';
  valueSelectedFromDropDown: boolean = false;
  locationDisabled = false;
  dropDownopen = false;
  employeeListCame = false;
  initResponse: any;
  detailCame = false;
  thankyoumodal : any;
  bsModalRef: BsModalRef;
  showCurrentLocation: boolean = false;
  currentHotelLocation = 'Current Location';
  selectedCurrentLocation = false;
  boxSelect = false;
  searchForm: FormGroup;
  searchHotelForm: FormGroup;
  companyApprovalSubscription: Subscription;
  passengerForm: FormGroup;
  timeOptions: any[];
  ageGroupList = [{ id: 'ADT', value: 'Adult(> 16 years)', label: '(Adult > 16 years age)' }, { id: 'CHD', value: 'Child(2-16 years)', label: '(Child 2-16 years age)' }, { id: 'INF', value: 'Infant without seat(< 2 years)', label: '(Infant without seat < 2 years age)' }]
  selectIndex: number;
  selectFormControlname = '';
  bookingType = [{ 'id': 'BOOK_FOR_OTHERS', "name": 'Guest' }, { 'id': 'BOOK_FOR_SELF', "name": 'Myself' }]
  multipleLeg = environment.multipleLeg;
  showTransparentOverlay: boolean = false;
  minimumDate: Date = new Date();
  minimumDate1: Date = new Date();
  maximumDate: Date = new Date();
  maximumDate3: Date = new Date();
  searchString = '';
  minimumDateMultiple: Date = new Date();
  minimumDateLeg2: Date = new Date();
  minimumDateCheckout: Date = new Date();
  previousSearch: FlightSearchQueryParam;
  isCollapsed: boolean = true;
  isMobile: boolean;
  multicity = false;
  invalidForm = [{ value: false, index: 0 }];
  flight: FlightResult[] = [];
  userDetails: TravelersInfo;
  deviceSubscription: Subscription;
  deviceLocationSubscription: Subscription;
  userDetailsSubscription: Subscription;
  userInItSubscription: Subscription;
  urlParameterSubscription: Subscription;
  listTravellerResponseSubscription: Subscription;
  listEmployeesResponseSubscription: Subscription;
  viewMode = 'tab1';
  departureAirports: string = "";
  arrivalAirports: string = "";
  timeTypeOptions = Constants.TIME_TYPE;
  departureTimeTypeOptions = [Constants.TIME_TYPE[0], Constants.TIME_TYPE[1]];
  returnTimeTypeOptions = [Constants.TIME_TYPE[1], Constants.TIME_TYPE[0]];
  tripOptions: any;
  origTripOption:any;
  classOptions = Constants.CLASS_OPTIONS;
  roomCountOptions = Constants.ROOMS_COUNT;
  passengerOptions = Constants.PASSENGER_OPTIONS;
  queryParmsSubscription: Subscription;
  hotelChainOptions = Constants.HOTEL_BRAND_CODES_NAME_MAP;
  airportSource = '';
  useAirpotOnlySearch: boolean = environment.userAirportOnlySearch;
  sourceAirportModel: string;
  destinationAirportModel: string;
  appLoading = true;
  searchInputType: AddressType = Constants.SEARCH_INPUT_TYPE;
  tripType = '';
  leisureValue = 1;
  passengerTypeSpecific: any;
  errorShow: boolean;
  disabled: boolean;
  infants = 0;
  totalLeg = 0;
  adults = 2;
  njoySpecificBuild: boolean;
  @ViewChild('employeeType') ngselect: NgSelectComponent;
  @ViewChild(FilterComponent) filterComponent: FilterComponent;
  connectionListener: Subscription;
  location_feature_enabled = false;
  autoCompleteOptions = {
    bounds: undefined,
    fields: ['formatted_address', 'place_id', 'types', 'address_components'],
    strictBounds: false,
    types: undefined,
    origin: undefined,
    componentRestrictions: undefined
  };
  trainLocations =[];
  CurrentTime: any;
  getEmployeeAndTravellerList:any;
  // leisureItems=[{name:'Adults',value:'',number:0},{name:'Children',value:'Aged 2-12',number:0},{name:'Infants',value:'Aged 0-2',number:0}];
  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private searchService: SearchService,
    private googlePlacesService: GooglePlacesService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private router: Router,
    private location: PlatformLocation,
    private bookingService: BookingService,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private titleService: Title,
    public ngxSmartModalService: NgxSmartModalService,
    public userAccountInfoService: UserAccountService,
    public deviceDetailsService: DeviceDetailsService,
    private searchHotelService: HotelSearchService,
    private cdRef: ChangeDetectorRef,
    private connectionService: ConnectionService,
    private gallopLocalStorage: GallopLocalStorageService,
    private geoLocationService: GeoLocationService,
    private location1: Location,
    private carBookingService: CarBookingService,
    private clientConfiguration: ClientConfiguration,
    private adminPanelService: AdminPanelService,
    private toastr: ToastrService,
  ) {
    if (this.searchService.bookingDone) {
      history.pushState(null, null, window.location.href);
    }
    if (this.searchService.hotelbookingDone) {
      history.pushState(null, null, window.location.href);
    }
    this.location.onPopState(() => {
      if (this.searchService.bookingDone) {
        window.history.forward()
      }
      if (this.searchService.hotelbookingDone) {
        this.router.navigate(["search"],
          {
            queryParams:
            {
            },
            replaceUrl: true
          }
        );
        setTimeout(() => {
          window.history.forward();
        }, 100);
      }
    });
    this.isCollapsed = true;
    this.njoySpecificBuild = this.userAccountInfoService.isItNjoyBuild();
    this.tripOptions = this.njoySpecificBuild ? Constants.TRIP_OPTIONS : Constants.TRIP_OPTIONS;
    if(this.searchService.selectedEventID){
      this.tripOptions = this.tripOptions.filter(item => item.id !== 'MULTICITY')
    }
    this.origTripOption = this.njoySpecificBuild ? Constants.TRIP_OPTIONS : Constants.TRIP_OPTIONS;
    setInterval(() => {
      this.CurrentTime = new Date().getHours()
    }, 1);
    this.maximumDate.setDate(this.maximumDate.getDate() + 329);
    if (this.searchService.maxAllowedHotelStay) {
      this.maximumDate3.setDate(this.maximumDate3.getDate() + this.searchService.maxAllowedHotelStay);
    }
    // this.maximumDate3.setDate(this.maximumDate3.getDate() + 21);
    this.minimumDate.setDate(this.minimumDate.getDate());
    this.minimumDate1.setDate(this.minimumDate.getDate() - 1);
    this.minimumDateMultiple.setDate(this.minimumDate1.getDate() - 1);
    this.minimumDateLeg2.setDate(this.minimumDate1.getDate() - 1);
    this.createForm();
    this.appLoading = false;
    if ((environment.platform === 'iOS' && environment.appStoreBuildNumber > 24)
      ||
      (environment.platform === 'Android' && environment.appStoreBuildNumber > 25)
    ) {
      this.location_feature_enabled = true;
    }
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const focused = $(':focus');
    if (focused) {
      if ($(focused).prop('tagName') === 'INPUT') {
        if (event.key === 'ArrowDown' && $('.airportItem.tabindex0')[0]) {
          if ($('#multipleAirports0') && $('#multipleAirports0').length > 0) {
            $('.airportItem.tabindex0_0')[0].focus();
          } else {
            $('.airportItem.tabindex0')[0].focus();
          }
        }
      }
    }
  }
  private switchTripType = environment.amnetSpecificRelease ? AlgoTypes.LEISURE : AlgoTypes.PODUCTIVITY;
  isInputSearchTypeAirport() {
    return this.useAirpotOnlySearch;
  }
  private initPassengerTypeSpecific() {
    this.passengerTypeSpecific = JSON.parse(JSON.stringify(Constants.PASSENGERS_SPECIFIC));
    for (let passengerTypeObj of this.passengerTypeSpecific) {
      passengerTypeObj.number = Number.parseInt(this.searchForm.controls[passengerTypeObj.id].value);
    }
  }
  search = this.translateService.instant('search.AddTraveler');
  createForm(): void {
    this.passengerTypeSpecific = JSON.parse(JSON.stringify(Constants.PASSENGERS_SPECIFIC));
    // var currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() + 15);
    // var returnDate = new Date();
    // returnDate.setDate(returnDate.getDate() + 22);
    // var returnDateHotel = new Date();
    // returnDateHotel.setDate(returnDateHotel.getDate() + 16);
    let initialFormFields: any = {
      source: ['', Validators.compose([Validators.required, this.spaceValidator, this.minLengthArray(3), this.maxLengthArray(3)])],
      sourceCity: [null],
      destination: ['', Validators.compose([Validators.required, this.spaceValidator, , this.minLengthArray(3), this.maxLengthArray(3)])],
      destinationCity: [null],
      constraint: [IntervalType.DEPARTURE_BASED],
      constraint1: [IntervalType.DEPARTURE_BASED],
      departureDate: [null, Validators.compose([Validators.required])], // [new Date(currentDate)],
      arrivalDate: [null, Validators.compose([Validators.required])], //[new Date(returnDate)],
      constraintTime: ['Anytime'],
      constraintTime1: ['Anytime'],
      passengers: ['01'],
      adults: ["1"],
      children: ["0"],
      infants: ["0"],
      trip: [FlightTripType.ROUNDTRIP],
      timeTypeFilter: [FlightTimeType.DEPARTURE_BASED],
      timeTypeFilter1: [FlightTimeType.DEPARTURE_BASED],
      class: [FlightClassType.ECONOMY],
      classTypeSwitch: [this.switchTripType],
      flights: this.fb.array([])
    };
    if (this.isInputSearchTypeAirport()) {
      initialFormFields.sourceAirportCode = ['', Validators.compose([Validators.required, this.spaceValidator])];
      initialFormFields.destinationAirportCode = ['', Validators.compose([Validators.required, this.spaceValidator])];
    }
    this.searchForm = this.fb.group(
      initialFormFields, { validator: sourceDestinationValidator });


    // this.addFlight();
    this.searchHotelForm = this.fb.group({
      hotelAddress: ['', Validators.compose([Validators.required, this.spaceValidator])],
      hotelAddressCity: [''],
      currentLocation: [''],
      roomCount: ['1'],
      personCount: ['01'],
      hotelChain: [''],
      checkinDate: [null, Validators.compose([Validators.required])], //[new Date(currentDate)],
      checkoutDate: [null, Validators.compose([Validators.required])], //[new Date(returnDateHotel)]
    });

  }

  onPassengersChange(passegnerItem) {
    // 

    let params = "?ua_action=TravellersMenuItem&ua_item=" + parseInt(passegnerItem.value) + "&ua_type=flight";
    this.searchService.letsTrack(params);
    this.searchForm.controls['adults'].setValue("" + passegnerItem.value);
  }
  getAdditionalReqeustControl(): AbstractControl[] {
    return (<FormArray>this.searchForm.controls['flights']).controls;

  }

  onAddLeg() {
    if (this.multipleLeg !== this.getAdditionalReqeustControl().length) {
      this.addFlight();
      var arrayValue = { value: false, index: 0 };
      this.invalidForm.push(arrayValue);
      this.setMinimumDate((this.getAdditionalReqeustControl().length - 1));
    }
    //(<FormArray>this.searchForm.controls['flights']).push(new FormControl());
  }
  ngAfterViewInit() {

    this.cdRef.detectChanges();

  }
  searchAgain(event) {
    console.log("filter selected", event);
    this.searchService.apiCallafterFlightSelection = false;
    this.searchService.backClick = false;
    this.searchAgainWithFilter.emit(event);
  }
  onRemoveLeg(index) {
    var tempArray = [...this.invalidForm];
    if (this.getAdditionalReqeustControl().length > 1) {
      this.removeFlight(index, tempArray);
    }
  }
  minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value) {
        if (c.value.length >= min)
          return null;

        return { 'minLengthArray': { valid: false } };
      }
    }
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value) {
        if (c.value.length <= max)
          return null;

        return { 'minLengthArray': { valid: false } };
      }
    }
  }
  public spaceValidator(control: AbstractControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true }
  }

  onBookingTypeClicked(type) {
    this.searchService.typeOfBooking = type;
    this.userAccountInfoService.typeOfBooking = type;
  }
  initializeQueryParamsfromRoute(): void {
    this.queryParmsSubscription = this.route.queryParamMap.subscribe((queryParams: Params) => {

      if (queryParams && queryParams.params && Object.keys(queryParams.params).length > 0
        && queryParams.params.resultFound) return;

      if (queryParams && queryParams.params && Object.keys(queryParams.params).length > 0
        && queryParams.params.query && queryParams.params.query.trim().length > 0) {
        if (queryParams.params.type && queryParams.params.type === 'hotel') {
          let queryParam: HotelQueryParam = deserialize(JSON.parse(decodeURIComponent(queryParams.params.query)), HotelQueryParam);
          if (parseInt(queryParam.roomCount) > 3) {
            queryParam.roomCount = "03";
          }
          this.searchHotelService.setPreviousSearch(queryParam);
        } else {
          let queryParam: FlightSearchQueryParam = deserialize(JSON.parse(decodeURIComponent(queryParams.params.query)), FlightSearchQueryParam);
          this.searchService.setPreviousSearch(queryParam);
        }
        this.setPreviousSearch();
      }

    });
  }

  getTranslatedOptions(items) {
    let returnItems = JSON.parse(JSON.stringify(items));
    for (let item of returnItems) {
      item.value = this.translate.instant(item.value);
    }
    console.log('getTranslatedOptions:' + JSON.stringify(returnItems))
    return returnItems;
  }
  isTextEnabled() {
    if (environment.platform.toLowerCase() === 'android' && environment.appStoreBuildNumber >= 26) {
      return true;
    }
    if (environment.platform.toLowerCase() === 'ios' && environment.appStoreBuildNumber >= 27) {
      return true;
    }
    return false;
  }
  getPhoneNo(): any {
    return Constants.GALLOP_HELP_CONTACT_NO;
  }
  getEmailId(): any {
    return this.clientConfiguration.agentEmailId;
  }
  setPreviousSearch(): void {
    // this.setInitTripType(this.initResponse, this.userAccountInfoService.initResponseReceivedFoorSearchPage)
    if (!this.route.snapshot.queryParams['query'] && this.searchService.bookingAndApprovalDone && !this.searchService.searchHeaderCliked) {
      return;
    }
    this.previousSearch = this.searchService.getPreviousSearch();


    if (this.previousSearch) {
      if (this.previousSearch.trip === 'MULTICITY') {
        this.multicity = true;
        this.searchService.isSearchPage = false;
      }
      if (this.previousSearch.travellerEmails && this.previousSearch.travellerEmails.length > 0 && !this.searchService.bookingAndApprovalDone && !this.bookingService.previousTransactionId && !this.searchService.multiTripBooking || this.searchService.searchHeaderCliked) {
        this.searchService.employeeEmail = [];
        for (let item of this.previousSearch.travellerEmails) {
          if (this.userAccountInfoService.showRadio) {
            if (item !== 'GUEST') {
              let employee = this.searchService.employeeList.filter(item1 => item1.email === item);
              let employee1 = { Name: '', Id: -1, Type: '' };
              if (employee && employee.length > 0 && employee[0].employeeInfo) {
                employee1['Name'] = employee[0].firstName + " " + employee[0].lastName;
                employee1['Id'] = employee[0].employeeInfo.employeeId;
                employee1['email'] = employee[0].email;
              } else {
                if (this.bookingService.rebookingTravlleremail && this.bookingService.rebookingTravlleremail.length > 0) {
                  let guestTraveler = this.bookingService.rebookingTravlleremail.find(item1 => item1.email === item)
                  if (guestTraveler) {
                    employee1['Name'] = guestTraveler.firstName + " " + guestTraveler.lastName;
                    employee1['Id'] = -1;
                    employee1['email'] = guestTraveler.email;
                  }
                }
              }
              let userid = this.userAccountInfoService.getUserEmail();
              if (userid === employee1['email']) {
                employee1['Id'] = 0;
                employee1['Name'] = 'search.Myself';
              } else if (item === 'GUEST') {
                employee1['Name'] = 'search.Guest';
                employee1['Id'] = 1;
                employee1['email'] = 'GUEST';
              }
              this.searchService.employeeEmail.push(employee1)
            } else {
              let employee1 = { Name: '', Id: -1, Type: '' };
              employee1['Name'] = 'search.Guest';
              employee1['Id'] = 1;
              employee1['email'] = 'GUEST';
              this.searchService.employeeEmail.push(employee1)
            }
          } else {
            let employee1 = { Name: '', Id: -1, Type: '' };
            let userid = this.userAccountInfoService.getUserEmail();
            employee1['Id'] = 0;
            employee1['Name'] = 'search.Myself';
            employee1['email'] = userid;
            if (!this.route.snapshot.queryParams['query']) {
              this.searchService.employeeEmail.push(employee1)
            }
          }
        }
        if (this.previousSearch.travellerAgeGroup && this.previousSearch.travellerAgeGroup.length > 0) {
          this.searchService.ageGroupArray = [];
          this.searchService.ageGroupArray = this.previousSearch.travellerAgeGroup;
        }

      }
      if (this.searchService.employeeEmail.length > 0) {
        for (let item of this.searchService.employeeEmail) {
          if (item.email !== 'GUEST') {
            this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
          }
        }
      }
      this.setPreviousSearchConstraint();
      this.searchForm.controls['departureDate'].enable();
      this.calenderMinimutimeSet(this.searchService.currentTimeZone);
      this.searchForm.patchValue(this.previousSearch);
      if (this.previousSearch.classTypeSwitch) {
        this.switchTripType = (this.previousSearch.classTypeSwitch) as AlgoTypes;
      }
      this.patchFormArrayFromQueryParam();
      if (this.isInputSearchTypeAirport()) {
        setTimeout(() => {
          $('#inputFieldIDSourceAirport').find('input[type=text]').val(this.previousSearch.source);
          $('#inputFieldIDDestAirport').find('input[type=text]').val(this.previousSearch.destination);
        }, 100);
        if (this.searchForm.controls['sourceAirportCode'].value == null
          || this.searchForm.controls['sourceAirportCode'].value.trim() === '') {
          this.searchForm.controls['sourceAirportCode'].setValue(this.previousSearch.source);
        }

        if (this.searchForm.controls['destinationAirportCode'].value == null
          || this.searchForm.controls['destinationAirportCode'].value.trim() === '') {
          this.searchForm.controls['destinationAirportCode'].setValue(this.previousSearch.destination);
        }
      } else {
        if (this.searchForm.controls['sourceCity'].value == null
          || this.searchForm.controls['sourceCity'].value.trim() === '') {
          this.searchForm.controls['sourceCity'].setValue(this.previousSearch.source);
        }

        if (this.searchForm.controls['destinationCity'].value == null
          || this.searchForm.controls['destinationCity'].value.trim() === '') {
          this.searchForm.controls['destinationCity'].setValue(this.previousSearch.destination);
        }
      }

    }
    let previousHotelSearchHotel = this.searchHotelService.getPreviousSearch();
    if (previousHotelSearchHotel) {
      if (previousHotelSearchHotel.travellerEmail && previousHotelSearchHotel.travellerEmail.length > 0 && this.viewMode !== 'tab1' && !this.searchService.bookingAndApprovalDone && !this.searchService.multiTripBooking || this.searchService.searchHeaderCliked) {
        this.searchService.employeeEmail = [];
        for (let item of previousHotelSearchHotel.travellerEmail) {
          if (this.userAccountInfoService.showRadio) {
            if (item !== 'GUEST') {
              let employee = this.searchService.employeeList.filter(item1 => item1.email === item);
              let employee1 = { Name: '', Id: -1, Type: '' };
              if (employee && employee.length > 0 && employee[0].employeeInfo) {
                employee1['Name'] = employee[0].firstName + " " + employee[0].lastName;
                employee1['Id'] = employee[0].employeeInfo.employeeId;
                employee1['email'] = employee[0].email;
              } else {
                if (this.bookingService.rebookingTravlleremail && this.bookingService.rebookingTravlleremail.length > 0) {
                  let guestTraveler = this.bookingService.rebookingTravlleremail.find(item1 => item1.email === item)
                  if (guestTraveler) {
                    employee1['Name'] = guestTraveler.firstName + " " + guestTraveler.lastName;
                    employee1['Id'] = -1;
                    employee1['email'] = guestTraveler.email;
                  }
                }
              }
              let userid = this.userAccountInfoService.getUserEmail();
              if (userid === employee1['email']) {
                employee1['Id'] = 0;
                employee1['Name'] = 'search.Myself';
              } else if (item === 'GUEST') {
                employee1['Name'] = 'search.Guest';
                employee1['Id'] = 1;
                employee1['email'] = 'GUEST';
              }
              this.searchService.employeeEmail.push(employee1)
            } else {
              let employee1 = { Name: '', Id: -1, Type: '' };
              employee1['Name'] = 'search.Guest';
              employee1['Id'] = 1;
              employee1['email'] = 'GUEST';
              this.searchService.employeeEmail.push(employee1)
            }
          } else {
            let employee1 = { Name: '', Id: -1, Type: '' };
            let userid = this.userAccountInfoService.getUserEmail();
            employee1['Id'] = 0;
            employee1['Name'] = 'search.Myself';
            employee1['email'] = userid;
            if (!this.route.snapshot.queryParams['query']) {
              this.searchService.employeeEmail.push(employee1)
            }
          }
        }
      }
      if (this.searchService.employeeEmail.length > 0) {
        for (let item of this.searchService.employeeEmail) {
          if (item.email !== 'GUEST') {
            this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
          }
        }
      }
      this.searchHotelForm.controls['checkinDate'].enable();

      this.setPreviousHotelSearch(previousHotelSearchHotel);
      this.searchHotelForm.patchValue(previousHotelSearchHotel);
      this.setSearchForm(-1, this.searchService.currentTimeZone);

      if (this.searchHotelForm.controls['hotelAddress'].value === 'Your current location') {
        this.selectedCurrentLocation = true;
      }
    }

  }
  getTaverleremail(item) {
    const [first, second] = item.split('@');
    //console.log("first",first);
    return first;
  }
  getTaverleremail1(item) {
    const [fisrt, second] = item.split('@');
    // console.log("second",second);
    return second;
  }
  setPreviousHotelSearch(searchObj: any) {
    let inputCheckInDate: Date = null;
    let inputCheckOutDate: Date = null;
    if (!(searchObj.checkinDate instanceof Date)) {
      inputCheckInDate = new Date(searchObj.checkinDate.split('T')[0]);
      inputCheckOutDate = new Date(searchObj.checkoutDate.split('T')[0]);
      searchObj.checkinDate = new Date(new Date(inputCheckInDate).getTime() + new Date(inputCheckInDate).getTimezoneOffset() * 60 * 1000);
      searchObj.checkoutDate = new Date(new Date(inputCheckOutDate).getTime() + new Date(inputCheckOutDate).getTimezoneOffset() * 60 * 1000);
    }
  }
  setPreviousSearchConstraintForGivenLeg(searchObj: any) {
    let inputDate: Date = new Date();

    let inputArrivalDate: Date = null;
    let inputDepartureDate: Date = null;
    if (!(searchObj.arrivalDate instanceof Date)) {
      inputDepartureDate = new Date(searchObj.departureDate.split('T')[0]);
      if (searchObj.arrivalDate && searchObj.arrivalDate.trim().length > 0) {
        inputArrivalDate = new Date(searchObj.arrivalDate.split('T')[0]);
      }
      searchObj.departureDate = new Date(new Date(inputDepartureDate).getTime() + new Date(inputDepartureDate).getTimezoneOffset() * 60 * 1000);
      searchObj.arrivalDate = new Date(new Date(inputArrivalDate).getTime() + new Date(inputArrivalDate).getTimezoneOffset() * 60 * 1000);
    }
    searchObj.source = searchObj.source.trim();
    searchObj.destination = searchObj.destination.trim();
  }

  setPreviousSearchConstraint() {
    this.setPreviousSearchConstraintForGivenLeg(this.previousSearch);
    if (this.previousSearch.flights && this.previousSearch.flights.length > 0) {
      for (let legIndex = 0; legIndex < this.previousSearch.flights.length; legIndex++) {
        let legN = this.previousSearch.flights[legIndex];
        this.setPreviousSearchConstraintForGivenLeg(legN);
      }
    }

  }
  setInitTripType(resp, item) {
    if (resp && resp.status === 'success' && !this.route.snapshot.queryParams['query']) {

      if (resp.defaultTripType && !item) {
        if (resp.defaultTripType === 'ROUNDTRIP') {
          this.searchForm.controls['arrivalDate'].setValidators([Validators.required]);
          this.searchForm.controls['arrivalDate'].updateValueAndValidity();
        } else {
          this.searchForm.controls['arrivalDate'].clearValidators();
          this.searchForm.controls['arrivalDate'].updateValueAndValidity();
        }
        if (resp.defaultTripType === 'ONEWAY') {
          this.searchForm.controls['trip'].setValue('ONEWAY');


        } else if (resp.defaultTripType === 'ROUNDTRIP') {
          this.searchForm.controls['trip'].setValue('ROUNDTRIP');

        } else if (resp.defaultTripType === 'MULTICITY') {
          var tempArray = [...this.invalidForm];
          this.invalidForm = [{ value: false, index: 0 }];
          this.multicity = false;
          while (this.getAdditionalReqeustControl().length !== 0) {
            this.removeFlight(0, tempArray);
          }
          setTimeout(() => {
            if (!this.previousSearch) {
              this.searchForm.controls['trip'].setValue('MULTICITY');
              this.addFlight();
              this.searchService.isSearchPage = false;
              this.setMinimumDate((0));
              this.multicity = true;
            }
          }, 100);


        }
        setTimeout(() => {
          this.userAccountInfoService.initResponseReceivedFoorSearchPage = true;
        }, 100);
      } else if (resp.defaultTripType && item) {
        if (resp.defaultTripType === 'ROUNDTRIP') {
          this.searchForm.controls['arrivalDate'].setValidators([Validators.required]);
          this.searchForm.controls['arrivalDate'].updateValueAndValidity();
        } else {
          this.searchForm.controls['arrivalDate'].clearValidators();
          this.searchForm.controls['arrivalDate'].updateValueAndValidity();
        }
        if (resp.defaultTripType === 'ONEWAY') {
          this.searchForm.controls['trip'].setValue('ONEWAY');


        } else if (resp.defaultTripType === 'ROUNDTRIP') {
          this.searchForm.controls['trip'].setValue('ROUNDTRIP');

        } else if (resp.defaultTripType === 'MULTICITY') {
          var tempArray = [...this.invalidForm];
          this.invalidForm = [{ value: false, index: 0 }];
          this.multicity = false;
          while (this.getAdditionalReqeustControl().length !== 0) {
            this.removeFlight(0, tempArray);
          }
          setTimeout(() => {
            if (!this.previousSearch) {
              this.searchForm.controls['trip'].setValue('MULTICITY');
              this.addFlight();
              this.searchService.isSearchPage = false;
              this.setMinimumDate((0));
              this.multicity = true;
            }
          }, 100);


        }
        setTimeout(() => {
          this.userAccountInfoService.initResponseReceivedFoorSearchPage = true;
        }, 100);
      }

    }
  }
  sortList(data) {
    data.sort(function (a, b) {
      // console.log("employe name",a.firstName,b.firstName);
      if (a.userName === "" || !a.userName) {
        return 1;
      } else if (b.userName === "" || !b.userName) {
        return -1;
      } else if (a.userName < b.userName) { return -1; }
      else if (a.userName > b.userName) { return 1; }
      return 0;
    })
    return data;
  }
  swapOriginAndDeatinationForMuticity(i) {
    let formarray: any = (this.searchForm.get('flights') as FormArray).at(i);
    let dest = formarray.controls['source'].value;
    formarray.controls['source'].setValue(formarray.controls['destination'].value);
    formarray.controls['sourceAirportCode'].setValue(formarray.controls['destination'].value);
    formarray.controls['destination'].setValue(dest);
    formarray.controls['destinationAirportCode'].setValue(dest);
    let params = "?ua_action=swappingSourceDestination=1&ua_item=" + 'MULTICITY';
    this.searchService.letsTrack(params);
    for (let item of this.searchService.sourceLocationForMultilatLng) {
      if (item.source === formarray.controls['source'].value) {
        this.setDateWithTimeZone(item, i)
      }
    }
    for (let item of this.searchService.destLocationForMultilatLng) {
      if (item.source === formarray.controls['source'].value) {
        this.setDateWithTimeZone(item, i)
      }
    }
  }
  swapOriginAndDeatination() {
    let dest = this.searchForm.controls['source'].value;
    this.searchForm.controls['source'].setValue(this.searchForm.controls['destination'].value);
    this.searchForm.controls['sourceAirportCode'].setValue(this.searchForm.controls['destination'].value);
    this.searchForm.controls['destination'].setValue(dest);
    this.searchForm.controls['destinationAirportCode'].setValue(dest);
    let params = "?ua_action=swappingSourceDestination=1&ua_item=" + 'ONEWAYORROUNDTRIP';
    this.searchService.letsTrack(params);
    if (this.searchService.sourceLocationForlatLng.source === this.searchForm.controls['source'].value) {
      this.setDateWithTimeZone(this.searchService.sourceLocationForlatLng, -1)
    }

    if (this.searchService.destLocationForlatLng.source === this.searchForm.controls['source'].value) {
      this.setDateWithTimeZone(this.searchService.destLocationForlatLng, -1)
    }
  }
  setTravellerForMUltiBooking() {
    let index = 0
    for (let item of this.searchService.employeeEmail) {
      if (this.userAccountInfoService.showRadio) {
        if (item.email !== 'GUEST') {
          let employee = this.searchService.employeeList.filter(item1 => item1.email === item.email);
          let employee1 = { Name: '', Id: -1, Type: '' };
          if (employee && employee.length > 0 && employee[0].employeeInfo) {
            employee1['Name'] = employee[0].firstName + " " + employee[0].lastName;
            employee1['Id'] = employee[0].employeeInfo.employeeId;
            employee1['email'] = employee[0].email;
          }
          let userid = this.userAccountInfoService.getUserEmail();
          if (userid === employee1['email']) {
            employee1['Id'] = 0;
            employee1['Name'] = 'search.Myself';
          } else if (item === 'GUEST') {
            employee1['Name'] = 'search.Guest';
            employee1['Id'] = 1;
            employee1['email'] = 'GUEST';
          }
          this.searchService.employeeEmail[index] = employee1
        } else {
          let employee1 = { Name: '', Id: -1, Type: '' };
          employee1['Name'] = 'search.Guest';
          employee1['Id'] = 1;
          employee1['email'] = 'GUEST';
          this.searchService.employeeEmail[index] = employee1
        }
      } else {
        let employee1 = { Name: '', Id: -1, Type: '' };
        let userid = this.userAccountInfoService.getUserEmail();
        employee1['Id'] = 0;
        employee1['Name'] = 'search.Myself';
        employee1['email'] = userid;
        if (!this.route.snapshot.queryParams['query']) {
          this.searchService.employeeEmail[index] = employee1;
        }
      }
      index++;
    }
  }
  ngOnInit() {
    addingHeader();
    this.searchHotelService.hotelCodeHasNoRoom = [];
    this.bookingService.bookRequestProgress = false;
    this.searchService.type1 = [];
    this.searchService.paymentPage = false;
    this.searchService.seatSelectArray1 = [];
    this.bookingService.priceChangeData = [];
    this.bookingService.priceChanngeFlight = undefined;
    // this.searchService.multipleOutgoingSelectedFlight =[];
    this.bookingService.cardAllowed = [];
    this.searchHotelService.selectTypeFrom = 'list';
    this.searchHotelService.selectedHotelFromMap = undefined;
    this.searchHotelService.roomPriceChange = [];
    this.carBookingService.originalCarSearchResponse = [];
    this.carBookingService.noFilterRequired = 0;
    this.carBookingService.locationFound = 0;
    this.urlParameterSubscription = this.route.queryParamMap.subscribe((queryParams: Params) => {
      if (queryParams && queryParams.params && Object.keys(queryParams.params).length > 0) {
        if (!this.searchService.searchHeaderCliked && !this.route.snapshot.queryParams['query']) {
          this.setEventData(queryParams.params);
        }
        if (queryParams.params && queryParams.params['redirectedBackFromSignIn']) {
          this.userAccountInfoService.comingFromLoginPage = true;
          this.detailCame = false;
          if (this.listEmployeesResponseSubscription) {
            this.listEmployeesResponseSubscription.unsubscribe();
          }
          if (this.listTravellerResponseSubscription) {
            this.listTravellerResponseSubscription.unsubscribe();
          }
        } else {
          this.userAccountInfoService.comingFromLoginPage = false;
        }
        if (queryParams.params.type && queryParams.params.type === 'hotel') {
          this.viewMode = 'tab2';
          if (this.searchService.multiTripBooking) {
            this.setTravellerForMUltiBooking();
          }
          console.log("traveler details", this.searchService.employeeEmail);
          //this.titleService.setTitle('Hotel Search');
          this.titleService.setTitle(this.translateService.instant('search.HotelSearch'));
        } else if (queryParams.params.type && queryParams.params.type === 'car') {
          this.viewMode = 'tab3';
          if (this.searchService.multiTripBooking) {
            this.setTravellerForMUltiBooking();
          }
          console.log("traveler details", this.searchService.employeeEmail);
          //this.titleService.setTitle('Car Search');
          if (this.searchService.multiTripBooking) {
            this.setTravellerForMUltiBooking();
          }
          this.titleService.setTitle(this.translateService.instant('search.CarSearch'));
        } else if (queryParams.params.type && queryParams.params.type === 'allbookings') {
          this.viewMode = 'tab4';
          this.showAllBookings = true;
          this.searchService.showAllBookings = true;
          //this.titleService.setTitle('Car Search');
          this.titleService.setTitle(this.translateService.instant('search.ALLBookings'));
        }else if (queryParams.params.type && queryParams.params.type === 'train') {
          this.viewMode = 'tab5';
          if(this.searchService.multiTripBooking){
            this.setTravellerForMUltiBooking();
          }
          this.titleService.setTitle(this.translate.instant('search.TrainSearch'));
          this.getStationListForTrain();
        }
        else {
          this.viewMode = 'tab1';
          if (this.searchService.multiTripBooking) {
            this.setTravellerForMUltiBooking();
          }
          if (!this.searchService.showFlightTab && this.searchService.showHotelTab) {
            this.hotelTabClicked();
          }
          if (!this.searchService.showFlightTab && !this.searchService.showHotelTab && this.searchService.showCarTab) {
            this.carTabClicked();
          }
          // this.titleService.setTitle('Flight Search');
          this.titleService.setTitle(this.translateService.instant('search.FlightSearch'));
          // this.searchForm.controls['trip'].setValue('ONEWAY');
          // this.setInitTripType(this.initResponse,false)
        }
      } else {
        this.viewMode = 'tab1';
        if (this.searchService.multiTripBooking) {
          this.setTravellerForMUltiBooking();
        }
        this.titleService.setTitle(this.translateService.instant('search.FlightSearch'));
        // this.titleService.setTitle('Flight Search');
        // this.searchForm.controls['trip'].setValue('ONEWAY');
        //this.setInitTripType(this.initResponse,false)
      }

    });
    this.userInItSubscription = this.userAccountInfoService.userAccountInitObjObserver$.subscribe(resp => {
      if (resp && resp.status === 'success') {
        this.initResponse = resp;
        if (resp.defaultConfig) {
          this.searchService.mapSupprted = resp.defaultConfig.mapsSupported;
          this.searchService.tripFeatureEnabled = resp.defaultConfig.tripFeatureEnabled;
          this.searchService.infantBookingAllowed = resp.defaultConfig.minorBookingSupported;
          this.searchService.showFlightTab = resp.defaultConfig.flightsEnabled;
          this.searchService.showCarTab = resp.defaultConfig.carsEnabled;
          this.searchService.showHotelTab = resp.defaultConfig.hotelsEnabled;
          this.searchService.logoIcon = resp.defaultConfig.logoIcon;
          if (!this.searchService.showFlightTab && this.searchService.showHotelTab) {
            this.hotelTabClicked();
          }
          if (!this.searchService.showFlightTab && !this.searchService.showHotelTab && this.searchService.showCarTab) {
            this.carTabClicked();
          }
        }
        if (resp.maxAllowedHotelStay) {
          this.searchService.maxAllowedHotelStay = resp.maxAllowedHotelStay;
          this.maximumDate3.setDate(this.maximumDate3.getDate() + resp.maxAllowedHotelStay);
        }
        this.setInitTripType(resp, this.userAccountInfoService.initResponseReceivedFoorSearchPage)
        if (!resp.multiCitySupported) {
          this.tripOptions = this.tripOptions.filter(item => item.id !== 'MULTICITY')
        }
        this.userAccountInfoService.initResponseReceived = true;
        if (this.userAccountInfoService.userIsDeptAdmin) {
          if (this.userAccountInfoService.onBoardingTask.indexOf('booking') === -1) {
            let params = "?ua_action=OnboardingTasks&ua_item=booking";
            this.searchService.letsTrack(params);
          }
        }
      }
    });

    this.searchInProgress = false;
    this.clearPreviousResults();

    this.deviceSubscription = this.deviceDetailsService.isMobile1().subscribe(isMobile => {
      this.isMobile = isMobile;
      if (this.isResult) {
        this.setIsCollapsed(true);
        if (isMobile) {
          addNavigationComponentPadding();
        }
        NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_RESULTS);
      } else {
        NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_BOOK);
        removeNavigationComponentPadding();
      }
    });
    if (this.location_feature_enabled) {
      this.searchHotelForm.get('hotelAddress').valueChanges.subscribe(value => {
        if (value) {
          this.showCurrentLocation = false;
        } else {
          this.showCurrentLocation = true;
        }
      }
      );
    }
    this.deviceLocationSubscription = this.deviceDetailsService.userIPLoation$.subscribe(location => {
      if (!this.searchForm.controls['source'].value || this.searchForm.controls['source'].value == null
        || this.searchForm.controls['source'].value.length == 0) {
        if (!this.isInputSearchTypeAirport() && location) this.setDefaultOrigin(location.address, location.city);
      }
    });
    if (!this.searchService.multiTripBooking || this.searchService.multiTripBooking) {
      this.listTravellerResponseSubscription = this.adminPanelService.employeeListResponseObservable$.subscribe(response => {
        if (response) {
          if (response.length > 0) {
            this.searchService.employeeList = response;
          }
        }
      });
          this.getEmployeeAndTravellerList = () => {
      this.listEmployeesResponseSubscription = this.searchService.travellerListResponseObservable$.subscribe(response => {
        if ((this.isUserCompanyTravelManager() || this.userAccountInfoService.isUserCorporateAdmin()) && (!this.searchService.multiTripBooking && !this.bookingService.previousTransactionId && !this.route.snapshot.queryParams['query'])) {
          this.searchService.employeeEmail = [];
          this.searchService.ageGroupArray = [];
        }
      
        if (!this.employeeListCame && !this.route.snapshot.queryParams['query']) {
          if (response) {
            if (response.length > 0) {
              this.searchService.travellerList = response;
              this.employeeListCame = true;
              let userid = this.userAccountInfoService.getUserEmail();
              let userName = this.userAccountInfoService.getUserName();
              let sortEmployee = this.sortList(response);
              if (this.searchService.selectedEventForBooking && this.searchService.selectedEventForBooking.length > 0) {
                let userid = this.userAccountInfoService.getUserEmail();
                this.searchService.employeeEmail[0] = { Name: 'search.Myself', Id: 0, email: userid };
              } else if (this.searchService.employeeEmail && this.searchService.employeeEmail.length === 0
                && (!this.bookingService.previousTransactionId && !this.isUserCompanyTravelManager() && !this.userAccountInfoService.isUserCorporateAdmin())) {
                this.searchService.employeeEmail[0] = { Name: 'search.Myself', Id: 0, email: userid };
              } else if (!this.searchService.searchStart && this.bookingService.previousTransactionId && this.bookingService.rebookingTravlleremail && this.bookingService.rebookingTravlleremail.length > 0) {
                this.searchService.employeeEmail = [];
                this.searchService.ageGroupArray = [];
                let i = 0;
                for (let user of this.bookingService.rebookingTravlleremail) {
                  let findIndex = sortEmployee.findIndex(item => item.userId === user.email);
                  if (findIndex > -1) {
                    let userid = this.userAccountInfoService.getUserEmail();
                    if (userid !== user.email) {
                      let employee = { Name: '', Id: -1, Type: '' }
                      employee['Name'] = sortEmployee[findIndex].userName;
                      employee['Id'] = 1;
                      employee['email'] = sortEmployee[findIndex].userId;
                      this.searchService.employeeEmail.push(employee);
                    } else {
                      let employee = { Name: 'search.Myself', Id: -1, Type: '' };
                      employee['Id'] = 0;
                      employee['email'] = sortEmployee[findIndex].userId;
                      this.searchService.employeeEmail.push(employee);
                    }
                  } else {
                    let employee = { Name: '', Id: -1, Type: '' }
                    employee['Name'] = user.firstName + user.lastName;
                    employee['Id'] = -1;
                    employee['email'] = user.email;
                    if (user && user.travellerType == 'INFANT') {
                      let groupAge = this.ageGroupList.find(item => item.id === 'INF');
                      this.searchService.ageGroupArray[i] = groupAge;
                    } else if (user && user.travellerType == 'ADULT') {
                      let groupAge = this.ageGroupList.find(item => item.id === 'ADT');
                      this.searchService.ageGroupArray[i] = groupAge;
                    }
                    else if (user && user.travellerType == 'CHILD') {
                      let groupAge = this.ageGroupList.find(item => item.id === 'CHD');
                      this.searchService.ageGroupArray[i] = groupAge;
                    }
                    this.searchService.employeeEmail.push(employee);
                  }
                  i++;
                }
                this.searchForm.controls['passengers'].setValue("" + this.searchService.employeeEmail.length);
                this.searchForm.controls['adults'].setValue("" + this.searchService.employeeEmail.length);
                console.log("form value", this.searchForm.controls);
              }
              this.searchService.employeeOptions = new Array(1).fill(null).map(_ => []);
              this.searchService.originalEmployeeOptions = new Array(1).fill(null).map(_ => []);
              this.searchService.originalEmployeeOptions[0] = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
              this.searchService.employeeOptions[0] = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
              for (let item of sortEmployee) {
                let employee = { Name: '', Id: -1, Type: '' }
                let userid = this.userAccountInfoService.getUserEmail();
                if (userid !== item.userId) {
                  employee['Name'] = item.userName
                  if (item['employeeIdentifier']) {
                    employee['Name'] = employee['Name'] + ' - ' + item['employeeIdentifier'];
                  }
                  employee['Id'] = 1;
                  employee['email'] = item.userId;
                  employee.Type = 'search.OtherEmployees';
                  this.searchService.employeeOptions[0].push(employee);
                  this.searchService.originalEmployeeOptions[0].push(employee);
                }
              }
              for (let item of this.searchService.employeeEmail) {
                if (item.email !== "GUEST") {
                  this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
                }
              }
            } else {
              let userid = this.userAccountInfoService.getUserEmail();
              let userName = this.userAccountInfoService.getUserName();
              if (this.searchService.employeeEmail && this.searchService.employeeEmail.length === 0
                && (!this.isUserCompanyTravelManager() && !this.userAccountInfoService.isUserCorporateAdmin())) {
                this.searchService.employeeEmail[0] = { Name: 'search.Myself', Id: 0, email: userid };
              }
              this.searchService.travellerList = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
              this.searchService.employeeOptions = new Array(1).fill(null).map(_ => []);
              this.searchService.originalEmployeeOptions = new Array(1).fill(null).map(_ => []);
              this.searchService.originalEmployeeOptions[0] = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
              this.searchService.employeeOptions[0] = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
              for (let item of this.searchService.employeeEmail) {
                if (item.email !== "GUEST") {
                  this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
                }
              }

            }
            console.log("employee list", this.searchService.employeeOptions, this.searchService.employeeEmail);

          } else {
            let userid = this.userAccountInfoService.getUserEmail();
            let userName = this.userAccountInfoService.getUserName();
            if (this.searchService.employeeEmail && this.searchService.employeeEmail.length === 0
              && (!this.isUserCompanyTravelManager() && !this.userAccountInfoService.isUserCorporateAdmin())) {
              this.searchService.employeeEmail[0] = { Name: 'search.Myself', Id: 0, email: userid };
            }
            this.searchService.travellerList = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
            this.searchService.employeeOptions = new Array(1).fill(null).map(_ => []);
            this.searchService.originalEmployeeOptions = new Array(1).fill(null).map(_ => []);
            this.searchService.originalEmployeeOptions[0] = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
            this.searchService.employeeOptions[0] = [{ Name: 'search.Myself', Id: 0, email: userid }, { Name: 'search.GuestTraveler', Id: 1, email: 'GUEST' }];
            for (let item of this.searchService.employeeEmail) {
              if (item.email !== "GUEST") {
                this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
              }
            }

          }
        }
      });
    }
  this.getEmployeeAndTravellerList();
  }
    this.detailCame = false;
    this.userDetailsSubscription = this.userAccountInfoService.userAccountInfoObjObserver$.subscribe((userProfile: UserAccountInfo) => {
      if (CommonUtils.doesPathContain(window.location.href, 'search')) {

        if (this.searchForm.controls['source'].value && this.searchForm.controls['source'].value.length >= 3) return;
        if (!this.isInputSearchTypeAirport() && userProfile && userProfile.userInfo != null) {
          if (userProfile.userInfo.home && userProfile.userInfo.home != null && userProfile.userInfo.home.address
            && userProfile.userInfo.home.address.length > 4) {
            this.setDefaultOrigin(userProfile.userInfo.home.address, "City");
          }
        }
      }


      let userid = this.userAccountInfoService.getUserEmail();
      let sToken = this.userAccountInfoService.getSToken();
      if (userProfile) {

        if ((this.isUserCompanyTravelManager() || this.userAccountInfoService.isUserCorporateAdmin()) && !this.bookingService.previousTransactionId && !this.searchService.multiTripBooking) {
          this.searchService.employeeEmail = [];
          this.searchService.ageGroupArray = [];
          if (this.route.snapshot.queryParams['query']) {
            this.searchService.bookingAndApprovalDone = true;
          }
        }
        this.detailCame = true;
        if (this.userAccountInfoService.showRadio) {
          if (this.userAccountInfoService.isConnected) {
            //   this.adminPanelService.fetchEmployeesList(this.userAccountInfoService.getUserCompanyId());
            //   this.searchService.fetchEmployeesList(this.userAccountInfoService.getUserCompanyId());
          }
        } else {
          if ((this.searchService.employeeEmail && this.searchService.employeeEmail.length === 0 && !this.route.snapshot.queryParams['query'])) {
            this.searchService.employeeEmail[0] = { Name: 'search.Myself', Id: 0, email: userid };
          }
        }
        if (this.userAccountInfoService.isConnected) {
          this.userAccountInfoService.fetchUserPreferences();
        }

        this.userAccountInfoService.fetchInviteList().subscribe(resp => {
          if (resp && resp.success) {
            if (resp.data && resp.data.length > 0) {
              this.userAccountInfoService.eventList = resp.data;
              if (!this.searchService.searchHeaderCliked) {
                if (this.route.snapshot.queryParams && this.route.snapshot.queryParams.eventid){
                  this.setEventData(this.route.snapshot.queryParams);
                }
              }
            } else {
              this.userAccountInfoService.eventList = [];
            }
          }
        })


        let startDate1: Date = new Date();
        let endDate1: Date = new Date();
        endDate1.setDate(endDate1.getDate() + 1);
        startDate1.setDate(startDate1.getDate() -1);
        let tempdate1 = DateUtils.getFormattedDateWithoutTimeZone(startDate1);
        let tempdate2 = DateUtils.getFormattedDateWithoutTimeZone(endDate1);
        let startDate = DateUtils.getDayAsLocalISODateString(tempdate1);
        let endDate = DateUtils.getDayAsLocalISODateString(tempdate2);
        this.adminPanelService.fetchCompanyApprovals(this.userAccountInfoService.getUserCompanyId(), startDate, endDate, 'all');
      }
      // if (!this.userAccountInfoService.njoySpecificBuild) {
      //   if (this.userAccountInfoService.userIsAdmin && this.userAccountInfoService.comingFromLoginPage) {

      //     // this.userAccountInfoService.comingFromLoginPage = false;
      //     if (this.userAccountInfoService.userIsDeptAdmin && !this.loginService.currentUrl) {
      //      // this.titleService.setTitle('Dashboard');
      //       this.titleService.setTitle(this.translateService.instant('search.Dashboard'));
      //       window.location.href = (this.loginService.initResponse.dashboardAppBaseUrl + 'admin?type=dashboard'
      //         + "&userid=" + userid + "&sToken=" + sToken
      //       );
      //       this.loginService.currentUrl = undefined;
      //     } else if (!this.loginService.currentUrl) {
      //      // this.titleService.setTitle('Pending Approvals');
      //       this.titleService.setTitle(this.translateService.instant('search.PendingApprovals'));
      //       window.location.href = (this.loginService.initResponse.dashboardAppBaseUrl + 'admin?type=approvals&subType=pending'
      //         + "&userid=" + userid + "&sToken=" + sToken
      //       )
      //       this.loginService.currentUrl = undefined;
      //     }
      //   }
      // } else {
          // this.userAccountInfoService.comingFromLoginPage = false;

      // }
    });
    this.companyApprovalSubscription = this.adminPanelService.companyApprovalResponseObservable$.subscribe((reportResponse) => {
      if (reportResponse) {
        if (reportResponse.pendingApprovals.length > 0) {
          for (let item of reportResponse.pendingApprovals) {
            if (this.getTimeDiffernece(item).mins > 0) {
              this.searchService.showApprovalaInMenu = true;
              break;
            } else {
              this.searchService.showApprovalaInMenu = false;
            }
          }

        } else {
          this.searchService.showApprovalaInMenu = false;
        }

      } else {
        this.searchService.showApprovalaInMenu = false;
      }
    });
    if (!this.route.snapshot.queryParams['query']) {
      //   this.setInitTripType(this.initResponse, this.userAccountInfoService.initResponseReceivedFoorSearchPage)
      if (this.searchService.bookingAndApprovalDone && !this.searchService.searchHeaderCliked) {
        return;
      } else {
        this.setPreviousSearch();
      }

    } else if (this.route.snapshot.queryParams['resultFound']) {
      this.setPreviousSearch();
    }
    else {
      this.initializeQueryParamsfromRoute();
    }

    this.initPassengerTypeSpecific();
    if (this.isResult) {
      this.setIsCollapsed(true);
      if (this.isMobile) {
        addNavigationComponentPadding();
      }
    } else {
      removeNavigationComponentPadding();
    }
  }
  latlongSubscription: Subscription;
  setEventData(val?) {
    if (this.searchService.selectedEventID!=='exitEvent') {
      let userprefernce = this.userAccountInfoService.getUserPredferences();
      let userProfile = this.userAccountInfoService.getAccountInfo();
      if (val && val.eventid){
        this.searchService.selectedEventID = val.eventid;
      }
      let event = this.userAccountInfoService.eventList.filter(item => item.event.id === this.searchService.selectedEventID);
      if (event && event[0] && event[0].event.id) {
        this.searchService.selectedEventForBooking = event;
        if (this.searchService.selectedEventForBooking && this.searchService.selectedEventForBooking.length > 0 && !this.route.snapshot.queryParams['query']) {
          this.tripOptions = this.tripOptions.filter(item => item.id !== 'MULTICITY')
          this.searchForm.controls['trip'].setValue('ROUNDTRIP');
          if (this.searchService.selectedEventForBooking[0].inviteDetails.allowedDepartAirports.length > 0) {
            this.searchForm.controls['source'].setValue(this.searchService.selectedEventForBooking[0].inviteDetails.allowedDepartAirports[0]);
            this.searchForm.controls['source' + 'AirportCode'].setValue(this.searchService.selectedEventForBooking[0].inviteDetails.allowedDepartAirports[0])
          } else {
            if (userprefernce && userprefernce.homeAirport && userprefernce.homeAirport.length > 0) {
              this.searchForm.controls['source'].setValue(userprefernce.homeAirport[0]);
              this.searchForm.controls['source' + 'AirportCode'].setValue(userprefernce.homeAirport[0]);
            }
          }
          if (this.searchService.selectedEventForBooking[0].event.items && this.searchService.selectedEventForBooking[0].event.items.length > 0 && this.searchService.selectedEventForBooking[0].event.items[0].destinationAirports.length > 0) {
            this.searchForm.controls['destination'].setValue(this.searchService.selectedEventForBooking[0].event.items[0].destinationAirports[0]);
            this.searchForm.controls['destination' + 'AirportCode'].setValue(this.searchService.selectedEventForBooking[0].event.items[0].destinationAirports[0]);
          }
          this.searchForm.controls['departureDate'].setValue(new Date(this.searchService.selectedEventForBooking[0].event.start));
          this.searchForm.controls['arrivalDate'].setValue(new Date(this.searchService.selectedEventForBooking[0].event.end));
          let userid = this.userAccountInfoService.getUserEmail();
          this.searchService.employeeEmail[0] = { Name: 'search.Myself', Id: 0, email: userid };

        }
      }else{
        this.searchService.selectedEventID =null;
      }
    }else if (val && val['eventid']==='exitEvent') {
      this.searchForm.controls['source'].setValue(null);
      this.searchService.selectedEventID =null;
      if (this.initResponse && this.initResponse.multiCitySupported) {
      this.tripOptions=this.origTripOption;
      }
      this.searchForm.controls['source' + 'AirportCode'].setValue(null);
      this.searchForm.controls['destination'].setValue(null);
      this.searchForm.controls['destination' + 'AirportCode'].setValue(null);
      this.searchForm.controls['departureDate'].setValue(null);
      this.searchForm.controls['arrivalDate'].setValue(null);
      this.router.navigate(['/search'], {
        queryParams: {},
        replaceUrl: true
      });
    }



  }
  enableChekinDateDate() {
    if (this.searchHotelForm.controls['hotelAddress'].value && this.searchHotelForm.controls['hotelAddress'].value !== '') {
      this.latlongSubscription = this.userAccountInfoService.getLatlng(this.searchHotelForm.controls['hotelAddress'].value).subscribe(resp => {
        if (resp && resp.results && resp.results.length > 0 && resp.results[0].geometry && resp.results[0].geometry.location) {
          console.log("hotel address", resp)
          let latlngObject = { location: { lat: -1, lon: -1 } };
          latlngObject.location.lat = resp.results[0].geometry.location.lat;
          latlngObject.location.lon = resp.results[0].geometry.location.lng;
          this.setDateWithTimeZone(latlngObject, -1);
        } else {
          if (resp) {
            this.setSearchForm(-1);
          }
        }
      })
    }

  }
  openCheckinCalender(checkinDatePicker) {
    if (this.searchHotelForm.controls['hotelAddress'].value && this.searchHotelForm.controls['hotelAddress'].value !== '') {
      checkinDatePicker.toggle();
    } else {
      this.toastr.error(this.translate.instant('setting.Pleaseenteradestination'));
    }
  }
  openDepartureCalender(checkinDatePicker) {
    if (this.searchForm.controls['source'].value && this.searchForm.controls['source'].value !== '') {
      checkinDatePicker.toggle();;
    }else{
         if(this.viewMode == 'tab1') this.toastr.error(this.translate.instant('setting.Pleaseenteranoriginairport'));
         else if(this.viewMode == 'tab5') this.toastr.error("Please enter an origin station.")
    }
  }
  openMulticityDepartureCalender(checkinDatePicker, index) {
    let form: FormGroup = <FormGroup>this.getFormArrayFLightObject(index);
    if (form.controls['source'].value && form.controls['source'].value !== '') {
      checkinDatePicker.toggle();
    }else{
      if(this.viewMode == 'tab1') this.toastr.error(this.translate.instant('setting.Pleaseenteranoriginairport'));
      else if(this.viewMode == 'tab5') this.toastr.error("Please enter an origin station.")
    }
  }
  autoCompleteSourceItems: any = [];
  autoCompleteDestItems: any = [];
  configOrigin: any = { 'class': 'autocompleteAirports', 'max': 10, 'placeholder': this.translate.instant('search.Origin'), 'sourceField': ['airport'] };
  configDestination: any = { 'class': 'autocompleteAirports', 'max': 10, 'placeholder': this.translate.instant('search.Destination'), 'sourceField': ['airport'] };
  setSearchForm(index,currTimezone?){
    if(this.viewMode==='tab1' || this.viewMode === 'tab5'){
      if(currTimezone){
        this.calenderMinimutimeSet(currTimezone)
      }
      if (index !== -1 || index === 0) {
        let form: FormGroup = <FormGroup>this.getFormArrayFLightObject(index);

        let selectedDate = (form.controls['departureDate'] && form.controls['departureDate'].value) ? form.controls['departureDate'].value.setHours(0, 0, 0, 0) : null;
        let rangeDate = this.minimumDate.setHours(0, 0, 0, 0);
        if (!this.searchService.searchHeaderCliked && form.controls['source'].value && form.controls['source'].value !== '' && selectedDate && selectedDate < rangeDate) {
          form.controls['departureDate'].setValue(null);
        }
      }

      let selectedDate = (this.searchForm.controls['departureDate'] && this.searchForm.controls['departureDate'].value) ? this.searchForm.controls['departureDate'].value.setHours(0, 0, 0, 0) : null;
      let rangeDate = this.minimumDate.setHours(0, 0, 0, 0);
      if (!this.searchService.searchHeaderCliked && this.searchForm.controls['source'].value && this.searchForm.controls['source'].value !== '' && selectedDate && selectedDate < rangeDate) {
        this.searchForm.controls['departureDate'].setValue(null);
      }




    } else if (this.viewMode === 'tab2') {

      if (currTimezone) {
        this.calenderMinimutimeSet(currTimezone);
      }
      let selectedDate = (this.searchHotelForm.controls['checkinDate'] && this.searchHotelForm.controls['checkinDate'].value) ? this.searchHotelForm.controls['checkinDate'].value.setHours(0, 0, 0, 0) : null;
      let rangeDate = this.minimumDate1.setHours(0, 0, 0, 0);
      if (!this.searchService.searchHeaderCliked && this.searchHotelForm.controls['hotelAddress'].value && this.searchHotelForm.controls['hotelAddress'].value !== '' && selectedDate && selectedDate < rangeDate) {
        this.searchHotelForm.controls['checkinDate'].setValue(null);
      }
    }
  }
calenderMinimutimeSet(currTimezone){
    if(currTimezone){
      let d = new Date(new Date().toLocaleString("en-US", { timeZone: currTimezone }));
      if(this.viewMode==='tab1' || this.viewMode === 'tab5'){
        this.minimumDate = d;
      }
      if (this.viewMode === 'tab2')
        var time = "5:00 AM";
      var now = new Date();
      var dt = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear() + " " + time;
      var userval = new Date(dt);
      if (d < userval) {
        this.minimumDate1.setDate(d.getDate() - 1);
      } else {
        this.minimumDate1.setDate(d.getDate());

      }
    }
    return;

  }
  setDateWithTimeZone(item, index) {
    this.userAccountInfoService.getTimeZone(item.location).subscribe(resp => {
      if (resp && resp.timeZoneId) {
        this.searchService.currentTimeZone = resp.timeZoneId;
        this.searchService.searchHeaderCliked = false;

        this.setSearchForm(index, resp.timeZoneId)
        console.log("response timezone", resp, this.searchService.currentTimeZone);
      } else {
        this.searchService.searchHeaderCliked = false;
        this.setSearchForm(index);
      }
    })
  }

  onAutoCompleteSelect(item: any) {
    this.valueSelectedFromDropDown = true;

    let form = { ...this.searchForm };
    if (item.code
    ) {
      form[this.selectFormControlname] = item.code;
      form[this.selectFormControlname + 'AirportCode'] = item.code;
      this.searchForm.patchValue({ ...form });
      if (this.selectFormControlname === 'source') {
        this.sourceAirportModel = item.code;
        let locationObj = { source: item.code, location: item.location };
        this.searchService.sourceLocationForlatLng = locationObj;
        this.setDateWithTimeZone(item, -1);
        // this.autoCompleteSourceItems = [];
      } else {
        this.destinationAirportModel = item.code;
        let locationObj = { source: item.code, location: item.location };
        this.searchService.destLocationForlatLng = locationObj
        // this.autoCompleteDestItems = [];
      }
      if (this.selectFormControlname === 'source') {
        this.autoCompleteSourceItems = [];
      } else {
        this.autoCompleteDestItems = [];
      }
      this.searchForm.markAsTouched();
      setTimeout(() => {
        $('#' + this.searchService.inputId).find('input[type=text]').val(item.code);
      }, 1);
    }
  }
  getTimeDiffernece(data) {
    const time = data.approvalExpiryTime;
    let bookingTime = new Date(time);
    let currentTime = new Date();
    let durationInMins = DateUtils.getDateDiffInMinutes(bookingTime, currentTime);
    //console.log("time diff",DateUtils.getDurationAsHrsMinObj(durationInMins));
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }
  onAutoCompleteDropDownClosed(matchedItem, index, formcontrolname) {
    if (matchedItem && matchedItem.code.trim().length == 3) {
      let data = this.autoCompleteDestItems;
      if (formcontrolname === 'source') {
        data = this.autoCompleteSourceItems;
        this.setDateWithTimeZone(matchedItem, index);
      }

      this.selectAirport(matchedItem.code, formcontrolname, index, data);
    } else {
      this.setSearchForm(index);
    }
  }
  showToatsr() {
    if (!this.detailCame) {
      this.toastr.warning(this.translateService.instant("search.FetchingDetails"))
      return;
    }
    if (!this.userAccountInfoService.showRadio) {
      this.toastr.error(this.translateService.instant("search.Bookingforothersisnotallowedforyou.Pleasecontactyouadministratortoenabletheabilitytobookforothers."))
      return;
    }
  }
  removeTravler(item, i, employeeDropdown) {
    if (!this.detailCame) {
      this.toastr.warning(this.translateService.instant("search.FetchingDetails"))
      return;
    }
    if (!this.userAccountInfoService.showRadio) {
      this.toastr.error(this.translateService.instant("search.Bookingforothersisnotallowedforyou.Pleasecontactyouadministratortoenabletheabilitytobookforothers."))
      return;
    }
    this.searchService.employeeEmail.splice(i, 1);
    this.searchService.ageGroupArray.splice(i, 1);
    this.searchService.employeeOptions[0] = this.searchService.originalEmployeeOptions[0];
    for (let item of this.searchService.employeeEmail) {
      if (item.email !== "GUEST") {
        this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
      }
    }
    this.searchForm.controls['adults'].setValue("" + this.searchService.employeeEmail.length);
    this.searchForm.controls['passengers'].setValue("" + this.searchService.employeeEmail.length);
    if (this.viewMode === 'tab2') {
      // this.searchHotelForm.controls['personCount'].setValue("" + this.searchService.employeeEmail.length);
      this.searchHotelForm.controls['roomCount'].setValue("" + this.searchService.employeeEmail.length);
    }
    if (this.searchService.employeeEmail.length === 0) {
      this.ngselect.toggle();
      setTimeout(() => {
        this.ngselect.focus();
      }, 10);
    }
  }
  ageGroupChanged(event, i) {
    if (event && event.id) {
      this.searchService.ageGroupArray[i] = event
    }

  }
  getAgeGroup(i) {
    let userid = this.userAccountInfoService.getUserEmail();
    if (this.searchService.employeeEmail[i] && this.searchService.employeeEmail[i].email !== 'GUEST') {

      let employee: any = this.searchService.travellerList.filter(item => item.userId === this.searchService.employeeEmail[i].email)
      if (employee && employee.length > 0 && employee[0].dob) {
        employee = employee[0];
        let dob = new Date(employee.dob);
        let leg1DateControl = this.searchForm.controls['departureDate'];
        let todayDate;
        if (leg1DateControl.value) {
          todayDate = leg1DateControl.value;
        } else {
          todayDate = new Date();
        }
        const oneDay = 24 * 60 * 60 * 1000;
        let twelveYearsAgo: Date = new Date(todayDate.getFullYear() - 17, todayDate.getMonth(), todayDate.getDate());
        let twoYearsAgo: Date = new Date(todayDate.getFullYear() - 2, todayDate.getMonth(), todayDate.getDate());
        let twoyesrDiff = Math.round(Math.abs((todayDate.getTime() - twoYearsAgo.getTime()) / oneDay));
        let differenceInMs: number = todayDate.getTime() - twelveYearsAgo.getTime();
        let days: number = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
        let diff2 = Math.floor((todayDate.getTime() - dob.getTime()) / oneDay);

        if (diff2 > days) {
          let ageGroup = this.ageGroupList.filter(item => item.id === 'ADT');
          this.searchService.ageGroupArray[i] = ageGroup[0];
          return this.searchService.ageGroupArray[i].value
        } else if (diff2 >= twoyesrDiff && diff2 <= days) {
          let ageGroup = this.ageGroupList.filter(item => item.id === 'CHD');
          this.searchService.ageGroupArray[i] = ageGroup[0];
          return this.searchService.ageGroupArray[i].value
        } else if (diff2 < twoyesrDiff) {
          let ageGroup = this.ageGroupList.filter(item => item.id === 'INF');
          this.searchService.ageGroupArray[i] = ageGroup[0];
          return this.searchService.ageGroupArray[i].value
        }
      } else if (userid === this.searchService.employeeEmail[i].email && this.userAccountInfoService.getAccountInfo()) {
        let userInfoDTO = this.userAccountInfoService.getAccountInfo().userInfo;
        let dob;
        if (userInfoDTO && userInfoDTO.dob) {
          dob = new Date(userInfoDTO.dob);

          let todayDate;
          let leg1DateControl = this.searchForm.controls['departureDate'];
          if (leg1DateControl.value) {
            todayDate = leg1DateControl.value;
          } else {
            todayDate = new Date();
          }
          const oneDay = 24 * 60 * 60 * 1000;
          let twelveYearsAgo: Date = new Date(todayDate.getFullYear() - 17, todayDate.getMonth(), todayDate.getDate());
          let twoYearsAgo: Date = new Date(todayDate.getFullYear() - 2, todayDate.getMonth(), todayDate.getDate());
          let twoyesrDiff = Math.round(Math.abs((todayDate.getTime() - twoYearsAgo.getTime()) / oneDay));
          let differenceInMs: number = todayDate.getTime() - twelveYearsAgo.getTime();
          let days: number = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
          let diff2 = Math.floor((todayDate.getTime() - dob.getTime()) / oneDay);

          if (diff2 > days) {
            let ageGroup = this.ageGroupList.filter(item => item.id === 'ADT');
            this.searchService.ageGroupArray[i] = ageGroup[0];
            return this.searchService.ageGroupArray[i].value
          } else if (diff2 >= twoyesrDiff && diff2 <= days) {
            let ageGroup = this.ageGroupList.filter(item => item.id === 'CHD');
            this.searchService.ageGroupArray[i] = ageGroup[0];
            return this.searchService.ageGroupArray[i].value
          } else if (diff2 < twoyesrDiff) {
            let ageGroup = this.ageGroupList.filter(item => item.id === 'INF');
            this.searchService.ageGroupArray[i] = ageGroup[0];
            return this.searchService.ageGroupArray[i].value
          }
        } else {
          if (!this.searchService.ageGroupArray[i]) {
            return 'Select age group';
          } else {
            return this.searchService.ageGroupArray[i].value;
          }
        }

      } else {
        if (!this.searchService.ageGroupArray[i]) {
          return 'Select age group';
        } else {
          return this.searchService.ageGroupArray[i].value;
        }
      }
    } else {
      if (!this.searchService.ageGroupArray[i]) {
        return 'Select age group';
      } else {
        return this.searchService.ageGroupArray[i].value;
      }
    }
  }
  openDropdown(item, item1) {
    let open = this.getItemDob(item1);
    if (!open && !this.bookingService.previousTransactionId) {
      item.toggle();
    }
  }
  getItemDob(item1) {
    let userid = this.userAccountInfoService.getUserEmail();
    if (item1 && item1.email !== 'GUEST') {
      let employee: any = this.searchService.travellerList.filter(item => item.userId === item1.email)
      if (employee && employee.length > 0 && employee[0].dob) {
        return true;
      } else if (userid === item1.email && this.userAccountInfoService.getAccountInfo()) {
        let userInfoDTO = this.userAccountInfoService.getAccountInfo().userInfo;
        if (userInfoDTO && userInfoDTO.dob) {
          return true;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  handleKeyDown1(event) {
    if (event && event.email) {
      this.showEmployeeTypeChanged(event.email);
    }

  }
  showEmployeeTypeChanged(event) {
    let userid = this.userAccountInfoService.getUserEmail();
    let finndIndex = this.searchService.employeeOptions[0].findIndex(item => item.email === event)
    if (this.searchService.employeeOptions[0][finndIndex].email === userid) {
      if (this.searchService.infantBookingAllowed && this.searchService.ageGroupArray.length > 0) {
        this.searchService.ageGroupArray[this.searchService.employeeEmail.length] = this.searchService.ageGroupArray[0];
      }
      this.searchService.employeeEmail.unshift(this.searchService.employeeOptions[0][finndIndex]);

    } else {
      this.searchService.employeeEmail.push(this.searchService.employeeOptions[0][finndIndex]);
    }

    for (let item of this.searchService.employeeEmail) {
      if (item.email !== "GUEST") {
        this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
      }
      if (this.ngselect.isOpen) {
        this.ngselect.toggle();

      }
    }
    console.log("employee selected", this.searchService.employeeEmail, this.searchService.employeeOptions[0])

    if (this.viewMode === 'tab2') {
      // this.searchHotelForm.controls['personCount'].setValue("" + this.searchService.employeeEmail.length);
      this.searchHotelForm.controls['roomCount'].setValue("" + this.searchService.employeeEmail.length);
    }
    this.searchForm.controls['passengers'].setValue("" + this.searchService.employeeEmail.length);
    this.searchForm.controls['adults'].setValue("" + this.searchService.employeeEmail.length);
    let storagePassangerData: any = this.gallopLocalStorage.getItem('passengersFormData');
    if (storagePassangerData && (storagePassangerData != null)) {
      this.gallopLocalStorage.removeItem('passengersFormData');

    }
  }
  showDropDoown() {
    if (this.searchService.multiTripBooking || this.bookingService.previousBooking) {
      return false;
    }else if ((this.viewMode === 'tab1' || this.viewMode === 'tab5') && this.searchService.employeeEmail.length !== environment.maxTravellersForFlight ) {
      return true;
    } else if ((this.viewMode === 'tab2') && this.searchService.employeeEmail.length !== environment.maxNumRoomsForHotels) {
      return true;
    }
    else if ((this.viewMode !== 'tab1' && this.viewMode !== 'tab2' && this.viewMode !== 'tab5') && this.searchService.employeeEmail.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  changeStyleOfheight() {
    if (this.viewMode === 'tab3') {
      return { 'height': '50px' };
    } else {
      return { 'height': 'auto' };
    }
  }
  isdropDownOpen() {
    this.dropDownopen = true;
    this.search = this.translateService.instant('search.AddTraveler');
  }
  closeDropdown() {
    let userid = this.userAccountInfoService.getUserEmail();
    if (this.searchService.employeeEmail.length === 0 && !this.isUserCompanyTravelManager() && !this.userAccountInfoService.isUserCorporateAdmin()) {
      this.searchService.employeeEmail[0] = { Name: 'search.Myself', Id: 0, email: userid };

      for (let item of this.searchService.employeeEmail) {
        if (item.email !== "GUEST") {
          this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email !== item.email);
        }
        this.searchForm.controls['adults'].setValue("" + this.searchService.employeeEmail.length);
        this.searchForm.controls['passengers'].setValue("" + this.searchService.employeeEmail.length);
      }
      let storagePassangerData: any = this.gallopLocalStorage.getItem('passengersFormData');
      if (storagePassangerData && (storagePassangerData != null)) {
        this.gallopLocalStorage.removeItem('passengersFormData');

      }
    }
    if (this.viewMode === 'tab2') {
      // this.searchHotelForm.controls['personCount'].setValue("" + this.searchService.employeeEmail.length);
      this.searchHotelForm.controls['roomCount'].setValue("" + this.searchService.employeeEmail.length);
    }
    this.dropDownopen = false;
  }
  searchByApproverNameAndEmailChanged(term: string, item: any) {
    term = term.toLowerCase();
    return (item.Name && item.Name.toLowerCase().indexOf(term) > -1) || (item.employeeIdentifier && item.employeeIdentifier.toLowerCase().indexOf(term) > -1) || item.email.toLowerCase().indexOf(term) > -1;

  }
  onAutoCompleteClose(eventObject, formcontrolname: string, parentDivId) {
    if (!this.valueSelectedFromDropDown) {
      let form = { ...this.searchForm };
      let inputText: any = $('#' + parentDivId).find('input[type=text]').val();
      let itemList;
      if (formcontrolname === 'source') {
        itemList = this.autoCompleteSourceItems;
      } else {
        itemList = this.autoCompleteDestItems;
      }
      if (itemList && itemList.length > 0 && inputText && inputText.length > 0) {
        let item = itemList.find(
          x => x.code === inputText.toUpperCase()
        );
        if (item) {
          this.onAutoCompleteSelect(item);
          return;
        }
      }
      this.clearAirportField('');
    }
  }
  onFocused(e, formcontrolname: string) {
    this.searchForm.controls[formcontrolname].markAsTouched();
    this.searchForm.controls[formcontrolname + 'AirportCode'].updateValueAndValidity();
    //this.clearAirportField(formcontrolname);
  }
  getAirportCodeFromSearchResponse(input) {
    return input
  }
  getAirportLocationFromSearchRersponse(input) {
    if (input.length > 4) {
      return input;
    }
    return this.getAirportCodeFromSearchResponse(input);
  }

  getAirportListInMobile(index, formcontrolname, elementId) {
    if (this.isMobile) {
      this.searchService.inputId = elementId;
      this.selectIndex = index;
      this.selectFormControlname = formcontrolname;
      this.cdRef.detectChanges();
      setInputOnReadonly(elementId);
      unsetsetFocusOnInputField(elementId)
      this.searchString = '';
      if (index > -1) {
        if (formcontrolname === 'source') {
          var value = (<FormGroup>this.getFormArrayFLightObject(index)).controls['source'].value;
          if (value && value.length > 0) {
            this.searchAirport(value);
            this.searchService.airportSelect = value;
          } else {
            this.searchService.airportSelect = '';
          }
        } else {
          var value = (<FormGroup>this.getFormArrayFLightObject(index)).controls['destination'].value;
          if (value && value.length > 0) {
            this.searchAirport(value);
            this.searchService.airportSelect = value;
          } else {
            this.searchService.airportSelect = '';
          }
        }
      } else {
        if (formcontrolname === 'source') {
          var value = this.searchForm.controls['source'].value;
          if (value && value.length > 0) {
            this.searchAirport(value);
            this.searchService.airportSelect = value;
          } else {
            this.searchService.airportSelect = '';
          }
        } else {
          var value = this.searchForm.controls['destination'].value;
          if (value && value.length > 0) {
            this.searchAirport(value);
            this.searchService.airportSelect = value;
          } else {
            this.searchService.airportSelect = '';
          }
        }
      }
      focusAndOpenKeyboardIos('airportInputOnFullScreen');
    } else {
      this.searchString = '';
      return;
    }
  }
  backToNormalSearch(event) {
    if (event) {
      if (this.selectIndex > -1) {
        this.clearAirportFieldMultiCity('');

      } else {
        this.clearAirportField('');
      }
    }

  }
  searchAirport(term) {
    let curQueryTime = new Date();
    if(this.viewMode == 'tab1'){
    this.searchService.search1(term, 'flights')
      .subscribe(response => {
        if (curQueryTime > this.lastAirportQueryTime) {
          this.lastAirportQueryTime = curQueryTime;
          this.searchString = term;
          let incomingData = this.groupingCityName(JSON.parse(JSON.stringify(response.data)));
          if (this.selectFormControlname === 'source') {
            this.autoCompleteSourceItems = incomingData;
          } else {
            this.autoCompleteDestItems = incomingData;
          }
          this.reOrderAirportListIfInputMatchesAirportCode(term, this.selectFormControlname,
            this.groupingCityName(JSON.parse(JSON.stringify(response.data))));
        }
      })
  }
    else if( this.viewMode == 'tab5') {
      let incomingData = this.groupingCityName();
      let filteredData = this.setAutoCompleteTrainSearchStation(incomingData);
      this.reOrderAirportListIfInputMatchesAirportCode(term, this.selectFormControlname,
       filteredData);
    }
  }
  searchAutoComplete(term: string, formcontrolname: string, index, elementId) {
    let curQueryTime = new Date();
    this.valueSelectedFromDropDown = false;
    this.selectIndex = index;
    this.selectFormControlname = formcontrolname;
    this.cdRef.detectChanges();
    this.searchString = term;
    this.searchService.inputId = elementId;
    if(this.viewMode == 'tab1'){
    this.searchService.search1(term, 'flights')
      .subscribe(response => {
        if (curQueryTime > this.lastAirportQueryTime) {
          this.lastAirportQueryTime = curQueryTime;
          let incomingData = this.groupingCityName(JSON.parse(JSON.stringify(response.data)));
          if (formcontrolname === 'source') {
            this.autoCompleteSourceItems = incomingData;
          } else {
            this.autoCompleteDestItems = incomingData;
          }
          this.reOrderAirportListIfInputMatchesAirportCode(term, formcontrolname,
            this.groupingCityName(JSON.parse(JSON.stringify(response.data))));
        }
      })
    }
      else if( this.viewMode == 'tab5') {
        let incomingData = this.groupingCityName();
       let filteredData =  this.setAutoCompleteTrainSearchStation(incomingData);
        this.reOrderAirportListIfInputMatchesAirportCode(term, formcontrolname,
          filteredData);
      }
  }
  modalOpen1() {
    if (this.ngxSmartModalService.getOpenedModals() &&
      (this.ngxSmartModalService.getOpenedModals().length > 0 && this.isMobile)
    ) {
      let modals = this.ngxSmartModalService.getOpenedModals();
      if (this.searchService.inputId && this.searchService.inputId.search('Dest') !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  modalOpen() {
    if (this.ngxSmartModalService.getOpenedModals() &&
      (this.ngxSmartModalService.getOpenedModals().length > 0 && this.isMobile)
    ) {
      let modals = this.ngxSmartModalService.getOpenedModals();
      if (this.searchService.inputId && this.searchService.inputId.search('Source') !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  reOrderAirportListIfInputMatchesAirportCode(code, formcontrolname, data) {
    let counter = 0;
    let rootCounter = 0;
    for (let item of data) {
      for (let item1 of item.airports) {
        if (item1.code.toLowerCase() === code.toLowerCase()) {
          if (item.airports.length > 1) {
            if (formcontrolname == 'source') {
              const elem =  this.autoCompleteSourceItems.splice(rootCounter, 1);
              this.autoCompleteSourceItems.splice(0, 0, elem[0]);
              return;
            } else {
              const elem =  this.autoCompleteDestItems.splice(rootCounter, 1);
              this.autoCompleteDestItems.splice(0, 0, elem[0]);
              return;
            }
          } else {
            if (counter !== 0) {
              if (formcontrolname == 'source') {
                this.autoCompleteSourceItems.unshift(item);
                this.autoCompleteSourceItems = this.filterArray(this.autoCompleteSourceItems);
                return;
              } else {
                this.autoCompleteDestItems.unshift(item);
                this.autoCompleteDestItems = this.filterArray(this.autoCompleteDestItems);
                return;
              }
            }
          }
        }
        counter++;
      }
      rootCounter++;
    }
  }
  selectAirport(code, formcontrolname, index, data) {
    let counter = 0;
    for (let item of data) {
      for (let item1 of item.airports) {
        if (item1.code.toLowerCase() === code.toLowerCase()) {
          if (index > -1 && !this.isMobile) {
            if (formcontrolname === 'source') {
              var value = (<FormGroup>this.getFormArrayFLightObject(index)).controls['destination'].value;
              if (item1.code === value) {
                this.invalidForm[index].value = true;
                this.invalidForm[index].index = index;
              } else {
                this.invalidForm[index].value = false;
                this.invalidForm[index].index = index;
              }
            } else {
              var value = (<FormGroup>this.getFormArrayFLightObject(index)).controls['source'].value;
              if (item1.code === value) {
                this.invalidForm[index].value = true;
                this.invalidForm[index].index = index;
              } else {
                this.invalidForm[index].value = false;
                this.invalidForm[index].index = index;
              }
            }
            let form: FormGroup = <FormGroup>this.getFormArrayFLightObject(index);
            if (item1.code
            ) {
              form.controls[formcontrolname].setValue(item1.code);
              form.controls[formcontrolname + 'AirportCode'].setValue(item1.code);
              // this.searchForm.patchValue({ ...form });
              if (formcontrolname === 'source') {
                let locationObj = { source: item1.code, location: item1.location };
                this.searchService.sourceLocationForMultilatLng.push(locationObj);
                this.sourceAirportModel = item1.code;
              } else {
                let locationObj = { source: item1.code, location: item1.location };
                this.searchService.destLocationForMultilatLng.push(locationObj);
                this.destinationAirportModel = item1.code;
              }
            }
            return;
          } else {
            if (!this.isMobile) {
              let form = { ...this.searchForm };
              if (item1.code
              ) {
                form[formcontrolname] = item1.code;
                form[formcontrolname + 'AirportCode'] = item1.code;
                this.searchForm.patchValue({ ...form });
                if (formcontrolname === 'source') {
                  let locationObj = { source: item1.code, location: item1.location };
                  this.searchService.sourceLocationForlatLng = locationObj;
                  this.sourceAirportModel = item1.code;
                } else {
                  let locationObj = { source: item1.code, location: item1.location };
                  this.searchService.destLocationForlatLng = locationObj;
                  this.destinationAirportModel = item1.code;
                }
              }
            }
          }
          return;
        } else {
          if (!this.isMobile) {
            if (index > -1) {
              this.clearAirportFieldMultiCity(code);
            } else {
              this.clearAirportField(code);
            }
          }
        }
        counter++;
      }

    }
  }

  filterArray(arr) {
    var tmp = [];
    for (var i = 0; i < arr.length; i++) {
      if (tmp.indexOf(arr[i]) == -1) {
        tmp.push(arr[i]);
      }
    }
    return tmp;
  }
  groupingCityName(data?) {
    var cityArray = [];
    if(this.viewMode == 'tab5' && !data){
     data = this.trainLocations;
    }
    for (let i = 0; i < data.length; i++) {
      var cityDataArray = { city: '', airports: [] };
      let index = cityArray.find(item => item.city === data[i].city);
      if (!index) {
        cityDataArray.city = data[i].city;
        for (let j = 0; j < data.length; j++) {
          if (data[j].city === cityDataArray.city) {
            cityDataArray.airports.push(data[j]);
          }
        }
        cityArray.push(cityDataArray);
      }
    }
    return cityArray;
  }
  clearAirportField(code) {
    let form = { ...this.searchForm };
    form.controls[this.selectFormControlname].setValue(code);
    form.controls[this.selectFormControlname + 'AirportCode'].setValue(code);
    form.controls[this.selectFormControlname].markAsTouched();
    form.controls[this.selectFormControlname + 'AirportCode'].updateValueAndValidity();
    this.searchForm.patchValue({ ...form });
    let inputFieldElementId = '';
    if (this.selectFormControlname === 'source') {
      inputFieldElementId = 'inputFieldIDSourceAirport';
    } else {
      //destination
      inputFieldElementId = 'inputFieldIDDestAirport';
    }
    if (!this.isMobile) {
      setFocusOnInputField(inputFieldElementId, '');
    }

  }

  onAutoCompleteSelectMultiCity(item: any) {
    this.valueSelectedFromDropDown = true;

    let form: FormGroup = <FormGroup>this.getFormArrayFLightObject(this.selectIndex);
    if (item.code
    ) {
      form.controls[this.selectFormControlname].setValue(item.code);
      form.controls[this.selectFormControlname + 'AirportCode'].setValue(item.code);
      if (this.selectFormControlname === 'source') {
        this.sourceAirportModel = item.code;
        let locationObj = { source: item.code, location: item.location };
        this.searchService.sourceLocationForMultilatLng.push(locationObj);
        this.setDateWithTimeZone(item, this.selectIndex)
      } else {
        let locationObj = { source: item.code, location: item.location };
        this.searchService.destLocationForMultilatLng.push(locationObj);
        this.destinationAirportModel = item.code;
      }
      if (this.selectFormControlname === 'source') {
        this.autoCompleteSourceItems = [];
      } else {
        this.autoCompleteDestItems = [];
      }
      setTimeout(() => {
        $('#' + this.searchService.inputId).find('input[type=text]').val(item.code);
      }, 1);
    }
  }
  onAutoCompleteCloseMultiCity(flightIndex: number, eventObject, formcontrolname: string, parentDivId) {
    if (!this.valueSelectedFromDropDown) {
      let form = this.getFormArrayFLightObject(flightIndex);
      let inputText: any = $('#' + parentDivId).find('input[type=text]').val();
      let itemList;
      if (formcontrolname === 'source') {
        itemList = this.autoCompleteSourceItems;
      } else {
        itemList = this.autoCompleteDestItems;
      }
      if (itemList && itemList.length > 0 && inputText && inputText.length > 0) {
        let item = itemList.find(
          x => x.code === inputText.toUpperCase()
        );
        if (item) {
          this.onAutoCompleteSelectMultiCity(flightIndex);
          return;
        }
      }
      this.clearAirportFieldMultiCity('');
    }
  }
  onFocusedMultiCity(flightIndex: number, e, formcontrolname: string) {
    let form: FormGroup = <FormGroup>this.getFormArrayFLightObject(flightIndex);
    form.controls[formcontrolname].markAsTouched();
    form.controls[formcontrolname + 'AirportCode'].updateValueAndValidity();
    //this.clearAirportField(formcontrolname);
  }

  clearAirportFieldMultiCity(code) {
    let form: FormGroup = <FormGroup>this.getFormArrayFLightObject(this.selectIndex);
    form.controls[this.selectFormControlname].setValue(code);
    form.controls[this.selectFormControlname + 'AirportCode'].setValue(code);
    form.controls[this.selectFormControlname].markAsTouched();
    form.controls[this.selectFormControlname + 'AirportCode'].updateValueAndValidity();
    this.invalidForm[this.selectIndex].value = false;
    let inputFieldElementId = '';
    if (this.selectFormControlname === 'source') {
      inputFieldElementId = 'inputFieldIDSourceAirport_' + this.selectIndex;
    } else {
      //destination
      inputFieldElementId = 'inputFieldIDDestAirport_' + this.selectIndex;
    }
    // this.autoCompleteSourceItems = [];
    //this.autoCompleteDestItems = [];
    if (!this.isMobile) {
      setFocusOnInputField(inputFieldElementId, '');
    }
  }

  setDefaultOrigin(address: string, city: string) {
    this.searchForm.controls['source'].setValue(address);
    this.searchForm.controls['sourceCity'].setValue(city);
  }

  //depricated
  subscribeToServerSideFilterValueChanges() {
    if (this.componentType == 'request') { return; }

    this.searchForm.controls['trip'].valueChanges.subscribe((val) => {
      this.searchForm.patchValue({ ...this.searchForm });
      this.submitForm()
    });

    this.searchForm.controls['passengers'].valueChanges.subscribe((val) => {
      this.searchForm.patchValue({ ...this.searchForm });
      this.submitForm()
    }
    );
    this.searchForm.controls['class'].valueChanges.subscribe((val) => {
      this.searchForm.patchValue({ ...this.searchForm });
      this.submitForm()
    });
  }

  get departDate() {
    return this.searchForm.get('departureDate').value;
  }

  get arriveDate() {
    return this.searchForm.get('arrivalDate').value;
  }

  departLeg2Date(i) {
    return (<FormGroup>this.getFormArrayFLightObject(i)).get('departureDate').value;
  }
  isSourceDestinationSame(i) {
    var value = (<FormGroup>this.getFormArrayFLightObject(i)).controls['source'].value;
    var value1 = (<FormGroup>this.getFormArrayFLightObject(i)).controls['destination'].value;
    if ((value !== '' && value1 !== '') && value === value1) {
      return true;
    } else {
      false
    }
  }
  get formIfInvalid() {
    let Valid = false;
    let control = <FormArray>this.searchForm.controls.flights;
    if (control && control.length > 0) {
      for (let i = 0; i < control.length; i++) {
        var value = (<FormGroup>this.getFormArrayFLightObject(i)).controls['source'].value;
        var value1 = (<FormGroup>this.getFormArrayFLightObject(i)).controls['destination'].value;
        if (value === value1) {
          Valid = true;
        }
      }
    }
    if (Valid) {
      return true;
    } else if (this.searchService.employeeEmail.length === 0) {
      return true;
    } else if (this.searchService.employeeEmail.length > 0 && this.searchService.infantBookingAllowed && this.searchService.employeeEmail.length !== this.searchService.ageGroupArray.length) {

      return true;

    }
    else {
      return this.searchForm.invalid;
    }
  }

  get formHotelIfInvalid() {
    if (this.searchService.employeeEmail.length === 0) {
      return true;
    } else {


      return this.searchHotelForm.invalid;
    }
  }

  allowAddFlight(): boolean {
    //For MVP: to restrict only till two way flights
    return (this.searchFormObjectsCount) <= 1 ? true : false;
  }
  showSelectedFlight(index): boolean {
    if (this.searchService.multipleOutgoingSelectedFlight.length > 0 && this.searchService.seletedIndex === (index + 2)) {
      return true;
    } else {
      return false;
    }
  }
  getInfantAdyltCount() {
    if (this.searchService.infantBookingAllowed) {
      let selectedAgeGroup = [];
      let adults = 0;
      let infants = 0
      let children = 0;
      if (this.searchService.ageGroupArray.length > 0) {
        for (let item of this.searchService.ageGroupArray) {
          if (item.id === 'ADT') {
            adults++;
          } else if (item.id === 'CHD') {
            children++;
          } else if (item.id === 'INF') {
            infants++;
          }
          selectedAgeGroup.push(item);
        }


      }
      if (infants > 0) {
        if (infants > adults) {
          return true;
        } else {
          return false
        }
      } else {
        return false;
      }
    }
    return false;
  }
  originalresponseForFutureBooking = [];
  overlapBookings = [];
  bsModalRefTripsModal: BsModalRef;
  @ViewChild("futureTripDetsils", { static: true }) futureTripDetsils: ElementRef;
  filterDataForTCheckingDuplicateBooking(response, firstReportDate, lastReportDate, type) {
    let firstDate = this.getDateForOverlapCheck(firstReportDate);
    let lastDate = this.getDateForOverlapCheck(lastReportDate);
    return this.filterTRavellersByDate(response, firstDate, lastDate, type);
  }
  getIsoFromMMDDYYY(input) {
    const dateParts = input.split("/");
    return dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1] ;
  }
  getDateForOverlapCheck(date) {
    return new Date(moment(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000).format());
  }
  filterTRavellersByDate(response, startDate, endDate, type) {
    let filteredReport = JSON.parse(JSON.stringify(response));
    filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
      let travelStartDate = new Date(CommonUtils.getTravelDate(booking));
      travelStartDate = new Date(travelStartDate.toDateString());
      let travelEndDate = null;
      if (type === 'flight' && booking.type === 'flight') {
        const lastLeg = booking.option.flight_option.legs[booking.option.flight_option.legs.length - 1];
        const lastHop = lastLeg.hops[lastLeg.hops.length -1];
        travelEndDate = this.getDateForOverlapCheck(lastHop.ends.split('T')[0]);
        travelStartDate = this.getDateForOverlapCheck(booking.option.flight_option.legs[0].hops[0].starts.split('T')[0]);
        if (travelStartDate >= startDate && travelStartDate < endDate) {
          return true;
        }
        if (travelEndDate > startDate && travelEndDate <= endDate) {
          return true;
        }
        if (travelStartDate <= startDate && travelEndDate >= endDate) {
          return true;
        }
      }
      if (type === 'hotel' && booking.type === 'hotel') {
        travelEndDate = this.getDateForOverlapCheck(booking.option.hotel_option.checkOutDate);
        travelStartDate = this.getDateForOverlapCheck(booking.option.hotel_option.checkInDate);
        if (travelStartDate >= startDate && travelStartDate < endDate) {
          return true;
        }
        if (travelEndDate > startDate && travelEndDate <= endDate) {
          return true;
        }
        if (travelStartDate <= startDate && travelEndDate >= endDate) {
          return true;
        }

      }
      if (type === 'cars' && booking.type === 'cars') {
        travelEndDate = this.getDateForOverlapCheck(this.getIsoFromMMDDYYY(booking.option.car_option.dropOffDate));
        travelStartDate = this.getDateForOverlapCheck(this.getIsoFromMMDDYYY(booking.option.car_option.pickUpDate));
        if (travelStartDate >= startDate && travelStartDate < endDate) {
          return true;
        }
        if (travelEndDate > startDate && travelEndDate <= endDate) {
          return true;
        }
        if (travelStartDate <= startDate && travelEndDate >= endDate) {
          return true;
        }
      }

      return false;
    });
    return filteredReport;
  }
  dismissModal() {
    this.bsModalRefTripsModal.hide();
  }
  duplicatBookingSubscription: Subscription;
  handleBackFromSearchPage(event) {
    this.isThisDuplicateTrip(this.futureTripDetsils, 'car', event);
  }
  isThisDuplicateTrip(modal, type, event?) {
    let emails = [];
    for (let item of this.searchService.employeeEmail) {
      emails.push(item.email);

    }
    if (this.duplicatBookingSubscription) {
      this.duplicatBookingSubscription.unsubscribe();
    }

    this.duplicatBookingSubscription = this.adminPanelService.getFutureTripsOfTravellers(emails).subscribe(resp => {
      if (resp && resp.success) {
        this.airlines = resp.data.airlineNames;
        this.airports = resp.data.airports;
        if (resp.data && resp.data.bookingList && resp.data.bookingList.length > 0) {
          this.overlapBookings = [];
          this.originalresponseForFutureBooking = resp.data;
          let selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"))
          let selectedCar = JSON.parse(this.gallopLocalStorage.getItem("selectedCar"))
          let selectedHotel = JSON.parse(this.gallopLocalStorage.getItem("selectedHotel"));
          if (type === 'flight') {
            if (this.searchForm.controls['trip'].value === 'MULTICITY') {
              let queryAsJSON = JSON.parse(JSON.stringify(this.searchForm.value));
              if ('flights' in queryAsJSON && queryAsJSON['flights'].length > 0) {

                let departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.departureDate)) + "T00:00:00.000Z";
                let arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON['flights'][queryAsJSON['flights'].length - 1].departureDate)) + "T00:00:00.000Z";



                let fligthFound = this.filterDataForTCheckingDuplicateBooking(this.originalresponseForFutureBooking, departureDate.split('T')[0], arrivalDate.split('T')[0], 'flight');
                if (fligthFound.bookingList.length > 0) {
                  this.overlapBookings = [...this.overlapBookings, ...fligthFound.bookingList]
                }
              }
            } else {
              let queryAsJSON = JSON.parse(JSON.stringify(this.searchForm.value));
              // 
              queryAsJSON.departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.departureDate)) + "T00:00:00.000Z";
              if (this.searchForm.controls['trip'].value === 'ROUNDTRIP') {
                queryAsJSON.arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.arrivalDate)) + "T00:00:00.000Z";
              } else {
                queryAsJSON.arrivalDate = queryAsJSON.departureDate;
              }
              let fligthFound = this.filterDataForTCheckingDuplicateBooking(this.originalresponseForFutureBooking, queryAsJSON.departureDate.split('T')[0], queryAsJSON.arrivalDate.split('T')[0], 'flight')
              if (fligthFound.bookingList.length > 0) {
                this.overlapBookings = [...this.overlapBookings, ...fligthFound.bookingList]
              }
            }

          }

          if (type === 'car') {
            let pickUpDate = new DatePipe('en-US').transform(new Date(event.pickUpDate), 'yyyy-MM-dd');
            let dropOffDate = new DatePipe('en-US').transform(new Date(event.dropOffDate), 'yyyy-MM-dd');
            let fligthFound = this.filterDataForTCheckingDuplicateBooking(this.originalresponseForFutureBooking, pickUpDate, dropOffDate, 'cars');
            if (fligthFound.bookingList.length > 0) {
              this.overlapBookings = [...this.overlapBookings, ...fligthFound.bookingList]
            }



          }
          if (type === 'hotel') {
            let queryAsJSON = JSON.parse(JSON.stringify(this.searchHotelForm.value));
            queryAsJSON.checkinDate = new DatePipe('en-US').transform(new Date(queryAsJSON.checkinDate), 'yyyy-MM-dd');
            queryAsJSON.checkoutDate = new DatePipe('en-US').transform(new Date(queryAsJSON.checkoutDate), 'yyyy-MM-dd');
            let fligthFound = this.filterDataForTCheckingDuplicateBooking(this.originalresponseForFutureBooking, queryAsJSON.checkinDate, queryAsJSON.checkoutDate, 'hotel');
            if (fligthFound.bookingList.length > 0) {
              this.overlapBookings = [...this.overlapBookings, ...fligthFound.bookingList]
            }



          }
          this.searchService.overlapBookingsList = [];
          if (this.overlapBookings.length > 0) {
            this.searchService.overlapBookingsList = this.overlapBookings;
            console.log("Overlap Bookings  List", this.searchService.overlapBookingsList);
            if (this.bsModalRefTripsModal) {
              this.bsModalRefTripsModal.hide();
            }
            this.bsModalRefTripsModal = this.modalService.show(modal, {
              initialState: {

              }, backdrop: true, keyboard: false, ignoreBackdropClick: true
            })
          }
          console.log("future booking ", resp, this.overlapBookings);
        }
      }

    })
  }
  getConfirmationNo(option): string {
    let confirmNo = '';
    const item = option


    if (item.type === 'flight') {
      if (item.option.flight_option.cancellationRequestReceived) { return 'cancelled'; };
      for (let leg of item.option.flight_option.legs) {
        for (let hop of leg.hops) {
          if (hop.pnr) {
            if (!confirmNo.match(hop.pnr)) {
              confirmNo = confirmNo + hop.pnr + ',';
            };
          };
        };
      };

      var trim = confirmNo.replace(/(^,)|(,$)/g, "");
      return trim;
    } else if (item.type === 'hotel') {
      if (item.option.hotel_option.cancellationRequestReceived) { return 'cancelled'; };
      return item.option.hotel_option.bookingId;
    } else {
      if (item.option.car_option.cancellationRequestReceived) { return 'cancelled'; };
      return item.option.car_option.bookingId;

    }
  }
  airplaneData: any;
  airports: any;
  airlines: any;
  tripSelectedToshowOnpopUp: any;
  selectedLegIndexForPopUp: any;
  bsModalRefForUpcomingTrip: BsModalRef;
  openItinenary(bookingOption) {
    let url = '/bookingHistory?type=detail&bookingType=upcoming&ticketid=' + bookingOption.ticketid + '&tripid=' + bookingOption.tripid + '&transactionid=' + bookingOption.option.selectTransId;
    if (this.njoySpecificBuild || this.isMobile) {
      if (bookingOption.type == "flight") {
        this.airplaneData = bookingOption;
        this.tripSelectedToshowOnpopUp = bookingOption;
        this.tripSelectedToshowOnpopUp['type'] = bookingOption.type;
      } else if (bookingOption.type == "cars") {

        this.tripSelectedToshowOnpopUp = bookingOption.option.car_option;
        this.tripSelectedToshowOnpopUp['type'] = 'car';

      } else {
        this.tripSelectedToshowOnpopUp = bookingOption.option.hotel_option;
        this.tripSelectedToshowOnpopUp['type'] = 'hotel';
      }
      this.bsModalRefForUpcomingTrip = this.modalService.show(SeeDetailsComponent, {
        initialState: {
          airlines: this.airlines,
          airports: this.airports,
          airplaneData: this.airplaneData,
          tripSelectedToshowOnpopUp: this.tripSelectedToshowOnpopUp,
          isMobile: this.isMobile,
          comingFromSearchPage: true

        },
      });

    } else {
      window.open(url, "_blank");
    }
  }
  submitForm(content?,modal?): void {
    if(this.searchInProgress){
      return;
    }
    // if (this.userAccountInfoService.isConnected) {
    if (this.searchService.employeeEmail.length > 0 && this.searchService.infantBookingAllowed && this.searchService.employeeEmail.length === this.searchService.ageGroupArray.length) {
      let atlestOneAdult = this.searchService.ageGroupArray.findIndex(item => item.id === 'ADT');
      if (atlestOneAdult === -1) {
        this.toastr.error("Please select at least 1 adult traveler");
        return;
      }

    }
    this.bookingService.showKtn = false;
    if(this.searchService.selectedEventID){
      this.searchService.isEventOutOfPolicy =false;
    }
    if (this.searchService.selectedCreditAirline) {
      this.searchService.selectedCreditAirline = undefined;
    }
    if (this.getInfantAdyltCount()) {
      this.toastr.error("Only 1 infant is allowed per adult traveler");
      return;
    }
    if (this.isResult) {
      this.setIsCollapsed(true);
      this.searchRequest.emit();
    }

    this.searchService.outgoingSelectedFlight = undefined;
    this.searchInProgress = true;
    if ((this.userAccountInfoService.isUserCorporateAdmin() || this.userAccountInfoService.isUserIsTravelManager()) && !this.bookingService.previousTransactionId) {
      this.searchService.bookingAndApprovalDone = true;
    }
    if (this.bookingService.previousTransactionId) {
      this.searchService.searchStart = true;
    } else {
      this.bookingService.previousTransaction = undefined;
    }
    if (!this.searchService.multiTripBooking && this.bookingService.previousTransactionId == undefined) {
      this.searchService.tripName = '';
    }
    if(this.viewMode == 'tab1') {this.isThisDuplicateTrip(this.futureTripDetsils,'flight');}
    this.searchService.comingFromSelectionPAge =false;
    this.searchHotelService.previousSearch = null;
    this.userAccountInfoService.initResponseReceivedFoorSearchPage = true;
    this.searchService.searchHeaderCliked = false;
    this.bookingService.bookingData = undefined;
    this.searchService.bookingDone = false;

    this.searchService.hotelbookingDone = false;
    this.userAccountInfoService.doNotSaveProfile = false;
    this.userAccountInfoService.paymentPageSave = false;
    this.searchService.seletedIndex = 0;
    this.searchService.selectedFareIndex=1;
    if(this.viewMode == 'tab5'){
      this.thankyoumodal = modal;
    }
    this.searchService.emptyIntervalID();
    this.goToSearchResults();

  }
  calculateAge(dateOfBirth: Date, currentDate: Date): number {
    const dob = new Date(dateOfBirth);
    const now = new Date(currentDate);

    let age = now.getFullYear() - dob.getFullYear();
    const monthDiff = now.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
      age--;
    } else if (monthDiff < 0 || (monthDiff === 0 && now.getDate() > dob.getDate())) {
      age++;
    }

    return age;
  }

  isAgeEighteenPlus() {
    let userid = this.userAccountInfoService.getUserEmail();
    let found = false;
    let count = 0
    for (let item1 of this.searchService.employeeEmail) {

      if (item1.email !== 'GUEST') {
        let employee: any = this.searchService.travellerList.filter(item => item.userId === item1.email)
        if (employee && employee.length > 0 && employee[0].dob) {
          employee = employee[0];
          let dob = new Date(employee.dob);
          let todayDate;
          if (this.searchHotelForm.controls['checkinDate'].value) {
            todayDate = this.searchHotelForm.controls['checkinDate'].value;
          } else {
            todayDate = new Date();
          }
          const oneDay = 24 * 60 * 60 * 1000;
          let twelveYearsAgo: Date = new Date(todayDate.getFullYear() - 18, todayDate.getMonth(), todayDate.getDate());
          let differenceInMs: number = todayDate.getTime() - twelveYearsAgo.getTime();
          let days: number = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
          let diff2 = Math.floor((todayDate.getTime() - dob.getTime()) / oneDay);
          if (diff2 <= days) {
            count = 1;
            break;
          }
        } else if (userid === this.searchService.employeeEmail[0].email && this.userAccountInfoService.getAccountInfo()) {
          let userInfoDTO = this.userAccountInfoService.getAccountInfo().userInfo;
          let dob;
          if (userInfoDTO && userInfoDTO.dob) {
            dob = new Date(userInfoDTO.dob);

            let todayDate;
            if (this.searchHotelForm.controls['checkinDate'].value) {
              todayDate = this.searchHotelForm.controls['checkinDate'].value;
            } else {
              todayDate = new Date();
            }
            const oneDay = 24 * 60 * 60 * 1000;
            let twelveYearsAgo: Date = new Date(todayDate.getFullYear() - 18, todayDate.getMonth(), todayDate.getDate());
            let differenceInMs: number = todayDate.getTime() - twelveYearsAgo.getTime();
            let days: number = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
            let diff2 = Math.floor((todayDate.getTime() - dob.getTime()) / oneDay);
            if (diff2 <= days) {
              count = 1;
              break;
            }

          }

        }

      }
    }
    if (count == 1) {
      found = true;
    }
    return found;
  }
  submitHotelForm(): void {
    //  if (this.userAccountInfoService.isConnected) {
    var todayDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    var checkInDate = this.searchHotelForm.controls['checkinDate'].value;
    var diff2 = Math.round(Math.abs((todayDate.getTime() - checkInDate) / oneDay));
    this.userAccountInfoService.doNotSaveProfile = false;
    if ((this.userAccountInfoService.isUserCorporateAdmin() || this.userAccountInfoService.isUserIsTravelManager()) && !this.bookingService.previousTransactionId) {
      this.searchService.bookingAndApprovalDone = true;
    }
    if (this.isAgeEighteenPlus()) {
      this.toastr.error("Hotel bookings are allowed only for travelers with age of 18+ years");
      return
    }
    this.userAccountInfoService.paymentPageSave = false;
    this.searchService.comingFromSelectionPAge = false;
    this.searchService.searchHeaderCliked = false;
    this.isThisDuplicateTrip(this.futureTripDetsils, 'hotel');
    this.bookingService.bookingData = undefined;
    this.searchService.bookingDone = false;
    this.searchService.hotelbookingDone = false;
    if (!this.searchService.multiTripBooking && this.bookingService.previousTransactionId == undefined) {
      this.searchService.tripName = '';
    } else {
      let seatArray = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
      if (seatArray && seatArray.length > 0) {
        //  seatArray.pop();
        this.gallopLocalStorage.removeItem("selectedSeat");
      }
    }
    if (this.isResult) {
      this.setIsCollapsed(true);
      this.searchRequest.emit();
      this.showCurrentLocation = false;
    }
    this.searchInProgress = true;
    let value1 = this.searchHotelForm.controls['hotelAddress'].value;
    if (value1 !== 'Your current location') {
      this.searchHotelForm.controls['currentLocation'].setValue("");
    }
    // window.alert(JSON.stringify(this.searchHotelForm.value));
      this.searchService.emptyIntervalID();
    this.goToHotelSearchResults();


  }
  selectCurrentLocation(modal) {
    if (!this.locationDisabled) {
      navigator.geolocation.getCurrentPosition(resp => {

        this.selectedCurrentLocation = true;
        this.currentHotelLocation = 'Current Location';
        this.searchHotelForm.controls['currentLocation'].patchValue(resp.coords.latitude + ',' + resp.coords.longitude);
        this.searchHotelForm.controls['hotelAddress'].patchValue('Your current location');

      },
        err => {
          this.selectedCurrentLocation = false;
          this.locationDisabled = true;
        });
    }
  }
  onModelCancel() {
    this.bsModalRef.hide();
  }
  hotelTabClicked(modal?) {
    // if (this.userAccountInfoService.isConnected) {
    this.searchService.currentTimeZone = null;
    if (this.bookingService.previousBooking && this.bookingService.previousBooking.type !== 'hotel' || this.searchService.multiTripBooking) {
      return;
    }

    if (this.searchService.infantBookingAllowed) {
      this.searchService.ageGroupArray = [];
    }
    this.searchService.isSearchPage = true;
    this.searchHotelForm.controls['roomCount'].setValue("" + this.searchService.employeeEmail.length);
    // this.titleService.setTitle('Hotel Search');
    this.titleService.setTitle(this.translateService.instant('search.HotelSearch'));
    this.userAccountInfoService.initResponseReceivedFoorSearchPage = false;
    this.viewMode = 'tab2';
    if (this.viewMode === 'tab2') {
      // this.searchHotelForm.controls['personCount'].setValue("" + this.searchService.employeeEmail.length);
      this.searchHotelForm.controls['roomCount'].setValue("" + this.searchService.employeeEmail.length);
    }
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: 'hotel',
        },
        replaceUrl: true
      }
    );

  }
  showAllBookings = false;
  isUserCompanyManager() {
    return this.userAccountInfoService.isUserCorporateAdmin();
  }
  isUserCompanyTravelManager() {
    return this.userAccountInfoService.isUserIsTravelManager();
  }
  BookingsTabClicked() {
    //  if (this.userAccountInfoService.isConnected) {
    if (this.bookingService.previousBooking || this.searchService.multiTripBooking) {
      return;
    }
    this.viewMode = 'tab4';
    this.showAllBookings = true;
    this.searchService.comingBackFromDetailsPAge = false;
    this.searchService.showAllBookings = true;
    this.userAccountInfoService.initResponseReceivedFoorSearchPage = false;
    // this.titleService.setTitle('Car Search');
    this.titleService.setTitle(this.translateService.instant('search.ALLBookings'));
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: 'allbookings',
        },
        replaceUrl: true
      }
    );

  }
  carTabClicked() {
    // if (this.userAccountInfoService.isConnected) {
    this.searchService.currentTimeZone = null;
    if (this.bookingService.previousBooking && this.bookingService.previousBooking.type !== 'cars' || this.searchService.multiTripBooking) {
      return;
    }
    if (this.searchService.infantBookingAllowed) {
      this.searchService.ageGroupArray = [];
    }
    this.searchService.isSearchPage = true;
    if (this.searchService.employeeEmail.length > 1) {
      this.searchService.employeeEmail.splice(1);
    }
    this.searchForm.controls['passengers'].setValue("" + this.searchService.employeeEmail.length);
    this.searchForm.controls['adults'].setValue("" + this.searchService.employeeEmail.length);
    this.viewMode = 'tab3';
    this.userAccountInfoService.initResponseReceivedFoorSearchPage = false;
    // this.titleService.setTitle('Car Search');
    this.titleService.setTitle(this.translateService.instant('search.CarSearch'));
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: 'car',
        },
        replaceUrl: true
      }
    );

  }
  flightTabClicked() {
    // if (this.userAccountInfoService.isConnected) {
    this.searchForm.reset();
    this.createForm();
    this.multicity = false;
    this.searchInProgress = false;
    this.searchService.currentTimeZone=null;
    if(this.bookingService.previousBooking && this.bookingService.previousBooking.type !=='flight' || this.searchService.multiTripBooking){
      return;
    }
    this.viewMode = 'tab1';
    if (this.searchService.employeeEmail.length > environment.maxTravellersForFlight) {
      this.searchService.employeeEmail.splice(environment.maxTravellersForFlight);
    }
    this.searchForm.controls['passengers'].setValue("" + this.searchService.employeeEmail.length);
    this.searchForm.controls['adults'].setValue("" + this.searchService.employeeEmail.length);
    //this.titleService.setTitle('Flight Search');
    this.titleService.setTitle(this.translateService.instant('search.FlightSearch'));
    // this.searchForm.controls['trip'].setValue('ONEWAY');
    // this.setInitTripType(this.initResponse,false)
    this.router.navigate(["search"]);
    this.cdRef.detectChanges();

  }
  addPassenger(item, index) {
    if (index == 0) {
      item.number = item.number + 1;
      this.adults = this.adults + 2;
      this.leisureValue = this.leisureValue + 1;
    } else if (index == 1) {
      item.number = item.number + 1;
      this.leisureValue = this.leisureValue + 1;
    } else if (index == 2) {
      item.number = item.number + 1;
      this.leisureValue = this.leisureValue + 1;
      this.infants = this.infants + 1;


    }
  }
  subPassenger(item, index) {
    if (index == 0) {
      item.number = item.number - 1;
      this.adults = this.adults - 2;
      this.leisureValue = this.leisureValue - 1;
    } else if (index == 1) {
      item.number = item.number - 1;
      this.leisureValue = this.leisureValue - 1;
    } else if (index == 2) {
      item.number = item.number - 1;
      this.leisureValue = this.leisureValue - 1;
      this.infants = this.infants - 1;

    }
  }
  //subPassenger(item){
  //if(item.number > 0){
  // item.number=item.number - 1;
  //}
  //}

  setTotalPassengersDetails(modalName) {
    let totalPassangers = 0;
    for (let typeItem of this.passengerTypeSpecific) {
      this.searchForm.controls[typeItem.id].setValue(typeItem.number);
      totalPassangers = totalPassangers + typeItem.number;
    }
    if (totalPassangers <= 9 && this.infants <= this.adults) {
      this.searchForm.controls['passengers'].setValue('0' + totalPassangers);
      this.onSmartModelCancel(modalName);

    }

  }
  searchInProgress: boolean = false;
  setFormGroupDateAsPerConstraint() {
    // this.searchForm.controls['constraintTime'].setValue(null);
    if (this.searchForm.controls['constraint'].value === IntervalType.DEPARTURE_BASED) {
      this.searchForm.controls['departureDate'].setValue(DateUtils.getFormattedDateWithoutTimeZone(new Date(this.departDate)));

      if (!this.isResult) {
        this.searchForm.controls['arrivalDate'].setValue(null);
      }
    } else {
      this.searchForm.controls['arrivalDate'].setValue(DateUtils.getFormattedDateWithoutTimeZone(new Date(this.arriveDate)));

      if (!this.isResult) {
        this.searchForm.controls['departureDate'].setValue(null);
      }
    }
  }

  backToSearch() {
    //  this.titleService.setTitle('Flight Search');
    this.titleService.setTitle(this.translateService.instant('search.FlightSearch'));
    this.searchService.searchHeaderCliked = true;
    if(!this.searchService.selectedEventID){
    this.router.navigate(["search"]);
    }else{
      this.router.navigate(['/search'], {
        queryParams: {eventid:this.searchService.selectedEventID},
        replaceUrl: true
      }); 
    }
  }
  setFormArrayDateAsPerConstraint() {

    let form: AbstractControl;

    for (let i = 0; i < this.formArrayLength; i++) {

      form = this.getFormArrayFLightObject(i);
      // form['constraintTime'] = null;

      if (form['controls'].constraint.value === IntervalType.DEPARTURE_BASED) {
        form['departureDate'] = DateUtils.getFormattedDateWithoutTimeZone(new Date(form['controls'].departureDate.value));

        if (!this.isResult) {
          form['arrivalDate'] = null;
        }
      } else {
        form['arrivalDate'] = DateUtils.getFormattedDateWithoutTimeZone(new Date(form['controls'].arrivalDate.value));

        if (!this.isResult) {
          form['departureDate'] = null;
        }
      }
      this.getFormArrayFLightObject(i).patchValue(form)
    }

  }

  minAgeValidator(control: AbstractControl): ValidationErrors | null {
    let result = null;
    if (control.value) {
      let yearsDiff = DateUtils.getYearsDiffFromNow(control.value);
      if (yearsDiff < Constants.MIN_DOB_AGE) {
        result = { 'lessThanMinAge': true };
      }
    }
    return result;
  }

  getCityNameFromGoogleAddress(address: any) {
    let componentForm = {
      locality: 'long_name',
      sublocality: 'long_name',
      administrative_area_level_2: 'short_name',
      administrative_area_level_4: 'long_name',
      administrative_area_level_3: 'short_name',
      neighborhood: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
    };

    let addressComponentsValues = {};
    // Get each component of the address from the place details
    // and   the corresponding field on the form.
    for (var i = 0; i < address.address_components.length; i++) {
      for (var typeCounter = 0; typeCounter < address.address_components[i].types.length;
        typeCounter++) {
        var addressType = address.address_components[i].types[typeCounter];
        if (componentForm[addressType]) {
          var val = address.address_components[i][componentForm[addressType]];
          addressComponentsValues[addressType] = val;
        }
      }
    }


    let cityName = '';
    if (addressComponentsValues['locality']) {
      cityName = addressComponentsValues['locality'];
    } else if (addressComponentsValues['sublocality']) {
      cityName = addressComponentsValues['sublocality'];
    } else if (addressComponentsValues['administrative_area_level_2']) {
      cityName = addressComponentsValues['administrative_area_level_2'];
    } else if (addressComponentsValues['administrative_area_level_4']) {
      cityName = addressComponentsValues['administrative_area_level_4'];
    } else if (addressComponentsValues['administrative_area_level_3']) {
      cityName = addressComponentsValues['administrative_area_level_3'];
    } else if (addressComponentsValues['neighborhood']) {
      cityName = addressComponentsValues['neighborhood'];
    }
    return cityName;
  }

  addCityFormControlValue(form: any, address: any, formcontrolname: string) {
    form[formcontrolname + 'City'] = this.getCityNameFromGoogleAddress(address);

  }
  handleAddressChange(address: any, formcontrolname: string) {
    let form = { ...this.searchForm };
    form[formcontrolname] = address.formatted_address;
    this.addCityFormControlValue(form, address, formcontrolname);
    this.searchForm.patchValue({ ...form });
  }

  handleAddressChangeMulticity(address: any, formcontrolname: string) {
    let form = { ...this.searchForm.controls['flights']['controls'][0] };
    form[formcontrolname] = address.formatted_address;
    form[formcontrolname + 'City'] = this.getCityNameFromGoogleAddress(address);
    this.searchForm.controls['flights']['controls'][0].patchValue({ ...form });
  }
  getInput(event) {
    if (event) {

    }

  }
  handleHotelAddressChange(address: any, fieldInput) {
    let form = { ...this.searchHotelForm };
    form['hotelAddress'] = fieldInput;
    console.log("hotel address", address, address.geometry.location.lat());
    if (address && address.geometry && address.geometry.location) {
      let latlngObject = { location: { lat: -1, lon: -1 } };
      latlngObject.location.lat = address.geometry.location.lat();
      latlngObject.location.lon = address.geometry.location.lng();
      this.setDateWithTimeZone(latlngObject, -1);
    }

    this.addCityFormControlValue(form, address, 'hotelAddress');
    this.searchHotelForm.patchValue({ ...form });
  }

  handleFormArrayAddressChange(address: any, formControlName: string, formArrayIndex: number) {
    let form = this.getFormArrayFLightObject(formArrayIndex);
    form[formControlName] = address.formatted_address;
    this.addCityFormControlValue(form, address, formControlName);
    (<FormArray>this.searchForm.controls['flights']).at(formArrayIndex).patchValue(form);
  }

  goToSearchResults(): void {
    let userid = this.userAccountInfoService.getUserEmail();
    let sToken = this.userAccountInfoService.getSToken();
   
    this.gallopLocalStorage.removeItem("emergencyContactDetails");
    this.searchService.previousDate = true;
    let queryAsJSON = JSON.parse(JSON.stringify(this.searchForm.value));
    // 
    queryAsJSON.departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.departureDate)) + "T00:00:00.000Z";
    if(queryAsJSON.arrivalDate){
    queryAsJSON.arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.arrivalDate)) + "T00:00:00.000Z";
    }
    if (this.isInputSearchTypeAirport()) {
      queryAsJSON.originType = AddressType.GIVEN_AIRPORT_ONLY;
      queryAsJSON.destinationType = AddressType.GIVEN_AIRPORT_ONLY;
    }
    let selectedTravler = [];
    if (this.searchService.employeeEmail.length > 0) {
      for (let item of this.searchService.employeeEmail) {
        selectedTravler.push(item.email);
      }
    }
    //   queryAsJSON.combo = !this.searchService.nonCombo;
    if (this.searchService.infantBookingAllowed) {
      let selectedAgeGroup = [];
      let adults = 0;
      let infants = 0
      let children = 0;
      if (this.searchService.ageGroupArray.length > 0) {
        for (let item of this.searchService.ageGroupArray) {
          if (item.id === 'ADT') {
            adults++;
          } else if (item.id === 'CHD') {
            children++;
          } else if (item.id === 'INF') {
            infants++;
          }
          selectedAgeGroup.push(item);
        }
        this.searchService.adultCount = adults + children;
        queryAsJSON.adults = adults;
        queryAsJSON.children = children;
        queryAsJSON.infants = infants;

      } else {
        this.searchService.adultCount = selectedTravler.length;
      }
      queryAsJSON.travellerAgeGroup = selectedAgeGroup;
    } else {
      this.searchService.adultCount = selectedTravler.length;
    }
    queryAsJSON.travellerEmails = selectedTravler;
    if (this.searchService.selectedEventID) {
      queryAsJSON.eventid = this.searchService.selectedEventID;
    }
    if ('flights' in queryAsJSON && queryAsJSON['flights'].length > 0) {
      for (let i = 0; i < queryAsJSON['flights'].length; i++) {
        let leg2FlightReq = queryAsJSON['flights'][i];
        leg2FlightReq.departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(leg2FlightReq.departureDate)) + "T00:00:00.000Z";
        leg2FlightReq.arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(leg2FlightReq.departureDate)) + "T00:00:00.000Z";
        if (this.isInputSearchTypeAirport()) {
          leg2FlightReq.originType = AddressType.GIVEN_AIRPORT_ONLY;
          leg2FlightReq.destinationType = AddressType.GIVEN_AIRPORT_ONLY;
        }
      }
    }
    this.searchService.noAvailability = [];
    if(this.viewMode == 'tab1'){
    this.router.navigate(["flights"],
      {
        queryParams:
        {
          query: encodeURIComponent(JSON.stringify(queryAsJSON)),
          step: 0,
          index: this.searchService.seletedIndex
        },
        replaceUrl: this.isResult
      }
    );
    }else {
      let trainSearchDataToSend = this.searchService.getSearchRequest(queryAsJSON);
      console.log("Train Search Query : ",trainSearchDataToSend);
      this.sendSearchDataForTrain(trainSearchDataToSend);
    }
  }

  goToHotelSearchResults(): void {
    let userid = this.userAccountInfoService.getUserEmail();
    let sToken = this.userAccountInfoService.getSToken();
    this.gallopLocalStorage.removeItem("emergencyContactDetails");
    let queryAsJSON = JSON.parse(JSON.stringify(this.searchHotelForm.value));
    queryAsJSON.checkinDate = new DatePipe('en-US').transform(new Date(queryAsJSON.checkinDate), 'yyyy-MM-dd');
    queryAsJSON.checkoutDate = new DatePipe('en-US').transform(new Date(queryAsJSON.checkoutDate), 'yyyy-MM-dd');
    let selectedTravler = [];
    if (this.searchService.employeeEmail.length > 0) {
      for (let item of this.searchService.employeeEmail) {
        selectedTravler.push(item.email);
      }
    }
    //   queryAsJSON.combo = !this.searchService.nonCombo;
    queryAsJSON.travellerEmail = selectedTravler;
    this.router.navigate(["hotels"],
      {
        queryParams:
        {
          query: encodeURIComponent(JSON.stringify(queryAsJSON)),
          step: 0
        },
        replaceUrl: this.isResult
      }
    );
  }
  zIndex(i) {
    var index = 'zIndex' + i;
    return index;
  }
  setDepartureConstraint(item) {
    let params = "?ua_action=TimeTypeMenuItem&ua_Flight=1&ua_item=" + item.value;
    this.searchService.letsTrack(params);
    this.searchForm.controls['constraint'].setValue(item.id);
  }

  setMultiLeg2DepartureConstraint(item, i) {
    let params = "?ua_action=TimeTypeMenuItem&ua_Flight=" + (i + 2) + "&ua_item=" + item.value;
    this.searchService.letsTrack(params);
    (<FormGroup>this.getFormArrayFLightObject(i)).controls['constraint'].setValue(item.id);
  }
  setArrivalConstraint(item) {
    let params = "?ua_action=TimeTypeMenuItem&ua_Flight=2&ua_item=" + item.value;
    this.searchService.letsTrack(params);
    this.searchForm.controls['constraint1'].setValue(item.id);
  }
  setConstraintTimeArrival(id) {
    // 
    let params = "?ua_action=SelectTimeIntervalItem&ua_Flight=2&ua_item=" + id;
    this.searchService.letsTrack(params);
  }
  setConstraintTimeDeparture(id) {
    let params = "?ua_action=SelectTimeIntervalItem&ua_Flight=1&ua_item=" + id;
    this.searchService.letsTrack(params);
  }
  getParams(i) {
    return 'Flight=' + (i + 2);
  }
  setMultiLeg2Constraint(id, i) {
    let params = "?ua_action=SelectTimeIntervalItem&ua_Flight=" + (i + 2) + "&ua_item=" + id;
    this.searchService.letsTrack(params);
    //(<FormGroup>this.getFormArrayFLightObject(i)).controls['constraint'].setValue(value);
  }
  setConstraintTimeDepart(id) {
    // 
    let params = "?ua_action=SelectTimeIntervalItem&ua_Flight=1&ua_item=" + id;
    this.searchService.letsTrack(params);
  }
  setTripType(value: string) {
    this.switchTripType = value === AlgoTypes.LEISURE.toString() ? AlgoTypes.LEISURE : AlgoTypes.PODUCTIVITY;
    this.searchForm.controls['classTypeSwitch'].setValue(value);

  }
  getFlightCount(i) {
    return (i + 2);
  }
  setFormConstraint(value: string, formArrayIndex?: number) {
    // if (formArrayIndex >= 0) {
    //   let form = this.getFormArrayFLightObject(formArrayIndex);
    //   form['constraint'] = value;

    //   (<FormArray>this.searchForm.controls['flights']).at(formArrayIndex).patchValue(form);
    // } else {
    //   this.searchForm.controls['constraint'].setValue(value);
    //   if (value == IntervalType.ARRIVAL_BASED) {
    //     this.onDateChange(this.arriveDate);
    //   }

    //   if (value == IntervalType.DEPARTURE_BASED) {
    //     this.onDateChange(this.departDate);
    //   }
    // }
  }

  getTimeOptions(timeConstraintType: string) {
    let time: number;
    let today = DateUtils.getCurrentDateAsUTCDate();
    let constraintDate;
    if (timeConstraintType == IntervalType.DEPARTURE_BASED) {
      this.timeOptions = Constants.CONSTRAINT_TIME_OPTIONS_FOR_DEPART;
    } else {
      this.timeOptions = Constants.CONSTRAINT_TIME_OPTIONS_FOR_ARRIVE;
    }
  }

  getTimeOptionValue(option) {

    if (option.title == 'Anytime') {
      return "";
    }

    let startAMPM = (option.hours.start < 12 || option.hours.start == 24) ? this.translateService.instant('search.AM') : this.translateService.instant('search.PM');
    let endAMPM = (option.hours.end < 12 || option.hours.end == 24) ? this.translateService.instant('search.AM') : this.translateService.instant('search.PM');

    let start = option.hours.start > 12 ? option.hours.start - 12 : option.hours.start;
    let end = option.hours.end > 12 ? option.hours.end - 12 : option.hours.end;
    start = (start == 0) ? 0 : start;

    return `${start} ${startAMPM} to ${end} ${endAMPM}`;
  }

  getPlaces(query) {
    this.googlePlacesService.search(query).subscribe(data => {
    })
  }

  tripTypeChanged(type?: any) {
    this.totalLeg = this.getAdditionalReqeustControl().length ? this.getAdditionalReqeustControl().length : 1;

    let params = "?ua_action=TripTypeItem&ua_triptype=" + type.id.toLowerCase();
    this.searchService.letsTrack(params);
    if (type.id === 'ROUNDTRIP') {
      this.searchForm.controls['arrivalDate'].setValidators([Validators.required]);
      this.searchForm.controls['arrivalDate'].updateValueAndValidity();
      if (this.searchForm.controls['arrivalDate'].value) {
        this.searchForm.controls['arrivalDate'].setValue(null);
      }
    } else {
      if (this.searchForm.controls['arrivalDate'].value) {
        this.searchForm.controls['arrivalDate'].setValue(null);
      }
      this.searchForm.controls['arrivalDate'].clearValidators();
      this.searchForm.controls['arrivalDate'].updateValueAndValidity();
    }
    if (type.id == 'MULTICITY') {
      // setTimeout(() => {
      this.addFlight();
      this.searchService.isSearchPage = false;
      this.setMinimumDate((0));
      this.multicity = true;
      // }, 10);

    } else {
      var tempArray = [...this.invalidForm];
      this.invalidForm = [{ value: false, index: 0 }];
      this.multicity = false;
      this.searchService.isSearchPage = true;
      while (this.getAdditionalReqeustControl().length !== 0) {
        this.removeFlight(0, tempArray);
      }
    }


  }

  addFlight(flight?: FlightQueryParam) {

    let prefilledReturnDate = new Date();
    // prefilledReturnDate.setDate( this.searchForm.controls['departureDate'].value.getDate() + 7);
    prefilledReturnDate.setDate(prefilledReturnDate.getDate() + 22);
    this.setIsCollapsed(false);

    let formArrayLength: number = this.formArrayLength;
    let destValue = ''
    let sourceValue = ''
    if (formArrayLength > 0) {
      if (this.searchService.sourceLocationForMultilatLng[formArrayLength - 1]) {
        let locationObj = { source: this.searchService.sourceLocationForMultilatLng[formArrayLength - 1].source, location: this.searchService.sourceLocationForMultilatLng[formArrayLength - 1].location };
        this.searchService.destLocationForMultilatLng.push(locationObj);
      }
      if (this.searchService.destLocationForMultilatLng[formArrayLength - 1]) {
        let locationObj1 = { source: this.searchService.destLocationForMultilatLng[formArrayLength - 1].source, location: this.searchService.destLocationForMultilatLng[formArrayLength - 1].location };
        this.searchService.sourceLocationForMultilatLng.push(locationObj1);
      }

      destValue = (this.getFormArrayFLightObject(formArrayLength - 1).get('destinationAirportCode').value)
      if (destValue && destValue.length !== 3) {
        destValue = '';
      }
      sourceValue = (this.getFormArrayFLightObject(formArrayLength - 1).get('sourceAirportCode').value)
      if (sourceValue && sourceValue.length !== 3) {
        sourceValue = '';
      }
    } else {
      destValue = this.searchForm.controls['destinationAirportCode'].value;
      if (this.searchService.sourceLocationForlatLng) {
        let locationObj = { source: this.searchService.sourceLocationForlatLng.source, location: this.searchService.sourceLocationForlatLng.location };
        this.searchService.destLocationForMultilatLng.push(locationObj);
      }
      if (this.searchService.destLocationForlatLng) {
        let locationObj1 = { source: this.searchService.destLocationForlatLng.source, location: this.searchService.destLocationForlatLng.location };
        this.searchService.sourceLocationForMultilatLng.push(locationObj1);
      }
      if (destValue && destValue.length !== 3) {
        destValue = '';
      }
      sourceValue = this.searchForm.controls['sourceAirportCode'].value;
      if (sourceValue && sourceValue.length !== 3) {
        sourceValue = '';
      }
    }

    let formArraySource = (formArrayLength == 0) ? destValue :
      (formArrayLength > 0 ? destValue : '');

    let formArraySourceCity = (formArrayLength == 0) ? sourceValue :
      (formArrayLength > 0 ? this.searchForm.controls['sourceAirportCode'].value : null);
    if (formArraySource === formArraySourceCity) {
      formArraySourceCity = (formArrayLength > 0 ? sourceValue : '')
    }
    if (formArrayLength > 1) {
      formArraySourceCity = null;
    }
    let formArrayDestination = (formArrayLength == 0 ? this.searchForm.controls['source'].value : null);
    let formArrayDestinationCity = (formArrayLength == 0 ? this.searchForm.controls['sourceCity'].value : null);
    let newFlightSearchForm = this.fb.group({
      source: [(flight && flight.source) ? flight.source : formArraySource, Validators.compose([Validators.required, this.spaceValidator])],
      // sourceCity: [(flight && flight['sourceCity']) ? flight['sourceCity'] : formArraySourceCity, Validators.compose([Validators.required, this.spaceValidator])],
      sourceCity: [null],
      sourceAirportCode: [(flight && flight['sourceAirportCode']) ? flight['sourceAirportCode'] : formArraySource, this.isInputSearchTypeAirport() ? Validators.compose([Validators.required, this.spaceValidator]) : null],
      destination: [(flight && flight.destination) ? flight.destination : formArraySourceCity, Validators.compose([Validators.required, this.spaceValidator])],
      // destinationCity: [(flight && flight['destinationCity']) ? flight['destinationCity'] : formArrayDestinationCity, Validators.compose([Validators.required, this.spaceValidator])],
      destinationCity: [null],
      destinationAirportCode: [(flight && flight['destinationAirportCode']) ? flight['destinationAirportCode'] : formArraySourceCity, this.isInputSearchTypeAirport() ? Validators.compose([Validators.required, this.spaceValidator]) : null],
      constraint: [(flight && flight.constraint) ? flight.constraint : IntervalType.DEPARTURE_BASED],
      departureDate: [(flight && flight.departureDate) ? new Date(flight.departureDate) : null, Validators.compose([Validators.required])],
      arrivalDate: [(flight && flight.arrivalDate) ? new Date(flight.arrivalDate) : null],
      constraintTime: [(flight && flight.constraintTime) ? flight.constraintTime : 'Anytime']
    });
    newFlightSearchForm.controls['source'].setValidators([Validators.required, this.spaceValidator, this.minLengthArray(3), this.maxLengthArray(3)]);
    newFlightSearchForm.controls['destination'].setValidators([Validators.required, this.spaceValidator, this.minLengthArray(3), this.maxLengthArray(3)]);
    (<FormArray>this.searchForm.controls['flights']).push(newFlightSearchForm);

  }

  removeFlight(fgIndex: number, tempArray) {
    (<FormArray>this.searchForm.controls['flights']).removeAt(fgIndex);
    if (this.multicity) {
      this.invalidForm.splice(fgIndex, 1);
      for (let i = fgIndex; i < this.getAdditionalReqeustControl().length; i++) {
        this.invalidForm[i].index = i;
        this.invalidForm[i].value = tempArray[i + 1].value;
      }
    }
  }

  fillReturnFlight() {

    setTimeout(() => {
      let returnFlight = this.getFormArrayFLightObject(0);
      if (!returnFlight) return;
      if (returnFlight.value.source !== '' && returnFlight.value.destination !== '') {
        return;
      }

      (<FormArray>this.searchForm.controls['flights']).controls[0].patchValue({
        destination: this.searchForm.controls['source'].value,
        destinationCity: this.searchForm.controls['sourceCity'].value
      });
      (<FormArray>this.searchForm.controls['flights']).controls[0].patchValue({
        source: this.searchForm.controls['destination'].value,
        sourceCity: this.searchForm.controls['destinationCity'].value
      })
    }, 500);
  }

  getFormArrayFLightObject(formArrayIndex: number): AbstractControl {
    return (<FormArray>this.searchForm.controls['flights']).at(formArrayIndex);
  }

  get formArrayLength(): number {
    return (<FormArray>this.searchForm.controls['flights']).length;
  }

  get searchFormObjectsCount(): number {
    return this.formArrayLength + 1;
  }

  patchFormArrayFromQueryParam() {
    // this.searchForm.controls['flights'] = this.fb.array([]);
    let formArrayIndex = 0;
    var arrayValue = { value: false, index: 0 };
    for (let f of this.previousSearch.flights) {
      let flight = f;
      this.invalidForm.push(arrayValue);
      flight.source = flight.source.trim();
      flight.destination = flight.destination.trim();
      this.addFlight(flight);
      let formGroup = (<FormGroup>(<FormArray>this.searchForm.controls['flights']).at(formArrayIndex));
      if (this.isInputSearchTypeAirport()) {
        if (formGroup.controls['sourceAirportCode'].value == null || formGroup.controls['sourceAirportCode'].value.trim() == '') {
          formGroup.controls['sourceAirportCode'].setValue(flight.source);
        }
        if (formGroup.controls['destinationAirportCode'].value == null || formGroup.controls['destinationAirportCode'].value.trim() == '') {
          formGroup.controls['destinationAirportCode'].setValue(flight.destination);
        }
      } else {
        if (formGroup.controls['sourceCity'].value == null || formGroup.controls['sourceCity'].value.trim() == '') {
          formGroup.controls['sourceCity'].setValue(flight.source);
        }
        if (formGroup.controls['destinationCity'].value == null || formGroup.controls['destinationCity'].value.trim() == '') {
          formGroup.controls['destinationCity'].setValue(flight.destination);
        }
      }
      formArrayIndex++;
    }

    if (!this.isInputSearchTypeAirport()) return;
    let fcount = 0;
    for (let f of this.previousSearch.flights) {
      this.prefillInputAirportFields(fcount, f);
      fcount++;
    }
  }

  prefillInputAirportFields(index: number, flight) {
    setTimeout(() => {
      $('#inputFieldIDSourceAirport_' + index).find('input[type=text]').val(flight.source);
      $('#inputFieldIDDestAirport_' + index).find('input[type=text]').val(flight.destination);
    }, 500);

  }
  get isResult() {
    return this.componentType === 'result';
  }

  setIsCollapsed(collapse) {
    this.isCollapsed = collapse;
    this.collapseToggle.emit(this.isCollapsed);
  }
  clearPreviousResults() {
    //     this.searchService.broadCastFlightResponse(null);
    //     this.searchService.broadcastNoOfFlightLegs(0);
  }
  onHidePicker() {
    this.searchService.calendarOpenEventListsner.next(null);
  }

  onShowPicker(event, picker) {
    this.searchService.calendarOpenEventListsner.next(picker);
    this.searchService.previousDate = false;
    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = (hoverEvent) => {
      const { cell, isHovered } = hoverEvent;

      if ((isHovered &&
        !!navigator.platform &&
        /iPad|iPhone|iPod/.test(navigator.platform)) &&
        'ontouchstart' in window
      ) {
        (picker as any)._datepickerRef.instance.daySelectHandler(cell);
      }

      return dayHoverHandler(hoverEvent);
    };
    event.dayHoverHandler = hoverWrapper;
  }

  getDepartureAirportsForLeg(index: number) {

  }

  getArrivalAirportsForLeg(index: number) {

  }

  setLeg1Date(date) {
    this.minimumDateLeg2 = new Date(date);
    if (date && this.searchForm) {
      let leg1DateControl = this.searchForm.controls['departureDate'];
      leg1DateControl.setValue(date);

      let leg2DateControl = this.searchForm.controls['arrivalDate'];
      if (leg2DateControl.value < this.minimumDateLeg2) {
        // this.searchForm.get('arrivalDate').setValue(this.minimumDateLeg2);
      }
      if (date && !this.searchService.previousDate) {
        if (this.getAdditionalReqeustControl().length > 0) {
          for (let i = 0; i < this.getAdditionalReqeustControl().length; i++) {
            if (date > (<FormGroup>this.getFormArrayFLightObject(i)).controls['departureDate'].value) {
              let leg2DateControl = (<FormGroup>this.getFormArrayFLightObject(i)).controls['departureDate']
              leg2DateControl.setValue(date);
              this.setMinimumDate(i)
            }
          }
        } else {
          this.minimumDateMultiple = date;
        }
      }

    }
  }
  setLeg2Date(date) {
    if (date && this.searchForm) {
      let leg2DateControl = this.searchForm.controls['arrivalDate'];
      leg2DateControl.setValue(date);
    }
  }

  setMultiLeg2Date(date, i) {
    if (date) {
      if (this.searchForm) {
        let leg2DateControl = (<FormGroup>this.getFormArrayFLightObject(i)).controls['departureDate']
        leg2DateControl.setValue(date);
        if (this.getAdditionalReqeustControl().length > i && !this.searchService.previousDate) {
          for (let j = (1 + i); j < this.getAdditionalReqeustControl().length; j++) {
            if (date > (<FormGroup>this.getFormArrayFLightObject(j)).controls['departureDate'].value) {
              let leg2DateControl = (<FormGroup>this.getFormArrayFLightObject(j)).controls['departureDate']
              leg2DateControl.setValue(date);
              this.setMinimumDate(j)
            }
          }
        }
      }
    }
  }

  setMinimumDate(i) {
    var index = i - 1;
    if (index >= 0) {
      this.minimumDateMultiple = (<FormGroup>this.getFormArrayFLightObject(index)).get('departureDate').value ? (<FormGroup>this.getFormArrayFLightObject(index)).get('departureDate').value : new Date();
    } else {
      this.minimumDateMultiple = this.searchForm.controls['departureDate'].value ? this.searchForm.controls['departureDate'].value : new Date();
    }
    return this.minimumDateMultiple;
  }
  setCheckInDate(date) {
    if (date) {
      this.minimumDateLeg2 = new Date(date);
      this.minimumDateLeg2.setDate(this.minimumDateLeg2.getDate() + 1);
      if (date) {
        this.maximumDate3 = new Date(date);
        this.maximumDate3.setDate(this.maximumDate3.getDate() + this.searchService.maxAllowedHotelStay);
      }
      if (this.searchHotelForm) {
        let leg1DateControl = this.searchHotelForm.controls['checkinDate'];
        leg1DateControl.setValue(date);
        let leg2DateControl = this.searchHotelForm.controls['checkoutDate']; const oneDay = 24 * 60 * 60 * 1000;
        if (date && leg2DateControl.value < this.minimumDateLeg2) {
          setTimeout(() => {
            this.searchHotelForm.get('checkoutDate').setValue(this.minimumDateLeg2);
          }, 200);
        }
        var checkOutDate = this.searchHotelForm.controls['checkoutDate'].value;
        var currntDate = Math.round(Math.abs((date - checkOutDate) / oneDay));
        if (currntDate > 21) {
          setTimeout(() => {
            this.searchHotelForm.get('checkoutDate').setValue(this.minimumDateLeg2);
          }, 200);

        }
      }
    }
  }
  setCheckOutDate(date) {
    if (date) {
      if (this.searchHotelForm) {
        let leg2DateControl = this.searchHotelForm.controls['checkoutDate'];
        leg2DateControl.setValue(date);
      }
    }
  }

  onHotelTravellerCountChanged(count) {
    if (Number.parseInt(this.searchHotelForm.controls['roomCount'].value) > Number.parseInt(count.id)) {
      this.searchHotelForm.controls['roomCount'].setValue(count.id);
    }
    let params = "?ua_action=NumberOfPeopleMenuItem&ua_item=" + parseInt(count.id) + "&ua_type=hotels";;
    this.searchService.letsTrack(params);
  }

  onHotelRoomCountChanged(count) {
    if (Number.parseInt(this.searchHotelForm.controls['personCount'].value) < Number.parseInt(count.id)) {
      this.searchHotelForm.controls['personCount'].setValue(count.id);
    }
    let params = "?ua_action=NumberOfRoomsMenuItem&ua_item=" + parseInt(count.id);
    this.searchService.letsTrack(params);
  }


  onDateChange(date) {
    if ((<FormArray>this.searchForm.controls['flights']).length == 0) { return; }
    let departureDateControl = (<FormGroup>(<FormArray>this.searchForm.controls['flights']).controls[0]).controls['departureDate'];
    let arrivalDateControl = (<FormGroup>(<FormArray>this.searchForm.controls['flights']).controls[0]).controls['arrivalDate'];

    if (departureDateControl.value > date || arrivalDateControl.value > date) {
      return;
    }

    departureDateControl.setValue(date);
    arrivalDateControl.setValue(date);
  }

  getFlightsFormArrayControl(): AbstractControl[] {
    return (<FormArray>this.searchForm.controls['flights']).controls;
  }

  getClassName(id) {
    let className;

    this.classOptions.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        className = item.value;
        return;
      }
    });

    return className;
  }

  getValueOfId(id, options) {
    let value;
    options.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        value = item.value;
        return;
      }
    });
    return value;
  }

  getValueOfRoomId(id, options) {
    let value;
    options.map(item => {
      if (Number.parseInt(item.id) == Number.parseInt(id)) {
        value = item.value;
        return;
      }
    });
    return value;
  }


  getTimeTypeName(id) {
    let timeTypeName;

    this.timeTypeOptions.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        timeTypeName = item.value;
        // return;
      }
    });

    return timeTypeName;
  }


  getTimeOptionName(id, item) {
    if (item) {
      this.getTimeOptions(item);
    }
    let timeOptionName;
    if (!this.timeOptions) {
      return 'ngOption.Anytime';
    }
    this.timeOptions.map(item => {
      if (item.titleID.toLowerCase() == id.toLowerCase()
        || item.value.toLowerCase() == id.toLowerCase()) {
        timeOptionName = item.value;
        // return;
      }
    });

    return timeOptionName;
  }
  showMutiCity(i, j) {
    if ((i + 1) !== this.searchService.flight.length) {
      return true;
    } else {
      return false;
    }
  }
  getClassName1(event) {
    //
    let className;
    this.classOptions.map(item => {
      if (item.id.toLowerCase() == event.id.toLowerCase()) {
        let params = "?ua_action=ClassMenuItem&ua_item=" + item.value;
        this.searchService.letsTrack(params);
        return;
      }
    });
  }
  getHotelChain(event) {
    let params = (event.id !== '') ? "?ua_action=HotelChainMenuItem&ua_item=" + event.id : "?ua_action=HotelChainMenuItem&ua_item=Any";
    this.searchService.letsTrack(params);
  }
  getTripName(id) {
    let tripName;

    this.tripOptions.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        tripName = item.value;
        return;
      }
    });

    return tripName;
  }

  ngOnDestroy() {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
    if (this.companyApprovalSubscription) {
      this.companyApprovalSubscription.unsubscribe();
    }
    if (this.listEmployeesResponseSubscription) {
      this.listEmployeesResponseSubscription.unsubscribe();
    }
    if (this.listEmployeesResponseSubscription) {
      this.listEmployeesResponseSubscription.unsubscribe();
    }
    if (this.userDetailsSubscription) {
      this.userDetailsSubscription.unsubscribe();
    }
    if (this.urlParameterSubscription) {
      this.urlParameterSubscription.unsubscribe();
    }
    if (this.userInItSubscription) {
      this.userInItSubscription.unsubscribe();
    }
  }

  public handleTripTypeChange(data: any) {

    let adult;
    if (data === 'PODUCTIVITY') {
      adult = +this.searchForm.controls['adults'].value;
      this.searchForm.controls['passengers'].setValue('0' + adult);
      this.setTripType(data);
    } else if (data === 'LEISURE') {
      adult = +this.searchForm.controls['passengers'].value;
      this.adults = 2 * adult;
      this.infants = 0;
      this.leisureValue = +this.searchForm.controls['passengers'].value;
      let index = 0;
      for (let typeItem of this.passengerTypeSpecific) {
        if (index > 0) {
          this.searchForm.controls[typeItem.id].setValue(0);
          typeItem.number = 0;
        } else {
          this.searchForm.controls[typeItem.id].setValue(adult);
          typeItem.number = adult;
        }
        index = index + 1;
      }
      this.setTripType(data);
    }

  }
  getSearchIndex() {
    return this.searchService.seletedIndex;
  }
  getOutgoingSelectedFlight() {
    var selectedFlights;
    if (this.searchService.seletedIndex === 1) {
      if (!this.searchService.nonCombo) {
        this.searchService.flight = this.searchService.multipleOutgoingSelectedFlight;
      } else {
        this.searchService.flight = this.searchService.nonComboSelectedFlight;
      }
    } else if (this.searchService.seletedIndex > 1) {
      if (!this.searchService.nonCombo) {
        selectedFlights = this.searchService.multipleOutgoingSelectedFlight;
        //this.selectedFlights.splice(0,1);
        for(let item of selectedFlights){
             if(item.travelCreditsInfo){
              for(let flight of this.searchService.flight){
                if(!flight.travelCreditsInfo){
                  flight.travelCreditsInfo = item.travelCreditsInfo;
                }
              } 
             }
        }
        this.searchService.flight = selectedFlights;
        for (let i = 0; i < this.searchService.multipleOutgoingSelectedFlight.length; i++) {
          if (this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].displayPrice) {
            this.searchService.flight[i].displayPrice = this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].displayPrice;

          }
          this.searchService.flight[i].price = this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].price;
          this.searchService.flight[i].fareBreakup = this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].fareBreakup;
        }
      } else {
        selectedFlights = this.searchService.nonComboSelectedFlight;
        this.searchService.flight = selectedFlights;
        for (let i = 0; i < this.searchService.nonComboSelectedFlight.length; i++) {
          if (this.searchService.nonComboSelectedFlight[i].displayPrice) {
            this.searchService.flight[i].displayPrice = this.searchService.nonComboSelectedFlight[i].displayPrice;

          }
          this.searchService.flight[i].price = this.searchService.nonComboSelectedFlight[i].price;
          this.searchService.flight[i].fareBreakup = this.searchService.nonComboSelectedFlight[i].fareBreakup;
        }
      }
    }

    return this.searchService.outgoingSelectedFlight;
  }

  flightUnSelected(index) {
    var selectedFlights;
    this.searchService.backClick = false;
    if (index === 0 && this.searchService.priceWithTravelCreditsZero) {
      this.searchService.priceWithTravelCreditsZero = false;
    }
    this.changeSelectedFlight.emit(true);
    this.searchService.flightId.splice(index);
    if (index === 0) {
      this.searchService.nonComboSelectedFlight = [];
      this.searchService.flight = [];
      this.searchService.flightId = [];
      //  this.searchService.outgoingSelectedFlight = undefined;
    } else {
      this.searchService.flight.splice(index);
      if (this.searchService.seletedIndex > 1) {
        this.searchService.nonComboSelectedFlight.splice(index);
        this.searchService.multipleOutgoingSelectedFlight.splice(index);
        if (!this.searchService.nonCombo) {
          selectedFlights = this.searchService.multipleOutgoingSelectedFlight;
          //this.selectedFlights.splice(0,1);
          this.searchService.flight = selectedFlights;
          for (let i = 0; i < this.searchService.multipleOutgoingSelectedFlight.length; i++) {
            if (this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].displayPrice) {
              this.searchService.flight[i].displayPrice = this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].displayPrice;

            }
            this.searchService.flight[i].price = this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].price;
            this.searchService.flight[i].fareBreakup = this.searchService.multipleOutgoingSelectedFlight[(this.searchService.multipleOutgoingSelectedFlight.length - 1)].fareBreakup;
          }
        } else {
          selectedFlights = this.searchService.nonComboSelectedFlight;
          this.searchService.flight = selectedFlights;
          for (let i = 0; i < this.searchService.nonComboSelectedFlight.length; i++) {
            if (this.searchService.nonComboSelectedFlight[i].displayPrice) {
              this.searchService.flight[i].displayPrice = this.searchService.nonComboSelectedFlight[i].displayPrice;

            }
            this.searchService.flight[i].price = this.searchService.nonComboSelectedFlight[i].price;
            this.searchService.flight[i].fareBreakup = this.searchService.nonComboSelectedFlight[i].fareBreakup;
          }
        }
      }
    }
    if (index !== 0 && this.searchService.nonComboSelectedFlight && this.searchService.nonComboSelectedFlight.length > 0) {
      // var creditDetails11 = CommonUtils.getCreditValue(this.searchService.nonComboSelectedFlight[0], this.noOfPassengers);
      if (this.searchService.nonComboSelectedFlight[0].travelCreditsInfo && this.searchService.nonComboSelectedFlight[0].travelCreditsInfo.length > 0) {
        this.searchService.priceWithTravelCreditsZero = true;
      }
    }
    this.searchService.resetOutgoingSelectedFlight(index);
    let queryAsJSON = JSON.parse(JSON.stringify(this.searchService.flightSearchQueryParam));
    queryAsJSON.departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.departureDate)) + "T00:00:00.000Z";
    queryAsJSON.arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.arrivalDate)) + "T00:00:00.000Z";
    if (queryAsJSON.flights && queryAsJSON.flights.length > 0) {
      for (let multiFlightObj of queryAsJSON.flights) {
        multiFlightObj.departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(multiFlightObj.departureDate)) + "T00:00:00.000Z";
        multiFlightObj.arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(multiFlightObj.arrivalDate)) + "T00:00:00.000Z";
      }
    }
    if (this.searchService.flightId.length === 0) {
      this.router.navigate(["flights"],
        {
          queryParams:
          {
            query: encodeURIComponent(JSON.stringify(queryAsJSON)),
            step: 0,
            index: this.searchService.flightId.length,
            resultFound: 1
          },
          replaceUrl: false
        }
      );
      //this.location1.replaceState('/search');
    } else {
      this.router.navigate(["flights"],
        {
          queryParams:
          {
            query: encodeURIComponent(JSON.stringify(queryAsJSON)),
            step: 0,
            index: this.searchService.flightId.length,
            flight: JSON.parse(JSON.stringify(this.searchService.flightId)),
            resultFound: 1
          },
          replaceUrl: false
        }
      );
    }
  }


  handleModalEvents(eventName: string, currentModalId: string) {
    CommonUtils.handleModalEvents(this.ngxSmartModalService, eventName, currentModalId);
  }
  onSmartModelCancel(modelName: string) {
    this.ngxSmartModalService.getModal(modelName).close();
  }

  clearHotelTextField() {
    this.selectedCurrentLocation = false;
    let form = { ...this.searchHotelForm };
    form['currentLocation'] = '';
    form['hotelAddress'] = '';
    form['hotelAddressCity'] = '';
    this.searchHotelForm.patchValue({ ...form });

    this.setSearchForm(-1);
    setFocusOnHotelInputField();
  }
  isSearchFormNonEmpty() {
    return (this.searchHotelForm.controls.hotelAddress
      && this.searchHotelForm.controls.hotelAddress.value
      && this.searchHotelForm.controls.hotelAddress.value.trim().length > 0);
  }

  getCheckinDate() {
    return this.searchHotelForm.controls['checkinDate'].value;
  }
  getCheckoutDate() {
    return this.searchHotelForm.controls['checkoutDate'].value;
  }
  public onHotelAddressFocused() {
    if (this.location_feature_enabled) {
      if (!this.isSearchFormNonEmpty()) {
        this.showCurrentLocation = true;
        return;
      }
    }
    this.showCurrentLocation = false;
  }
  public isAlgoSwitchVisible() {
    return !this.userAccountInfoService.restrictOutsidePolicyBooking();
  }
  public isCarsEnabled() {
    return this.userAccountInfoService.isCarsEnabled;
  }
  public isShowAllBookings() {
    return this.userAccountInfoService.isShowAllBookings();
  }
  trainTabClicked(){
    if(this.searchService.multiTripBooking){
      return;
    }
    this.getStationListForTrain();
    this.searchForm.reset();
    this.createForm();
    this.multicity = false;
      this.viewMode = 'tab5';
       if (this.searchService.employeeEmail.length > environment.maxTravellersForFlight) {
         this.searchService.employeeEmail.splice(environment.maxTravellersForFlight);
       }
       this.searchForm.controls['passengers'].setValue("" + this.searchService.employeeEmail.length);
       this.searchForm.controls['adults'].setValue("" + this.searchService.employeeEmail.length);
       this.cdRef.detectChanges();
       this.titleService.setTitle("Train Search.");
       this.router.navigate(["search"],
         {
           queryParams:
           {
             type: 'train',
           },
           replaceUrl: true
         }
     );
  }
  sendSearchDataForTrain(searchData){
    this.searchService.sendSearchDataForTrain(searchData).subscribe(resp=>{
      if(resp && resp.success && resp.success == true){
        this.searchInProgress = false;
       this.bsModalRef = this.modalService.show(this.thankyoumodal, {
        backdrop: 'static',
        keyboard: false
      });
        console.log(resp);
      }else{
        this.searchInProgress = false;
       this.toastr.error("Something went wrong.");
      }
    })
  }
  searchAgainForTrain(){
    this.bsModalRef.hide();
    this.searchService.employeeEmail=[];
    this.searchForm.reset();
    this.createForm();
    this.multicity = false;
    this.viewMode = 'tab5';
    this.employeeListCame = false;
    this.getEmployeeAndTravellerList();
  }
  getStationListForTrain(){
    this.searchService.getStationListDataForTrain().subscribe(data => {
    if(data){
      this.trainLocations = data;
      console.log(data);
    } 
    });
  }
  setAutoCompleteTrainSearchStation(incomingData){
    let  selectedTrainRegion = this.findRegionForMulticity();
    if (this.selectFormControlname === 'source') {
      this.autoCompleteSourceItems = incomingData;
      if (selectedTrainRegion) {
        this.autoCompleteSourceItems = incomingData.filter(ele => ele.airports[0].region === selectedTrainRegion);
      }
      return this.autoCompleteSourceItems;
    } else {
      this.autoCompleteDestItems = incomingData;
      if (selectedTrainRegion) {
        this.autoCompleteDestItems = incomingData.filter(ele => ele.airports[0].region === selectedTrainRegion);
      }
     return  this.autoCompleteDestItems;
    }
  }
  findRegionForMulticity() {
    let trainLocations = this.trainLocations;
    let searchForm = JSON.parse(JSON.stringify(this.searchForm.value));
    if ((searchForm['source'] === searchForm['sourceAirportCode']) && searchForm['source'] != '' && searchForm['source'] != null) {
      return trainLocations.filter(ele => ele.code === searchForm['sourceAirportCode'])[0].region;
    }
    else if ((searchForm['destination'] === searchForm['destinationAirportCode']) && searchForm['destination'] != '' && searchForm['destination'] != null) {
      return trainLocations.filter(ele => ele.code === searchForm['destinationAirportCode'])[0].region;
    } else {
      for (let i = 0; i < searchForm['flights'].length; i++) {
        let flight = searchForm['flights'][i];
        if ((flight['source'] === flight['sourceAirportCode']) && flight['source'] != '' && flight['source'] != null ) {
          return trainLocations.filter(ele => ele.code === flight['sourceAirportCode'])[0].region;
        }
        else if ((flight['destination'] === flight['destinationAirportCode']) && flight['destination'] != '' &&  flight['destination'] != null) {
          return trainLocations.filter(ele => ele.code === flight['destinationAirportCode'])[0].region;
        }
      }
    }
    return undefined;
  }
}
