import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { HotelQueryParam } from '../entity/hotel-query-param';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { HotelSearchService } from '../hotel-search.service';
import { UserAccountService } from '../user-account.service';
import { SearchService } from '../search.service';

@Component({
  selector: 'hotel-modal-basic',
  templateUrl: './hotel-modal.component.html',
  styleUrls: ['./hotel-modal.component.scss']
})
export class hotelModalBasic implements OnInit {
  njoySpecificBuild: boolean;
  hotelSearchQueryParam: HotelQueryParam;
  previousSearch: HotelQueryParam;
  city: any;
  checkIn: any;
  checkOut: any;
  passenger: any;
  //  @ViewChild('searchModal') modal: ModalDirective;
  constructor(
    private modalService: BsModalService,
    private gallopLocalStorage: GallopLocalStorageService,
    private userAccountService: UserAccountService,
    public searchService1: SearchService,
    private searchService: HotelSearchService,) { }

  public ngOnInit(): void {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.searchService.hotelRequest$.subscribe((hotelSearchRequest) => {
      this.previousSearch = hotelSearchRequest;

      if (this.previousSearch) {
        this.city = this.previousSearch.hotelAddress;
        this.checkIn = this.previousSearch.checkinDate;
        this.checkOut = this.previousSearch.checkoutDate;
        this.passenger = this.previousSearch.personCount;
      }
    });
  }
  showModal(hotelModal) {
    this.modalService.show(hotelModal, {});
  }


}
