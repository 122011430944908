import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { UserEntity } from '../../entity/user-entity';
import { UserAccountService } from 'src/app/user-account.service';
import { Subscription } from 'rxjs';
import { UserAccountInfo } from 'src/app/entity/user-account-info';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { DeviceDetailsService } from '../../device-details.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginComponent } from 'src/app/login/login.component';
import { CommonUtils } from 'src/app/util/common-utils';
import { NgxAnalytics } from 'ngx-analytics';
import { GallopAnalyticsUtil } from 'src/app/analytics.service';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/login.service';
import { PopupComponent } from 'src/app/popup/popup.component';
import { SearchService } from 'src/app/search.service';
import { Location } from '@angular/common';
import { BookingService } from 'src/app/booking.service';
import { GallopLocalStorageService } from 'src/app/gallop-local-storage.service';
import { ClientConfiguration } from 'src/app/client-config.service';
import { HotelSearchService } from 'src/app/hotel-search.service';
import { CarBookingService } from 'src/app/car-booking.service';
import { AdminPanelService } from 'src/app/admin-panel.service';
import { UserCredentialsService } from 'src/app/user-credentials.service';
import { Item } from 'vscroll/dist/typings/classes/item';
import { DateUtils } from 'src/app/util/date-utils';
declare var mixPanelLanguageChanged: any;
@Component({
  selector: 'email-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class EmailHeaderComponent implements OnInit {
  userEmail: string = '';
  userName: string = 'User';
  gallopCash: number = 0.00;
  profileImage: string = 'assets/images/profile-head.jpg';
  isShownProfile = false;
  userPhoneNumber: string;
  deviceSubscription: Subscription;
  isMobile: boolean = false;
  bsModalRef: BsModalRef;
  addEvent2Progress=false;
  addEvent1Progress=false
  profilePage = false;
  njoySpecificBuild: boolean;
  private userAccountSubscription: Subscription;
  constructor(public userAccountService: UserAccountService,
    public router: Router,
    private location: Location,
    public bookingService: BookingService,
    private el: ElementRef, 
    private gallopLocalStorage: GallopLocalStorageService,
    private modalService: BsModalService,
    private carBookingService: CarBookingService,
    private deviceDetailsService: DeviceDetailsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private userCredentials: UserCredentialsService,
    private searchHotelService: HotelSearchService,
    private ngxAnaltics: NgxAnalytics,
    private adminPanelService: AdminPanelService,
    private loginService: LoginService,
    private clientConfig: ClientConfiguration,
    private titleService: Title, public searchService: SearchService,
  ) {
    this.userEmail = this.userAccountService.getUserEmail();
    this.userName = this.userAccountService.getUserName();
    this.gallopCash = this.userAccountService.getGallopCash();
    this.profileImage = this.userAccountService.getUserImage();
    this.userPhoneNumber = this.userAccountService.getUserPhoneNumber();
  }
  backTobooking(){
    window.scrollTo(0, 0);
    // this.titleService.setTitle('');
     this.titleService.setTitle(this.translate.instant('search.TravellerDetails'));
     this.searchService.exitFromMultiTrip =true;
    // this.searchService.tripName='My Trip to';
     this.router.navigate(['/emailflow'], { queryParams: { pageMode: 'WebSearch', }, replaceUrl: true });
  }
  ngOnInit() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.profilePage = false;
    this.userAccountSubscription = this.userAccountService.userAccountInfoObjObserver$.subscribe((userAccountInfo: UserAccountInfo) => {
      this.userEmail = this.userAccountService.getUserEmail();
      this.userName = this.userAccountService.getUserName();
      this.gallopCash = this.userAccountService.getGallopCash();
      this.profileImage = this.userAccountService.getUserImage();
      this.userPhoneNumber = this.userAccountService.getUserPhoneNumber();

    });
    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }
  getDisplayDate(dateString) {
    return new Date(dateString);
  }
  getDisplayTime(datestring){
    let time = datestring.split('T')[1];
    if(time){
   return this.convertToAmPm(datestring); 
    }
  }
  isDateSame(date1,date2){
    date1 = new Date(date1);
    date2 = new Date(date2);
    if(date1.getDate()===date2.getDate() && date1.getMonth()===date2.getMonth() && date1.getYear()===date2.getYear()){
      return false;
    }
    return true;
  }
  convertToAmPm(timeStr: string): string {
    const date = new Date(timeStr);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? ' PM' : ' AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesString} ${ampm}`;
  }
  isNotProfilePage() {
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'profile'));
  }

  isNotSearchPage() {
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'search'));
  }
  isNotSelectionPage() {
    return (CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'emailflow'));
  }

  isNotCompanyAdminPage() {
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'admin'));
  }
  isNotCompanyApprovalPage() {
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'approval'));
  }
  isNotCardsPage() {
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'cards'));
  }
  isNotBookingDetailsPage() {
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'emailflow'));
  }
  isLoggedIn() {
    return this.userAccountService.isLoggedIn();
  }

  isuserHascard(): boolean {
    return this.userAccountService.userhascard;
  }
  isLoggingIn() {
    return this.userAccountService.isLoginInProgress;
  }
  isNavigationBarVisible() {
    if (this.njoySpecificBuild && this.isLoggedIn()) {
return true
    }else{
      return false;
    }
  }
  isNotManageTripsPage() {
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'bookingHistory'));
  }
  isNotApprovalRequestPage(){
    return !(CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'approvalRequest'));
  }
  goToTrips() {
    this.titleService.setTitle('Bookings');
    this.searchService.showAllBookings =false;
    this.router.navigate(["bookingHistory"],
      {
        queryParams:
        {
          type: 'list',
        },
        replaceUrl: false
      }
    );
    return false;
  }
  setHeading() {
    //this.titleService.setTitle('Flight Search');
    this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
  }
  openEvents(){
    let myTag = this.el.nativeElement.querySelector(".down-arrow")
    if (!myTag.classList.contains('removeTransform')) {
      myTag.classList.add('removeTransform');
    } else {
      myTag.classList.remove('removeTransform');
    }
    $('.eventList').slideToggle();
  
  }
  closeRebooking(){
    if(this.bookingService.bookRequestProgress){
      return;
    }
    this.bookingService.oldTripSessionId = undefined;
    this.bookingService.previousBooking =undefined;
    this.bookingService.previousTransaction=undefined;
    this.bookingService.previousTransactionId= undefined;
    this.bookingService.rebookingTravlleremail=[];
    this.searchService.searchStart=false;
    if ((this.isUserCompanyTravelManager() || this.userAccountService.isUserCorporateAdmin()) && !this.bookingService.previousTransactionId) {
      this.searchService.employeeEmail = [];
      this.searchService.bookingAndApprovalDone =true;
    }
    this.searchService.employeeOptions[0]= this.searchService.originalEmployeeOptions[0];
    this.goToSearch();
  }
  isUserCompanyTravelManager(){
    return this.userAccountService.isUserIsTravelManager();
  }
  getSourceAndDestination(item){
    if(item.legs && item.legs.length ===1){
      return (item.legs[0].hops[0].from +"-"+item.legs[0].hops[item.legs[0].hops.length-1].to);
    }else  if(item.legs && item.legs.length ===2){
      if(item.legs[0].hops[0].from === item.legs[1].hops[item.legs[1].hops.length-1].to && item.legs[0].hops[item.legs[0].hops.length-1].to === item.legs[1].hops[0].from){
        return (item.legs[0].hops[0].from +"-"+item.legs[0].hops[item.legs[0].hops.length-1].to)
      }else{
        let info='';
        let index=0;
        for(let leg of item.legs){
         info = info +  leg.hops[0].from +"-"+leg.hops[leg.hops.length-1].to
         if(index!==(item.legs.length-1)){
           info = info +","
         }
         index=index+1;
        }
        return info;
      }
    }else{
      let info='';
        let index=0;
        for(let leg of item.legs){
         info = info +  leg.hops[0].from +"-"+leg.hops[leg.hops.length-1].to
         if(index!==(item.legs.length-1)){
           info = info +", "
         }
         index=index+1;
        }
        return info;
    }
  }
  getTripType(item){
    if(item.legs && item.legs.length ===1){
      return this.translate.instant("flightSelected.one-way");
    }else  if(item.legs && item.legs.length ===2){
      if(item.legs[0].hops[0].from === item.legs[1].hops[item.legs[1].hops.length-1].to && item.legs[0].hops[item.legs[0].hops.length-1].to === item.legs[1].hops[0].from){
        return this.translate.instant("flightSelected.Roundtrip");
      }else{
        return this.translate.instant("flightSelected.Multicity");
      }
    }else{
      return this.translate.instant("flightSelected.Multicity");
    }
  }
  openDropdown(modal?){
    
    if(this.bookingService.previousTransactionId){
      this.bsModalRef = this.modalService.show(modal, {
        initialState: {
        }, backdrop: true, ignoreBackdropClick: true
      });
      return;
    }
    if(this.searchService.selectedEventID){
      this.bsModalRef = this.modalService.show(modal, {
        initialState: {
        }, backdrop: true, ignoreBackdropClick: true
      });
      return;
    }
    if(this.searchService.multiTripBooking){
      this.bsModalRef = this.modalService.show(modal, {
        initialState: {
        }, backdrop: true, ignoreBackdropClick: true
      });
      return ;
         }
    if (this.userAccountService.showRadio) {
      this.searchService.searchHeaderCliked =false;
      }else {
        this.searchService.searchHeaderCliked =true;
      }
    this.isShownProfile=!this.isShownProfile;
  
  }
  signOut() {
  
    this.titleService.setTitle('Login');
    this.searchService.employeeEmail = [];
    this.searchService.bookingAndApprovalDone =true;
    this.searchService.employeeList = [];
    this.searchService.employeeOptions = new Array(1).fill(null).map(_ => []);
    this.searchService.originalEmployeeOptions = new Array(1).fill(null).map(_ => []);
    this.searchService.previousSearch = null;
    this.searchService.previousSearch = undefined;
    this.searchService.emptytravelList();
    this.adminPanelService.emptyEmployeeList();
    this.searchHotelService.previousSearch = null;
    this.carBookingService.carSearchDetail = null;
    CommonUtils.signout(this.userAccountService, this.gallopLocalStorage, this.loginService);
    this.isShownProfile = false;
    this.router.navigate(['/login'], { queryParams: {}, replaceUrl: true });
 
  }

  signin() {
    this.bsModalRef = this.modalService.show(LoginComponent, {});
    this.loginService.loginPopupSubject.next(this.bsModalRef);
  }

  goToProfile() {
   
    this.titleService.setTitle('Profile');
    this.router.navigate(['/profile'], { queryParams: {} });
    return false;
  
  }

  navigateToApprovalListCheck(modal?){
    if(this.searchService.multiTripBooking || this.bookingService.previousTransactionId || this.searchService.selectedEventID){
      this.bsModalRef = this.modalService.show(modal, {
        initialState: {
        }, backdrop: true, ignoreBackdropClick: true
      });
    }else{
      this.goToApproval();
    }
  }
  goToApproval(){
   // if (this.userAccountService.isConnected) {
    if(this.searchService.multiTripBooking){this.exitMultiBooking();};
    if(this.searchService.selectedEventID){
      this.searchService.selectedEventID=null;
      this.searchService.selectedEventForBooking=null;
      if ((this.isUserCompanyTravelManager() || this.userAccountService.isUserCorporateAdmin()) && !this.bookingService.previousTransactionId) {
        this.searchService.employeeEmail = [];
        this.searchService.bookingAndApprovalDone =true;
      }
      this.bsModalRef.hide();
        }
    if(this.bookingService.previousTransactionId){this.closeRebooking();this.bsModalRef.hide();};
    this.router.navigate(['/approval'], { queryParams: {type:'pending'} });
  
  }
  goToCards() {
   
    this.titleService.setTitle('Cards');
    this.router.navigate(['/cards']);
    return false;
  
  }
  dashboardUrl() {
    let userid = this.userAccountService.getUserEmail();
    let sToken = this.userAccountService.getSToken();
   // this.titleService.setTitle('Dashboard');
    if (this.userAccountService.showReportsTab()) {

      return (this.clientConfig.dashboardAppBaseUrl + '/admin?type=dashboard'
        + "&userid=" + userid + "&sToken=" + sToken
      );
    } else {
      return (this.clientConfig.dashboardAppBaseUrl + '/admin?type=approvals&subType=pending'
        + "&userid=" + userid + "&sToken=" + sToken
      )
    }
  }
  goToCompanyPanel1() {
    let userid = this.userAccountService.getUserEmail();
    let sToken = this.userAccountService.getSToken();
    this.titleService.setTitle('Dashboard');
    if (this.userAccountService.showReportsTab()) {
      // this.router.navigate(["admin"],
      //   {
      //     queryParams:
      //       {
      //         type:'dashboard',
      //         userid:userid,
      //         sToken:sToken
      //     },
      //     replaceUrl: false
      // }
      // );
      window.location.href = (this.clientConfig.dashboardAppBaseUrl + '/admin?type=dashboard'
        + "&userid=" + userid + "&sToken=" + sToken
      );
    } else {
      // this.router.navigate(["admin"],
      //   {
      //     queryParams:
      //       {
      //         type:'approvals',
      //         subType:'pending',
      //         userid:userid,
      //         sToken:sToken
      //     },
      //     replaceUrl: false
      // }
      // );
      window.location.href = (this.clientConfig.dashboardAppBaseUrl + '/admin?type=approvals&subType=pending'
        + "&userid=" + userid + "&sToken=" + sToken
      )
    }

  }
  goToCompanyPanel() {
    let userid = this.userAccountService.getUserEmail();
    let sToken = this.userAccountService.getSToken();
    this.titleService.setTitle('Dashboard');
    if (this.userAccountService.showReportsTab()) {
      this.router.navigate(["admin"],
        {
          queryParams:
          {
            type: 'dashboard',
            userid: userid,
            sToken: sToken
          },
          replaceUrl: false
        }
      );
    } else {
      this.router.navigate(["admin"],
        {
          queryParams:
          {
            type: 'approvals',
            subType: 'pending',
          },
          replaceUrl: false
        }
      );
    }
  }
closeHeaderDroddown(){
  if(this.bookingService.previousTransactionId){
    return;
  }
  
  this.isShownProfile=false
}
exitEvent(){
  this.searchService.selectedEventID=null;
  this.searchService.selectedEventForBooking=null;
  if ((this.isUserCompanyTravelManager() || this.userAccountService.isUserCorporateAdmin()) && !this.bookingService.previousTransactionId) {
    this.searchService.employeeEmail = [];
    this.searchService.bookingAndApprovalDone =true;
  }
  this.router.navigate(['/search'], {
    queryParams: {eventid:'exitEvent'},
    replaceUrl: true
  });
  this.bsModalRef.hide();
}
exitMultiAndReBooking(){
  if(this.searchService.multiTripBooking){this.exitMultiBooking();};
  if(this.searchService.selectedEventID){
this.exitEvent();
  }
  if(this.bookingService.previousTransactionId){this.closeRebooking();this.bsModalRef.hide();};
}
exitMultiBooking(){
  this.searchService.tripName='';
    this.searchService.multiTripBooking =false;
   // this.searchService.selectedEventID=null;
    this.searchService.selectedEventForBooking=null;
    this.searchService.multiflightQuery =[];
    this.searchService.multicarQuery =[];
    this.searchService.multihotelQuery =[];
    this.searchService.previousSelectedCreditAirline=[];
    if ((this.isUserCompanyTravelManager() || this.userAccountService.isUserCorporateAdmin()) && !this.bookingService.previousTransactionId) {
      this.searchService.employeeEmail = [];
      this.searchService.bookingAndApprovalDone =true;
    }
  this.routeToHomePage();
  this.bsModalRef.hide();
}
onModelCancel(){
  this.bsModalRef.hide();
}
cancelEvent(eventid,msg){
  if(msg==='BOOKED_ALTERNATE'){
    this.addEvent2Progress=true;
  }else{
    this.addEvent1Progress=true;
  }
  this.userAccountService.respondEvent(eventid,msg).subscribe(resp=>{
    if(resp && resp.success){
      this.userAccountService.eventList =  this.userAccountService.eventList.filter(item => item.event.id!==this.searchService.selectedEventID);
        this.searchService.selectedEventForBooking=null;
        this.searchService.selectedEventID=null;
        this.router.navigate(['/search'], {
          queryParams: {eventid:'exitEvent'},
          replaceUrl: true
        });
    }
  })

}
goToEvent(item){
  this.searchService.selectedEventID = item.event.id;
  this.router.navigate(['/search'], {
    queryParams: {eventid:item.event.id},
    replaceUrl: true
  });
}
  goToSearch(modal?) {
   // this.titleService.setTitle('Flight Search');
  // if (this.userAccountService.isConnected) {
     if(this.searchService.selectedEventID){
      this.bsModalRef = this.modalService.show(modal, {
        initialState: {
        }, backdrop: true, ignoreBackdropClick: true
      });
      return;
    }
   if(this.bookingService.previousTransactionId){
      this.bsModalRef = this.modalService.show(modal, {
        initialState: {
        }, backdrop: true, ignoreBackdropClick: true
      });
     return;
   }
    if (this.searchService.multiTripBooking) {
      this.bsModalRef = this.modalService.show(modal, {
        initialState: {
        }, backdrop: true, ignoreBackdropClick: true
      });
      return;
    }
   if (this.userAccountService.showRadio) {
    this.searchService.searchHeaderCliked =false;
    }else {
      this.searchService.searchHeaderCliked =true;
    }
    this.routeToHomePage();
    return false;
  
  }
  routeToHomePage(){
    if(this.searchService.showFlightTab){
      this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
      // this.userAccountService.initResponseReceivedFoorSearchPage=true;
      this.router.navigate(['/search'], {
        queryParams: {},
        replaceUrl: true
      });
    }
    if(!this.searchService.showFlightTab && this.searchService.showHotelTab){
      this.titleService.setTitle(this.translate.instant('search.HotelSearch'));
      this.router.navigate(["search"],
        {
          queryParams:
          {
            type: 'hotel',
          },
          replaceUrl: true
        }
      );
     } 
     if(!this.searchService.showFlightTab && !this.searchService.showHotelTab && this.searchService.showCarTab){
      this.titleService.setTitle(this.translate.instant('search.CarSearch'));
      this.router.navigate(["search"],
        {
          queryParams:
          {
            type: 'car',
          },
          replaceUrl: true
        }
      );
     } 
  }
  isAmnetRelease() {
    return environment.amnetSpecificRelease;
  }

  isUserCompanyManager() {
    return this.userAccountService.isUserCorporateAdmin();
  }
  convertFile() {
    this.userAccountService.convertLocalizationFile();
  }
  goToApprovalRequestPage(){
    this.router.navigate(['/approvalRequest'],{  queryParams:{type: 'list',},replaceUrl:true});
  }

  showApprovalRequest(){
    return this.userAccountService.approvalRequestData && this.userAccountService.approvalRequestData.length >0;
  }
}
