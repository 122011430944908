import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonUtils } from '../util/common-utils';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UserAccountService } from '../user-account.service';
import { DeviceDetailsService } from '../device-details.service';
declare var setNgxSmartModalOpenStateClosed: any;
@Component({
  selector: 'app-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.scss']
})
export class HelpPopupComponent implements OnInit, AfterViewInit {
  searchPopClicked = true;
  njoySpecificBuild: boolean;
  constructor(public ngxSmartModalService: NgxSmartModalService,
    private deviceDetailsService: DeviceDetailsService,
    public userAccountInfoService: UserAccountService,) {
    this.njoySpecificBuild = this.userAccountInfoService.isItNjoyBuild();
  }
  isMobile = false;
  isMobile1 = false;
  ngOnInit() {
    this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.deviceDetailsService.isMobile2().subscribe(isMobile => {
      this.isMobile1 = isMobile;
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.ngxSmartModalService.getModal('helpModal').open();
    }, 100);
    setTimeout(() => {
      $('.modalAirportFilterInfo').slideToggle();
    }, 500);
  }
  openHelpPopup(){
    let modals = this.ngxSmartModalService.getOpenedModals();
    for (let index = 0; index < modals.length; index++) {
      setNgxSmartModalOpenStateClosed(modals[index].id);
    }
    this.ngxSmartModalService.getModal('helpModal').open()
  }
  isuserHascard(): boolean {
    return this.userAccountInfoService.userhascard;
  }
  handleModalEvents(eventName: string, currentModalId: string) {
    CommonUtils.handleModalEvents(this.ngxSmartModalService, eventName, currentModalId);
  }
  onSmartModelCancel(modelName: string) {
    $('.modalAirportFilterInfo').slideToggle();
    let modals = this.ngxSmartModalService.getOpenedModals();
    for (let index = 0; index < modals.length; index++) {
      setNgxSmartModalOpenStateClosed(modals[index].id);
    }
    // this.ngxSmartModalService.getModal(modelName).close();
  }

}
