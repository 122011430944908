import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgSelectModule } from '@ng-select/ng-select';
import { NouisliderModule } from 'ng2-nouislider';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { TabModule } from 'angular-tabs-component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { TruncateModule } from 'ng2-truncate';



import { AppRoutingModule } from './app-routing.module';
import { SearchRequestComponent } from './search-request/search-request.component';
import { PositionDirective } from './position.directive';
import { WidthDirective } from './width.directive';
import { FixedOnScrollDirective } from './fixed-on-scroll.directive';
import { NgProgressModule } from '@ngx-progressbar/core';
import { searchModalBasic } from './search-modal/search-modal.component';
import { hotelModalBasic } from './hotel-modal/hotel-modal.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { BookingMessageModalComponent } from './booking-message-modal/booking-message-modal.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { SearchService } from './search.service';
import { SearchResultService } from './search-result.service';
import { NgxMaskModule } from 'ngx-mask';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { FilterService } from './filter.service';
import { BookingService } from './booking.service';
import { ItineraryGuard } from './itinerary/itinerary.guard';
import { SearchResultGuard } from './search-result/search-result.guard';
import { NotFoundGuard } from './error/not-found.guard';
import { GeoLocationService } from './geo-location.service';
import { ConfirmMessageModalComponent } from './confirm-message-modal/confirm-message-modal.component';
import { UserAccountService } from './user-account.service';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from "./header/header.component";
import { ExpensifyDetailsComponent } from './email-booking-flow/expensify-details/expensify-details.component';
import { EmailQuoteOptionsService } from './email-quote-options.service';
import { SuccessModelComponent } from './email-booking-flow/success-model/success-model.component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DeleteCardModelComponent } from './email-booking-flow/delete-card-model/delete-card-model.component';
import { HotelSearchService } from './hotel-search.service';
import { HotelAsyncImagesComponent } from './hotel-async-images/hotel-async-images.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateOptionsPipe } from './util/translate-option';
import { SafeUrlPipe } from './util/safeUrl';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { NgxAnalyticsModule } from 'ngx-analytics';
import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';
import { CookieService } from 'ngx-cookie-service';
import { HandleError, ErrorHandlingService } from './shared/error-HandlingService.service';

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
} from "angular-6-social-login";
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxPaginationModule } from 'ngx-pagination';

import { APP_INITIALIZER } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';



export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("422028917695-p42rqrg3dbm5achnsp2i5lqehu6ark77.apps.googleusercontent.com")
      }
    ]
  );
  return config;
}
import { HotelHistoryModalComponent } from './hotel-history-modal/hotel-history-modal.component';
import { CancelSuccessComponent } from './cancel-success/cancel-success.component';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { ChartsModule } from 'ng2-charts';
import { AdminPanelService } from './admin-panel.service';
import { PopupComponent } from './popup/popup.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { SupportComponent } from './support/support.component';
import { CarModalComponent } from './car-modal/car-modal.component';
import { InitClientComponent } from './cancel-success/init-client-component';
import { DomainRestrictedEmailComponent } from './domain-restricted-email/domain-restricted-email.component';
import { LookupEmployeeByEmailComponent } from './lookup-employee-by-email/lookup-employee-by-email.component';
import { FaqComponent } from './faq/faq.component';
import { SeatComponent } from './seat/seat.component';
import { PaginationModule } from './app-pagination/app-pagination.module';
import { UserCredentialsService } from './user-credentials.service';
import { ClientConfiguration } from './client-config.service';
import { ErrorHandlingComponent } from './error-handling/error-handling.component';
import { Router } from '@angular/router';
import { FeedbackComponent } from './feedback/feedback.component';
import { HelpPopupComponent } from './help-popup/help-popup.component';
import { ShareModule } from './share.module';
import { AppApprovalComponent } from './app-approval/app-approval.component';
import { environment } from 'src/environments/environment';
import { SeeDetailsComponent } from './see-details/see-details.component';
import { ApprovalRequest } from './approval-request/approval-request.component';





@NgModule({
  declarations: [
    AppComponent,
    SearchRequestComponent,
    PositionDirective,
    WidthDirective,
    searchModalBasic,
    hotelModalBasic,
    FixedOnScrollDirective,
    WidthDirective,
    UserAccountComponent,
    BookingMessageModalComponent,
    ItineraryComponent,
    ConfirmMessageModalComponent,
    HeaderComponent,
    ExpensifyDetailsComponent,
    SuccessModelComponent,
    DeleteCardModelComponent,
    HotelAsyncImagesComponent,
    FooterComponent,
    LoginComponent,
    HotelHistoryModalComponent,
    CancelSuccessComponent,
    InitClientComponent,
    AddEmployeeComponent,
    PopupComponent,
    SupportComponent,
    CarModalComponent,
    DomainRestrictedEmailComponent,
    LookupEmployeeByEmailComponent,
    FaqComponent,
    SeatComponent,
    ErrorHandlingComponent,
    FeedbackComponent,
    HelpPopupComponent,
    AppApprovalComponent,
    SeeDetailsComponent,
    ApprovalRequest
    
  
    
  ],
  imports: [
    ShareModule,
    UiSwitchModule,
    TooltipModule,
    NgxGalleryModule,
    TabModule,
    ChartsModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    NgSelectModule,
    NouisliderModule,
    BrowserModule,
    NgxSmartModalModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyA8z-JfNhr9cQgPz58usOioKHIXWSJsvy0' }),
    TruncateModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgProgressModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserModule,
  
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxAnalyticsModule.forRoot([NgxAnalyticsGoogleAnalytics], {
      pageTracking: {
        clearIds: true,
        clearQueryParams: true
      }
    }),
    SocialLoginModule,
    PaginationModule,
    NgxPaginationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (modalService: BsModalService) {
        return new ErrorHandlingService(modalService);
      },
      multi: true,
      deps: [BsModalService, Router, Injector]
    },
    SearchService,
    ErrorHandlingService,
    HotelSearchService,
    SearchResultService,
    FilterService,
    BookingService,
    ItineraryGuard,
    SearchResultGuard,
    NotFoundGuard,
    GeoLocationService,
    UserAccountService,
    EmailQuoteOptionsService,
    DatePipe,
    TranslateOptionsPipe,
    SafeUrlPipe,
    CurrencyPipe,
    TranslateService,
    AdminPanelService,
    CookieService,
    GallopHttpClient,
    UserCredentialsService,
    ClientConfiguration,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [BookingMessageModalComponent, ConfirmMessageModalComponent, SuccessModelComponent,
    DeleteCardModelComponent, searchModalBasic, hotelModalBasic, LoginComponent, HotelHistoryModalComponent, CancelSuccessComponent,
    InitClientComponent, AddEmployeeComponent, PopupComponent, CarModalComponent, SeeDetailsComponent
    , PopupComponent, LookupEmployeeByEmailComponent, SeatComponent, ErrorHandlingComponent, FeedbackComponent],

})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.appVersion);
}
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let langToSet = 'en';
      const selectedLang = localStorage.getItem('selectedLanguage');
      if (selectedLang) {
        langToSet = selectedLang;
      }
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}