<div class="booking-left-inner" *ngIf="this.details.type =='flight'" >
    <div class="booking-detail-data" [ngStyle]="{'background': forReports ?'#F5F5FA':'#fff'}">
        <div>
            <div class="booking-details-MainContainer">
                <div class="icon-parent-div">
                    <div class="booking-img">
                        <img src="assets/images/flight-circle.png" />
                        <span class="item-name">{{'bookingHistory.flight'| translate}}</span>
                        <div  *ngIf="this.detailList.length >1 && this.tripDeatilsIndex != this.detailList.length-1" class="vertical_dotted_line"></div>
                    </div>
                    <div></div>
                </div>
                <div class="details-box" >
                            <div class="details-and-price-box" >
                                <div style="padding: 30px 5px;position: relative;" >
                                    <div class="credit-appled-desktop" *ngIf="checkTransactionEventType()">
                                        <img class="green-img" style="max-width: 15px;max-height: 15px;"
                                        src="assets/images/credit-applied-green.svg" /><span style="color:#27C198">{{'flightChart.CreditApplied' | translate}}</span>
                                    </div>
                                    <div  style="margin-top: 10px;margin-left: 23px;margin-bottom: 25px;" class="information">
                                        <div class="textInformation" style="font-family: 'apercu-b';font-size: 12px;color:rgb(249, 61, 48); " *ngIf="this.details.outOfPolicyReason">{{this.details.outOfPolicyReason}}</div>
                                      </div>  
                                    <div *ngFor="let num of this.details.option.flight_option.legs;let legIndex=index">
                                        <div class="img-details-holder" >
                                            <div class="company-img" >
                                                <img onerror="this.onerror = null; this.src = 'https://s3.amazonaws.com/images.biztravel.ai/template/default.gif';"
                                                    [src]="getFlightIconURL(num.hops[0].carrier)" />
                                            </div>
                                            <div class="booking-details-holder">
                                                <div  class="d-flex  w-10 ">
                                                    <div class="flight-start-timing">
                                                        <div class="arrow-holder-div">
                                                            <span>{{getDisplayDateTimeForFlights(num.hops[0].starts,'h:mm a')}}</span>
                                                                    <div class="flight-timings-line">
                                                                            <div class="stop-box-container">
                                                                                    <ng-container >
                                                                                          <ng-container *ngIf="num.stops != 'non-stop'">
                                                                                             <span class="block text-center stop-box-div"
                                                                                                style="width: 100%">
                                                                                                <span  class="stop-box"><span class="numOfStops">{{getNumberOfStops(num.stops)}}</span></span>
                                                                                             </span>
                                                                                          </ng-container>
                                                                                       </ng-container>
                                                                              </div>
                                                                  </div>
                                                            
                                                           
                                                        </div>
                                                        <div>{{getDisplayDateForFlights(num.hops[0].starts,'EEEE, MMM d, yyyy')}}</div>
                                                    </div>
                                                    
                                                        
                                                    <div class="flight-start-timing">
                                                        <div>{{getDisplayDateTimeForFlights(num.hops[getLastIndex(num.hops)].ends,'h:mm a')}}</div> 
                                                        <div>{{getDisplayDateForFlights(num.hops[getLastIndex(num.hops)].ends,'EEEE, MMM d, yyyy')}}</div>
                                                    </div>
                                                </div>
                                                
                                                <div>
                                                    <span *ngIf="num.stops == 'non-stop'">{{'flightChart.nonstop' | translate}}</span>
                                                    <span *ngIf="num.stops != 'non-stop'">{{num.stops}}</span>  • 
                                                    <span>{{getDuration(this.details,legIndex)?.hrs + 'h ' + getDuration(this.details,legIndex)?.mins + 'm'}}</span> 
                                                    • 
                                                    <span>{{num.hops[0].from}} - {{num.hops[getLastIndex(num.hops)].to}}</span>
                                                    
                                                    <span *ngIf="getLayoverDuration(legIndex)"> •  {{getLayoverDuration(legIndex)}}</span>
                                                </div>
                                                <div class="booking-description" >{{getAllHopes(legIndex)}} • {{getAirlineFullName(num.hops[0].carrier)}}  <span *ngIf="num.hops[0].ocarrier
                                                    && num.hops[0].operatingCarrier !== num.hops[0].ocarrier" class="booking-description">
                                                    . operated by {{this.airlines[num.hops[0].ocarrier]}}
                                                 </span></div>
                                               
                                                <div class="see-details-button"  >
                                                    <span *ngIf="checkIfMixedClass()" style=" color: rgb(249, 61, 48);margin-right:5px;cursor: default;">
                                                        <i class="fa fa-exclamation-triangle  fare-attr-red" aria-hidden="true"></i>
                                                        {{'flightChart.Mixedclass' | translate}}
                                                    </span>
                                                    <span (click)="openModal(tripDetails,'flight',num,legIndex)" >See details</span>
                                                </div>
                                                <div class="credit-appled-mobile" *ngIf="checkTransactionEventType()">
                                                    <img class="green-img" style="max-width: 15px;max-height: 15px;"
                                                    src="assets/images/credit-applied-green.svg" /><span style="color:#27C198">{{'flightChart.CreditApplied' | translate}}</span>
                                                </div>
                                                <div *ngIf="this.details.option.flight_option.legs.length - 1 == legIndex && getPaymentDetailsByDetails()" class="payment-details">
                                                    <span>{{'bookingHistory.Paymentdetails' | translate}}: </span> {{getPaymentDetailsByDetails()}}
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                                <div  class="price-continer-box">
                                    <div class="text-elm">
                                        <p class="price-show">
                                            {{getTotalFare() | currency : getCurrencySymbol(this.details.option.flight_option.legs[0]) :'code': '1.2-2'}}
                                            <br>
                                            <span class="block-flight-trip-type is-mobile" style="margin-top:0px;" *ngIf=" iscreditAppled && !this.isMobile">{{'option.Creditapplied.Grossfare' | translate}}: {{ getGrossFare()| currency :getCurrencySymbol(this.details.option.flight_option.legs[0]) : 'code': '1.0-0' }}</span>                                            
                                            <!-- <span  class="paymentdetailsModal" style="float: none;" (click)="openPaymentdetailsModal(paymentdetailsModal)"><i class="fa fa-question-circle-o" aria-hidden="true"></i></span> -->
                                        </p>
                                        <p class="block-flight-trip-type is-desktop" style="margin-top:0px;" *ngIf=" iscreditAppled && !this.isMobile">{{'option.Creditapplied.Grossfare' | translate}}: {{ getGrossFare()| currency :getCurrencySymbol(this.details.option.flight_option.legs[0]) : 'code': '1.0-0' }}</p>
                                        <p class="policy-show" *ngIf="this.details.option.flight_option.withinPricePolicy == true">WITHIN POLICY</p>
                                        <p class="policy-show" style="color: red;" *ngIf="this.details.option.flight_option.withinPricePolicy == false">OUTSIDE POLICY</p>
                                        <div class="confirmation-text" *ngIf="this.forApproval == false">
                                            <p>{{'bookingHistory.Confirmation' | translate}}#</p>
                                            <p style="word-break:break-all;">{{getConfirmationNo() | uppercase}}</p>
                                        </div>
                                        <div class="confirmation-text text-uppercase" *ngIf="this.forApproval == true && getApprovalStatus()">
                                            <p>{{getApprovalStatus()}}</p>
                                        </div>
                                        
                                    </div>
                                    <div class="reports-invoice-button" *ngIf="this.forReports && showDownloadInvoiceForCancelApproval(this.details)" (click)="downloadInvoices()">
                                        <!-- <div><img src="assets/images/ic_receipt.svg" alt="" /></div> -->
                                        <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                                    </div>
                                </div>
                            </div>
                            
                        <div  class="booking-details-icons" *ngIf="showActionButtons() && this.showDetailsActionButtons && showDownloadInvoiceForCancelApproval(this.details)">
                            
                            <div class="item"  *ngIf="!this.hideChanges && showCalenderButton() && showActionButtons()" (click)="goToCalendar(addCalendarModal)">
                                <div >
                                    <img src="assets/images/calender.svg" alt="" />
                                </div>

                                <div>{{'bookingHistory.AddtoCalender' |translate}}</div>
                            </div>
                            <div class="item" *ngIf="!this.hideChanges && showActionButtons() && showTripItButton()"  (click)="goToTripItLink()">
                                <div><img src="assets/images/tripit.png" alt=""></div>
                                <div>{{'bookingHistory.AddtoTripIt' |translate}}</div> 
                            </div>
                            <div class="item" *ngIf="showExpensifyButton() && showExpenseButtons(this.tripDeatilsIndex)" (click)="showModal(addExpensifyModal)">
                                <div>
                                    <img src="assets/images/Expense.svg" alt="" />
                                </div>
                                <div>{{'bookingHistory.Expense' | translate}}</div>
                            </div>
                            <div class="item" *ngIf="showDownloadInvoiceButton() && (showActionButtons() && showExpenseButtons())" (click)="downloadInvoices()">
                                <div><img src="assets/images/ic_receipt.svg" alt="" /></div>
                                <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                            </div>
                            <div class="item" *ngIf="showActionButtons() && showRebookButton(this.tripDeatilsIndex) && showChangesFlag() && rebookAndCancelButtonCheckForPendingApproval() " (click)="showModal(requestChangeModalFlight)">
                                <div><img src="assets/images/ic_edit.svg" alt="" /></div>
                                <div>{{'bookingHistory.REBOOKANDCANCEL' |translate}}</div>
                            </div>
                            <div class="item" *ngIf="showActionButtons() && showRebookButton(this.tripDeatilsIndex) && showChangesFlag()" (click)="showCancelModal(requestCancelModal,requestFlightCancelModal,cancelApprovalRequestModal,cancelFailFlightModal)">
                                <div>
                                    <img src="assets/images/ic_cancel.svg" alt="" />
                                </div>
                                <div>{{'bookingHistory.CANCELRESERVATION'|translate}}</div>
                            </div>
                            
                        </div>

                        <div  class="booking-details-icons booking-details-icons-reports" *ngIf="this.forReports && showDownloadInvoiceForCancelApproval(this.details)">
                            <div class="item"  (click)="downloadInvoices()">
                                <div><img src="assets/images/ic_receipt.svg" alt="" /></div>
                                <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                            </div>
                        </div>

                </div>
            </div>
            
        </div>
    </div>
</div>
<div class="booking-left-inner" *ngIf="this.details.type =='hotel'" >
<div class="booking-detail-data" [ngStyle]="{'background': forReports ?'#F5F5FA':'#fff'}">
        <div>
            <div class="booking-details-MainContainer">
                <div class="icon-parent-div">
                    <div class="booking-img">
                        <img src="assets/images/hotel-circle.png" />
                        <span class="item-name">{{'bookingHistory.hotel'| translate}}</span>
                        <div  *ngIf="this.detailList.length >1 && this.tripDeatilsIndex != this.detailList.length-1" class="vertical_dotted_line "></div>
                    </div>
                    <div></div>
                </div>
                <div class="details-box">
                            <div class="details-and-price-box">
                                <div style="padding: 30px 5px;" >
                                    <div  style="margin-top: 10px;margin-left: 23px;margin-bottom: 25px;" class="information">
                                        <div class="textInformation" style="font-family: 'apercu-b';font-size: 12px;color:rgb(249, 61, 48); " *ngIf="this.details.outOfPolicyReason">{{this.details.outOfPolicyReason}}</div>
                                      </div>  
                                    <div >
                                        <div class="img-details-holder" >
                                            <div class="company-img" >
                                                <img onerror="this.onerror=null;this.src='assets/images/hotel/hotel.png'" style="width: 71px !important; height: 71px !important;" *ngIf="this.details.url !==''" src="{{this.details.url}}" /> <img *ngIf="this.details.url===''"
                                                src="assets/images/hotel/hotel.png" />
                                            </div>
                                            <div class="booking-details-holder">
                                                <div  class="d-flex  w-10 ">
                                                    <div class="flight-start-timing">
                                                        <div class="arrow-holder-div">
                                                            <div>{{getDisplayDate(this.details.option.hotel_option.checkInDate,'MMM d, yyyy')}}</div>
                                                            <div>
                                                                <div class="hotel-arrow"><span></span></div>
                                                            </div>
                                                        </div>
                                                        <div>{{getDisplayDate(this.details.option.hotel_option.checkInDate,'EEEE')}}</div>
                                                        
                                                    </div>
                                                    
                                                    <div class="flight-start-timing">
                                                        <div>{{getDisplayDate(this.details.option.hotel_option.checkOutDate,'MMM d, yyyy')}}</div>
                                                        <div>{{getDisplayDate(this.details.option.hotel_option.checkOutDate,'EEEE')}}</div>
                                                    </div>
                                                </div>
                                                
                                                <div class="booking-description" >{{this.details.option.hotel_option.address}}</div>
                                                <div class="booking-description">{{'support.Phone' | translate}}: {{getHotelPhoneNo(this.details)}}</div>
                                                <div class="booking-description" >{{this.details.option.hotel_option.hotelRooms.length}} {{'bookingHistory.Room' | translate}} {{this.details.option.hotel_option.hotelRooms[0].hotelRoomName}}</div>
                                                <div class="see-details-button"  (click)="openModal(tripDetails,'hotel',this.details.option.hotel_option)"  >See details</div>
                                                <div  class="payment-details" *ngIf="getPaymentDetailsByDetails()">
                                                    <span>{{'bookingHistory.Paymentdetails' | translate}}: </span> {{getPaymentDetailsByDetails()}}
                                                </div>
                                                <div *ngIf="this.authFormUrl" class="cardDetails">
                                                <div class="see-details-button"  (click)="openCardDetailsModal(cardDetailsModal)"  >{{'employee.ViewCardDetails' | translate}}</div>
                                                <div class="see-details-button"  style="margin-left: 30px;" (click)="downloadAuthForm()"  >{{'employee.ViewAuthorizationForm' | translate}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div  class="price-continer-box">
                                    <div class="text-elm">
                                        
                                        <div class="price-show" >
                                            <span>{{ getTotalFare() | currency :getCurrencySymbol(this.details.option.hotel_option):'code': '1.2-2'}}</span>
                                            <span *ngIf="checkForPriceChangeInPendingApprovalHotel()" class="material-icons"
                                                data-toggle="tooltip" title="Price updated"
                                                style="font-size:13px;">warning</span>
                                            <span *ngIf="checkForPriceChangeInPendingApprovalHotel()"
                                                style="font-size:50%;position: relative;top: -3px;">{{'option.Updated'
                                                | translate}}</span>
                                            <!-- <span  class="paymentdetailsModal" style="float: none;" (click)="openPaymentdetailsModal(paymentdetailsModal)"><i class="fa fa-question-circle-o" aria-hidden="true"></i></span> -->
                                        </div>
                                        <p class="policy-show" *ngIf="this.details.option.hotel_option.inPolicy == true">WITHIN POLICY</p>
                                        <p class="policy-show" style="color: red;" *ngIf="this.details.option.hotel_option.inPolicy == false">OUTSIDE POLICY</p>
                                        <div class="confirmation-text" *ngIf="this.forApproval == false">
                                            <p>{{'bookingHistory.Confirmation' | translate}}#</p>
                                            <p style="word-break:break-all;">{{getConfirmationNo() | uppercase}}</p>
                                        </div>
                                        <div class="confirmation-text text-uppercase" *ngIf="this.forApproval == true && getApprovalStatus()">
                                            <p>{{getApprovalStatus()}}</p>
                                        </div>
                                    </div>
                                    <div class="reports-invoice-button" *ngIf="this.forReports && showDownloadInvoiceForCancelApproval(this.details)" (click)="downloadInvoices()">
                                        <!-- <div><img src="assets/images/ic_receipt.svg" alt="" /></div> -->
                                        <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                        <div  class="booking-details-icons" *ngIf="showActionButtons() && this.showDetailsActionButtons && showDownloadInvoiceForCancelApproval(this.details)">
                            <div class="item"  *ngIf="!this.hideChanges && showCalenderButton()  && showActionButtons()" (click)="goToCalendar(addCalendarModal)">
                                <div >
                                    <img src="assets/images/calender.svg" alt="" />
                                </div>

                                <div>{{'bookingHistory.AddtoCalender' |translate}}</div>
                            </div>
                            <div class="item" *ngIf="!this.hideChanges && showActionButtons() && showTripItButton()"  (click)="goToTripItLink()">
                                <div><img src="assets/images/tripit.png" alt=""></div>
                                <div>{{'bookingHistory.AddtoTripIt' |translate}}</div> 
                            </div>
                            <div class="item" *ngIf="showExpensifyButton() && showExpenseButtons(this.tripDeatilsIndex)" (click)="showModal(addExpensifyModal)">
                                <div>
                                    <img src="assets/images/Expense.svg" alt="" />
                                </div>
                                <div>{{'bookingHistory.Expense' | translate}}</div>
                            </div>
                            <div class="item" *ngIf="showDownloadInvoiceButton() && (showActionButtons() && showExpenseButtons())" (click)="downloadInvoices()">
                                <div><img src="assets/images/ic_receipt.svg" alt="" /></div>
                                <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                            </div>
                            <div class="item" *ngIf="showActionButtons() && showRequestChangeButton(this.tripDeatilsIndex) && showChangesFlag()" (click)="showModal(requestChangeModal,this.tripDeatilsIndex)">
                                <div><img src="assets/images/ic_edit.svg" alt="" /></div>
                                <div>{{'bookingHistory.REQUESTCHANGES' |translate}}</div>
                            </div>
                            
                            <div class="item" *ngIf="showActionButtons() && showRebookButton(this.tripDeatilsIndex) && showChangesFlag()" (click)="showCancelModal(requestCancelModal,requestFlightCancelModal,cancelApprovalRequestModal)">
                                <div>
                                    <img src="assets/images/ic_cancel.svg" alt="" />
                                </div>
                                <div>{{'bookingHistory.CANCELRESERVATION'|translate}}</div>
                            </div>
                            
                        </div>

                        <div  class="booking-details-icons booking-details-icons-reports" *ngIf="this.forReports && showDownloadInvoiceForCancelApproval(this.details)">
                            <div class="item"  (click)="downloadInvoices()">
                                <div><img src="assets/images/ic_receipt.svg" alt="" /></div>
                                <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                            </div>
                        </div>


                </div>
            </div>
            
        </div>	
    </div>
    
</div>
<div class="booking-left-inner" *ngIf="this.details.type =='cars'" >
    <div class="booking-detail-data" [ngStyle]="{'background': forReports ?'#F5F5FA':'#fff'}">
        
        <div>
            <div class="booking-details-MainContainer">
                <div class="icon-parent-div">
                    <div class="booking-img">
                        <img src="assets/images/car-circle.png" />
                        <span class="item-name">{{'bookingHistory.car'| translate}}</span>
                        <div  *ngIf="this.detailList.length >1 && this.tripDeatilsIndex != this.detailList.length-1" class="vertical_dotted_line"></div>
                    </div>
                </div>
                <div class="details-box">
                            <div class="details-and-price-box">
                                <div style="padding: 30px 5px;" >
                                    <div  style="margin-top: 10px;margin-left: 23px;margin-bottom: 25px;" class="information">
                                        <div class="textInformation" style="font-family: 'apercu-b';font-size: 12px;color:rgb(249, 61, 48); " *ngIf="this.details.outOfPolicyReason">{{this.details.outOfPolicyReason}}</div>
                                      </div>  
                                    <div >
                                        <div class="img-details-holder" >
                                            <div class="company-img" >
                                                <div><img *ngIf="carUrl(this.details.option.car_option)" src="{{carUrl(this.details.option.car_option)}}" onerror="this.src='assets/images/cars/vehicle_notavailable_S.jpg' " />
                                                </div>
                                                <div> <img style="margin-top:5px;height:auto!important;width:auto !important;border-radius: 6px" *ngIf="this.details.option.car_option.partnerLogo" src="{{ this.details.option.car_option.partnerLogo }}" /></div>
                                            </div>
                                            <div class="booking-details-holder">
                                                <div  class="d-flex  w-10 ">
                                                    <div class="flight-start-timing ">
                                                        <div class="arrow-holder-div">
                                                            <div>{{this.details.option.car_option.pickUpTime}}</div>
                                                            <div><div class="flight-arrow"><span></span></div></div>
                                                        </div>
                                                        <div>{{getDisplayDateTimeForFlights(this.details.option.car_option.pickUpDate,
                                                            'EEEE, MMM d, yyyy')}}</div>
                                                            
                                                    </div>
                                                    
                                                    <div class="flight-start-timing">
                                                        <div>{{this.details.option.car_option.dropOffTime}}</div>
                                                        <div>{{getDisplayDateTimeForFlights(this.details.option.car_option.dropOffDate,
                                                            'EEEE, MMM d, yyyy')}}</div>
                                                    </div>
                                                </div>
                                                
                                                <div class="booking-description" ><span>{{this.details.option.car_option.description}} </span></div>
                                                <div class="booking-description" >{{this.details.option.car_option.pickUpLocation}}</div>
                                                <div class="see-details-button" (click)="openModal(tripDetails,'car',this.details.option.car_option)">See details</div>
                                                <div  class="payment-details" *ngIf="getPaymentDetailsByDetails()">
                                                    <span>{{'bookingHistory.Paymentdetails' | translate}}: </span> {{getPaymentDetailsByDetails()}}
                                                </div>
                                                <div  class="payment-details" *ngIf="this.forReports && showDownloadInvoiceForCancelApproval(this.details)">
                                                    <span *ngIf="this.details.option.car_option.partnerName">{{'bookingHistory.Vendorcontactdetails' | translate}}:</span> {{this.details.option.car_option.partnerName}}
                                                    <div *ngIf="this.details.option.car_option.dropLocationPhoneNumber && this.details.option.car_option.partnerName" >{{this.details.option.car_option.dropLocationPhoneNumber.displayText}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div  class="price-continer-box">
                                    <div class="text-elm">
                                        <p class="price-show">
                                            <span>{{getTotalFare() | currency : getCurrencySymbol(this.details.option.car_option) :'code': '1.2-2'}}</span>
                                            <!-- <span  class="paymentdetailsModal" style="float: none;" (click)="openPaymentdetailsModal(paymentdetailsModal)"><i class="fa fa-question-circle-o" aria-hidden="true"></i></span> -->
                                        </p>
                                        <p class="policy-show" *ngIf="this.details.option.car_option.policy == true">WITHIN POLICY</p>
                                        <p class="policy-show" style="color: red;" *ngIf="this.details.option.car_option.policy == false">OUTSIDE POLICY</p>
                                        <div class="confirmation-text" *ngIf="this.forApproval == false">
                                            <p>{{'bookingHistory.Confirmation' | translate}}#</p>
                                            <p style="word-break:break-all;">{{getConfirmationNo() | uppercase}}</p>
                                        </div>
                                        <div class="confirmation-text text-uppercase" *ngIf="this.forApproval == true && getApprovalStatus()">
                                            <p>{{getApprovalStatus()}}</p>
                                        </div>
                                    </div>
                                    <div class="reports-invoice-button" *ngIf="this.forReports && showDownloadInvoiceForCancelApproval(this.details)" (click)="downloadInvoices()">
                                        <!-- <div><img src="assets/images/ic_receipt.svg" alt="" /></div> -->
                                        <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                            <div  class="booking-details-icons " *ngIf="showActionButtons() && this.showDetailsActionButtons && showDownloadInvoiceForCancelApproval(this.details)">
                            
                                <div class="item"  *ngIf="!this.hideChanges && showCalenderButton()  && showActionButtons()" (click)="goToCalendar(addCalendarModal)">
                                    <div >
                                        <img src="assets/images/calender.svg" alt="" />
                                    </div>

                                    <div>{{'bookingHistory.AddtoCalender' |translate}}</div>
                                </div>
                                <div class="item" *ngIf="!this.hideChanges && showActionButtons() && showTripItButton()"  (click)="goToTripItLink()">
                                    <div><img src="assets/images/tripit.png" alt=""></div>
                                    <div>{{'bookingHistory.AddtoTripIt' |translate}}</div> 
                                </div>
                                <div class="item" *ngIf="showExpensifyButton() && showExpenseButtons(this.tripDeatilsIndex)" (click)="showModal(addExpensifyModal)">
                                    <div>
                                        <img src="assets/images/Expense.svg" alt="" />
                                    </div>
                                    <div>{{'bookingHistory.Expense' | translate}}</div>
                                </div>
                                <div class="item" *ngIf="showDownloadInvoiceButton() && (showActionButtons() && showExpenseButtons())" (click)="downloadInvoices()">
                                    <div><img src="assets/images/ic_receipt.svg" alt="" /></div>
                                    <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                                </div>
                                <div class="item" *ngIf="showActionButtons() && showRequestChangeButton(this.tripDeatilsIndex) && showChangesFlag()" (click)="showModal(requestChangeModal,this.tripDeatilsIndex)">
                                    <div><img src="assets/images/ic_edit.svg" alt="" /></div>
                                    <div>{{'bookingHistory.REQUESTCHANGES' |translate}}</div>
                                </div>
                                <div class="item" *ngIf="showActionButtons() && showRebookButton(this.tripDeatilsIndex) && showChangesFlag()" (click)="showCancelModal(requestCancelModal,requestFlightCancelModal,cancelApprovalRequestModal)">
                                    <div>
                                        <img src="assets/images/ic_cancel.svg" alt="" />
                                    </div>
                                    <div>{{'bookingHistory.CANCELRESERVATION'|translate}}</div>
                                </div>
                            </div>

                            <div  class="booking-details-icons booking-details-icons-reports" *ngIf="this.forReports && showDownloadInvoiceForCancelApproval(this.details)">
                                <div class="item"  (click)="downloadInvoices()">
                                    <div><img src="assets/images/ic_receipt.svg" alt="" /></div>
                                    <div>{{'bookingHistory.DOWNLOADINVOICE'| translate}}</div>
                                </div>
                            </div>
                </div>
            </div>
            
        </div>	
    </div>
</div>



<!-- For action buttons  -->
<ng-template #addExpensifyModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">
            {{'bookingHistory.Expense' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body">
        <div class="input-field">
            <form method="post" id="expensifyForm" class="expensifyForm">
                <div class="row">

                    <div style="width:100%;margin-top:10px;padding-left:10px;padding: 0 8px;">
                        <label style="color:gray;margin-top:0px;margin-bottom: 0px;"> {{'bookingHistory.Selectyourexpensingtool' | translate}}:</label>
                        <div class="formControl99" id="expennsify">
                            <ng-select #expense [items]="expenseProviders" bindLabel="" dropdownPosition="bottom" bindValue="id" [searchable]="false" [clearable]="false" [formControl]="expensifyEmailControlDD" class="booking-history-expense-form">
                                <ng-template ng-header-tmp>
                                    <div class="selectox-header">
                                        <span> {{'bookingHistory.Expensingtool' | translate}}</span>
                                        <span class="selectBox-remove" (click)="expense.toggle()"><span
                                                class="material-icons">clear</span></span>
                                    </div>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <img class="expense-image" src="{{item.url}}" />
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <img class="expense-image" src="{{item.url}}" />
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                        <div class="input-box">
                            <input [formControl]="expensifyEmailControl" style="max-width: 100% !important;" class="input-textfield input-textfield-lg" id="expensifyLoginEmail" name="expensifyLoginEmail" placeholder="{{this.getPlaceholderText()}}" type="text">
                        </div>
                        <div *ngIf="expensifyEmailControl.hasError('required') && (expensifyEmailControl.touched || expensifyEmailControl.dirty)" class="error">{{'bookingHistory.Thisfieldisrequired' | translate}}
                        </div>
                        <div *ngIf="expensifyEmailControl.hasError('pattern') && (expensifyEmailControl.touched || expensifyEmailControl.dirty)" class="error">{{'bookingHistory.Pleaseenteravalidemail' | translate}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="this.errorMsg">
            <span class="text-danger">{{'bookingHistory.Pleaseselectanyofcheckboxdefineabove'| translate}}</span>
        </div>
        <div class="modal-form-button">
            <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="onExpensifyAdd()">{{'bookingHistory.SendReceipttoExpense' | translate}}</button>
            <button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'| translate}}</button>
        </div>
    </div>
</ng-template>


<ng-template #requestChangeModalFlight let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
            {{'bookingHistory.RebookAndCancel' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body requestModalBody">
        <div class="input-field">
            <label style="font-size: 16px;font-family:'apercu-r';text-align: left;
            margin-left: 13px;">{{'bookingHistory.Pleasenoteonceyourebookthisreservationwillbecancelledanditcannotbeundone.'
                | translate}}</label>
    
        </div>
        
        <div class="modal-form-button" style="margin-bottom: 30px;">
            <button class="btn primary-button" (click)="onRequestChangeForFlight()" [disabled]="this.checked">{{'bookingHistory.PROCEEDTOREBOOK'
                | translate}}</button>
        </div>
    </div>
    <div class="modal-footer">
        
    </div>
</ng-template>


<ng-template #requestCancelModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
            {{'bookingHistory.Cancelyour' | translate}} {{getHeading()}}?</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body requestModalBody" style="text-align: left !important;">
        <div *ngFor="let item of selectedDetail;let i=index;">
            <div class="input-field" style="font-size: 15px;float: left;padding-left: 12px;text-align: left;">
                <label *ngIf="i===0 && getHeading()!=='approval request'">{{getCarCancelMsg(item.type,item)}}</label>
            </div>
            <div *ngIf="item.type =='flight'" style="text-align: left;">
                <ng-container *ngFor="let flight of item.option.flight_option.legs;">
                    <label style="float: left;padding-left: 12px;margin-top:10px;">
                        {{getAirlineFullName(flight.hops[0].ocarrier)}}:{{getDisplayDateTimeForFlights(flight.hops[0].starts,'EE, MMM d')}} 
                        {{'bookingHistory.from' | translate}}{{flight.hops[0].from}}
                        {{'bookingHistory.to' | translate}}{{flight.hops[flight.hops.length-1].to}}
                    </label>
                </ng-container>
            </div>
            <div *ngIf="item.type =='hotel'">
                <label style="float: left;padding-left: 12px;margin-top:10px;">{{'bookingHistory.Hotel' | translate}}:
                    {{item.option.hotel_option.hotelName}},{{item.option.hotel_option.address}}
                </label>
                <label style="float: left;padding-left: 12px;margin-top:10px;">{{'bookingHistory.CheckIn' |
                    translate}}:
                    {{getDisplayDate(item.option.hotel_option.checkInDate,'MMM d')}} ; {{'bookingHistory.CheckOut' |
                    translate}}:
                    {{getDisplayDate(item.option.hotel_option.checkOutDate,'MMM d')}}</label>
            </div>
            <div *ngIf="item.type =='cars'">
                <label style="float: left;padding-left: 12px;margin-top:10px;"> {{'bookingHistory.Pick-Up' | translate}}:
                    {{getDisplayDateTimeForFlights(item.option.car_option.pickUpDate,
                    'MMM d')}}, {{item.option.car_option.pickUpTime}} - {{item.option.car_option.pickUpLocation}}
                </label>
                <label style="float: left;padding-left: 12px;margin-top:10px;"> {{'bookingHistory.Drop-off' | translate}}:
                    {{getDisplayDateTimeForFlights(item.option.car_option.dropOffDate,
                    'MMM d')}}, {{item.option.car_option.dropOffTime}} - {{item.option.car_option.dropOffLocation}}
                </label>
            </div>
        </div>
        <div class="modal-form-button">
            <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="cancelRequest()"><span style="margin-right: 4px;">{{'bookingHistory.Confirm' | translate}}</span> <span
                    *ngIf="getHeading()!=='approval request'" >{{getHeading()}}</span> {{'bookingHistory.Cancellation' | translate}}</button>
            <button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'
                | translate}}</button>
        </div>
    </div>
    <div class="modal-footer" style="text-align: center;padding-top:15px;padding-bottom:25px;display:block !important;color:gray;">
        {{'bookingHistory.Pleasenoteonce' | translate}} {{getHeading1()}} {{'bookingHistory.iscancelleditcannotbeundone.' | translate}}
    </div>
</ng-template>
<ng-template #cancelFailFlightModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
                {{'bookingHistory.Autocancellationnotsupported' | translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="onModelFailFlightCancel()">
                <i class="material-icons">close</i>
            </button>
        </div>
        <div class="modal-body" style="text-align: left !important;">
           <div class="cancelHeading">
                {{'bookingHistory.Ticketstatus' | translate}} {{'bookingHistory.Checkedin' | translate}}
           </div>
           <div class="cancelHeading">
                {{'bookingHistory.Autocancellation' | translate}} {{'bookingHistory.Notsupported' | translate}}
           </div>
           <div class="cancelHeading">
                {{'bookingHistory.Youllreceiveanemailupdateoncecancellationiscomplete' | translate}} 
           </div>
        </div>
        <div class="modal-button-wrapper" style="padding-bottom: 40px;">
                <button type="button" name="buttonName"
                (click)="onModelFailFlightCancel()" class="btn btn-default modal-button">{{'option.OK' | translate}}</button>    
            </div>

       
       
    </ng-template>
<ng-template #requestFlightCancelModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
            {{'bookingHistory.Cancelyour' | translate}} {{getHeading()}}?</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body requestModalBody">
        <div *ngIf="getHeading()!=='approval request'" style="font-size: 15px;float: left;padding-left: 12px;text-align: left;">
            {{getCancelMsg()}}</div>
        <div *ngFor="let flight of selectedDetail;let i=index;">
            <div *ngIf="flight.type =='flight'" style="text-align: left;">
                <ng-container *ngFor="let item of flight.option.flight_option.legs;let flightIndex = index">
                    <label style="float: left;padding-left: 12px;margin-top:10px;">
                        {{'bookingHistory.flight' | translate}} {{flightIndex+1}} : {{getDisplayDateTimeForFlights(item.hops[0].starts,'MMM d')}} {{'bookingHistory.from' | translate}}
                        {{item.hops[0].from}}
                        ({{getAirportCity(item.hops[0].from)}})
                        {{'bookingHistory.to' | translate}}
                        {{item.hops[item.hops.length-1].to}}
                        ({{getAirportCity(item.hops[item.hops.length-1].to)}})
                    </label>
                </ng-container>
            </div>
        </div>
        <div class="modal-form-button">
            <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="cancelRequest()"><span style="margin-right: 4px;">{{'bookingHistory.Confirm' | translate}}</span><span
                    *ngIf="getHeading()!=='approval request'" >{{getHeading()}}</span> {{'bookingHistory.Cancellation' | translate}}</button>
            <button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'
                | translate}}</button>
        </div>
    </div>
    <div class="modal-footer" style="text-align: center;padding-top:15px;padding-bottom:25px;display:block !important;color:gray;">
        {{'bookingHistory.Pleasenoteonceflightiscancelleditcannotbeundone.' | translate}}

    </div>
</ng-template>
<ng-template #cancelApprovalRequestModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
            {{'bookingHistory.Cancelyour' | translate}} {{getHeading()}}?</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body requestModalBody" style="text-align: left !important;">
        <div *ngFor="let item of selectedDetail;let i=index;">
            <div class="input-field" style="font-size: 15px;float: left;padding-left: 12px;text-align: left;">
                <label *ngIf="i===0 && getHeading()!=='approval request'">{{getCarCancelMsg(item.type,item)}}</label>
            </div>
            <div  style="text-align: left;">
                <ng-container >
                    <label style="float: left;padding-left: 12px;margin-top:10px;">
                    <span>{{'bookingHistory.caneclApprovalMessage' | translate}}</span>
                    </label>
                </ng-container>
            </div>
        </div>
        <div class="modal-form-button">
            <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="cancelApprovalRequest()">{{'bookingHistory.continue' | translate}}</button>
            <button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'| translate}}</button>
        </div>
    </div>
    <div class="modal-footer" style="text-align: center;padding-top:15px;padding-bottom:25px;display:block !important;color:gray;">
        {{'bookingHistory.Pleasenoteonce' | translate}} {{getHeading1()}} {{'bookingHistory.iscancelleditcannotbeundone.' | translate}}
    </div>
</ng-template>
<ng-template #requestChangeModal let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
                {{'bookingHistory.RequestChanges' | translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
                <i class="material-icons">close</i>
            </button>
        </div>
        <div class="modal-body requestModalBody">
        <div class="input-field">
                <label>{{'bookingHistory.PleasetelluswhatchangeyouwouldlikeforyourTrip'
                    | translate}}</label>
                <div *ngFor="let item of this.selectedDetail">
                    <div *ngIf="item.type =='flight'">
                    <label class="font-bold">{{getAirlineFullName(item.option.flight_option.legs[0].hops[0].ocarrier)}}:{{getDisplayDateTimeForFlights(item.option.flight_option.legs[0].hops[0].starts,
                            'EE, MMM d')}} {{'bookingHistory.from' | translate}}
                            {{item.option.flight_option.legs[0].hops[0].from}}
                            {{'bookingHistory.to' | translate}}
                            {{item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to}}
                        </label>
                    </div>
                    <div *ngIf="item.type =='hotel'">
                        <label class="font-bold"> {{'bookingHistory.CheckIn' | translate}} {{getDisplayDate(item.option.hotel_option.checkInDate ,
                            'EE, MMM d')}}-{{item.option.hotel_option.hotelName}},{{item.option.hotel_option.address}}
                        </label>
                    </div>
                    <div *ngIf="item.type =='cars'">
                        <label class="font-bold">
                            {{'bookingHistory.Pick-Up' | translate}}:{{getDisplayDateTimeForFlights(item.option.car_option.pickUpDate,
                            'EE, MMM
                            d')}}-{{item.option.car_option.description}},{{item.option.car_option.pickUpLocation}}
                        </label>
                    </div>
                </div>
                <div style="position: relative;float: left;
                width: 100%;">
                        <span  class="showNumber">
                                {{messageForChange.length}}/255
                              </span>
                <textarea placeholder="{{'bookingHistory.Adddetailsforyourchange' | translate}}" [(ngModel)]="messageForChange" maxlength="255" (focus)="getChecked()" style="position: relative;padding-right: 55px;padding-top: 20px;" class="modal-textarea input-textfield"></textarea>
                          </div>
            </div>
            <div *ngIf="showChangeRequestError">
                <span class="text-danger">{{'bookingHistory.youmusthavetowritethechangemessage'
                    | translate}} </span>
            </div>
            <div class="modal-form-button">
            <button class="btn primary-button" (click)="onRequestChange(detailList)" [disabled]="this.checked">{{'bookingHistory.SubmitChangeRequest'
                    | translate}}</button>
            </div>
        </div>
        <div class="modal-footer">
            <p>
            <span>{{'bookingHistory.Note' | translate}}</span> {{'bookingHistory.Sendingarequestwillnotimpactormodifyyourreservationwithoutfurtheraction.YouwillreceiveanemailfromTripwiththebestoptionsandapplicablechangefeeorfaredifferencesforyoutorespondtobeforeachangeisexecuted'
                | translate}}
            </p>
        </div>
</ng-template>

<ng-template #addTripitModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">
            {{'bookingHistory.AddtoTripIt' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body">
        <div *ngFor="let option of expensifyType">
            <div class="header-inner">
                <div class="header-left">
                    <input id="{{option.name}}" value="{{option.value}}" class="mdl-radio__outer-circle" type="radio" name="{{option.name}}" [(ngModel)]="radioSelect" (change)="setTripItUrl(option.value)">
                </div>
                <div class="header-right">
                    <span class="mdl-radio__label"> {{option.name | translate}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="this.showRadioError">
            <span class="text-danger">{{'bookingHistory.youmusthavetoselectoneoftheabove'
                | translate}}</span>
        </div>
        <div class="modal-form-button">
            <button class="btn primary-button" (click)="proceedtoTripIt()">{{'bookingHistory.AddtoTripIt'
                | translate}}</button>
        </div>
    </div>
</ng-template>

<ng-template #addCalendarModal let-modal style="max-width: 310px !important;">
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">
            {{'bookingHistory.AddtoCalender' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body" (click)="onSmartModelCancel('calendarOptionModal')">
       
            <ngx-new-modal [flightUrl]="this.calenderUrl" [hotelUrl]="this.calenderUrlHotel" [selectOption]="this.selectOptionForModal" [calUrls]="this.calUrls" [template]="this.calenderTemplate"></ngx-new-modal>
       
    </div>
</ng-template>
<ng-template #cardDetailsModal let-modal style="max-width: 310px !important;">
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">
            {{'employee.cardDetails' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body" >
        <div class="cardModalHeader">
        <div><span style="font-size: 20px;" >{{this.cardDetails.name}}</span> </div>
        <div>
            <div style="display: inline-block;">
                <div style="font-family: Helvetica, sans-serif;font-size: 18px;margin-top: 15px;" >
                   {{getCardNumberDetails(this.cardDetails.cardNumber)}}
                </div>
                <div style="margin-top: 15px;margin-bottom: 5px;">
                    <div class="cardMsg" style="padding-right: 10px;">
                        <span class="expcvv_name">Exp: </span><span >{{this.cardDetails.exp_month}}/{{this.cardDetails.exp_year}}</span>
                    </div>
                    <div class="cardMsg">
                        <span class="expcvv_name"> CVV: </span><span >{{this.cardDetails.cvc}}</span>
                    </div>
                </div>
            </div>
           
        </div>
        <div>
            <div  class="nameOnCard"></div>    
            <div class="brand"><img class="inlineblock_m" style="width:35px;" src="https://s3.amazonaws.com/images.biztravel.ai/cc/{{this.cardDetails.brand}}.svg" /></div>
                        </div>
    </div>  
          
       
    </div>
</ng-template>
<!-- action button pop ends  -->

<ng-template #paymentdetailsModal let-modal>
    <div class="modal-header"
        style="padding: 8px 10px 10px 20px !important;background:#315EF6;color: #fff;height: 42px;font-size: 14px;font-family: 'apercu-mono';">
        <span *ngIf="this.billingItem">
            {{'paymentDetails.SummaryofCharges' | translate}}</span>
        <button type="button" class="close" data-dismiss="modal" (click)="onCancel()">
            <i class="material-icons" style="color: #fff;">close</i>
        </button>
    </div>
    <div class="modal-body" style="background-color: #FFF !important;padding:22px 43px; ">
        <ng-container >
                <ng-container *ngFor="let fare of fareList">
                    <ng-container *ngIf="this.billingItem.option.flight_option">
                        <tr  style="display: flex;justify-content: space-between;" *ngIf="fare.event_type=='flight'">
                            <ng-container>
                                <td class="text-left">{{'bookingHistory.FLIGHTFARE'| translate}}</td>
                                <td class="text-right">{{getPriceItem(fare) | currency: getCurrencySymbol(this.billingItem.option.flight_option):'code':'1.2-2'}}</td>
                            </ng-container>
                        </tr>
                        <tr  style="display: flex;justify-content: space-between;" *ngIf="fare.event_type=='other'  && fare.transactionEventType ==='travel_credit'">
                            <ng-container>
                                <td class="text-left">{{'paymentDetails.CreditsUsed' | translate}}</td>
                                <td class="text-right">-{{getCreditAmount(fare.travelCreditInfo,this.billingItem.option.flight_option.price) | currency :getCurrencySymbol(this.billingItem.option.flight_option):'code': '1.2-2'}}</td>
                            </ng-container>
                        </tr>
                        <tr  style="display: flex;justify-content: space-between;" *ngIf="fare.event_type=='other'  && fare.transactionEventType ==='travel_credit'">
                            <ng-container>
                                <td class="text-left">{{fare.description | uppercase}}</td>
                                <td class="text-right">{{getCreditPrice(fare) | currency :getCurrencySymbol(this.billingItem.option.flight_option):'code': '1.2-2'}}</td>
                            </ng-container>
                        </tr>
                        <ng-container *ngIf="fare.event_type=='other' && fare.price != 0 && fare.transactionEventType!=='travel_credit'">
                            <hr>
                            <tr  style="display: flex;justify-content: space-between;" >
                                <td class="text-left">{{fare.description|uppercase}}</td>
                                <td class="text-right">{{getPriceItem(fare) | currency :getCurrencySymbol(this.billingItem.option.flight_option):'code': '1.2-2'}}</td>
                            </tr>
                        </ng-container>
                        <tr  style="display: flex;justify-content: space-between;" *ngIf="this.gallopCash > 0">
                            <ng-container>
                                <td class="text-left">{{'bookingHistory.GallopCashApplied' | translate}}</td>
                                <td class="text-right">{{this.gallopCash| currency :getCurrencySymbol(this.billingItem.option.flight_option) :'code': '1.2-2'}}</td>
                            </ng-container>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="this.billingItem.option.hotel_option">
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type == 'hotel'">
                            <td class="text-left" style="line-height:14px !important; "> {{'bookingHistory.Roomcost(pernight)' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getPerNightCost(billingItem) |currency :getCurrencySymbol(billingItem.option.hotel_option) :'code': '1.2-2'}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type == 'hotel'">
                            <td class="text-left" style="line-height:14px !important; "> {{'bookingHistory.Nights' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{billingItem.option.hotel_option.stay}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type == 'hotel'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.Rooms' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{billingItem.option.hotel_option.hotelRooms.length}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type == 'hotel'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.Guests' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getTotalPassengerFromEmail(billingItem)}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type == 'hotel'">
                            <td class="text-left" style="line-height:14px !important; ">{{'paymentDetails.RoomSubtotal' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getRoomSubtotal(billingItem,getPriceItem(fare)) | currency: getCurrencySymbol(billingItem.option.hotel_option):'code':'1.2-2'}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="getTax(billingItem) && showTaxes() && fare.event_type == 'hotel'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.TaxesandFees' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getTax(billingItem) |currency : getCurrencySymbol(billingItem.option.hotel_option) : 'code': '1.2-2'}}
                            </td>
                        </tr>
                        
                        <tr style="display: flex;justify-content: space-between;" *ngIf="getResortFee(billingItem) && fare.event_type == 'hotel'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.Resortfees' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getResortFee(billingItem) |currency : getCurrencySymbol(billingItem.option.hotel_option) : 'code': '1.2-2'}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type =='hotel'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.HOTELFARE' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getPriceItem(fare) | currency : getCurrencySymbol(billingItem.option.hotel_option): 'code': '1.2-2'}}
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="this.billingItem.option.car_option">
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type == 'cars'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.BasePrice' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getCarPrice(getTotalFare(),getTaxForCar(billingItem)) |currency :getCurrencySymbol(billingItem.option.car_option) :'code': '1.2-2'}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type == 'cars'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.TaxesandFees' | translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getTaxForCar(billingItem)| currency :getCurrencySymbol(billingItem.option.car_option) :'code': '1.2-2'}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="fare.event_type =='cars'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.CarFare'| translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{getPriceItem(fare)  | currency :getCurrencySymbol(billingItem.option.car_option) :'code': '1.2-2'}}
                            </td>
                        </tr>
                        <tr style="display: flex;justify-content: space-between;" *ngIf="this.gallopCash > 0 && fare.event_type == 'cars'">
                            <td class="text-left" style="line-height:14px !important; ">{{'bookingHistory.GallopCashApplied'| translate}}:</td>
                            <td class="text-right" style="line-height:14px !important; ">
                                {{this.gallopCash | currency :getCurrencySymbol(billingItem.option.car_option) :'code': '1.2-2'}}
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                    <hr>
                     <tr style="display: flex;justify-content: space-between;">
                        <td class="text-left total-payment-amount">{{'paymentDetails.Total' | translate}}</td>
                        <td style="display: none;" class="text-left total-payment-amount">{{'paymentDetails.DueToday' | translate}}</td>
                        <td class="text-right total-payment-amount" >{{getTotalFare() | currency: getCurrencySymbol(getOptionsByIndex(billingItem)): "code" : '1.2-2' }}</td>
                        
                    </tr>
        </ng-container>
    </div>
</ng-template>

                        