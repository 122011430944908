<app-loader *ngIf="appLoading"></app-loader>
<div *ngIf="!appLoading">
    <div class="summery-view-container" *ngIf="isResult">
        <div class="summary-view">
            <div class="container">
                <div class="summary-view-inner">
                    <div class="source-dest">
                        <div class="source-dest-place">{{departureAirports}} <br>{{departureAirports &&
                            departureAirports.trim().length > 0 && searchForm.controls['source'].value.length == 3 ? ''
                            : searchForm.controls['source'].value}} </div>
                        <div class="switch2">
                            <span class="icon-arrow"></span>
                        </div>
                        <div class="source-dest-place">{{arrivalAirports}} <br>{{ arrivalAirports &&
                            arrivalAirports.trim().length > 0 && searchForm.controls['destination'].value.length == 3 ?
                            '' : searchForm.controls['destination'].value}}</div>
                    </div>
                    <div class="summary-view-middle">
                        <div>{{(searchForm.controls['departureDate'].value| date:'EEE MMM d')}}
                            <span *ngIf="searchForm.controls['trip'].value == 'ROUNDTRIP'"> -
                                {{(searchForm.controls['arrivalDate'].value| date:'EEE MMM d')}}</span>
                        </div>
                        <div class="class-passenger" >
                            <div class="summery-class" style="display: none;">{{getClassName(searchForm.controls['class'].value) | translate}}
                            </div>
                            <div class="summery-passenger">
                                <span class="icon-customer"></span>
                                <span>{{searchForm.controls['passengers'].value}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="edit-summery-view">
                        <span class="icon-edit" attr.data-track="EditFlightSearch" (click)="backToSearch()"></span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="getOutgoingSelectedFlight() && getSearchIndex() > 0" class="selected-flight-container">
            <flight-selected (deSelectEmitter)="flightUnSelected($event)"
                [noOfPassengers]="this.searchService.employeeEmail.length" [flight]="this.searchService.flight[0]">
            </flight-selected>
        </div>
        <div *ngFor="let flightReq of getAdditionalReqeustControl(); let i= index" class="summary-view">
            <div class="container">
                <div class="summary-view-inner">
                    <div class="source-dest">
                        <div class="source-dest-place">{{getDepartureAirportsForLeg(i)}}
                            <br>{{getDepartureAirportsForLeg(i) && getDepartureAirportsForLeg(i).trim().length > 0 &&
                            flightReq.controls['source'].value.length == 3 ? '' : flightReq.controls['source'].value}}
                        </div>
                        <div class="switch2">
                            <span class="icon-arrow"></span>
                        </div>
                        <div class="source-dest-place">{{getArrivalAirportsForLeg(i)}} <br>{{
                            getArrivalAirportsForLeg(i) && getArrivalAirportsForLeg(i).trim().length > 0 &&
                            flightReq.controls['destination'].value.length == 3 ? '' :
                            flightReq.controls['destination'].value}}</div>
                    </div>
                    <div class="summary-view-middle">
                        <div>{{(flightReq.controls['departureDate'].value| date:'EEE MMM d')}}
                            <!-- <span *ngIf="searchForm.controls['trip'].value == 'ROUNDTRIP'"> - {{(searchForm.controls['arrivalDate'].value| date:'EEE MMM d')}}</span> -->
                        </div>
                        <div class="class-passenger">
                            <div class="summery-class" style="display: none;">{{getClassName(searchForm.controls['class'].value) | translate}}
                            </div>
                            <div class="summery-passenger">
                                <span class="icon-customer"></span>
                                <span>{{searchForm.controls['passengers'].value}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="edit-summery-view">
                        <span class="widthAdjust"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="this.searchService.seletedIndex > 1 &&  getOutgoingSelectedFlight()"
                class="selected-flight-container">
                <div *ngFor="let item of this.searchService.flight;let j = index">
                    <div *ngIf=" showMutiCity(i,j) && j === i">
                        <flight-selected (deSelectEmitter)="flightUnSelected($event)"
                        [noOfPassengers]="this.searchService.employeeEmail.length" [flight]="this.searchService.flight[i +1]"></flight-selected>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-container" *ngIf="showFilter">
        <flight-search-filter [searchForm]="searchForm" (selectedFilter)="searchAgain($event)"></flight-search-filter>
    </div>
</div>


<!--<tabs>
  <tab tabTitle="Flights">
    I am the foo content
  </tab>
  <tab tabTitle="Hotels" active="true">
    I am the bar content
  </tab>
</tabs>-->

<div class="tab" *ngIf="!isResult" (click)="this.showCurrentLocation=false;">
    <div *ngIf="njoySpecificBuild && this.userAccountInfoService.showRadio" style="width:100%;" [ngStyle]="{'height':this.bookingService.previousBooking ? '0px;':'55px'}"></div>
    <div class="tab-list top-strip">

        <ul style="">
            <li class="tab-list-item active" *ngIf="this.searchService.showFlightTab"  [class.active]="viewMode == 'tab1'" rel="tab1"
                (click)="flightTabClicked()">
                <a attr.data-track="SearchTab" attr.data-params="tab=Flights" href="javascript:void(0)">
                    <img class="inlineblock_m" attr.data-track="SearchTab" attr.data-params="tab=Flights"
                        src="assets/images/flight-white-icon.png" />
                    <span class="inlineblock_m" attr.data-track="SearchTab" attr.data-params="tab=Flights">{{
                        'search.tab_flights' | translate }}</span>
                </a>
            </li>
            <li class="tab-list-item " *ngIf="!this.searchService.selectedEventID  && !this.bookingService.previousBooking &&   this.searchService.showHotelTab" [class.active]="viewMode == 'tab2'" rel="tab2"
                (click)="hotelTabClicked(errorModal)">
                <a attr.data-track="SearchTab" attr.data-params="tab=Hotels" href="javascript:void(0)">
                    <img class="inlineblock_m" attr.data-track="SearchTab" attr.data-params="tab=Hotels"
                        src="assets/images/hotel-white-icon.svg" />
                    <span class="inlineblock_m" attr.data-track="SearchTab" attr.data-params="tab=Hotels">{{
                        'search.tab_hotels' | translate }}</span>
                </a>
            </li>
            <ng-container *ngIf="!this.searchService.selectedEventID && isCarsEnabled() && !this.bookingService.previousBooking &&  this.searchService.showCarTab">
                <li class="tab-list-item " [class.active]="viewMode == 'tab3'" rel="tab3"
                    (click)="carTabClicked(errorModal)">
                    <a attr.data-track="SearchTab" attr.data-params="tab=Cars" href="javascript:void(0)">
                        <span class="inlineblock_m"> <i class="fa fa-car" attr.data-track="SearchTab"
                                attr.data-params="tab=Cars" style="margin-right:8px;" aria-hidden="true"></i></span>
                        <span class="inlineblock_m" attr.data-track="SearchTab" attr.data-params="tab=Cars">{{
                            'search.tab_cars' | translate }}</span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="this.searchService.showTrainsTab && !this.bookingService.previousBooking" >
                <li class="tab-list-item"  [class.active]="viewMode == 'tab5'" rel="tab5"
                (click)="trainTabClicked()">
                <a attr.data-track="SearchTab" attr.data-params="tab=Trains" href="javascript:void(0)">
                    <span class="inlineblock_m"> <i class="fa fa-train" attr.data-track="SearchTab"
                        attr.data-params="tab=Cars" style="margin-right:8px;" aria-hidden="true"></i>
                    </span>
                    <span class="inlineblock_m" attr.data-track="SearchTab" attr.data-params="tab=Trains">Trains</span>
                </a>
            </li>
            </ng-container>
            <ng-container  *ngIf="((isUserCompanyManager() || isUserCompanyTravelManager()) && isShowAllBookings() &&  !this.bookingService.previousBooking) && !this.searchService.selectedEventID">
                    <li class="tab-list-item " [class.active]="viewMode == 'tab4'" rel="tab4"
                        (click)="BookingsTabClicked()">
                        <a attr.data-track="SearchTab" attr.data-params="tab=Bookings" href="javascript:void(0)">
                            <span class="inlineblock_m"> <i class="fa fa-globe" style="font-size: 16px;
                                margin-right: 5px;" aria-hidden="true"></i></span>
                            <span class="inlineblock_m" attr.data-track="SearchTab" attr.data-params="tab=Bookingss">{{
                                'search.tab_bookings' | translate }}</span>
                        </a>
                    </li>
                </ng-container>
            <ng-container *ngIf="false">
                <li class="tab-list-item " rel="tab3">
                    <a href="https://taazu.com/" target="_blank">
                        <span class="inlineblock_m">{{ 'search.Experiences' | translate }}</span>
                    </a>
                </li>
                <li class="tab-list-item " rel="tab4">
                    <a href="http://www.travelwithamnet.com/" target="_blank">
                        <span class="inlineblock_m">{{ 'search.USA' | translate }}</span>
                    </a>
                </li>
                <li class="tab-list-item " rel="tab5">
                    <a href="https://www.amnet.jp/" target="_blank">
                        <span class="inlineblock_m">{{ 'search.Japan' | translate }}</span>
                    </a>
                </li>
                <li class="tab-list-item " rel="tab6">
                    <a href="https://triptribe.com/" target="_blank">
                        <span class="inlineblock_m">{{ 'search.Retreats' | translate }}</span>
                    </a>
                </li>
            </ng-container>
        </ul>
        <!--        <app-switch-component *ngIf="this.userAccountInfoService.showRadio" class="switch-div switch-book-for" [leftOptionValue]="'BOOK_FOR_SELF'" [leftOptionText]="'Book for myself'"
                [rightOptionValue]="'BOOK_FOR_OTHERS'" [rightOptionText]= "'Book for others'"
                [switchGroupName] = "'BookForSelfOrOthers'" [selectedOption]="this.searchService.typeOfBooking" [lastSelectedOption]="this.searchService.typeOfBooking"
                [toggleSwitchClassName]="'class-toggle-switch-book-for'" [toggleSwitchLabelClassName]="'switch-toggle-label-book-for'"
                (tripTypeChange)="onBookingTypeClicked($event)"
                ></app-switch-component>-->

    </div>
    <div class="{{ (searchForm.controls['trip'].value === 'MULTICITY') || this.showAllBookings ? 'tab-content1 active':'tab-content active'}}"
        [ngSwitch]="viewMode">
        <!-- <search-modal-basic></search-modal-basic> -->
        <div *ngIf="this.viewMode !=='tab4'" class="travlerBlock" [ngStyle]="changeStyleOfheight()">
            <div class="left-panel"> <label>{{ 'search.Selecttraveler' | translate }}:</label></div>
            <div class="right-panel">
                <div class="row">
                    <div *ngFor="let item of this.searchService.employeeEmail;let i=index"
                        class="col-lg-3 col-md-3 col-sm-12" style="min-width: 200px;">
                        <div class="selectedTraveler">
                                <div class="travelerName" *ngIf="item.Name ==='Myself'">{{'search.Myself' | translate }}</div>
                            <div class="travelerName" *ngIf="item.Name !=='Myself'">{{item.Name | translate }}</div>
                            <div *ngIf="item.email !=='GUEST'" class="travelerEmail">{{item.email}}</div>
                        </div>
                        <span class="travlerCross" *ngIf="!this.searchService.multiTripBooking && !this.bookingService.previousBooking" (click)="removeTravler(item.email,i,employeeType)"> <i
                                class="material-icons">close</i></span>
                        <div *ngIf="this.searchService.infantBookingAllowed && (this.viewMode ==='tab1' || this.viewMode ==='tab5') " class="col-auto" id="ageGroup-filter_{{i}}" style="padding-right: 0px !important;
                        padding-left: 0px !important;">
                                <div class="filter custom-selectbox"  style="margin-right: 0px !important;padding-right: 12px !important;"  attr.data-track="TripTypeMenu"
                                    (click)="openDropdown(AgeGroup,item)">
                                    <div class="select-input">
                                        <ng-select [ngClass]="{'custom-header': true}" #AgeGroup appendTo="#ageGroup-filter_{{i}}"
                                            dropdownPosition="bottom"  [searchable]="false" 
                                            [clearable]="false" [items]="ageGroupList"
                                            bindLabel="value" bindValue="id" (change)="ageGroupChanged($event,i)"
                                            >
                                            <ng-template ng-header-tmp>
                                                <div class="selectox-header">
                                                        Traveler Type
                                                    <span></span>
                                                    <span class="selectBox-remove" (click)="openDropdown(AgeGroup,item)"><span
                                                            class="material-icons">clear</span></span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-option="item">
                                                    <span class="option-title">{{option.value}}</span>
                                                   
                                                </ng-template>
                                           
                                        </ng-select>

                                        <div class="select-overlay"></div>
                                    </div>
                                    <div class="field-value custom-selectbox-value"  [ngStyle]="{'color':getItemDob(item) || this.bookingService.previousTransactionId ? '#AEAEAE':'#000'}" attr.data-track="AgeGroup">
                                        {{getAgeGroup(i) | translate}}</div>
                                    <span class="control-icon icon-triangle" attr.data-track="AgeGroup"></span>
                                </div>
                            </div>
                      
                    </div>
                    <div [hidden]="!showDropDoown()" class="col-lg-4 col-md-4 col-sm-12"
                        style="min-width: 200px;cursor: pointer;">
                        <div id="typeEmployee" (click)="showToatsr();" class="selectedTraveler">
                            <ng-select #employeeType appendTo="#typeEmployee"
                                [disabled]="!this.userAccountInfoService.showRadio" (click)="isdropDownOpen()"
                                groupBy="Type" dropdownPosition="bottom" [searchable]="true" [clearable]="false"
                                [closeOnSelect]="true" (close)="closeDropdown()"  (change)="handleKeyDown1($event)"
                                [items]="this.searchService.employeeOptions[0]" bindLabel="" bindValue=""
                                [(ngModel)]="search" [searchFn]="searchByApproverNameAndEmailChanged">
                               
                                <ng-template *ngIf="!this.dropDownopen" ng-label-tmp let-item="item">
                                    <span> {{ 'search.AddTraveler' | translate }} </span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                    <div (click)="showEmployeeTypeChanged(item.email)" tabindex="0" (keydown)="handleKeyDown1($event)"
                                        style="display: inline-block !important;
                                                            white-space: nowrap;padding:0px 15px 0px 0px;width:100%;">{{item.Name | translate}}</div>
                                </ng-template>
                                <ng-template ng-optgroup-tmp let-item="item">
                                    {{item.Type | translate}}
                                </ng-template>
                            </ng-select>
                            <div class="select-overlay"></div>
                            <div class="travelerAddIcon" (click)="employeeType.toggle();"><i class="fa fa-user-plus"
                                    aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="false" class="row">
                    <div class="col-lg-3 col-md-3 col-sm-12" style="min-width: 200px;cursor: pointer;">
                        <div id="typeEmployee" (click)="showToatsr();" class="selectedTraveler">
                            <ng-select #employeeType appendTo="#typeEmployee" (click)="isdropDownOpen()" groupBy="Type"
                                dropdownPosition="bottom" [searchable]="true" [clearable]="false" [closeOnSelect]="true"
                                (close)="closeDropdown()" [items]="this.searchService.employeeOptions[0]"
                                bindLabel="Name" [(ngModel)]="search" bindValue=""
                                [searchFn]="searchByApproverNameAndEmailChanged"
                                (change)="showEmployeeTypeChanged($event.email)">
                                <ng-template ng-header-tmp>
                                    <div class="selectox-header">
                                        <span> {{ 'search.Selecttraveler' | translate }}</span>
                                    </div>
                                </ng-template>
                                <ng-template *ngIf="!this.dropDownopen" ng-label-tmp let-item="item">
                                    <span> {{ 'search.AddTraveler' | translate }}</span>
                                </ng-template>
                            </ng-select>
                            <div class="select-overlay"></div>
                            <div class="travelerAddIcon" (click)="employeeType.toggle();"><i class="fa fa-user-plus"
                                    aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div id="tab1" class="tab-content-item" *ngIf="this.viewMode === 'tab1' || this.viewMode === 'tab5'" >
            <form [formGroup]="searchForm" autocomplete="off">
                <div [ngClass]="{'search-wrapper': true, 'result': true}">

                    <div class="server-side-filters">
                        <div class="filter-row">
                            <div class="filter-row-left" style="display: flex;">
                                <div class="col-auto" id="trip-filter">
                                    <div class="filter custom-selectbox" attr.data-track="TripTypeMenu"
                                        (click)="trip.toggle()">
                                        <div class="select-input">
                                            <ng-select [ngClass]="{'custom-header': true}" #trip appendTo="#trip-filter"
                                                dropdownPosition="bottom" [groupValue]="Classes" [searchable]="false"
                                                [clearable]="false" [items]="tripOptions | translateOptions"
                                                bindLabel="value" bindValue="id" (change)="tripTypeChanged($event)"
                                                formControlName="trip">
                                                <ng-template ng-header-tmp>
                                                    <div class="selectox-header">
                                                        <span>{{ 'search.select_ticket_type' | translate }}</span>
                                                        <span class="selectBox-remove" (click)="trip.toggle()"><span
                                                                class="material-icons">clear</span></span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-option="item">
                                                        <span class="option-title">{{option.name |
                                                            translate}}</span>
                                                       
                                                    </ng-template>
                                                <!-- <ng-option *ngFor="let item of tripOptions | translateOptions"
                                                        [value]="item.id">
                                                            {{ item.value}}
                                                        </ng-option> -->
                                            </ng-select>

                                            <div class="select-overlay"></div>
                                        </div>
                                        <div class="field-value custom-selectbox-value" attr.data-track="TripTypeMenu">
                                            {{getTripName(searchForm.controls['trip'].value) | translate}}</div>
                                        <span class="control-icon icon-triangle" attr.data-track="TripTypeMenu"></span>
                                    </div>
                                </div>
                                <div class="col-auto" id="class-filter" style="display: none;">
                                    <div class="filter custom-selectbox" attr.data-track="ClassMenu"
                                        (click)="class.toggle()">
                                        <div class="select-input">
                                            <ng-select #class appendTo="#class-filter" dropdownPosition="bottom"
                                                [searchable]="false" [clearable]="false" [closeOnSelect]="true"
                                                [items]="classOptions | translateOptions" bindLabel="" bindValue="id"
                                                formControlName="class" (change)="getClassName1($event)">
                                                <ng-template ng-header-tmp>
                                                    <div class="selectox-header">
                                                        <span>{{'search.SelectClass' | translate }}</span>
                                                        <span class="selectBox-remove" (click)="class.toggle()"><span
                                                                class="material-icons">clear</span></span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-option="item">
                                                        <span class="option-title">{{option.name |
                                                            translate}}</span>
                                                       
                                                    </ng-template>
                                            </ng-select>
                                            <div class="select-overlay"></div>
                                        </div>
                                        <div class="field-value custom-selectbox-value colorSecondary"
                                            attr.data-track="ClassTypeMenu">
                                            {{getClassName(searchForm.controls['class'].value) | translate}}</div>
                                        <span class="control-icon icon-triangle" attr.data-track="ClassMenu"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-row-right">

                                <div *ngIf="searchForm.controls['classTypeSwitch'].value === 'PODUCTIVITY' && false"
                                    class="col-auto" id="passenger-filter">
                                    <div class="filter custom-selectbox" attr.data-track="TravellersMenu"
                                        attr.data-params="type=flights" (click)="passengers.toggle()">
                                        <span class="icon-customer" attr.data-track="TravellersMenu"
                                            attr.data-params="type=flights"></span>
                                        <div class="select-input">
                                            <ng-select #passengers appendTo="#passenger-filter"
                                                dropdownPosition="middle" [searchable]="false" [clearable]="false"
                                                [items]="passengerOptions | translateOptions" bindLabel="value"
                                                bindValue="id" formControlName="passengers"
                                                (change)="onPassengersChange($event)">
                                                <ng-template ng-header-tmp>

                                                    <div class="selectox-header">
                                                        <span>{{'search.SelectNumberofPassengers' | translate }}</span>
                                                        <span class="selectBox-remove"
                                                            (click)="passengers.toggle()"><span
                                                                class="material-icons">clear</span></span>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                            <div class="select-overlay"></div>
                                        </div>
                                        <div class="field-value custom-selectbox-value colorSecondary"
                                            attr.data-track="TravellersMenu" attr.data-params="type=flights">
                                            {{searchForm.controls['passengers'].value}}</div>
                                        <span class="control-icon icon-triangle" attr.data-track="TravellersMenu"
                                            attr.data-params="type=flights"></span>
                                    </div>
                                </div>
                                <!-- <div *ngIf="searchForm.controls['classTypeSwitch'].value === ''LEISURE''"> -->
                                <div *ngIf="searchForm.controls['classTypeSwitch'].value === 'LEISURE'" class="col-auto"
                                    id="leisureTopModal">
                                    <div class="filter custom-selectbox"
                                        (click)="ngxSmartModalService.getModal('leisureTopModal').open()">
                                        <span class="icon-customer"></span>
                                        <div class="field-value custom-selectbox-value colorSecondary">
                                            {{searchForm.controls['passengers'].value}}</div>
                                        <span class="control-icon icon-triangle"></span>
                                    </div>
                                    <ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'leisureTopModal')"
                                        [hideDelay]="0" (onClose)="handleModalEvents('onClose', 'leisureTopModal')"
                                        (onDismiss)="handleModalEvents('onDismiss', 'leisureTopModal')"
                                        [closable]="false" #leisureTopModal identifier="leisureTopModal">

                                        <div class="modal-container flight-modal-container filter-modal modalAirportFilterInfo"
                                            (click)="$event.stopPropagation();">
                                            <div class="modal-header">
                                                <h5 class="modal-title">{{'search.TravellerDetail' | translate }}</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    (click)="onSmartModelCancel('leisureTopModal')">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </div>

                                            <div class="modal-body">
                                                <div *ngFor="let item of passengerTypeSpecific,let i=index;">
                                                    <div class="header-inner">
                                                        <div class="header-left">
                                                            <span class="leizure">{{item.value | translate}}</span><br>
                                                            <span class="leizure2 text-secondary">{{item.ageRange |
                                                                translate}}</span>
                                                        </div>
                                                        <div class="header-right">
                                                            <div class="leizure1">
                                                                <button class="btn btn-default"
                                                                    (click)="subPassenger(item,i)"
                                                                    [disabled]="item.number <= item.minValue"> -
                                                                </button>
                                                                <input type="text" class="box"
                                                                    formControlName="{{item.id}}"
                                                                    [attr.disabled]="true">
                                                                <span class="leizure3">{{item.number}}</span>
                                                                <button class="btn btn-default1"
                                                                    (click)="addPassenger(item,i)"
                                                                    [disabled]="item.number >= item.maxValue"> +
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="this.leisureValue > 9">
                                                <span
                                                    class="text-danger">{{'search.Sorrywedonotsupportmorethan9passengers'
                                                    | translate }}</span>
                                            </div>
                                            <div *ngIf="this.infants > this.adults ">
                                                <span
                                                    class="text-danger">{{'search.Youmusthaveatleastoneadultpertwoinfants'
                                                    | translate }}</span>
                                            </div>
                                            <div class="modal-footer">
                                                <div class="text-center">
                                                    <!-- <button class="btn primary-button">{{'search.Apply' | translate }}</button> -->
                                                    <button (click)="setTotalPassengersDetails('leisureTopModal')"
                                                        class="btn primary1-button">{{'search.Apply' | translate
                                                        }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ngx-smart-modal>
                                </div>
                                <!-- </div> -->
                                <div *ngIf="isAlgoSwitchVisible()" class="col-auto switch-div d-none d-md-block">
                                    <app-switch-component class="switch-div" [leftOptionValue]="'LEISURE'"
                                        [leftOptionText]="'search.Leisure' | translate"
                                        [rightOptionValue]="'PODUCTIVITY'"
                                        [rightOptionText]="'search.Business' | translate"
                                        [lastSelectedOption]="switchTripType" [switchGroupName]="'classTypeSwitch'"
                                        [selectedOption]="switchTripType"
                                        (tripTypeChange)="handleTripTypeChange($event)"></app-switch-component>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="search-container">
                        <div [collapse]="isCollapsed && isResult">
                            <div class="row">
                                <div class="col-xl-6 col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-6 col-lg">
                                            <div *ngIf="!isInputSearchTypeAirport()">
                                                <div class="text-input-wrapper" style="margin-right: 1px;">
                                                    <div [ngClass]="{'text-input': true, 'invert': true}">

                                                        <input type="text" ngx-google-places-autocomplete
                                                            (onAddressChange)="handleAddressChange($event,'source')"
                                                            placeholder="{{'search.Origin' | translate}}"
                                                            formControlName="source" tabindex="1"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            (focusout)="fillReturnFlight()" />

                                                        <input type="hidden" formControlName="sourceCity" />
                                                        <div *ngIf="searchForm.controls['sourceCity'].hasError('required') && (searchForm.controls['source'].touched || searchForm.controls['source'].dirty)"
                                                            class="error">{{'search.Pleaseentercityaddress' | translate
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="isInputSearchTypeAirport()">
                                                <span *ngIf="multicity  && this.viewMode=='tab1'" class="flightCount"> {{ 'search.Flight' | translate }} 1</span>
                                                <span *ngIf="multicity  && this.viewMode=='tab5'" class="flightCount"> Train 1</span>
                                                <div class="text-input-wrapper" style="margin-right: 1px;">
                                                    <div (click)="getAirportListInMobile(-1,'source','inputFieldIDSourceAirport')"
                                                        [ngClass]="{'text-input': true, 'invert': true, 'ng-autocomplete': true}"
                                                        [ngStyle]="{'z-index':modalOpen() ? '100':'12'}">
                                                        <input id="inputFieldIDSourceAirport" type="text"
                                                            placeholder="{{'search.Origin' | translate }}"
                                                            (input)="searchAutoComplete($event.target.value,'source',-1,'inputFieldIDSourceAirport')"
                                                            formControlName="source" (ngModel)="sourceAirportModel"  (keydown)="handleKeyDown($event)"/>
                                                        <div
                                                            *ngIf="this.searchService.inputId==='inputFieldIDSourceAirport'">
                                                            <app-airports
                                                                [autoCompleteSourceItems]="this.autoCompleteSourceItems"
                                                                [selectFormControlname]="this.selectFormControlname"
                                                                [searchString]="this.searchString"
                                                                [viewMode]="this.viewMode"
                                                                (goBackSearchPage)='onAutoCompleteSelect($event)'
                                                                (goBackSearchPage1)='backToNormalSearch($event)'
                                                                (goBackSearchPage2)='searchAirport($event)'
                                                                (autoCompleteDropDownClosed)="onAutoCompleteDropDownClosed($event, -1,'source')">
                                                            </app-airports>
                                                        </div>
                                                        <div *ngIf="(viewMode == 'tab1')&& (searchForm.controls['sourceAirportCode'].hasError('required') && (searchForm.controls['source'].touched || searchForm.controls['source'].dirty) || searchForm.controls['source'].hasError('minLengthArray') || searchForm.controls['source'].hasError('maxLengthArray'))"
                                                            class="error">{{'search.Pleaseselectfromdropdown' |
                                                            translate }}
                                                        </div>
                                                        <div *ngIf="(viewMode == 'tab5') && (searchForm.controls['sourceAirportCode'].hasError('required') && (searchForm.controls['source'].touched || searchForm.controls['source'].dirty) || searchForm.controls['source'].hasError('minLengthArray') || searchForm.controls['source'].hasError('maxLengthArray'))"
                                                            class="error">Please enter a valid train station
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="">
                                                <span *ngIf="(searchForm.controls['trip'].value === 'MULTICITY')"
                                                    style="margin-top:20px !important;">
                                                    <div class="switch2" style="margin-top:20px !important;" (click)="swapOriginAndDeatination()">
                                                        <span class="icon-arrow"></span>
                                                    </div>
                                                </span>
                                                <div *ngIf="(searchForm.controls['trip'].value !== 'MULTICITY')"
                                                    class="switch2" (click)="swapOriginAndDeatination()">
                                                    <span class="icon-arrow"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <ng-container *ngIf="!isInputSearchTypeAirport()">
                                                <div class="text-input-wrapper" style="margin-left: 1px;">
                                                    <div [ngClass]="{'text-input': true, 'invert': true}">
                                                        <input type="text" ngx-google-places-autocomplete
                                                            placeholder="{{'search.Destination' | translate}}"
                                                            (onAddressChange)="handleAddressChange($event,'destination')"
                                                            formControlName="destination" tabindex="2"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            (focusout)="fillReturnFlight()"  (keydown)="handleKeyDown($event)"/>
                                                        <input type="hidden" formControlName="destinationCity" />
                                                        <div *ngIf="searchForm.controls['destinationCity'].hasError('required') && (searchForm.controls['destination'].touched || searchForm.controls['destination'].dirty)"
                                                            class="error">{{'search.Pleaseentercityaddress' | translate
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="isInputSearchTypeAirport()">
                                                <span *ngIf="multicity" style="visibility: hidden !important;"
                                                    class="flightCount"> {{'search.Flight' | translate }} 1</span>
                                                <div class="text-input-wrapper" style="margin-left: 1px;">
                                                    <div (click)="getAirportListInMobile(-1,'destination','inputFieldIDDestAirport')"
                                                        [ngClass]="{'text-input': true, 'invert': true, 'ng-autocomplete': true}"
                                                        [ngStyle]="{'z-index':modalOpen1() ? '100':'12'}">
                                                        <input id="inputFieldIDDestAirport" type="text"
                                                            (input)="searchAutoComplete($event.target.value,'destination',-1,'inputFieldIDDestAirport')"
                                                            placeholder="{{'search.Destination' | translate}}" formControlName="destination"
                                                            (ngModel)="destinationAirportModel"  (keydown)="handleKeyDown($event)"/>
                                                        <div
                                                            *ngIf="this.searchService.inputId==='inputFieldIDDestAirport'">
                                                            <app-airports
                                                                [autoCompleteSourceItems]="this.autoCompleteDestItems"
                                                                [selectFormControlname]="this.selectFormControlname"
                                                                [searchString]="this.searchString"
                                                                [viewMode]="this.viewMode"
                                                                (goBackSearchPage)='onAutoCompleteSelect($event)'
                                                                (goBackSearchPage1)='backToNormalSearch($event)'
                                                                (goBackSearchPage2)='searchAirport($event)'
                                                                (autoCompleteDropDownClosed)="onAutoCompleteDropDownClosed($event, -1,'destination')">
                                                            </app-airports>
                                                        </div>
                                                        <div *ngIf="(viewMode == 'tab1')&& (searchForm.controls['destinationAirportCode'].hasError('required') && (searchForm.controls['destination'].touched || searchForm.controls['destination'].dirty) || searchForm.controls['destination'].hasError('minLengthArray') || searchForm.controls['destination'].hasError('maxLengthArray'))"
                                                            class="error">{{'search.Pleaseselectfromdropdown' |
                                                            translate }}
                                                        </div>
                                                        <div *ngIf="(viewMode == 'tab5') && (searchForm.controls['destinationAirportCode'].hasError('required') && (searchForm.controls['destination'].touched || searchForm.controls['destination'].dirty) || searchForm.controls['destination'].hasError('minLengthArray') || searchForm.controls['destination'].hasError('maxLengthArray'))"
                                                            class="error">Please enter valid train station
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-12 col-md-12">
                                    <span *ngIf="multicity" style="visibility: hidden !important;"
                                        class="flightCount1">{{'search.Flight' | translate }} 1</span>
                                    <div class="row">
                                        <div *ngIf="isMobile && (searchForm.controls['trip'].value === 'MULTICITY')"
                                            [ngClass]="{'col-6': true, 'col-lg': true}">
                                            <div *ngIf="isMobile && (searchForm.controls['trip'].value === 'MULTICITY')"
                                                class="time-type" id="time-type">
                                                <div class="filter custom-selectbox" attr.data-track="TimeTypeMenu"
                                                    attr.data-params="Flight=1" (click)="timeTypeFilter.toggle()">
                                                    <div class="select-input">
                                                        <ng-select #timeTypeFilter appendTo="#time-type"
                                                            dropdownPosition="bottom" [searchable]="false"
                                                            bindLabel="" bindValue="id" [clearable]="false"
                                                            [items]="timeTypeOptions | translateOptions"
                                                            formControlName="timeTypeFilter"
                                                            (change)="setDepartureConstraint($event)">
                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTimeType' | translate
                                                                        }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="timeTypeFilter.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                    <span class="option-title">{{option.name |
                                                                        translate}}</span>
                                                                   
                                                                </ng-template>
                                                            <!-- <ng-option *ngFor="let item of getDepartureTimeType() | translateOptions | async | keyvalue"
                                                                    [value]="item.id">
                                                                        {{ item.value}}
                                                                    </ng-option> -->
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                    </div>
                                                    <div class="field-value custom-selectbox-value"
                                                        attr.data-track="TimeTypeMenu" attr.data-params="Flight=1">
                                                        {{getTimeTypeName(searchForm.controls['timeTypeFilter'].value) |
                                                        translate}}</div>
                                                    <span class="icon-triangle control-icon"
                                                        attr.data-track="TimeTypeMenu"
                                                        attr.data-params="Flight=1"></span>
                                                </div>
                                            </div><br>
                                            <div *ngIf="isMobile && (searchForm.controls['trip'].value === 'MULTICITY')"
                                                class="constraint-time" id="constraint-time" style="position:relative;">
                                                <div class="control" attr.data-track="SelectTimeInterval"
                                                    attr.data-params="Flight=1" (click)="constraintTimeSelect.toggle()"
                                                    style="position:relative;">
                                                    <div class="select-input">
                                                        <div class="value custom-selectbox-value"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="Flight=1">
                                                            {{getTimeOptionName(searchForm.controls['constraintTime']?.value
                                                            ,searchForm.controls['constraint']?.value)| translate}}
                                                        </div>
                                                        <ng-select class="" #constraintTimeSelect
                                                            appendTo="#constraint-time" dropdownPosition="bottom"
                                                            [searchable]="false" [closeOnSelect]="true"
                                                            [clearable]="false" [items]="timeOptions | translateOptions"
                                                            formControlName="constraintTime" bindValue="titleID"
                                                            bindLabel="title" (open)="getTimeOptions()"
                                                            (change)="setConstraintTimeDeparture($event.titleID)">

                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTime' | translate }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="constraintTimeSelect.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>

                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                <span class="option-title">{{option.title |
                                                                    translate}}</span>
                                                                <span> {{getTimeOptionValue(option) | translate}}</span>
                                                            </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                        <span class="icon-triangle control-icon"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="Flight=1"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <div class="date-input" (click)="setFormConstraint('LEG1')">
                                                <span class="input-icon material-icons"
                                                    (click)="openDepartureCalender(departDatePicker);">event</span>
                                                <div [ngClass]="{'text-input': true, 'invert': true}"
                                                    (click)="openDepartureCalender(departDatePicker)">
                                                    <input class="textfield-with-icon" type="text"
                                                        placeholder="{{'search.SelectDepartureDate' | translate}}"
                                                        value="{{departDate| date: 'EEE MMM d'}}" readonly="" />
                                                </div>
                                                <input bsDatepicker #departDatePicker="bsDatepicker"
                                                    formControlName="departureDate" [minDate]="minimumDate"
                                                    [maxDate]="maximumDate" [outsideClick]="true"
                                                    [bsConfig]="{showWeekNumbers: false}"
                                                    (onShown)="onShowPicker($event, departDatePicker)"
                                                    (bsValueChange)="setLeg1Date($event)" (onHidden)="onHidePicker()"
                                                    readonly />
                                            </div>
                                            <div *ngIf="!isMobile && (searchForm.controls['trip'].value === 'MULTICITY')"
                                                class="time-type" id="time-type">
                                                <div class="filter custom-selectbox" attr.data-track="TimeTypeMenu"
                                                    attr.data-params="Flight=1" (click)="timeTypeFilter.toggle()">
                                                    <div class="select-input">
                                                        <ng-select #timeTypeFilter appendTo="#time-type"
                                                            dropdownPosition="bottom" [searchable]="false"
                                                            bindLabel="" bindValue="id" [clearable]="false"
                                                            [items]="timeTypeOptions | translateOptions"
                                                            formControlName="timeTypeFilter"
                                                            (change)="setDepartureConstraint($event)">
                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTimeType' | translate
                                                                        }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="timeTypeFilter.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                    <span class="option-title">{{option.name |
                                                                        translate}}</span>
                                                                   
                                                                </ng-template>
                                                            <!-- <ng-option *ngFor="let item of getDepartureTimeType() | translateOptions | async | keyvalue"
                                                        [value]="item.id">
                                                            {{ item.value}}
                                                        </ng-option> -->
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                    </div>
                                                    <div class="field-value custom-selectbox-value"
                                                        attr.data-track="TimeTypeMenu" attr.data-params="Flight=1">
                                                        {{getTimeTypeName(searchForm.controls['timeTypeFilter'].value) |
                                                        translate}}</div>
                                                    <span class="icon-triangle control-icon"
                                                        attr.data-track="TimeTypeMenu"
                                                        attr.data-params="Flight=1"></span>
                                                </div>
                                            </div>
                                            <div *ngIf="!isMobile && (searchForm.controls['trip'].value === 'MULTICITY')"
                                                class="constraint-time" id="constraint-time" style="position:relative;">
                                                <div class="control" attr.data-track="SelectTimeInterval"
                                                    attr.data-params="Flight=1" (click)="constraintTimeSelect.toggle()"
                                                    style="position:relative;">
                                                    <div class="select-input">
                                                        <div class="value custom-selectbox-value"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="Flight=1">
                                                            {{getTimeOptionName(searchForm.controls['constraintTime']?.value,this.searchForm.controls['constraint'].value
                                                            )| translate}}</div>
                                                        <ng-select class="" #constraintTimeSelect
                                                            appendTo="#constraint-time" dropdownPosition="bottom"
                                                            [searchable]="false" [closeOnSelect]="true"
                                                            [clearable]="false" [items]="timeOptions | translateOptions"
                                                            formControlName="constraintTime" bindValue="titleID"
                                                            bindLabel="title" (open)="getTimeOptions()"
                                                            (change)="setConstraintTimeDeparture($event.titleID)">

                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTime' | translate }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="constraintTimeSelect.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>

                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                <span class="option-title">{{option.title |
                                                                    translate}}</span>
                                                                <span>{{getTimeOptionValue(option) | translate}}</span>
                                                            </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                        <span class="icon-triangle control-icon"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="Flight=1"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(searchForm.controls['trip'].value !== 'MULTICITY')"
                                                class="time-type" id="time-type">
                                                <div class="filter custom-selectbox" attr.data-track="TimeTypeMenu"
                                                    attr.data-params="Flight=1" (click)="timeTypeFilter.toggle()">
                                                    <div class="select-input">
                                                        <ng-select #timeTypeFilter appendTo="#time-type"
                                                            dropdownPosition="bottom" [searchable]="false"
                                                            bindLabel="" bindValue="id" [clearable]="false"
                                                            [items]="timeTypeOptions | translateOptions"
                                                            formControlName="timeTypeFilter"
                                                            (change)="setDepartureConstraint($event)">
                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTimeType' | translate
                                                                        }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="timeTypeFilter.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                    <span class="option-title">{{option.name |
                                                                        translate}}</span>
                                                                   
                                                                </ng-template>
                                                            <!-- <ng-option *ngFor="let item of getDepartureTimeType() | translateOptions | async | keyvalue"
                                                            [value]="item.id">
                                                                {{ item.value}}
                                                            </ng-option> -->
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                    </div>
                                                    <div class="field-value custom-selectbox-value"
                                                        attr.data-track="TimeTypeMenu" attr.data-params="Flight=1">
                                                        {{getTimeTypeName(searchForm.controls['timeTypeFilter'].value) |
                                                        translate}}</div>
                                                    <span class="icon-triangle control-icon"
                                                        attr.data-track="TimeTypeMenu"
                                                        attr.data-params="Flight=1"></span>
                                                </div>
                                            </div>
                                            <div *ngIf="searchForm.controls['trip'].value !== 'MULTICITY'" class="constraint-time" id="constraint-time" style="position:relative;">
                                                <div class="control" (click)="constraintTimeSelect.toggle()"
                                                    attr.data-track="SelectTimeInterval" attr.data-params="Flight=1"
                                                    style="position:relative;">
                                                    <div class="select-input">
                                                        <div class="value custom-selectbox-value"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="Flight=1">
                                                            {{getTimeOptionName(searchForm.controls['constraintTime']?.value,this.searchForm.controls['constraint']?.value)| translate}}</div>
                                                        <ng-select class="" #constraintTimeSelect
                                                            appendTo="#constraint-time" dropdownPosition="bottom"
                                                            [searchable]="false" [closeOnSelect]="true"
                                                            [clearable]="false" [items]="timeOptions | translateOptions"
                                                            formControlName="constraintTime" bindValue="titleID"
                                                            bindLabel="title" (open)="getTimeOptions()"
                                                            (change)="setConstraintTimeDeparture($event.titleID)">

                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTime' | translate }}</span>
                                                                    <span class="selectBox-remove" (click)="constraintTimeSelect.toggle()"><span class="material-icons">clear</span></span>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                <span class="option-title">{{option.title |
                                                                    translate}}</span>
                                                                <span>{{getTimeOptionValue(option) | translate}}</span>
                                                            </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                        <span class="icon-triangle control-icon" attr.data-track="SelectTimeInterval" attr.data-params="Flight=1"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'col-6': true, 'col-lg': true}" *ngIf="(searchForm.controls['trip'].value === 'ROUNDTRIP')">
                                            <div class="date-input" (click)="setFormConstraint('LEG2')">
                                                <span class="input-icon material-icons"
                                                    (click)="arriveDatePicker.toggle()">event</span>
                                                <div [ngClass]="{'text-input': true, 'invert': true}"
                                                    (click)="arriveDatePicker.toggle()">
                                                    <input class="textfield-with-icon" type="text"
                                                        placeholder="{{'setting.Return' | translate}}"
                                                        value="{{arriveDate| date: 'EEE MMM d'}}" readonly="" />
                                                </div>
                                                <input bsDatepicker #arriveDatePicker="bsDatepicker"
                                                    formControlName="arrivalDate" [minDate]="minimumDateLeg2"
                                                    [maxDate]="maximumDate" [outsideClick]="true"
                                                    [bsConfig]="{showWeekNumbers: false}"
                                                    (onShown)="onShowPicker($event, arriveDatePicker)"
                                                    (bsValueChange)="setLeg2Date($event)" (onHidden)="onHidePicker()"
                                                    readonly />
                                            </div>
                                            <div class="time-type" id="time-type1">
                                                <div class="filter custom-selectbox" attr.data-track="TimeTypeMenu"
                                                    attr.data-params="Flight=2" (click)="timeTypeFilter1.toggle()">
                                                    <div class="select-input">
                                                        <ng-select #timeTypeFilter1 appendTo="#time-type1"
                                                            dropdownPosition="bottom" [searchable]="false"
                                                            bindLabel="" bindValue="id" [clearable]="false"
                                                            [items]="returnTimeTypeOptions | translateOptions"
                                                            formControlName="timeTypeFilter1"
                                                            (change)="setArrivalConstraint($event)">
                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTimeType' | translate
                                                                        }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="timeTypeFilter1.toggle()">
                                                                        <span class="material-icons">clear</span></span>
                                                                </div>

                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                    <span class="option-title">{{option.name |
                                                                        translate}}</span>
                                                                
                                                                </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                    </div>
                                                    <div class="field-value custom-selectbox-value"
                                                        attr.data-track="TimeTypeMenu" attr.data-params="Flight=2">
                                                        {{getTimeTypeName(searchForm.controls['timeTypeFilter1'].value)
                                                        | translate}}</div>
                                                    <span class="icon-triangle control-icon"
                                                        attr.data-track="TimeTypeMenu"
                                                        attr.data-params="Flight=2"></span>
                                                </div>
                                            </div>
                                            <div class="constraint-time" id="constraint-time1"
                                                style="position:relative;">
                                                <div class="control" attr.data-track="SelectTimeInterval"
                                                    attr.data-params="Flight=2"
                                                    (click)="constraintTimeSelect1.toggle()">
                                                    <div class="select-input">
                                                        <div class="value custom-selectbox-value"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="Flight=2">
                                                            {{getTimeOptionName(searchForm.controls['constraintTime1']?.value,this.searchForm.controls['constraint1'].value)
                                                            | translate}}</div>
                                                        <ng-select class="" #constraintTimeSelect1
                                                            appendTo="#constraint-time1" dropdownPosition="bottom"
                                                            [searchable]="false" [closeOnSelect]="true"
                                                            [clearable]="false" [items]="timeOptions | translateOptions"
                                                            formControlName="constraintTime1" bindValue="titleID"
                                                            (open)="getTimeOptions()"
                                                            (change)="setConstraintTimeArrival($event.titleID)">

                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.SelectTime' | translate }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="constraintTimeSelect1.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>

                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                <span class="option-title">{{option.title |
                                                                    translate}}</span>
                                                                <span>{{getTimeOptionValue(option) | translate}}</span>
                                                            </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                        <span class="icon-triangle control-icon"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="Flight=2"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="multicity" formArrayName="flights">
                                    <ng-container *ngFor="let flightReq of getAdditionalReqeustControl(); let i= index;"
                                        [formGroupName]="i">
                                        <div class="col-xl-6 col-lg-12 col-md-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <span class="text-left" *ngIf="multicity &&  this.viewMode=='tab1'" class="flightCount"
                                                        style="margin-top:5px !important;">{{ 'search.Flight' | translate }} {{getFlightCount(i) }}</span>
                                                     <span class="text-left" *ngIf="multicity && this.viewMode=='tab5'" class="flightCount"
                                                        style="margin-top:5px !important;">Train {{getFlightCount(i) }}</span>
                                                    <span class="text-right" *ngIf="multicity && isMobile"
                                                        style="text-align:right !important;cursor:pointer !important;margin-top: 0px;color: gray !important;"><span
                                                            *ngIf="getAdditionalReqeustControl().length > 1 && i >0"
                                                            (click)="onRemoveLeg(i)" class="close"
                                                            style="margin-left:5px;font-size:23px;color: gray !important;"><i
                                                                class="material-icons">close</i></span></span>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-lg">
                                                    <div *ngIf="!isInputSearchTypeAirport()">
                                                        <div class="text-input-wrapper" style="margin-right: 1px;">
                                                            <div [ngClass]="{'text-input': true, 'invert': true}">
                                                                <input type="text" ngx-google-places-autocomplete
                                                                    (onAddressChange)="handleAddressChangeMulticity($event,'source')"
                                                                    placeholder="{{'search.Origin' | translate}}" formControlName="source"
                                                                    tabindex="11"
                                                                    (keydown.enter)="$event.preventDefault()" />
                                                                <input type="hidden" formControlName="sourceCity" />
                                                                <div *ngIf="flightReq.controls['sourceCity'].hasError('required') && (flightReq.controls['source'].touched || flightReq.controls['source'].dirty)"
                                                                    class="error">{{'search.Pleaseentercityaddress.' | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isInputSearchTypeAirport()">

                                                        <div class="text-input-wrapper" style="margin-right: 1px;">
                                                            <div (click)="getAirportListInMobile(i,'source','inputFieldIDSourceAirport_'+i)"
                                                                [ngClass]="{'text-input': true, 'invert': true, 'ng-autocomplete': true}"
                                                                class="{{ modalOpen() ? 'zIndex(1-1)':'zIndex(i)'}}">
                                                                <input id="inputFieldIDSourceAirport_{{i}}" type="text"
                                                                    (input)="searchAutoComplete($event.target.value,'source',i,'inputFieldIDSourceAirport_'+i)"
                                                                    placeholder="{{'search.Origin' | translate}}" formControlName="source"  (keydown)="handleKeyDown($event)"/>
                                                                <div
                                                                    *ngIf="this.searchService.inputId==='inputFieldIDSourceAirport_'+i">
                                                                    <app-airports
                                                                        [autoCompleteSourceItems]="this.autoCompleteSourceItems"
                                                                        [selectFormControlname]="this.selectFormControlname"
                                                                        [searchString]="this.searchString"
                                                                        [viewMode]="this.viewMode"
                                                                        (goBackSearchPage)='onAutoCompleteSelectMultiCity($event)'
                                                                        (goBackSearchPage1)='backToNormalSearch($event)'
                                                                        (goBackSearchPage2)='searchAirport($event)'
                                                                        (autoCompleteDropDownClosed)="onAutoCompleteDropDownClosed($event, i,'source')">
                                                                    </app-airports>
                                                                </div>
                                                                <input type="hidden"
                                                                    formControlName="sourceAirportCode" />
                                                                <div *ngIf="(viewMode =='tab5') && (flightReq.controls['sourceAirportCode'].hasError('required') && (flightReq.controls['source'].touched || flightReq.controls['source'].dirty) || flightReq.controls['source'].hasError('minLengthArray') || flightReq.controls['source'].hasError('maxLengthArray')) "
                                                                    class="error">Please enter a valid station name
                                                                </div>
                                                                <div *ngIf="(viewMode =='tab1' ) && (flightReq.controls['sourceAirportCode'].hasError('required') && (flightReq.controls['source'].touched || flightReq.controls['source'].dirty) || flightReq.controls['source'].hasError('minLengthArray') || flightReq.controls['source'].hasError('maxLengthArray')) "
                                                                    class="error">{{'search.Pleaseselectfromdropdown' |
                                                                    translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class="switch1" (click)="swapOriginAndDeatinationForMuticity(i)">
                                                            <span class="icon-arrow"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg">
                                                    <ng-container *ngIf="!isInputSearchTypeAirport()">
                                                        <div class="text-input-wrapper" style="margin-left: 1px;">
                                                            <div [ngClass]="{'text-input': true, 'invert': true}">
                                                                <input type="text" ngx-google-places-autocomplete
                                                                placeholder="{{'search.Destination' | translate}}"
                                                                    (onAddressChange)="handleAddressChangeMulticity($event,'destination')"
                                                                    formControlName="destination" tabindex="2"
                                                                    (keydown.enter)="$event.preventDefault()" (keydown)="handleKeyDown($event)"/>
                                                                <input type="hidden"
                                                                    formControlName="destinationCity" />
                                                                <div *ngIf="flightReq.controls['destinationCity'].hasError('required') && (flightReq.controls['destination'].touched || flightReq.controls['destination'].dirty)"
                                                                    class="error">{{'search.Pleaseentercityaddress.' | translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="isInputSearchTypeAirport()">
                                                        <div class="text-input-wrapper" style="margin-left: 1px;">
                                                            <div (click)="getAirportListInMobile(i,'destination','inputFieldIDDestAirport_'+i)"
                                                                [ngClass]="{'text-input': true, 'invert': true, 'ng-autocomplete': true}"
                                                                class="{{ modalOpen1() ? 'zIndex(1-1)':'zIndex(i)'}}">
                                                                <input id="inputFieldIDDestAirport_{{i}}"
                                                                    (input)="searchAutoComplete($event.target.value,'destination',i,'inputFieldIDDestAirport_'+i)"
                                                                    type="text"  placeholder="{{'search.Destination' | translate}}"
                                                                    formControlName="destination"  (keydown)="handleKeyDown($event)"/>
                                                                <div
                                                                    *ngIf="this.searchService.inputId==='inputFieldIDDestAirport_'+i">
                                                                    <app-airports
                                                                        [autoCompleteSourceItems]="this.autoCompleteDestItems"
                                                                        [selectFormControlname]="this.selectFormControlname"
                                                                        [searchString]="this.searchString"
                                                                        [viewMode]="this.viewMode"
                                                                        (goBackSearchPage)='onAutoCompleteSelectMultiCity($event)'
                                                                        (goBackSearchPage1)='backToNormalSearch($event)'
                                                                        (goBackSearchPage2)='searchAirport($event)'
                                                                        (autoCompleteDropDownClosed)="onAutoCompleteDropDownClosed($event, i,'destination')">
                                                                    </app-airports>
                                                                </div>

                                                                <input type="hidden"
                                                                    formControlName="destinationAirportCode" />
                                                                <div *ngIf="(viewMode == 'tab1') && ( flightReq.controls['destinationAirportCode'].hasError('required') && (flightReq.controls['destination'].touched || flightReq.controls['destination'].dirty) || flightReq.controls['destination'].hasError('minLengthArray') || flightReq.controls['destination'].hasError('maxLengthArray'))"
                                                                    class="error">{{'search.Pleaseselectfromdropdown' |
                                                                    translate }}
                                                                </div>
                                                                <div *ngIf="(viewMode == 'tab5')&& (flightReq.controls['destinationAirportCode'].hasError('required') && (flightReq.controls['destination'].touched || flightReq.controls['destination'].dirty) || flightReq.controls['destination'].hasError('minLengthArray') || flightReq.controls['destination'].hasError('maxLengthArray'))"
                                                                class="error">Please enter valid train station
                                                            </div>
                                                                <div *ngIf="isSourceDestinationSame(i)" class="error">
                                                                    {{'search.SourceandDestinationcannotbesame' |
                                                                    translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                            <div *ngIf="multicity" style="visibility: hidden !important;;"
                                                class="flightCount1">{{'search.Flight' | translate }} 1</div>
                                            <div class="row">
                                                <div *ngIf="isMobile" [ngClass]="{'col-6': true, 'col-lg': true}">
                                                    <div class="{{ multicity? 'time-type1':'time-type'}}"
                                                        id="time-type-leg{{i}}" style="margin-bottom:6px !important;">
                                                        <div class="filter custom-selectbox"
                                                            attr.data-track="TimeTypeMenu"
                                                            attr.data-params="{{getParams(i)}}"
                                                            (click)="timeTypeFilterLeg2.toggle()">
                                                            <div class="select-input">
                                                                <ng-select #timeTypeFilterLeg2
                                                                    appendTo="#time-type-leg{{i}}"
                                                                    dropdownPosition="bottom" [searchable]="false"
                                                                    bindLabel="" bindValue="id" [clearable]="false"
                                                                    [items]="timeTypeOptions | translateOptions "
                                                                    formControlName="constraint"
                                                                    (change)="setMultiLeg2DepartureConstraint($event,i)">
                                                                    <ng-template ng-header-tmp>

                                                                        <div class="selectox-header">
                                                                            <span>{{'search.SelectTimeType' | translate
                                                                                }}</span>
                                                                            <span class="selectBox-remove"
                                                                                (click)="timeTypeFilterLeg2.toggle()"><span
                                                                                    class="material-icons">clear</span></span>
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-option="item">
                                                                            <span class="option-title">{{option.name |
                                                                                translate}}</span>
                                                                           
                                                                        </ng-template>
                                                                </ng-select>
                                                                <div class="select-overlay"></div>
                                                            </div>
                                                            <div class="field-value custom-selectbox-value"
                                                                attr.data-track="TimeTypeMenu"
                                                                attr.data-params="{{getParams(i)}}">
                                                                {{getTimeTypeName(flightReq.controls['constraint'].value)
                                                                | translate}}</div>
                                                            <span class="icon-triangle control-icon"
                                                                attr.data-track="TimeTypeMenu"
                                                                attr.data-params="{{getParams(i)}}"></span>
                                                        </div>
                                                    </div>
                                                    <div class="{{ multicity? 'constraint-type1':'constraint-type'}}"
                                                        id="constraint-time-leg{{i}}"
                                                        style="position:relative;margin-bottom:6px !important;">
                                                        <div class="control" attr.data-track="SelectTimeInterval"
                                                            attr.data-params="{{getParams(i)}}"
                                                            (click)="constraintTimeSelectLeg2.toggle()"
                                                            style="position:relative;">
                                                            <div class="select-input">
                                                                <div class="value custom-selectbox-value"
                                                                    attr.data-track="SelectTimeInterval"
                                                                    attr.data-params="{{getParams(i)}}">
                                                                    {{getTimeOptionName(flightReq.controls['constraintTime']?.value,flightReq.controls['constraint']?.value)
                                                                    | translate}}</div>
                                                                <ng-select class="" #constraintTimeSelectLeg2
                                                                    appendTo="#constraint-time-leg{{i}}"
                                                                    dropdownPosition="bottom" [searchable]="false"
                                                                    [closeOnSelect]="true" [clearable]="false"
                                                                    [items]="timeOptions | translateOptions"
                                                                    formControlName="constraintTime" bindValue="titleID"
                                                                    bindLabel="title" (open)="getTimeOptions()"
                                                                    (change)="setMultiLeg2Constraint($event.titleID,i)">

                                                                    <ng-template ng-header-tmp>
                                                                        <div class="selectox-header">
                                                                            <span>{{'search.SelectTime' | translate
                                                                                }}</span>
                                                                            <span class="selectBox-remove"
                                                                                (click)="constraintTimeSelectLeg2.toggle()"><span
                                                                                    class="material-icons">clear</span></span>
                                                                        </div>

                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-option="item">
                                                                        <span class="option-title">{{option.title |
                                                                            translate}}</span>
                                                                        <span>{{getTimeOptionValue(option) |
                                                                            translate}}</span>
                                                                    </ng-template>
                                                                </ng-select>
                                                                <div class="select-overlay"></div>
                                                                <span class="icon-triangle control-icon"
                                                                    attr.data-track="SelectTimeInterval"
                                                                    attr.data-params="{{getParams(i)}}"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg">
                                                    <div class="date-input" (click)="setFormConstraint('LEG2',0)">
                                                        <span class="input-icon material-icons"
                                                            style="z-index:6 !important;"
                                                            (click)="openMulticityDepartureCalender(departDatePickerLeg2,i)">event</span>
                                                        <div [ngClass]="{'text-input': true, 'invert': true}"
                                                            (click)="openMulticityDepartureCalender(departDatePickerLeg2,i)">
                                                            <input class="textfield-with-icon" type="text"
                                                                placeholder="{{'search.SelectDepartureDate' | translate}}"
                                                                value="{{departLeg2Date(i) | date: 'EEE MMM d'}}"
                                                                readonly="" />
                                                        </div>
                                                        <input bsDatepicker #departDatePickerLeg2="bsDatepicker"
                                                            formControlName="departureDate"
                                                            [minDate]="setMinimumDate(i)" [maxDate]="maximumDate"
                                                            [outsideClick]="true" [bsConfig]="{showWeekNumbers: false}"
                                                            (onShown)="onShowPicker($event, departDatePickerLeg2)"
                                                            (bsValueChange)="setMultiLeg2Date($event,i)"
                                                            (onHidden)="onHidePicker()" readonly />
                                                    </div>
                                                    <div *ngIf="!isMobile" class="time-type" id="time-type-leg{{i}}"
                                                        style="margin-bottom:6px !important;">
                                                        <div class="filter custom-selectbox"
                                                            attr.data-track="TimeTypeMenu"
                                                            attr.data-params="{{getParams(i)}}"
                                                            (click)="timeTypeFilterLeg2.toggle()">
                                                            <div class="select-input">
                                                                <ng-select #timeTypeFilterLeg2
                                                                    appendTo="#time-type-leg{{i}}"
                                                                    dropdownPosition="bottom" [searchable]="false"
                                                                    bindLabel="" bindValue="id" [clearable]="false"
                                                                    [items]="timeTypeOptions | translateOptions "
                                                                    formControlName="constraint"
                                                                    (change)="setMultiLeg2DepartureConstraint($event,i)">
                                                                    <ng-template ng-header-tmp>

                                                                        <div class="selectox-header">
                                                                            <span>{{'search.SelectTimeType' | translate
                                                                                }}</span>
                                                                            <span class="selectBox-remove"
                                                                                (click)="timeTypeFilterLeg2.toggle()"><span
                                                                                    class="material-icons">clear</span></span>
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-option="item">
                                                                            <span class="option-title">{{option.name |
                                                                                translate}}</span>
                                                                           
                                                                        </ng-template>
                                                                </ng-select>
                                                                <div class="select-overlay"></div>
                                                            </div>
                                                            <div class="field-value custom-selectbox-value"
                                                                attr.data-track="TimeTypeMenu"
                                                                attr.data-params="{{getParams(i)}}">
                                                                {{getTimeTypeName(flightReq.controls['constraint'].value)
                                                                | translate}}</div>
                                                            <span class="icon-triangle control-icon"
                                                                attr.data-track="TimeTypeMenu"
                                                                attr.data-params="{{getParams(i)}}"></span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!isMobile" class="constraint-time"
                                                        id="constraint-time-leg{{i}}"
                                                        style="position:relative;margin-bottom:6px !important;">
                                                        <div class="control" (click)="constraintTimeSelectLeg2.toggle()"
                                                            attr.data-track="SelectTimeInterval"
                                                            attr.data-params="{{getParams(i)}}"
                                                            style="position:relative;">
                                                            <div class="select-input">
                                                                <div class="value custom-selectbox-value"
                                                                    attr.data-track="SelectTimeInterval"
                                                                    attr.data-params="{{getParams(i)}}">
                                                                    {{getTimeOptionName(flightReq.controls['constraintTime']?.value,flightReq.controls['constraint']?.value)
                                                                    | translate}}</div>
                                                                <ng-select class="" #constraintTimeSelectLeg2
                                                                    appendTo="#constraint-time-leg{{i}}"
                                                                    dropdownPosition="bottom" [searchable]="false"
                                                                    [closeOnSelect]="true" [clearable]="false"
                                                                    [items]="timeOptions | translateOptions"
                                                                    formControlName="constraintTime" bindValue="titleID"
                                                                    bindLabel="title" (open)="getTimeOptions()"
                                                                    (change)="setMultiLeg2Constraint($event.titleID,i)">

                                                                    <ng-template ng-header-tmp>
                                                                        <div class="selectox-header">
                                                                            <span>{{'search.SelectTime' | translate
                                                                                }}</span>
                                                                            <span class="selectBox-remove"
                                                                                (click)="constraintTimeSelectLeg2.toggle()"><span
                                                                                    class="material-icons">clear</span></span>
                                                                        </div>

                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-option="item">
                                                                        <span class="option-title">{{option.title |
                                                                            translate}}</span>
                                                                        <span>{{getTimeOptionValue(option) |
                                                                            translate}}</span>
                                                                    </ng-template>
                                                                </ng-select>
                                                                <div class="select-overlay"></div>
                                                                <span class="icon-triangle control-icon"
                                                                    attr.data-track="SelectTimeInterval"
                                                                    attr.data-params="{{getParams(i)}}"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 col-lg" *ngIf="multicity && !isMobile"
                                                    class="text-right"
                                                    style="cursor:pointer !important;margin-top: 15px;color: gray !important;">
                                                    <span *ngIf="getAdditionalReqeustControl().length > 1 && i >0"
                                                        (click)="onRemoveLeg(i)" class="close"
                                                        style="margin-left:5px;font-size:23px;color: gray !important;"><i
                                                            class="material-icons">close</i></span></div>
                                            </div>
                                        </div>

                                    </ng-container>
                                </ng-container>
                                <div class="col-xl-2 col-lg-2 col-md-2"
                                    *ngIf="multicity && (this.multipleLeg!==this.getAdditionalReqeustControl().length)"
                                    style="cursor:pointer !important;margin-top:40px !important;">
                                    <span (click)="onAddLeg()" *ngIf="this.viewMode=='tab1'" class="addLegs">{{'search.ADDAFLIGHT ' | translate}}</span>
                                    <span (click)="onAddLeg()" *ngIf="this.viewMode=='tab5'" class="addLegs">Add A TRAIN</span>
                                </div>
                                <div class="col-xl-2 col-lg-12 col-md-12">
                                    <div *ngIf="multicity" style="visibility: hidden !important;" class="flightCount1">{{'search.Flight' | translate}} 1</div>
                                    <div [ngClass]="{'searchButton text-center': true}">
                                        <button type="button"  *ngIf="this.viewMode == 'tab1'" class="btn primary-button" (click)="submitForm(content)" [disabled]="formIfInvalid">{{'search.Search' | translate }}</button>
                                        <button type="button" *ngIf="this.viewMode == 'tab5'" style="padding:10px 35px;" class="btn primary-button" (click)="submitForm(content,thankyoumodal)" [disabled]="formIfInvalid">Submit Request</button>
                                        <div *ngIf="searchInProgress">
                                            <loader-dots></loader-dots>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isAlgoSwitchVisible()" class="col-xl-12 d-block d-md-none text-center">
                                    <app-switch-component [leftOptionValue]="'LEISURE'"
                                        [leftOptionText]="'search.Leisure' | translate"
                                        [rightOptionValue]="'PODUCTIVITY'"
                                        [rightOptionText]="'search.Business' | translate"
                                        [lastSelectedOption]="switchTripType" [switchGroupName]="'classTypeSwitch1'"
                                        [selectedOption]="switchTripType"
                                        (tripTypeChange)="handleTripTypeChange($event)"></app-switch-component>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="collapse-control-container" *ngIf="isResult && formArrayLength>0 && !isMobile">
                        <div class="collapse-control" (click)="setIsCollapsed(!isCollapsed)">
                            <span [ngClass]="{'icon-down': isCollapsed, 'icon-up': !isCollapsed}"></span>
                        </div>
                    </div>

                    <div class="row"
                        *ngIf="searchForm.errors?.sourceDestinationEqual && (searchForm.touched || searchForm.dirty)">
                        <div class="col">
                            <span class="text-danger">{{'search.SourceandDestinationcannotbesame' | translate }}</span>
                        </div>
                    </div>


                </div>
            </form>


        </div>


        <div id="tab2" class="tab-content-item" *ngSwitchCase="'tab2'">
            <form [formGroup]="searchHotelForm" autocomplete="off">
                <div class="search-wrapper result">
                    <div class="hotel-search-form">
                        <div class="hotal-filters">
                            <div class="row hotal-filters-row">
                                <div *ngIf="false"
                                    class="col-xl-5 col-lg-12 col-md-12 col-sm-6 col-6 d-none d-md-block">
                                    <div class="hotel-filters-left">
                                        <div class="hotel-label">
                                            <label>{{'search.TipEnteryourdestinationaddress' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="hotel-filters-right">
                                            <div *ngIf="this.searchService.employeeEmail && this.searchService.employeeEmail.length > 0" class="hotel-filters-right-section1">
                                            <div  class="field-value custom-selectbox-value colorSecondary"
                                            >
                                            {{searchHotelForm.controls['roomCount'].value}} <span *ngIf="searchHotelForm.controls['roomCount'].value ==='1'">{{'bookingHistory.Room' | translate }}</span> <span *ngIf="searchHotelForm.controls['roomCount'].value ===1">{{'bookingHistory.Room' | translate }}</span> <span *ngIf="searchHotelForm.controls['roomCount'].value >1">{{'bookingHistory.Rooms' | translate }}</span></div>
                                            </div>
                                        <div class="hotel-filters-right-section2">
                                            <div class="filter-select-container" id="hotelChainContainer">
                                                <div class="filter custom-selectbox" attr.data-track="HotelChainMenu"
                                                    (click)="hotelChain.toggle()">
                                                    <div class="select-input">
                                                        <ng-select placeholder="Hotel Chain" #hotelChain
                                                            appendTo="#hotelChainContainer" dropdownPosition="bottom"
                                                            [searchable]="false" [clearable]="false"
                                                            [closeOnSelect]="true"
                                                            [items]="hotelChainOptions | translateOptions"
                                                            bindLabel="" bindValue="id"
                                                            formControlName="hotelChain"
                                                            (change)="getHotelChain($event)">
                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.HotelChains' | translate }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="hotelChain.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-option="item">
                                                                    <span class="option-title">{{option.name |
                                                                        translate}}</span>
                                                                
                                                                </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                    </div>
                                                    <div class="field-value custom-selectbox-value colorSecondary"
                                                        attr.data-track="HotelChainMenu">
                                                        {{getValueOfId((searchHotelForm.controls['hotelChain'].value),hotelChainOptions)|
                                                        translate}}</div>
                                                    <span class="control-icon icon-triangle"
                                                        attr.data-track="HotelChainMenu"></span>
                                                </div>
                                            </div>
                                        </div>
                                   
                                        <div class="hotel-filters-right-section1" style="visibility: hidden;">
                                            <div class="filter-select-container room-container" id="roomCountContainer">
                                                <div class="filter custom-selectbox" attr.data-track="NumberOfRoomsMenu"
                                                    (click)="roomCount.toggle()">
                                                    <div class="select-input">
                                                        <ng-select #roomCount appendTo="#roomCountContainer"
                                                            dropdownPosition="bottom" [searchable]="false"
                                                            [clearable]="false" [closeOnSelect]="true"
                                                            [items]="roomCountOptions | translateOptions"
                                                            bindLabel="value" bindValue="id" formControlName="roomCount"
                                                            (change)="onHotelRoomCountChanged($event)">
                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.NumberofRooms' | translate }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="roomCount.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>
                                                            </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                    </div>
                                                    <div class="field-value custom-selectbox-value colorSecondary"
                                                        attr.data-track="NumberOfRoomsMenu">
                                                        {{getValueOfRoomId((searchHotelForm.controls['roomCount'].value),roomCountOptions)
                                                        | translate}}</div>
                                                    <span class="control-icon icon-triangle"
                                                        attr.data-track="NumberOfRoomsMenu"></span>
                                                </div>
                                            </div>

                                            <div class="filter-select-container" id="personCountContainer">
                                                <div class="filter custom-selectbox"
                                                    attr.data-track="NumberOfPeopleMenu" attr.data-params="type=hotels"
                                                    (click)="personCount.toggle()">
                                                    <div class="select-input">
                                                        <ng-select #personCount appendTo="#personCountContainer"
                                                            dropdownPosition="bottom" [searchable]="false"
                                                            [clearable]="false" [closeOnSelect]="true"
                                                            [items]="passengerOptions | translateOptions"
                                                            bindLabel="value" bindValue="id"
                                                            formControlName="personCount"
                                                            (change)="onHotelTravellerCountChanged($event)">
                                                            <ng-template ng-header-tmp>
                                                                <div class="selectox-header">
                                                                    <span>{{'search.NumberofPeople' | translate
                                                                        }}</span>
                                                                    <span class="selectBox-remove"
                                                                        (click)="personCount.toggle()"><span
                                                                            class="material-icons">clear</span></span>
                                                                </div>
                                                            </ng-template>
                                                        </ng-select>
                                                        <div class="select-overlay"></div>
                                                    </div>

                                                    <div class="field-value custom-selectbox-value colorSecondary"
                                                        attr.data-track="NumberOfPeopleMenu"
                                                        attr.data-params="type=hotels"><span class="container-icon"><img
                                                                src="assets/images/head.png"
                                                                attr.data-track="NumberOfPeopleMenu"
                                                                attr.data-params="type=hotels"
                                                                alt="" /></span>{{searchHotelForm.controls['personCount'].value
                                                        | translate}}</div>
                                                    <span class="control-icon icon-triangle"
                                                        attr.data-track="NumberOfPeopleMenu"
                                                        attr.data-params="type=hotels"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-none d-md-block">
                                            <!-- <app-switch-component [leftOptionValue]="'LEISURE'" [leftOptionText]="'Leisure'"
                                                              [rightOptionValue]="'PODUCTIVITY'" [rightOptionText]="'Business'"
                                                              [lastSelectedOption] = "switchTripType" [switchGroupName] = "'classTypeSwitch2'"
                                                              ></app-switch-component>  -->
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="hotel-search-input-container">
                            <div class="row">
                                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div *ngIf="!this.selectedCurrentLocation" class="text-input-wrapper"
                                        style="margin-right: 1px;">
                                        <div class="text-input invert">
                                            <input type="text" ngx-google-places-autocomplete
                                                [options]="[autoCompleteOptions]"
                                                (onAddressChange)="handleHotelAddressChange($event, hotelSearchInput.value)"
                                                placeholder="{{'search.DestinationCityAirportorAddress' | translate}}"
                                                formControlName="hotelAddress"
                                                style="padding-right:40px !important;border: 1px solid #e4e4e4 !important; border-radius: 6px !important;"
                                                tabindex="1" (keydown.enter)="$event.preventDefault()"
                                                (click)="$event.stopPropagation();" (focusout)="this.boxSelect=false;"
                                                [ngStyle]="{'border-color': this.boxSelect ? '#8A27F7':'#e4e4e4'}"
                                                id="hotelAddressCustomeLocationInputField"
                                                (input)="getInput($event.target.value)"
                                                (focusout)="enableChekinDateDate()"
                                                (focus)="onHotelAddressFocused();this.boxSelect=true;"
                                                #hotelSearchInput />
                                            <div *ngIf="this.showCurrentLocation" class="currentLocation"
                                                (click)="selectCurrentLocation(errorModal);$event.stopPropagation();">
                                                <span *ngIf="!this.locationDisabled"><i class="fa fa-location-arrow"
                                                        style="color:blue !important;padding-right:10px !important;"
                                                        aria-hidden="true"></i> {{'search.Yourcurrentlocation' | translate}}</span>
                                                <span *ngIf="this.locationDisabled" style="color:#adadad !important"><i
                                                        class="fa fa-location-arrow"
                                                        style="padding-right:10px !important;"
                                                        aria-hidden="true"></i> {{'search.Locationsharingisdisabled' | translate}}</span>
                                            </div>
                                            <div *ngIf="isSearchFormNonEmpty()" class="x"
                                                (click)="clearHotelTextField()"><i class="material-icons">close</i>
                                            </div>
                                            <input type="hidden" formControlName="hotelAddressCity" />
                                            <div *ngIf="this.searchHotelForm.controls['hotelAddress'].hasError('required') && (this.searchHotelForm.controls['hotelAddress'].touched || this.searchHotelForm.controls['hotelAddress'].dirty)"
                                                class="error">{{'search.Pleaseentercityaddress' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="this.selectedCurrentLocation" class="text-input-wrapper"
                                        style="margin-right: 1px;" (click)="clearHotelTextField()">
                                        <div class="text-input invert">
                                            <input type="text" [attr.disabled]="true"><label class="locate">
                                                {{currentHotelLocation}}</label>
                                            <div class="x" (click)="clearHotelTextField()"><i
                                                    class="material-icons">close</i></div>
                                            <input type="hidden" formControlName="hotelAddressCity" />
                                            <div *ngIf="!this.searchHotelForm.controls['hotelAddress'].value && !this.searchHotelForm.controls['currentLocation'].value"
                                                class="error">{{'search.Pleaseentercityaddress' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <div class="date-input">
                                        <span class="input-icon material-icons"
                                            (click)="openCheckinCalender(checkinDatePicker)">event</span>
                                        <div class="text-input invert" (click)="openCheckinCalender(checkinDatePicker)">
                                            <input class="textfield-with-icon" type="text"
                                                placeholder="{{'search.SelectCheckinDate' | translate}}"
                                                value="{{getCheckinDate()| date: 'EEE MMM d'}}" readonly="" />
                                        </div>
                                        <input bsDatepicker #checkinDatePicker="bsDatepicker"
                                            formControlName="checkinDate" [minDate]="minimumDate1"
                                            [maxDate]="maximumDate" [outsideClick]="true"
                                            [bsConfig]="{showWeekNumbers: false}"
                                            (onShown)="onShowPicker($event, checkinDatePicker)"
                                            (bsValueChange)="setCheckInDate($event)" (onHidden)="onHidePicker()"
                                            readonly />
                                    </div>
                                    <div class="hotel-check-label" style="display: none;">{{'search.Check-in' | translate }}</div>
                                </div>

                                <div class="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <div class="date-input">
                                        <span class="input-icon material-icons"
                                            (click)="checkoutDatePicker.toggle()">event</span>
                                        <div class="text-input invert" (click)="checkoutDatePicker.toggle()">
                                            <input class="textfield-with-icon" type="text"
                                                placeholder="{{'search.SelectCheckoutDate' | translate}}"
                                                value="{{getCheckoutDate()| date: 'EEE MMM d'}}" readonly="" />
                                        </div>
                                        <input bsDatepicker #checkoutDatePicker="bsDatepicker"
                                            formControlName="checkoutDate" [minDate]="minimumDateLeg2"
                                            [maxDate]="maximumDate3" [outsideClick]="true"
                                            [bsConfig]="{showWeekNumbers: false}"
                                            (onShown)="onShowPicker($event, checkoutDatePicker)"
                                            (bsValueChange)="setCheckOutDate($event)" (onHidden)="onHidePicker()"
                                            readonly />
                                    </div>
                                    <div class="hotel-check-label" style="display: none;">{{'search.Check-out' | translate }}</div>
                                </div>
                                <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-none d-md-block">
                                    <div class="searchButton text-center">
                                        <button type="button" class="btn primary-button"
                                            (click)="submitHotelForm(content)"
                                            [disabled]="formHotelIfInvalid">{{'search.Search' | translate }}</button>
                                        <div *ngIf="searchInProgress">
                                            <loader-dots></loader-dots>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div class="mobile-vs d-block d-md-none">
                        <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="searchButton text-center">
                                <button type="button" class="btn primary-button" (click)="submitHotelForm(content)"
                                    [disabled]="formHotelIfInvalid">{{'search.Search' | translate }}</button><br>
                                <div *ngIf="searchInProgress">
                                    <loader-dots></loader-dots>
                                </div>
                            </div>
                        </div>
                        <div class="d-block d-md-none text-center">
                        </div>
                    </div>


                </div>


            </form>
        </div>
        <div id="tab3" class="tab-content-item" *ngSwitchCase="'tab3'">
            <app-car-search (comeBackEmitter)="handleBackFromSearchPage($event)"></app-car-search>
        </div>
        <div id="tab4" class="tab-content-item" *ngSwitchCase="'tab4'">
              <booking-history-component [showAllBookings]="showAllBookings"></booking-history-component> 
            </div>
    </div>

</div>
<div>

        <ng-template #futureTripDetsils let-modal>

                <div class="modal-header">
                    <h5 class="modal-title" style="width: 100% !important;" id="myModalLabel">
                        {{'bookingHistory.UpcomingTrips' | translate}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" style="color: #fff !important;"
                        (click)="dismissModal()">
                        <i class="material-icons">close</i>
                    </button>
                </div>
                <div class="modal-body" style="text-align: left;margin: 10px 20px 10px 20px ">
                    <div style="margin-top:20px;margin-bottom: 20px;">
                        {{'bookingHistory.Thereisanexistingbookingforthetravelerslistedbelowthatoverlapswiththegiventraveldates' |
                        translate}}
                    </div>
                    <div *ngFor="let item of this.overlapBookings;let i=index">
                        <div *ngIf="item.primaryTraveller">
            
                            <span class="travellerName" style="min-width: 15px !important;">{{i+1}}.</span>
                            <span class="travellerName"> {{item.primaryTraveller.userName}}   <span *ngIf="(this.isMobile || this.njoySpecificBuild) && (getConfirmationNo(item))" class="" 
                                >({{getConfirmationNo(item)}})</span></span>
                            <span *ngIf="!this.isMobile &&  !this.njoySpecificBuild" class="travellerName" style="color: blue; cursor: pointer;"
                                (click)="openItinenary(item)">{{'successModel.showmeitinerary' | translate}}</span>
                                <span *ngIf="this.isMobile || this.njoySpecificBuild" class="travellerName" style="color: blue; cursor: pointer;"
                                (click)="openItinenary(item)">{{'bookingHistory.ViewDetail' | translate}}s</span>
                              
                        </div>
            
                    </div>
                    <div class="modal-form-button" style="margin-top: 20px;">
                        <button class="btn-secondary1" (click)="dismissModal()"> {{'policy.Proceed' | translate}}</button>
                    </div>
                </div>
            
            </ng-template>
                
    <ng-template #errorModal let-modal>
        <div class="">
            <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
                <i class="material-icons">close</i>
            </button>
        </div>
        <div class="modal-body">
            <div class="name"> We are unable to determine your current location</div>
            <div class="name2"> Please turn on the location services on your device so we can determine your location or
                type in a destination</div>
            <div class="modal-form-button">

            </div>
        </div>
    </ng-template>    <ng-template  #thankyoumodal let-modal class=" show" id="">
        <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header" style="background-color: #ffffff">
                <span class="icon-search-from modal-search-from"></span>
        
                <button type="button" class="close pull-right cross-button" aria-label="Close" (click)="searchAgainForTrain()">
                  <span class="icon-cross modal-cross"></span>
                </button>
              </div>
              <div class="modal-body">
                <span class="logo-border checkmark">
                  <span class="icon-checkmark modal-logo icon-warning">
                    <span class="path1">
                      <span class="path2"></span>
                    </span>
                  </span>
                </span>
        
                <h4 class="modal-title title-msg">Train Request sent</h4>
                <div class="email-text-content" > We have received your train  booking request. Please allow us 30 minutes to process and confirm the train. If you have any questions, please contact customer support.
                </div>
        
                <a  class="link" href="javascript:void(0)"
                 (click)="searchAgainForTrain()" >Book travel</a>
              </div>
        
        
            </div>
          </div>
      </ng-template>