import { Component, OnInit } from '@angular/core';
import {
  AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validator, ValidatorFn,
  Validators
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from './../util/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../login.service';
import { Subscription, throwError } from 'rxjs';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider, LinkedinLoginProvider } from 'angular-6-social-login';
import { environment } from 'src/environments/environment';
import { DeviceDetailsService } from '../device-details.service';
import { UserAccountService } from '../user-account.service';
import { CommonUtils } from '../util/common-utils';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [BsModalService, BsModalRef]
})
//,
//providers: [BsModalService, BsModalRef]

export class LoginComponent implements OnInit {
  njoySpecificBuild: boolean;
  ssoApiResponsecame=false;
  socialLoginsSupported=false;
  amnetSpecificRelease: boolean = environment.amnetSpecificRelease;
  loginForm: FormGroup;
  loginFirstForm: FormGroup;
  signUpForm: FormGroup;
  deviceSubscription: Subscription;
  bsModalRef: BsModalRef;
  addEmailForPassword: FormGroup;
  isMobile: boolean;
  errormsg = 'Authorizing using Microsoft Account';
  googleSignInUrl= environment.googleSignInUrl;
  stopPollinng = false;
  dataConsentRequired  = 'false';
  dataConsentAlreadyAccepted = 'false';
  loaderShow = false;
  loginServiceSubscription: Subscription;
  uuidKey = '';
  showError = false;
  agreePolicy = false;
  public userPostData = {
    email: '',
    name: '',
    provider: '',
    provider_id: '',
    provider_pic: '',
    token: ''
  };
  constructor(private fb: FormBuilder,
    private modalService: BsModalService,
    public loginService: LoginService,
    private activatedRoute: ActivatedRoute, private toastr: ToastrService,
    private router: Router,
    private titleService: Title,
    private gallopLocalStorage: GallopLocalStorageService,
    private deviceDetailsService: DeviceDetailsService,
    private userAccountService: UserAccountService,
    public translateService: TranslateService,
    private socialAuthService: AuthService,
    private bsModelLoginRef: BsModalRef) {
  }
  hidebsModalRef() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
      this.bsModalRef = null;
    }
  }
  checkBoxChange(event) {
    if (event) {
      this.showError = false;
      this.agreePolicy = true;
    } else {
      this.agreePolicy = false;
    }
  }
  getSelectedLanguage() {
    const selectedLang = localStorage.getItem('selectedLanguage');
    if (selectedLang) {
      return selectedLang;
    } else {
      const userLang = CommonUtils.getUserPreferredLanguage(this.translateService);
      if (userLang !== this.translateService.currentLang) {
        this.translateService.use(userLang);
      }
      return userLang;
    }
  }
  ngOnInit() {
    this.dataConsentAlreadyAccepted = localStorage.getItem('dataConsentAlreadyAccepted');
    if (this.dataConsentAlreadyAccepted !== 'true') {
      this.dataConsentRequired = localStorage.getItem('dataConsentRequired');

      if(!this.dataConsentRequired) {
        this.getCheckDataConstraint();
      }
    }
    this.titleService.setTitle(this.translateService.instant('login.Login'));
    if (this.router && this.router.url && CommonUtils.doesPathContain(this.router.url, '/logout')) {
      CommonUtils.signout(this.userAccountService, this.gallopLocalStorage, this.loginService);
    }
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_EMAIL)])],
      password: ['', Validators.required]
    });
    this.loginFirstForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_EMAIL)])],
      
    });
    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.signUpForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_ONLY_ALPHA)])],
      lastName: ['', Validators.compose([Validators.pattern(Constants.RGEX_ONLY_ALPHA)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_EMAIL)])],
      password: ['', Validators.required],
      terms: [null, Validators.required]
    });
    this.addEmailForPassword = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_EMAIL)])]
    });

    this.loginServiceSubscription = this.loginService.signInSubject$.subscribe(res => {
      if (this.bsModalRef) {
        this.bsModalRef.hide();
      }
      if (res && res === 'success') {
        if (this.bsModelLoginRef) {
          this.bsModelLoginRef.hide();
        }
      }
      this.loginService.isRequestInProgress = false;
    });

    this.loginService.loginPopupSubject.asObservable().subscribe(input => {
      this.bsModelLoginRef = input;
    });
  }
 
  getCheckDataConstraint(){
    this.loginService.getDataCheckContraint().subscribe(res=>{
      if(res && res.status==='success'){
        if(res.data && res.data.dataCheckRequired) {
          this.dataConsentRequired = 'true';
        } else {
          this.dataConsentRequired = 'false';
        }
        localStorage.setItem('dataConsentRequired', this.dataConsentRequired );
      }
    });
  }
  goToSignUp(createAccountModal) {
    this.hidebsModalRef();
    this.bsModalRef = this.modalService.show(createAccountModal);
    //this.bsModelLoginRef.hide();
  }
  hideLoginModal() {
    this.bsModelLoginRef.hide();
  }
  handleClickOnSignInLink() {
    this.hidebsModalRef();
    //  this.bsModalRef= this.modalService.show(LoginComponent,{});
  }
  onModelCancel() {
    this.hidebsModalRef();
    // if(!this.njoySpecificBuild){
    // this.bsModalRef= this.modalService.show(LoginComponent,{});
    // }
  }
  goToSignIn() {
    this.hidebsModalRef();
    // if(!this.njoySpecificBuild){
    // this.bsModalRef= this.modalService.show(LoginComponent,{});
    // }
  }
  goToRequestResetPassword(createPasswordModal) {
    this.hidebsModalRef();
    this.bsModalRef = this.modalService.show(createPasswordModal);
    //  this.bsModelLoginRef.hide();
  }
  getTerms(): string {
    if (this.amnetSpecificRelease) {
      return 'http://www.travelwithamnet.com/terms-of-use';
    } else {
      return 'https://routespring.com/tos.html';
    }
  }
  getPolicy(): string {
    if (this.amnetSpecificRelease) {
      return 'http://www.travelwithamnet.com/privacy-policy';
    } else {
      return 'https://routespring.com/privacy.html';
    }
  }

  ssoPolicy='none';
  ssourl='';
  getPiSSologinStatu(){
    if(this.dataConsentRequired === 'true') {
      if (!this.agreePolicy) {
        this.showError =true;
        return;
      }
      this.loginService.dataConsentAccepted =true;
    }
    if (this.loginFirstForm.valid) {
      this.loginService.isRequestInProgress = true;
    this.loginService.dataConsentAccepted =true;
    this.loginService.getSSologinStatus(this.loginFirstForm.controls['email'].value).subscribe(resp=>{
       if(resp && resp.status==='success'){
        if(resp.data){
        this.socialLoginsSupported = resp.data.socialLoginsSupported;
        }
         if(resp.data && resp.data.ssoPolicy && resp.data.ssoPolicy==='mandatory'){
          this.ssoPolicy = resp.data.ssoPolicy
          this.ssourl = resp.data.ssoUrl;
          window.location.replace(this.ssourl);
         } if(resp.data && resp.data.ssoPolicy && resp.data.ssoPolicy!=='none'){
          this.ssoPolicy = resp.data.ssoPolicy
          this.ssourl = resp.data.ssoUrl
         }
         if(resp.data && resp.data.ssoPolicy && resp.data.ssoPolicy!=='mandatory'){
         this.ssoApiResponsecame =true;
         this.loginService.isRequestInProgress = false;
         this.loginForm.controls['email'].setValue(this.loginFirstForm.controls['email'].value);
         }
         

       }else{
        this.loginService.isRequestInProgress = false;
       }
    }, error => {
      setTimeout(() => {
        this.loginService.isRequestInProgress = false;
        this.toastr.error(this.translateService.instant('reset.Pleasecheckyourinternetandtryagain').toString());
      }, 100);})
  }else{
    this.loginFirstForm.controls['email'].markAsTouched();
  }
  }
  changeEmail(){
    this.ssoApiResponsecame =false;
  }
  signWithSSo(){
    window.location.replace(this.ssourl);
  }
  signInWithGallop() {
    if(this.dataConsentRequired === 'true') {
      if (!this.agreePolicy) {
        this.showError =true;
        return;
      }
      this.loginService.dataConsentAccepted =true;
    }
    if (this.loginForm.valid) {
      this.loginService.isRequestInProgress = true;
      this.loginService.userEmailSignIn(this.loginForm.controls['email'].value,
        this.loginForm.controls['password'].value, this.router.url, this.activatedRoute.snapshot.queryParams);
    } else {
      this.loginForm.controls['email'].markAsTouched();
      this.loginForm.controls['password'].markAsTouched();
      this.loginForm.updateValueAndValidity();
    }
  }

  requestResetPasswordLink() {
    if (this.addEmailForPassword.valid) {
      this.loginService.isRequestInProgress = true;
      this.loginService.userResetPasswordLink(this.addEmailForPassword.controls['email'].value);
    } else {
      this.addEmailForPassword.controls['email'].markAsTouched();
      this.addEmailForPassword.updateValueAndValidity();
    }
  }
  private generateUUID(): string {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
  googleSigningIn=false;
  handleGoogleSignInCLick(modal) {
    if(this.dataConsentRequired === 'true') {
      if (!this.agreePolicy) {
        this.showError =true;
        return;
      }
      this.loginService.dataConsentAccepted =true;
    }
    this.uuidKey = this.generateUUID();
    let url = environment.googleSignInUrl + '?request-id=' + this.uuidKey;
    let ref;
    if (this.njoySpecificBuild) {
      url = url + '&dummy=/TripItAPI/';
      window.location.href = url;
    } else {
      ref = window.open(url, '_blank');
    }
    this.errormsg = this.translateService.instant("login.AuthorizingusingGoogleAccount");
    this.bsModelLoginRef = this.modalService.show(modal);
    this.loaderShow = true;
    this.googleSigningIn=true;
    this.stopPollinng = false;
    setInterval(() => {
      if (ref) {
        ref.close();
      }
      this.stopPollinng = true;
      this.googleSigningIn=false;
      this.bsModelLoginRef.hide();
    }, 300000);
    setTimeout(() => {
      this.getGoogleSignInStatus()
    }, 5000);
  }
  handleMSALClick(modal) {
    if(this.dataConsentRequired === 'true') {
      if (!this.agreePolicy) {
        this.showError =true;
        return;
      }
      this.loginService.dataConsentAccepted =true;
    }
    this.googleSigningIn=false;
    this.uuidKey = this.generateUUID()
    let url = environment.urlForADSignIn + "?request-id=" + this.uuidKey;
    let ref; // We can use this to close the opened tab, on timeout and on click of cross or cancel on the Azure login popup
    if (this.njoySpecificBuild) {
      url = url + '&dummy=/TripItAPI/';
      window.location.href = url;
    } else {
      ref = window.open(url, '_blank');
    }
    this.errormsg = this.translateService.instant("login.AuthorizingusingMicrosoftAccount");
    this.bsModelLoginRef = this.modalService.show(modal);
    this.loaderShow = true;
    this.stopPollinng = false;
    setInterval(() => {
      if (ref) {
        ref.close();
      }
      this.stopPollinng = true;
      this.bsModelLoginRef.hide();;
    }, 300000);
    setTimeout(() => {
      this.getSSOStatus()
    }, 5000);
  }
  getGoogleSignInStatus(){
      try {
        this.loginService.pollingForGoogleSignInResponse(this.uuidKey)
          .pipe(catchError(err => {
            return throwError(err);
          }))
          .subscribe((res) => {
            this.processLogin(res);
          }, error => {
  
          });
      } catch (error) {
  
      }
  
  }
  getSSOStatus() {
    try {
      this.loginService.pollingForBookingResponse(this.uuidKey)
        .pipe(catchError(err => {
          return throwError(err);
        }))
        .subscribe((res) => {
          this.processLogin(res);
        }, error => {

        });
    } catch (error) {

    }
  }
  processLogin(resp) {
    if (resp.success) {
      let responseType = resp.data.status;
      if (responseType === 'inProgress' && !this.stopPollinng) {
        this.initiatePolling();
        return;
      }
      if (responseType === 'success') {
        this.bsModelLoginRef.hide();
        let redirectQueryParams = {};
        this.loginService.initResponse = resp.initResponse;
        redirectQueryParams['userid'] = resp.data.userid;
        redirectQueryParams['sToken'] = resp.data.sToken;
        this.loginService.userid = redirectQueryParams['userid'];
        this.loginService.sToken = redirectQueryParams['sToken']
        redirectQueryParams['redirectedBackFromSignIn'] = 'true';
        if (this.loginService.currentUrl && this.loginService.currentUrl !== '/'  && !this.userAccountService.njoySpecificBuild) {
          this.loginService.redirectToPath();
        } else {
          //this.titleService.setTitle('Flight Search');
          this.titleService.setTitle(this.translateService.instant('search.FlightSearch'));
          const navigateTo = [];
          navigateTo.push(this.loginService.getDefaultPath());
          this.router.navigate(navigateTo,
            {
              queryParams: redirectQueryParams,
              replaceUrl: true
            });
          this.loginService.currentUrl = undefined;
        }
      } else if (responseType === 'none') {
        // this.bsModelLoginRef.hide();
        this.loaderShow = false;
        this.errormsg = resp.data.errorMessage;
      } else if (responseType === 'failed') {
        // this.bsModelLoginRef.hide();
        this.loaderShow = false;
        this.errormsg = resp.data.errorMessage;
      }
    }
  }
  initiatePolling() {
    setTimeout(() => {
      if(this.googleSigningIn){
        this.getGoogleSignInStatus();
      }else{
      this.getSSOStatus();
      }
    }, 5000);
  }
  onModelCancel1() {
    this.bsModelLoginRef.hide();

  }
  signUpWithGallop() {
    if (this.signUpForm.valid) {
      this.loginService.userEmailSignUp(this.signUpForm.controls['email'].value,
        this.signUpForm.controls['password'].value,
        this.signUpForm.controls['firstName'].value,
        this.signUpForm.controls['lastName'].value,
        this.router.url, this.activatedRoute.snapshot.queryParams);
    } else {
      this.signUpForm.controls['email'].markAsTouched();
      this.signUpForm.controls['firstName'].markAsTouched();
      this.signUpForm.controls['lastName'].markAsTouched();
      this.signUpForm.controls['password'].markAsTouched();
      this.signUpForm.updateValueAndValidity();
    }
  }

  handleGoogleSignUpCLick() {
    if(this.dataConsentRequired === 'true') {
      if (!this.agreePolicy) {
        this.showError =true;
        return;
      }
      this.loginService.dataConsentAccepted =true;
    }
    if (this.signUpForm.controls['terms'].valid && this.signUpForm.controls['terms'].value) {
      this.socialSignUp('google');
    } else {
      this.signUpForm.controls['terms'].setValue(null);
      this.signUpForm.controls['terms'].markAsTouched();
      this.signUpForm.updateValueAndValidity();
    }



  }

  handleGoogleSignInCallBack(emailId: string, accessToken: string) {
    this.loginService.userGoogleSignIn(emailId,
      accessToken, this.router.url, this.activatedRoute.snapshot.queryParams);
  }

  handleGoogleSignUpCallBack() {
    this.loginService.userGoogleSignUp(this.userPostData.email, this.userPostData.token, this.userPostData.name.split(" ")[0],
      this.userPostData.name.split(" ")[1], this.userPostData.provider_pic, this.router.url, this.activatedRoute.snapshot.queryParams);
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "linkedin") {
      socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {

        this.userPostData.email = userData.email;
        this.userPostData.name = userData.name;
        this.userPostData.provider = userData.provider;
        this.userPostData.provider_id = userData.id;
        this.userPostData.provider_pic = userData.image;
        this.userPostData.token = userData.token;
        this.handleGoogleSignInCallBack(userData.email, userData.token);
      }
    );
  }


  public socialSignUp(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "linkedin") {
      socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {

        this.userPostData.email = userData.email;
        this.userPostData.name = userData.name;
        this.userPostData.provider = userData.provider;
        this.userPostData.provider_id = userData.id;
        this.userPostData.provider_pic = userData.image;
        this.userPostData.token = userData.token;
        this.handleGoogleSignUpCallBack();
      }
    );
  }
}
