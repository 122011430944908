<ng-container *ngIf="show">
  <div class="dashboard-custom-popup"  style="zoom:80%">
    <div class="dashboard-custom-popup-wrapper">
    <div class="modal-header">
        <div *ngIf="this.addEmployeeReqInProgress" class="approval_request_diaglog_bg_clickhandler" (click)="$event.stopPropagation();"></div>
        <h5 *ngIf="mode === 'add'" class="modal-title" id="myModalLabel">
            {{ 'employee.AddanEmployee' | translate}}
        </h5>
        <h5 *ngIf="mode === 'edit'" class="modal-title" id="myModalLabel">
            {{ 'employee.EditEmployee' | translate}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
      <i class="material-icons" style="color:#fff;">close</i>
    </button>

    </div>
    <div class="modal-body" style=" text-align: left !important;">
        <form [formGroup]="employeeForm">
            <div class="row" style="margin-top:0px;">
              <div class="col-12 col-md-6">
                  <span class="nameLabelHeader"> {{ 'employee.FirstName' | translate}}</span>
                  <!-- <div *ngIf = "isValidField('firstName')" class="formControl3">{{getValidFieldValue('firstName')}}</div> -->
                  <input type="text" class="inputFormBox" formControlName="firstName" placeholder="{{ 'employee.FirstName' | translate}}" />
                  <div *ngIf="employeeForm.controls['firstName'].hasError('required') && (employeeForm.controls['firstName'].touched || employeeForm.controls['firstName'].dirty)" class="error"> {{ 'employee.Thisfieldisrequired' | translate}}
                  </div>
                  <div *ngIf="employeeForm.controls['firstName'].hasError('pattern') && (employeeForm.controls['firstName'].touched || employeeForm.controls['firstName'].dirty)" class="error"> {{ 'employee.Pleaseentervalidfirstname.' | translate}}
                  </div>
              </div>
              <div class="col-12 col-md-6">
                  <span class="nameLabelHeader"> {{ 'employee.LastName' | translate}}</span>
                  <!-- <div *ngIf = "isValidField('lastName')" class="formControl3">{{getValidFieldValue('lastName')}}</div> -->
                  <input type="text" class="inputFormBox" formControlName="lastName" placeholder="{{ 'employee.LastName' | translate}}" />
                  <div *ngIf="employeeForm.controls['lastName'].hasError('required') && (employeeForm.controls['lastName'].touched || employeeForm.controls['lastName'].dirty)" class="error">{{ 'employee.Thisfieldisrequired' | translate}}
                  </div>
                  <div *ngIf="employeeForm.controls['lastName'].hasError('pattern') && (employeeForm.controls['lastName'].touched || employeeForm.controls['lastName'].dirty)" class="error"> {{ 'employee.Pleaseentervalidlastname.' | translate}}
                  </div>
              </div>
          </div>
          <div class="row" style="margin-top:24px;">


            <div class="col-12 col-md-6">
                <label class="nameLabelHeader"> {{ 'employee.Email' | translate}}</label>
                <!--  <div *ngIf = "isValidField('email')" class="formControl3">{{getValidFieldValue('email')}}</div>-->
                <input type="text" [attr.disabled]="((this.mode=='edit')? true:null)" (input)="checkEmailDomain($event.target.value)" class="inputFormBox" formControlName="email" placeholder="{{'employee.Email' | translate}}" />
                <div *ngIf="employeeForm.controls['email'].hasError('pattern') && (employeeForm.controls['email'].touched || employeeForm.controls['email'].dirty)" class="error">{{'login.pleaseenteravalidemail' | translate}}
                </div>
                <div *ngIf="employeeForm.controls['email'].hasError('required') && (employeeForm.controls['email'].touched || employeeForm.controls['email'].dirty)" class="error">{{'login.thisfieldisrequired' | translate}}
                </div>
            </div>
            <div class="col-12 col-md-6">
                <label class="nameLabelHeader"> {{ 'employee.EmployeeId' | translate}}</label>
                <!--  <div *ngIf = "isValidField('email')" class="formControl3">{{getValidFieldValue('email')}}</div>-->
                <input type="text" class="inputFormBox" formControlName="employeeIdentifier" maxlength="20" placeholder="{{'employee.EmployeeId' | translate}}" />
                <div *ngIf="employeeForm.controls['employeeIdentifier'].hasError('pattern') && (employeeForm.controls['employeeIdentifier'].touched || employeeForm.controls['employeeIdentifier'].dirty)" class="error">{{'Please enter a valid employee id' | translate}}
                </div>
            </div>
        </div>
            
            
            <hr>
            <div class="row" style="margin-top:24px;">
              <div class="col-lg-6 col-md-12">
                  <label class="nameLabelHeader"> {{ 'employee.Department' | translate}}</label>
                  <div id="policylistDiv11">
                      <div class="inputFormBox">
                          <ng-select #department appendTo="#policylistDiv11" class="custom-dashboard-select-input"  (change)="departmentSelected($event)" style="font-family: 'apercu-r' !important;bottom: 10px;" dropdownPosition="bottom" [searchable]="false" [clearable]="false" formControlName="department" [items]="departmentOptions"
                              bindLabel="" bindValue="id">
                              <span class="selectBox-remove"><span class="material-icons">clear</span></span>
                              <ng-template ng-label-tmp let-item="item">
                                  <span class="option-title">{{item.name | translate}}</span>
                              </ng-template>
                              <ng-template ng-option-tmp let-option="item">
                                  <span class="option-title">{{option.name | translate}}</span>

                              </ng-template>
                          </ng-select>

                      </div>
                      <svg class="down-arrow" (click)="department.toggle()" style="top: -22px;position:relative;float:right;right:30px;" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
              fill="gray" />
          </svg>
                  </div>
                  <div *ngIf="employeeForm.controls['department'].hasError('required') && (employeeForm.controls['department'].touched || employeeForm.controls['department'].dirty)" class="error" style="position: relative;">{{'login.thisfieldisrequired' | translate}}
                  </div>
              </div>
              <div class="col-lg-6 col-md-12">
                  <label _ngcontent-gwe-c27="" class="nameLabelHeader">Role</label>
                  <div id="roleDropdown">
                      <div class="inputFormBox">
                          <ng-select #roleDropdown appendTo="#roleDropdown" class="custom-dashboard-select-input"   dropdownPosition="bottom" [searchable]="false" [clearable]="false" formControlName="userRole" [items]="userRoleOptions" bindLabel="value" bindValue="id" (change)="roleChange($event)">
                              <ng-template ng-option-tmp let-option="item">
                                  <span class="option-title">{{option.name}}</span>
                              </ng-template>
                              <ng-template ng-label-tmp let-item="item">
                                  <span>{{item.name}}</span>
                              </ng-template>
                          </ng-select>
                          <div class="select-overlay"></div>
                      </div>
                      <svg class="down-arrow" (click)="statusDropdown.toggle()" style="top: -22px;position: relative;float: right;right: 30px;" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
              fill="gray" />
          </svg>
                  </div>
              </div>
          </div>
            <div class="row" style="margin-top:20px;margin-bottom: 35px;">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label class="nameLabelHeader">{{ 'employee.Policy' | translate}}</label>

                    <div id="policylistDiv22">
                        <div class="inputFormBox">
                            <ng-select #policylist appendTo="#policylistDiv22" class="custom-dashboard-select-input"  style="bottom: 10px;" dropdownPosition="middle" [searchable]="false" [clearable]="false" formControlName="policy" (change)="onPolicyChangeClicked($event)" [items]="policyOptions" bindLabel="" bindValue="id">
                                <ng-template ng-label-tmp let-item="item">
                                    <span> {{getSelectedPolicyName()}}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-option="item">
                                    <span class="option-title">{{option.name |
                  translate}}</span>

                                </ng-template>
                            </ng-select>
                            <div class="select-overlay"></div>

                        </div>
                        <svg class="down-arrow" (click)="policylist.toggle()" style="top: -22px;position:relative;float:right;right:30px;" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                fill="gray" />
            </svg>
                        <div *ngIf="this.showPolicyRefreshButton" class="update-message" (click)="refreshpolicy()">
                            <i class="fa fa-refresh" aria-hidden="true"></i><span style="margin-left: 5px;"> {{
'employee.Updateaspernewdepartment' | translate}}</span>
                        </div>
                    </div>


                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label class="nameLabelHeader"> {{ 'employee.Approver' | translate}}</label>
                    <div id="newDashboardCheckboxList3" style="position: relative;">
                        <div class="dropDownSearchBox" style="overflow: hidden;">
                            <ng-select #newDashboardCheckboxList3  class="select-checkbox-dashbord-ui" appendTo="#newDashboardCheckboxList3"  (click)="this.dropDownopen =true;filterSelectedlist(this.multipleSelectedApprover)" dropdownPosition="middle" (close)="closeDropdown()" [searchable]="true" [closeOnSelect]="false"
                                [clearable]="false" [items]="this.appproverList" [searchFn]="searchByApproverNameAndEmailChanged" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" >
                                <ng-template ng-label-tmp let-item="item">
                                    <span> {{getLabelValue() | translate}}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                    <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect mdl_checkbox_approver_span" for="{{item.email}}">
                    <input type="checkbox" id="{{item.email}}" class="mdl-checkbox__input"
                      (change)="onApproverChangeClicked(item.email, $event)" [checked]="isApproverChecked(item.email)">
                    <span class="mdl-checkbox__label1"><span style="width: 100px !important;min-width: 100px !important;text-overflow: ellipsis;display: inline-block;overflow: hidden;position: relative;
                        top: 0px;">{{item.firstName}} {{item.lastName}}</span>
                      <span
                        style=" top: -5px;text-align:right;text-overflow: ellipsis;position: relative;white-space: nowrap;overflow: hidden;margin-left:20px;">
                        {{item.email}}</span></span>
                  </label>

                                </ng-template>
                            </ng-select>
                            <div class="select-overlay"></div>
                            <div *ngIf="this.multipleSelectedApprover.length > 0 && !this.dropDownopen" (click)="this.dropDownopen =true;filterSelectedlist(this.multipleSelectedApprover);newDashboardCheckboxList3.toggle()" class="modalApprover1">
                                <span *ngFor="let item1 of this.multipleSelectedApprover;let i=index">
                  <span class="nameLabelHeader">{{item1}} <span
                      *ngIf="((i !== (this.multipleSelectedApprover.length-1)))">,
                    </span></span>
                                </span>
                            </div>
                        </div>
                        <svg class="down-arrow" (click)="this.dropDownopen =true;filterSelectedlist(this.multipleSelectedApprover);newDashboardCheckboxList3.toggle()" style="top: -22px;position:relative;float:right;right:30px;" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                fill="gray" />
            </svg>
                        <div *ngIf="showRefreshButton"  class="update-message" (click)="refreshApprover()">
                            <i class="fa fa-refresh" aria-hidden="true"></i><span style="margin-left: 5px;"> {{
                'employee.Updateaspernewdepartment' | translate}}</span>
                        </div>
                    </div>
                </div>


            </div>
            <hr>
            <div class="switchPanel">
                <div class="checkbox-container1" style="padding-left:0px !important;">



                    <ui-switch formControlName="companyCardAccess" [checked]="companyCardAccess"></ui-switch>


                    <span class="swicthLabel" style=""> {{ 'employee.Allowusingcompanyscentralpaymentmethod' |
                translate}}</span>


                </div>

                <div class="checkbox-container1" style="padding-left:0px !important;">



                    <ui-switch formControlName="allowedCardAccess" [checked]="allowedCardAccess"></ui-switch>



                    <span class="swicthLabel"> {{ 'employee.Allowusingpersonalcard' | translate}}</span>


                </div>

                <div class="checkbox-container1" style="padding-left:0px !important;">



                    <ui-switch formControlName="showGovtRates" [checked]="showGovtRates"></ui-switch>


                    <span class="swicthLabel"> {{ 'employee.Eligibleforgovernmentratesforhotels' | translate}}</span>


                </div>
                <div class="checkbox-container1" style="padding-left:0px !important;">


                    <ui-switch formControlName="showAAARates" [checked]="showAAARates"></ui-switch>


                    <span class="swicthLabel"> {{ 'employee.EligibleforAAAratesforhotels' | translate}}</span>

                </div>
                <div class="checkbox-container1" style="padding-left:0px !important;">


                    <ui-switch [disabled]="disbaleSwicth()" formControlName="allowBookForOthers" (change)="setBFO($event)" [checked]="allowBookForOthers"></ui-switch>



                    <span class="swicthLabel"> {{ 'employee.Allowbookingforotheremployees' | translate}}</span>

                </div>
                <div *ngIf="employeeForm.controls['allowBookForOthers'].value" style="width: 100%;" class="popup-select-boxes">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 popup-selct-label">


                            <label class="nameLabelHeader" style="white-space: pre-line; line-height: 17px;">
              {{ 'employee.Sendbookingemailsto' | translate}}</label>
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <div class="row" style="position: relative;width: 100%;">
                                <div id="newDashboardCheckboxList2" style="width: 100%;">
                                    <div class="dropDownSearchBox">
                                        <ng-select #newDashboardCheckboxList2  class="select-checkbox-dashbord-ui" appendTo="#newDashboardCheckboxList2"  dropdownPosition="middle" [searchable]="false" (close)="closeDropdown1()" (open)="this.dropDownopen1 =true;" [closeOnSelect]="false" [clearable]="false" [items]="emailDropdown" formControlName="bookingEmailConfig"
                                            bindLabel="" bindValue="value" >

                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="{{item.id}}">
                                                  <input type="checkbox" id="{{item.id}}" class="mdl-checkbox__input"
                                                    (change)="onEmailSendNotificationChangeClicked(item.id, $event)"
                                                    [checked]="isEmailSendNotificationChecked(item.id)">
                                                    <span class="mdl-checkbox__label" >
                                                      <span >
                                                        {{item.value | translate}}</span>
                                                    </span>
                                                </label>

                                            </ng-template>
                                        </ng-select>
                                        <div class="select-overlay"></div>
                                        <div *ngIf="this.bookingEmailsSentTo.length > 0 && !this.dropDownopen1" class="modalApprover" (click)="newDashboardCheckboxList2.toggle()" >
                                            <span *ngFor="let item1 of this.bookingEmailsSentTo;let i=index">
                      <span class="nameLabelHeader">{{getEmailNotificationLabel(item1)}}<span
                          *ngIf="((i !== (this.bookingEmailsSentTo.length-1)))">,
                        </span></span>
                                            </span>
                                        </div>
                                        <div *ngIf="this.bookingEmailsSentTo.length === 0 && !this.dropDownopen1" class="modalApprover" (click)="newDashboardCheckboxList2.toggle()" >
                                            <span>
                      <span class="nameLabelHeader">{{'employee.Default' | translate}}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <svg class="down-arrow" (click)="this.dropDownopen1 =true;newDashboardCheckboxList2.toggle()" style="top: -22px;position:relative;float:right;right:30px;" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                    fill="gray" />
                </svg>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div *ngIf="isCustomSelectedInEmailSendNotification()" class="row" style="width: 100%;margin-right:0px !important;margin-left: 0px !important;">
                        <div class="select-box" (click)="setFocusOnInput('notificationEmail')">
                            <div class="row" style="padding-top:0px;"><span *ngFor="let item of this.emailNotificationList;let i=index" class="input-boss"><span style="color: gray !important;display: inline-block;
                                white-space: nowrap;max-width: 120px;min-width: 120px;
                                overflow: hidden !important;
                                text-overflow: ellipsis !important;">{{item}}</span><span style="float:right;font-size:12px;position: relative;top: -2px;"><i class="material-icons"
                    style="color: gray !important;font-size:20px !important;cursor: pointer;"
                    (click)="unselectedEmployee(i)">close</i></span></span><input type="email" id="notificationEmail" class="inputLowBalance11" formControlName="notificationEmail" (focusout)="addEmailList()" (input)="inputEmailValue($event.target.value)"
                                    (keyup)="($event.key === 'Enter' || $event.key === ',') && addEmailList()"></div>


                        </div>
                        <div *ngIf="employeeForm.controls['notificationEmail'].hasError('pattern') && (employeeForm.controls['notificationEmail'].touched || employeeForm.controls['notificationEmail'].dirty)" class="error" style="width: 100%">{{'personal.Pleaseenteravalidemail' | translate}}
                        </div>
                        <div class="error" style="width: 100%" *ngIf="employeeForm.controls['notificationEmail'].hasError('required') && (employeeForm.controls['notificationEmail'].touched || employeeForm.controls['notificationEmail'].dirty)">
                            {{'login.thisfieldisrequired' | translate}}
                        </div>

                        <div style="color: #808080;" >
                          <span  style="margin-right: 5px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <g clip-path="url(#clip0_38_3697)">
                            <path d="M2 8.25C2 9.03793 2.15519 9.81815 2.45672 10.5461C2.75825 11.2741 3.20021 11.9355 3.75736 12.4926C4.31451 13.0498 4.97595 13.4917 5.7039 13.7933C6.43185 14.0948 7.21207 14.25 8 14.25C8.78793 14.25 9.56815 14.0948 10.2961 13.7933C11.0241 13.4917 11.6855 13.0498 12.2426 12.4926C12.7998 11.9355 13.2417 11.2741 13.5433 10.5461C13.8448 9.81815 14 9.03793 14 8.25C14 6.6587 13.3679 5.13258 12.2426 4.00736C11.1174 2.88214 9.5913 2.25 8 2.25C6.4087 2.25 4.88258 2.88214 3.75736 4.00736C2.63214 5.13258 2 6.6587 2 8.25Z" stroke="#677099" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 6.25H8.00625" stroke="#677099" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.33203 8.25H7.9987V10.9167H8.66536" stroke="#677099" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_38_3697">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.25)"/>
                            </clipPath>
                            </defs>
                            </svg></span>
                            {{ 'setting.Separatemultipleemailsaddresseswithcommas' | translate}}
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 popup-selct-label">
                            <label class="nameLabelHeader" style="white-space: pre-line; line-height: 27px;top: 0px;margin-top: 0px;">
              {{ 'employee.SaveguestusersbookedasSharedProfile' | translate}}
            </label>
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <div class="row" style="position:relative;width:100%;">
                                <div id="cardlistDiv" style="width: 100%;">
                                    <div class="inputFormBox">
                                        <ng-select #cardlist appendTo="#cardlistDiv" class="custom-dashboard-select-input"  dropdownPosition="middle" style="bottom:18px !important;" formControlName="saveGuestUsers" [searchable]="false" [clearable]="false" [items]="switchDrodpdown | translateOptions" bindLabel="value" bindValue="id">


                                        </ng-select>
                                        <div class="select-overlay"></div>
                                    </div>
                                    <svg class="down-arrow" (click)="cardlist.toggle()" style="top: -22px;position:relative;float:right;right:30px;" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                    fill="gray" />
                </svg>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.userAccountService.infantBookingAllowed" class="row">
                        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 popup-selct-label">
                            <label class="nameLabelHeader" style="white-space: pre-line; line-height: 27px;margin-top: 0px;">
              {{ 'employee.EnableBookingforminors' | translate}}
            </label>
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <div class="row" style="position: relative;width: 100%;">
                                <div id="cardlistDiv3" style="width: 100%;">
                                    <div class="inputFormBox">
                                        <ng-select #cardlist1 appendTo="#cardlistDiv3"  class="custom-dashboard-select-input"  dropdownPosition="middle" style="bottom:18px !important;" [searchable]="false" formControlName="minorBookingEnabled" [clearable]="false" [items]="switchDrodpdown | translateOptions" bindLabel="value" bindValue="id">


                                        </ng-select>
                                        <div class="select-overlay"></div>
                                    </div>
                                    <svg class="down-arrow" (click)="cardlist1.toggle()" style="top: -22px;position:relative;float:right;right:30px;" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                    fill="gray" />
                </svg>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" *ngIf="!addEmployeeReqInProgress" class="dashboardButtons" (click)="addUserAsEmployee()"><span *ngIf="mode === 'add'" >
          {{ 'employee.AddUser' | translate}}</span>
        <span *ngIf="mode === 'edit'" > {{ 'employee.Update' | translate}}</span>
        <span *ngIf="mode === 'addManager'" class="add">Add Manager</span>
        <span *ngIf="mode === 'addAdmin'" class="add">Add Admin</span>
      </button>
                <button *ngIf="addEmployeeReqInProgress" class="dashboardButtons"><span > {{ 'employee.Wait' |
          translate}}...</span>
      </button>
      <span *ngIf="addEmployeeReqInProgress" class="loaderClass">
        <loader-dots class="loaderAlign"></loader-dots>
      </span>
                <!-- <div class="modal-form-button">

                </div> -->
            </div>
        </form>
    </div>
    </div>
  </div>   
</ng-container>



<ng-container *ngIf="!show">
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">
            Add an Employee
        </h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
      <i class="material-icons" style="color:#fff;">close</i>
    </button>
    </div>
    <div class="modal-body" style=" text-align: left !important;">
        <span class="heading">Confirm Employee Details</span>
        <form [formGroup]="employeeForm">
            <div class="row" style="margin-top:24px;">
                <div class="col-6" style=" text-align: left !important;">
                    <span class="nameLabelHeader">First Name</span>
                    <div class="formControl3">Gregory</div>
                </div>
                <div class="col-6" style=" text-align: left !important;">
                    <span class="nameLabelHeader">Middle Name</span>
                    <div class="formControl3">Kumar</div>
                </div>
            </div>
            <div class="row" style="margin-top:24px;">
                <div class="col-6" style=" text-align: left !important;">
                    <span class="nameLabelHeader">Last Name</span>
                    <div class="formControl3">Doyle</div>
                </div>
            </div>
            <div class="row" style="margin-top:24px;">
                <div class="col-6">
                    <label class="nameLabelHeader">Gender</label>
                    <div class="checkbox-container">
                        <label class="mdl-radio selection-checkbox checkbox2 checkbox-seperate mdl-js-radio mdl-js-ripple-effect" style="background-color:blue;">
              <span class="mdl-radio__label" style="color:#fff;font-weight: normal;font-family: apercu !important;
                 letter-spacing: 1px;">Male</span>

            </label>
                    </div>
                </div>
                <div class="col-6">
                    <label class="nameLabelHeader">Date Of Birth</label>
                    <div class="formControl3">19/9/1984</div>
                </div>
            </div>
            <div class="row" style="margin-top:24px;">
                <div class="col-6">
                    <label class="nameLabelHeader">Email</label>
                    <div class="formControl3">gdoyle@njoy.com</div>
                </div>
                <div class="col-6">
                    <label class="nameLabelHeader">Phone Number</label>
                    <div class="phone-number" style="padding-top:10px;">
                        <img class="flag-label" src="assets/flags/{{getCountryCode(employeeForm.controls['dialCode'].value)}}.png" />
                        <i class="fa fa-chevron-down" style="display:none;"></i>
                        <span style="padding-left:10px;font-weight:bold;">{{employeeForm.controls['dialCode'].value}}</span>
                    </div>
                    <div class="formControl3" style="padding-left:79px;">9871420384</div>
                </div>
            </div>
            <div class="row" style="margin-top:24px;">
                <div class="col-6" style=" text-align: left !important;">
                    <span class="nameLabelHeader">Title</span>
                    <div class="formControl3">CFO</div>
                </div>
                <div class="col-6">
                    <label class="nameLabelHeader">Department</label>
                    <div class="formControl3">Executive</div>
                </div>
            </div>
            <div class="row" style="margin-top:24px;">
                <div class="col-6">
                    <span class="nameLabelHeader">Manager Name</span>
                    <div class="formControl3">paul Sturman</div>
                </div>
                <div class="col-6">
                    <label class="nameLabelHeader">Manager Email</label>
                    <div class="formControl3">pSturman@njoy.com</div>
                </div>
            </div>
            <div class="checkbox-container1" style="padding-left:0px !important;">
                <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="term">
          <input type="checkbox" id="term" formControlName="terms" class="mdl-checkbox__input">
          <span class="mdl-checkbox__label">This employee has the permission to bill the company</span>
        </label>
            </div>
            <button class="btn btn-secondary"><span class="add">ADD & SEND INVITE</span></button>
            <div class="modal-form-button">

            </div>
        </form>
    </div>
</ng-container>

<ng-template #changeManager let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">
            Update Approver
        </h5>
        <button type="button" class="close" data-dismiss="modal" (click)="onApproveModelCancel()">
      <i class="material-icons" style="color:#fff;">close</i>
    </button>
    </div>
    <div *ngIf="this.showSpinner" class="approval_request_diaglog_bg_clickhandler" (click)="$event.stopPropagation();">
    </div>
    <div class="modal-body" style=" text-align: left !important;">
        <form [formGroup]="emailForm">
            <span class="heading">Approver's email</span>
            <div class="row" style="margin-top:24px;">
                <div class="col-12 col-md-5">
                    <label class="nameLabelHeader">Email</label>
                    <input type="text" class="inputFormBox" formControlName="emailUserName" placeholder="" (input)="emailUserNameChange($event.target.value)" />
                    <div *ngIf="emailForm.controls['email'].hasError('pattern') && (emailForm.controls['email'].touched || emailForm.controls['email'].dirty)" class="error">{{'login.pleaseenteravalidemail' | translate}}
                    </div>
                    <div *ngIf="emailForm.controls['emailUserName'].hasError('required') && (emailForm.controls['emailUserName'].touched || emailForm.controls['emailUserName'].dirty)" class="error">{{'login.thisfieldisrequired' | translate}}
                    </div>
                    <div *ngIf="emailForm.controls['domain'].hasError('required') && (emailForm.controls['emailUserName'].touched || emailForm.controls['emailUserName'].dirty)" class="error">{{'Please select a domain' | translate}}
                    </div>
                </div>
                <div class="col-1 d-none d-md-block" style="margin-top:25px !important; margin-left:15px !important;text-align:center !important;">
                    <span class="symbol">@</span>
                </div>
                <div class="col-xl-12 d-block d-md-none" style="">
                    <span class="symbol">@</span>
                </div>
                <div class="col-12 col-md-5">
                    <div id="managerUpdateInAddEditemployee">
                        <div class="inputFormBox">
                            <ng-select #domain dropdownPosition="bottom" [searchable]="false" [clearable]="false" formControlName="domain" [items]="domainOptions" bindLabel="value" bindValue="value" (change)="emailDomainChange($event)">
                                <span class="selectBox-remove"><span class="material-icons">clear</span></span>
                                <span class="fa fa-chevron-down"></span>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showToUpdateManagerDetail()" class="row" style="margin-top:24px;">
                <div class="col-12 col-md-6">
                    <span class="nameLabelHeader">First Name</span>
                    <!-- <div *ngIf = "isValidField('firstName')" class="formControl3">{{getValidFieldValue('firstName')}}</div> -->
                    <input type="text" class="inputFormBox" formControlName="firstName" [attr.disabled]="isDisable()" placeholder="First Name" />
                    <div *ngIf="emailForm.controls['firstName'].hasError('required') && (emailForm.controls['firstName'].touched || emailForm.controls['firstName'].dirty)" class="error">This field is required
                    </div>
                    <div *ngIf="emailForm.controls['firstName'].hasError('pattern') && (emailForm.controls['firstName'].touched || emailForm.controls['firstName'].dirty)" class="error">Please enter valid first name
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <span class="nameLabelHeader">Last Name</span>
                    <!-- <div *ngIf = "isValidField('lastName')" class="formControl3">{{getValidFieldValue('lastName')}}</div> -->
                    <input type="text" class="inputFormBox" formControlName="lastName" [attr.disabled]="isDisable()" placeholder="Last Name" />
                    <div *ngIf="emailForm.controls['lastName'].hasError('required') && (emailForm.controls['lastName'].touched || emailForm.controls['lastName'].dirty)" class="error">This field is required
                    </div>
                    <div *ngIf="emailForm.controls['lastName'].hasError('pattern') && (emailForm.controls['lastName'].touched || emailForm.controls['lastName'].dirty)" class="error">Please enter valid last name
                    </div>
                </div>
            </div>
            <button *ngIf="!showToUpdateManagerDetail()" class="btn btn-secondary" style="margin-top:25px !important;" (click)="checkManagerDetail()"><span class="add">NEXT</span></button>
            <button *ngIf="showToUpdateManagerDetail()" class="btn btn-secondary" style="margin-top:25px !important;" (click)="updateManager()"><span class="add">Update</span></button>
            <button class="btn btn-normal" (click)="onApproveModelCancel()"><span class="add1">GO BACK</span></button>
            <app-loader *ngIf="this.showSpinner" [spinnerStyle]="true"></app-loader>
            <div class="modal-form-button">

            </div>
        </form>
    </div>
</ng-template>
