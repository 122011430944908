<div id="searchInputModal">
  <ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'searchInputModal')" [hideDelay]="0"
    (onClose)="handleModalEvents('onClose', 'searchInputModal')"
    (onDismiss)="handleModalEvents('onDismiss', 'searchInputModal')" [closable]="false" #searchInputModal
    identifier="searchInputModal">
    <div class="modal-container flight-modal-container filter-modal1 modalAirportFilterInfo"
      (click)="$event.stopPropagation();">
      <div *ngIf="isMobile" class="modal-header" style="background-color: #fff !important;padding-top:10px !important;">
        <span class="fa fa-long-arrow-left" (click)="backToNormalSearch('searchInputModal')"
          style=" color:black !important;padding-right: 0px;font-size: 20px;padding-top: 2px;cursor: pointer;"
          aria-hidden="true"></span><input id="airportInputOnFullScreen" type="text" class="mobile-input"
          placeholder="{{this.selectFormControlname}}" (input)="searchAirport($event.target.value)"
          [(ngModel)]="this.searchService.airportSelect" />
      </div>
      <hr *ngIf="isMobile">
      <div class="modal-body" style="padding-right:0" >
        <div  *ngFor="let item of this.autoCompleteSourceItems;let i=index" 
          style="width: auto;min-height: 35px;display:flex"  class="airportItem tabindex{{i}}" tabindex="0"  (keydown)="handleKeyDown($event,i)">
          <div *ngIf="item.airports.length===1" class="airportCity1"   (click)="selectAirport(item.airports[0])"
            [ngStyle]="{'padding-top':this.autoCompleteSourceItems.length===1 ? '7px':'0px'}">
            <div style="width:100%;"><i *ngIf="item.airports.length ===1 && this.viewMode == 'tab1'" class="fa fa-plane" aria-hidden="true"
                style="font-size: 90%;padding-right: 10px;"></i>
                <i *ngIf="item.airports.length ===1 && this.viewMode == 'tab5'" class="fa  fa-train" aria-hidden="true"
                style="font-size: 80%;padding-right: 10px;"></i>{{item.airports[0].airport}}</div>
            <div style="font-size:14px;color:#5f6368;padding-left:22px;margin-bottom:7px;">
               <span *ngIf="this.viewMode == 'tab1'"> {{'airport.Airportin' | translate }} </span>
               <span *ngIf="this.viewMode == 'tab5'"> Station in </span>
                 {{item.city}}
            </div>
          </div>
          <span *ngIf="item.airports.length >1" id="multipleAirports{{i}}" class="card-div11" [ngClass]="{'active':i===0 }"><span
              onclick="openCardEdit1(this);" class="airportCity" ><i class="fa fa-map-marker" style="margin-right: 10px;"
                aria-hidden="true"></i>{{item.city}} <span class="edit-text">Edit</span><span class="edit-icon2"><i
                  class="fa fa-angle-down"></i></span></span>
            <div class="card-div-body" style="padding-left:30px !important;margin-top:10px!important;margin-bottom:0px;"
              [ngStyle]="{'display':i===0 ? 'block':'none'}" >
              <div *ngFor="let item1 of item.airports;let childindex=index" class="city-child airportItem tabindex{{i}}_{{childindex}}" tabindex="0"  >
                <span style="cursor: pointer;display:inline-block;min-height:35px;"  (click)="selectAirport(item1)"><i
                  *ngIf="this.viewMode=='tab1'"  class="fa fa-plane" aria-hidden="true"
                    style="font-size: 90%;padding-right: 10px;"></i>
                    <i  *ngIf="this.viewMode=='tab5'"   class="fa fa-train" aria-hidden="true"
                    style="font-size: 80%;padding-right: 10px;"></i>
                    {{item1.airport}}</span>
              </div>
            </div>
          </span>
        </div>
        <div *ngIf="this.autoCompleteSourceItems.length===0 && this.searchString.length > 0"
          style="padding-left:10px;padding-bottom: 10px;">
           {{'airport.Nomatchinglocationsfound' | translate }}
        </div>
      </div>
    </div>
  </ngx-smart-modal>
</div>