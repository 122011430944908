<app-super-header ></app-super-header>
<div class="{{njoySpecificBuild===true ? 'container1':'container'}}">
  <div class="row" style="margin-left: -15px !important; margin-right: -20px !important;width: auto !important;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="">
      <div class="header-inner" [ngStyle]="{'margin-top':!this.isMobile ? '10px':'49px'}">
        <div class="header-left">
          <div class="logo">
            <a routerLink="/login" routerLinkActive="true">
              <img class="map-image" src="assets/images/logo.png" />
            </a>
          </div>
        </div>
        <!-- <button *ngIf="!njoySpecificBuild" type="button" class="close" data-dismiss="modal" (click)="hideLoginModal()">
                    <i class="material-icons">close</i>
                </button>-->
      </div>

    </div>
    <div *ngIf="this.isMobile" class="row">
      <div class="col-12" style="width:100%;background: #8A27F7;">
        <div class="tab profileTab">
          <div class="tab-list top-strip21">
            <ul>
              <li class="tab-list-item active" rel="tab1"><a href="javascript:void(0);"
                  style="text-transform: none !important;">{{'login.Signin' | translate}}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 d-none d-md-block"></div>
    <div class="col-auto" style="width:100%;">
      <div class="{{ this.isMobile ? 'form-content login-container1':'form-content login-container'}}"
        style="padding-bottom:32px;">
        <h3 *ngIf="!this.isMobile" style="padding-bottom:5px;margin:0">{{'login.Signin' | translate}}</h3>
        <!--  <div *ngIf="!njoySpecificBuild" >{{'login.Or' | translate}}  <a class="linkstyle" (click)="goToSignUp(createAccountModal);"><span class="text-primary"style="font-size:16px;">{{'login.createanaccount' | translate}}</span></a></div>-->
        <br>
        <div *ngIf="!ssoApiResponsecame">
            <form   [formGroup]="loginFirstForm" (keyup.enter)="getPiSSologinStatu()">
            <div class="input-box input-box-with-icon">
                <input *ngIf="!njoySpecificBuild" type="email" maxlength="50" name="email" style="padding-right: 5px !important" formControlName="email"
                  placeholder="{{'dashboard.WorkEmail' | translate}}"  />
                <input *ngIf="njoySpecificBuild" type="email" name="email" maxlength="50" style="padding-right: 5px !important"  formControlName="email"
                  placeholder="{{'dashboard.WorkEmail' | translate}}" />
                <span class="input-box-icon-container">
                  <img class="input-box-icon" src="assets/images/mail.png" alt="" />
                </span>
                <div
                  *ngIf="this.loginFirstForm.controls['email'].hasError('pattern') && (this.loginFirstForm.controls['email'].touched || this.loginFirstForm.controls['email'].dirty)"
                  class="error">{{'login.pleaseenteravalidemail' | translate}}
                </div>
                <div
                  *ngIf="this.loginFirstForm.controls['email'].hasError('required') && (this.loginFirstForm.controls['email'].touched || this.loginFirstForm.controls['email'].dirty)"
                  class="error">{{'login.thisfieldisrequired' | translate}}
                </div>
              </div>
            <div *ngIf="this.dataConsentRequired === 'true' && this.dataConsentAlreadyAccepted !== 'true' " class="checkbox-container1" style="margin-top:0px !important;">
                <label class="agreePolicy" for="agreePolicy">
                  <input type="checkbox" id="agreePolicy" value="agreePolicy"
                    (change)="checkBoxChange($event.target.checked)" [checked]="agreePolicy" class="mdl-checkbox__input">
                  <span class="mdl-checkbox__label">{{'reset.IagreetodataprocessingintheUnitedStates' | translate}} 
                    </span>
                </label>
                <div *ngIf="showError" style="margin-top: 10px; float: left; width: 51%;" class="error">
                  {{'reset.Pleaseaccepttheterms' | translate}}
                </div>
              </div>
              </form>
              <div class="button-container">
                  <button *ngIf="!this.loginService.isRequestInProgress" type="button"  (click)="getPiSSologinStatu()"
                    class="button button-primary">{{'dashboard.Continue' | translate}}</button>
                  <button *ngIf="this.loginService.isRequestInProgress" type="button" class="button button-primary"><span
                      class="loaderClass">{{'profilePreference.PleaseWait' | translate}}<loader-dots class="loaderAlign">
                      </loader-dots></span></button>
                      </div>
        </div>
        <form  *ngIf="ssoApiResponsecame" [formGroup]="loginForm" (keyup.enter)="signInWithGallop()">
          <div class="input-box input-box-with-icon">
            <input [attr.disabled]="true" maxlength="50"  style="border: 1px solid gray !important;color:rgb(174,174,174);" *ngIf="!njoySpecificBuild" type="email" formControlName="email"
              placeholder="{{'login.Email' | translate}}" class="inputEmail" /><span *ngIf="!njoySpecificBuild" class="changeEmail" (click)="changeEmail()"> {{'dashboard.Change' | translate}}</span>
            <input [attr.disabled]="true" maxlength="50" *ngIf="njoySpecificBuild"  style="border: 1px solid gray !important;color:rgb(174,174,174);"type="email" formControlName="email"
              placeholder="{{'login.CorpEmail' | translate}}" class="inputEmail" /><span *ngIf="njoySpecificBuild" class="changeEmail" (click)="changeEmail()"> {{'dashboard.Change' | translate}}</span>
            <span class="input-box-icon-container">
              <img class="input-box-icon" src="assets/images/mail.png" alt="" />
            </span>
            <div
              *ngIf="loginForm.controls['email'].hasError('pattern') && (loginForm.controls['email'].touched || loginForm.controls['email'].dirty)"
              class="error">{{'login.pleaseenteravalidemail' | translate}}
            </div>
            <div
              *ngIf="loginForm.controls['email'].hasError('required') && (loginForm.controls['email'].touched || loginForm.controls['email'].dirty)"
              class="error">{{'login.thisfieldisrequired' | translate}}
            </div>
          </div>
          <div class="input-box input-box-with-icon">
            <input type="password" id="login_password" maxlength="20" formControlName="password"
              placeholder="{{'login.Password' | translate}}" />
            <span class="input-box-icon-container">
              <img class="input-box-icon" src="assets/images/lock.png" alt="" />
            </span>
            <div
              *ngIf="loginForm.controls['password'].hasError('required') && (loginForm.controls['password'].touched || loginForm.controls['password'].dirty)"
              class="error">{{'login.thisfieldisrequired' | translate}}
            </div>
          </div>
          <div *ngIf="this.isMobile" class="redirect-div">
              <div><a (click)="goToRequestResetPassword(createPasswordModal)"  style="
                position: relative;
                top: -10px;"><span class="text-primary"
                    style="color:#8A27F7 !important;">{{'login.Forgotpassword' | translate}}</span></a></div>
            </div>
          <div *ngIf="!this.isMobile" class="redirect-div">
              <div style="width: 100%;float: left;"><a (click)="goToRequestResetPassword(createPasswordModal)" [ngStyle]="{'width' : getSelectedLanguage() === 'it' ? '68%':'60%'}" style="
                display: block;
                position: relative;
                top: -5px;
                margin-left: 90px;"><span class="text-primary"
                    style="color:#8A27F7 !important;width: 60%; display: block;">{{'login.Forgotpassword' | translate}}</span></a></div>
            </div>
         
          <div class="button-container">
            <button *ngIf="!this.loginService.isRequestInProgress" type="button" (click)="signInWithGallop()"
              class="button button-primary">{{'login.Signin' | translate}}</button>
            <button *ngIf="this.loginService.isRequestInProgress" type="button" class="button button-primary"><span
                class="loaderClass">{{'profilePreference.PleaseWait' | translate}}<loader-dots class="loaderAlign">
                </loader-dots></span></button>
            <button *ngIf="socialLoginsSupported"  type="button"
              class="button button-google button-white button-with-static-icon" (click)="handleGoogleSignInCLick(azureOpen)">
              <img src="assets/images/google-icon.png" alt="" />
              {{'login.SigninwithGoogle' | translate}}
            </button>
            <button *ngIf="socialLoginsSupported" type="button" class="button button-google button-white button-with-static-icon"
              (click)="handleMSALClick(azureOpen)">
              <img src="assets/images/microsoft-logo.png" alt="" />
               {{'login.SigninwithMicrosoft' | translate}}
            </button>
            <button  type="button"  *ngIf="this.ssoPolicy ==='optional' && socialLoginsSupported" class="button button-google button-white button-with-static-icon"
            (click)="signWithSSo()">
           
             {{'dashboard.SigninwithSSo' | translate}}
          </button>
          </div>
         
         
        </form>
      </div>
      <div class="col-4 d-none d-md-block"></div>
    </div>
  </div>
</div>

<ng-template #createAccountModal let-modal>
  <div class="modal-header" style="background-color: #fcfcfc;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="header1-inner">
          <div class="header-left">
            <div class="logo">
              <a href="javascript:void(0)">
                <img src="assets/images/logo.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="form-header" style="background-color: #fcfcfc;">
    <h2>{{'login.SignUp' | translate}}</h2>
  </div>
  <div class="form-data" style="background-color: #fcfcfc;padding-bottom:32px;padding-bottom:32px;">
    <form [formGroup]="signUpForm">
      <input type="hidden" id="profileImageUrl" name="profileImageUrl" />
      <input type="hidden" id="googleAccessToken" name="googleAccessToken" />
      <label></label>
      <div class="input-box input-box-with-icon">
        <input type="text" formControlName="firstName" placeholder="{{'login.Firstname' | translate}}" />
        <span class="input-box-icon-container">
          <img class="input-box-icon" src="assets/images/user.png" alt="" />
        </span>
        <div
          *ngIf="signUpForm.controls['firstName'].hasError('required') && (signUpForm.controls['firstName'].touched || signUpForm.controls['firstName'].dirty)"
          class="error">{{'login.thisfieldisrequired' | translate}}
        </div>
        <div
          *ngIf="signUpForm.controls['firstName'].hasError('pattern') && (signUpForm.controls['firstName'].touched || signUpForm.controls['firstName'].dirty)"
          class="error">{{'login.pleaseentervalidfirstname' | translate}}
        </div>
      </div>
      <div class="input-box input-box-with-icon">
        <input type="text" formControlName="lastName" placeholder="{{'login.Lastname' | translate}}" />
        <span class="input-box-icon-container">
          <img class="input-box-icon" src="assets/images/user.png" alt="" />
        </span>
        <div
          *ngIf="signUpForm.controls['lastName'].hasError('required') && (signUpForm.controls['lastName'].touched || signUpForm.controls['lastName'].dirty)"
          class="error">{{'login.thisfieldisrequired' | translate}}
        </div>
        <div
          *ngIf="signUpForm.controls['lastName'].hasError('pattern') && (signUpForm.controls['lastName'].touched || signUpForm.controls['lastName'].dirty)"
          class="error">{{'login.pleaseentervalidlastname' | translate}}
        </div>
      </div>
      <div class="input-box input-box-with-icon">
        <input type="text" formControlName="email" placeholder="{{'login.Email' | translate}}" />
        <span class="input-box-icon-container">
          <img class="input-box-icon" src="assets/images/mail.png" alt="" />
        </span>
        <div
          *ngIf="signUpForm.controls['email'].hasError('pattern') && (signUpForm.controls['email'].touched || signUpForm.controls['email'].dirty)"
          class="error">{{'login.pleaseenteravalidemail' | translate}}
        </div>
        <div
          *ngIf="signUpForm.controls['email'].hasError('required') && (signUpForm.controls['email'].touched || signUpForm.controls['email'].dirty)"
          class="error">{{'login.thisfieldisrequired' | translate}}
        </div>
      </div>
      <div class="input-box input-box-with-icon">
        <input type="password" formControlName="password" placeholder="{{'login.Password' | translate}}" />
        <span class="input-box-icon-container">
          <img class="input-box-icon" src="assets/images/lock.png" alt="" />
        </span>
        <div
          *ngIf="signUpForm.controls['password'].hasError('required') && (signUpForm.controls['password'].touched || signUpForm.controls['password'].dirty)"
          class="error">{{'login.thisfieldisrequired' | translate}}
        </div>
      </div>
      <div class="checkbox-container">
        <label class="mdl-checkbox" for="terms">
          <input type="checkbox" formControlName="terms" name="terms1" class="mdl-checkbox__box-outline ">
          <span class="mdl-checkbox__label">{{'login.Iagreetothe' | translate}}
            <a class="terms" href="{{getTerms()}}" target="_blank">{{'login.Termsofuse' | translate}}</a>
            {{'login.and' | translate}} <a class="terms" href="{{getPolicy()}}" target="_blank">{{'login.Privacypolicy'
              | translate}}</a></span>
          <div
            *ngIf="signUpForm.controls['terms'].hasError('required') && (signUpForm.controls['terms'].touched || signUpForm.controls['password'].touched || signUpForm.controls['password'].dirty)"
            class="error">{{'login.Pleaseaccepttheterms' | translate}}
          </div>
        </label>
      </div>
      <div class="button-container">
        <button type="button" (click)="signUpWithGallop();" class="button button-primary">{{'login.SignUp' |
          translate}}</button>
        <button type="button" class="button button-google button-white button-with-static-icon"
          (click)="handleGoogleSignUpCLick()">
          <img src="assets/images/google-icon.png" alt="" />
          {{'login.SignUpwithGoogle' | translate}}
        </button>
        <!-- <button type="button" class="button button-google button-white button-with-static-icon" onclick="handleOffice365SignUpCLick()">
                          <img src="assets/images/office365.svg" alt="" />
                          Sign Up with offfice365
                      </button> -->
      </div>
      <div class="redirect">
        <div>{{'login.Alreadyhaveanaccount' | translate}} <a (click)="handleClickOnSignInLink()"><span
              class="text-primary">{{'login.Signin' | translate}}</span></a></div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #azureOpen let-modal>
  <div class="modal-header1">
    <h5 class="modal-title">
        {{'login.Userlogin' |  translate}}
    </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel1()">
      <i class="material-icons" style="color:#fff;">close</i>
    </button>
  </div>
  <div class="modal-body1">
    {{this.errormsg}}
    <div class="" *ngIf="this.loaderShow" style="margin-top: 30px;">
      <app-loader [spinnerStyle]="true"></app-loader>
    </div>
    <div class="modal-form-button" style="text-align: center;">

    </div>
  </div>
</ng-template>
<ng-template #createPasswordModal let-modal>
  <div style="box-shadow:0 15px 15px 2px rgba(0,0,0,.12)">
    <div class="modal-header" style="background-color: #fcfcfc;">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="header1-inner">
            <div class="header-left">
              <div class="logo">
                <a href="javascript:void(0)">
                  <img src="assets/images/logo.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="form-content login-container" style="background-color: #fcfcfc;padding-bottom:32px;">
      <form [formGroup]="addEmailForPassword" (keyup.enter)="requestResetPasswordLink()">
        <h3>{{'login.Forgotpassword' | translate}}</h3>
        <span style="padding-bottom:10px;">{{'login.Enteryouremailaddresstoresetyourpassword' | translate}}</span>
        <div class="input-box input-box-with-icon">
          <input type="email" formControlName="email" placeholder="{{'login.Email' | translate}}" />
          <span class="input-box-icon1-container">
            <img class="input-box-icon" src="assets/images/mail.png" alt="" />
          </span>
          <div
            *ngIf="addEmailForPassword.controls['email'].hasError('pattern') && (addEmailForPassword.controls['email'].touched || addEmailForPassword.controls['email'].dirty)"
            class="error">{{'login.pleaseenteravalidemail' | translate}}
          </div>
          <div
            *ngIf="addEmailForPassword.controls['email'].hasError('required') && (addEmailForPassword.controls['email'].touched || addEmailForPassword.controls['email'].dirty)"
            class="error">{{'login.thisfieldisrequired' | translate}}
          </div>
        </div>
        <div class="button-container">
          <button *ngIf="!this.loginService.isRequestInProgress" type="button" style="margin-bottom:20px !important;"
            (click)="requestResetPasswordLink()" class="button button-primary">{{'login.Submit' | translate}}</button>
          <button *ngIf="this.loginService.isRequestInProgress" type="button" style="margin-bottom:20px !important;"
            class="button button-primary"><span class="loaderClass">{{'profilePreference.PleaseWait' | translate}}
              <loader-dots class="loaderAlign"></loader-dots></span></button>
        </div>
        <div class="redirect">
          <div><a class="terms" (click)="goToSignIn()"><span class="text-primary">{{'login.Signin' |
                translate}}</span></a></div>
        </div>
      </form>
    </div>
  </div>
</ng-template>