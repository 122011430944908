<email-header *ngIf="!this.showAllBookings"></email-header>
<div class="main-wrapper">
	<div class="content"  [ngClass]="isMobile ? 'tab' : ' '"  >
        <div class="container" [ngClass]="{'':showAllBookings===true, 'container': showAllBookings===false}">

			<div class="tab" *ngIf="!detailView">
				<div class="tab-list top-strip" *ngIf=" !this.showAllBookings">

					<ul>
                        <li class="tab-list-item active" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="switchBookingListTab('tab1')"><a href="javascript:void(0)" attr.data-track="TripsTab" attr.data-params="tab=Upcoming">
								{{'bookingHistory.UpcomingTrips' |
								translate}} </a></li>
                        <li class="tab-list-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="switchBookingListTab('tab2')"><a href="javascript:void(0)" attr.data-track="TripsTab" attr.data-params="tab=Past"> {{'bookingHistory.PastTrips' |
								translate}} </a></li>
					</ul>

				</div>
				<div class="tab-content" [ngSwitch]="viewMode" [ngStyle]="{'box-shadow' : this.showAllBookings ? 'none':'0 0 10px 0 rgba(0, 0, 0, 0.14)'}" >
					<div id="tab1" class="tab-content-item" *ngSwitchCase="'tab1'" [ngStyle]="applyHeightAndWidth()" style="position: relative;">
							<app-loader *ngIf="(this.appLoader || this.updateLoader)" style="width: 100%;" [spinnerStyle]="true"></app-loader>
							<div  *ngIf="this.showAllBookings && !this.appLoader" class="filter-row" style="margin-top: 35px;">
									<div class="col-auto" style="height: 40px;width: 253px;top:1vh">
									
								   <div class="filter custom-selectbox" style="top:auto !important;">
									<div class="input-box" style="display:contents">
										<span (click)="openNgxModal('daterangeSelection',chartDatePicker);chartDatePicker.show()"  *ngIf="viewMode11 == 'tab21'" class="input-icon material-icons" style="position: absolute;margin-left: 5px;margin-top: 4px;">event</span>
										<input class="input" id="daterangeSelection" style="min-width:210px !important;"
										  (click)="openNgxModal('daterangeSelection',chartDatePicker);chartDatePicker.show()"
										  readonly="">
										  <span *ngIf="viewMode11 == 'tab22'" class="dateShow" style="left: 5% !important;"
										  (click)="openNgxModal('daterangeSelection',chartDatePicker);chartDatePicker.show()">
										  {{getdateType() | translate}}
										</span>
										  <span *ngIf="viewMode11 == 'tab21' && !this.searchService.comingBackFromDetailsPAge"  class="dateShow" 
										  (click)="openNgxModal('daterangeSelection',chartDatePicker);chartDatePicker.show()">{{daterangepickerModel[0]
										  | date : 'dd MMM yyyy'}} {{ 'itinerary.to' | translate}} {{daterangepickerModel[1] | date: 'dd MMM yyyy'}}</span>
										  <span *ngIf="viewMode11 == 'tab21' && this.searchService.comingBackFromDetailsPAge"  class="dateShow" 
										  (click)="openNgxModal('daterangeSelection',chartDatePicker);chartDatePicker.show()">{{this.searchService.daterangepickerModel[0]
										  | date : 'dd MMM yyyy'}} {{ 'itinerary.to' | translate}} {{this.searchService.daterangepickerModel[1] | date: 'dd MMM yyyy'}}</span>
										 
									  </div>
									  <svg class="down-arrow" (click)="openNgxModal('daterangeSelection',chartDatePicker);chartDatePicker.show()"  *ngIf="viewMode11 == 'tab22'" style="" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
										  d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
										  fill="#8936F3" />
									  </svg> 
										<ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'daterangeSelection')" [hideDelay]="0" (onClose)="handleModalEvents('onClose', 'daterangeSelection')" (onDismiss)="handleModalEvents('onDismiss', ' daterangeSelection')" [closable]="false" #daterangeSelection
											identifier="daterangeSelection">
											<div class="modal-container flight-modal-container filter-modal1 modalAirportFilterInfo" [ngStyle]="changeStyle()" (click)="$event.stopPropagation();">
												<div class="modal-header" style="background-color: #fff !important;padding-top:10px !important;overflow:visible;">
												<div class="tab-list top-strip" style="background: #fff !important;">
						
													<ul style="overflow: visible !important;">
															<li class="{{ viewMode11 == 'tab22' ? 'select':'unselect'}}" [class.active]="viewMode11 == 'tab22'" (click)="presetsTabClicked();" rel="tab22" style="margin-left:0px !important;margin-right: 0px!important;">{{ 'report.Presets' | translate }}
																<div *ngIf="viewMode11 == 'tab22'" class="underline"></div>
													</li>
															<li *ngIf="true" class="{{ viewMode11 == 'tab21' ? 'select':'unselect'}}"  rel="tab21" (click)="customTabClicked();chartDatePicker.show();">{{ 'report.Custom' | translate }}
																<div *ngIf="viewMode11 == 'tab21'" class="underline"></div>
													</li>
													</ul>
												</div>
												<input class="input"    [hidden]="viewMode11=='tab22'"
												style="width:100% !important;position: relative;top:8px; visibility: hidden;" readonly=""><span
												class="dateShow1" [hidden]="viewMode11=='tab22'"
												style="top:23px !important; visibility: hidden;">{{daterangepickerModel[0] | date : 'dd MMM yyyy'}} {{ 'itinerary.to' | translate}}
												{{daterangepickerModel[1] | date: 'dd MMM yyyy'}}</span>
												</div>
												<hr>
												
												<div class="modal-body" style="" [ngStyle]="changeStyle1()">
												<div [hidden]="viewMode11=='tab21'">
														<div *ngFor="let item of this.DATE_OPTIONS;let i=index" style="width: auto;min-height: 15px;display:flex;line-height: 3em;min-height: 3em;">
															<div style="font-size:14px;color:#5f6368;padding-left:22px;margin-bottom:7px;cursor: pointer;" (click)="showDateChanged(item.id,true)">{{item.name | translate}}</div>
													</div>
												</div>
												<div [hidden]="viewMode11=='tab22'" style="position: relative;top: -60px;">
													<input class="input" bsDaterangepicker #chartDatePicker="bsDaterangepicker"
													  style="width:100% !important;visibility: hidden;" [(ngModel)]="daterangepickerModel"
													  (bsValueChange)="setStartDate($event)" [outsideClick]="true" 
													  [bsConfig]="{ showWeekNumbers: false , showPreviousMonth: true }"
													  (onShown)="onShowPicker($event, chartDatePicker)" (onHidden)="onHidePicker()" container=""
													  readonly />
												  </div>
												</div>
											</div>
										</ngx-smart-modal>
									  </div>
									</div>
									<div *ngIf="this.statusOptions.length >0"  class="col-auto" style="height: 40px;">
                                <div id="statusDropdown" class="statusDrop">
													<div class="input" style="background: #F7F7F9 !important;">
                                        <ng-select #statusDropdown appendTo="#statusDropdown" [disabled]="this.appLoader" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [(ngModel)]="statusValue" [items]="statusOptions" bindLabel="value" bindValue="id" (change)="showStatusChanged($event.id)">
														<ng-template ng-option-tmp let-option="item">
															<span class="option-title">{{option.name |
																translate}}</span>
														   
														</ng-template>
														<ng-template ng-label-tmp let-item="item">
															<span style="text-transform: capitalize;"> {{item.name | translate}}</span>
														  </ng-template>
													  </ng-select>
													  <div class="select-overlay"></div>
													</div>
                                    <svg class="down-arrow" (click)="statusDropdown.toggle()" style="" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
													  <path fill-rule="evenodd" clip-rule="evenodd"
														d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
														fill="#8936F3" />
													</svg>
												  </div>
									   </div>
									   <div *ngIf="this.originalBookinngList.length >0" class="col-auto" style="height: 40px;">
											<div id="bookingDropdown" style="margin-top: 5px;margin-left: 0px;width: 253px;">
													<div class="input" style="background: #F7F7F9 !important;">
                                        <ng-select #bookingDropdown appendTo="#bookingDropdown" [disabled]="this.appLoader" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [(ngModel)]="bookingValue" [items]="bookingTypeOptions" bindLabel="value" bindValue="id" (change)="showBookingTypeChanged($event.id)">
														<ng-template ng-option-tmp let-option="item">
															<span class="option-title">{{option.value |
																translate}}</span>
														   
														</ng-template>
														<ng-template ng-label-tmp let-item="item">
															<span style="text-transform: capitalize;"> {{item.value | translate}}</span>
														  </ng-template>
													  </ng-select>
													  <div class="select-overlay"></div>
													</div>
                                    <svg class="down-arrow" (click)="bookingDropdown.toggle()" style="" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
													  <path fill-rule="evenodd" clip-rule="evenodd"
														d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
														fill="#8936F3" />
													</svg>
												  </div>
									   </div>
									  
								</div>
								<div  *ngIf=" !this.appLoader && this.showAllBookings" class="filter-row trip-search-div" style="margin-top: 30px;">
									<div class="trips-count">Showing {{this.flightList.length}} Trips</div>
									<div *ngIf="this.originalBookinngList.length >0" class="col-auto">
										<input [(ngModel)]="empNameSearchValue" type='text' class="search-input search-box" placeholder="{{ 'activeTraveler.SearchbynameConfirmation' | translate}}" (input)="filterData()" />
									</div>
								</div>
								<div class="empty-container" *ngIf="this.showAllBookings && this.flightList.length ===0 && !this.appLoader">
										<div class="empty-img">
											<img src="assets/images/no-flight-found.png" />
										</div>
										<div class="empty-text">{{'bookingHistory.NoBookings'	| translate}}</div>
										<div class="empty-button">
											<!--         <button class="btn primary-button">{{'bookingHistory.BOOKNOW' | translate}}</button>-->
										</div>
									</div>
						<div *ngIf=" !this.showAllBookings" class="" style="background: #fff !important;display: flex;">
                       
							<ul style="display: flex;margin-top:10px;margin-bottom: 10px;">
                                <li class="{{ viewMode1 == 'tab11' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab11'" (click)="showMYTrip('myself')">
									{{'bookingHistory.BookedForMyself' | translate}}
								<li>
                                        <li class="{{ viewMode1 == 'tab12' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab12'" (click)="showMYTrip('others')">{{'bookingHistory.BookedForOthers' | translate}}
								</li>
							</ul>

						</div>
						<div  *ngIf=" !this.appLoader && !this.showAllBookings" class="filter-row trip-search-div" style="margin-top: 30px;">
							<div class="trips-count">Showing {{this.flightList.length}} Trips</div>
							<div *ngIf="this.originalBookinngList.length >0" class="col-auto">
								<input [(ngModel)]="empNameSearchValue" type='text' class="search-input search-box" placeholder="{{ 'activeTraveler.SearchbynameConfirmation' | translate}}" (input)="filterData()" />
							</div>
						</div>
						<div class="booking-container" *ngIf="!isEmpty">
							<!-- <div class="trip-search-div">
								<div class="trips-count">Showing {{this.flightList.length}} Trips</div>
								<div class="search-input-div">
									<input type="text" [(ngModel)]="empNameSearchValue" placeholder="Search Trips" class="search-input" placeholder="{{ 'activeTraveler.SearchbynameConfirmation' | translate}}" (input)="filterData()">
								</div>
							</div> -->
                            <div *uiScroll="let data of flightsDatasource;let i = index" [style.minHeight]="'122px'">
                                <!-- <div *ngFor="let flight of flightList;let i=index"> -->
                                <div class="result-card-box" [style.minHeight]="'106px'" >
										<div *ngFor=" let flight of data.data">
												<div *ngIf="flight.type=='flight' &&  showBookedOptionInTripList(flight,data.data)">
													<div [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'upcoming',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
														<div class="result-card-box-inner" [ngStyle]="{'height': 'auto'}" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
															<div class="deviding-container">
																<div class="booking-details">
																	<div class="booking-details-inner">
																		<div class="booking-img">
																			<img src="assets/images/flight-circle.png" />
																		</div>
																		<div class="booking-text">
																			<p style="margin-right: 2px;">{{'ngOption.Depart' | translate}}</p>
																			<p class="font-bold">
																				{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts, 'EE MMM d, yyyy')}}
																			</p>
																		</div>
																	</div>
																</div>
																<div class="show-destination"  *ngIf="!showSecondLegOrNot(flight.option.flight_option)">
																	<div>
																		<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[0].from}}</p>
																	</div>
																	<span class="destination-devider">-</span>
																	<div>
																		<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to}}</p>
																	</div>
																</div>
																<ng-container *ngIf="showSecondLegOrNot(flight.option.flight_option)" >
																<div  class="show-destination"  >
																	<ul  class="list-group"  >
																		<li     class="font-bold primary-text " *ngFor="let leg of flight.option.flight_option.legs">
																		 {{leg.hops[0].from}} 		
																		 <span class="destination-devider" >-</span>
																		 {{leg.hops[leg.hops.length-1].to}}
																		</li>
																	</ul>
																</div>
																
															</ng-container>
															</div>
															<div class="deviding-container">
																<div class="booking-description">
																	<p class="font-bold1 primary-text">{{getAirlineFullName(flight.option.flight_option.legs[0].hops[0].carrier)}}</p>
																</div>
																<div class="flight-timing">
																	<div>{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts,'h:mm a')}}</div>
																	<span>-</span>
																	<div *ngIf="!showSecondLegOrNot(flight.option.flight_option)">{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[getLastIndex(flight.option.flight_option.legs[0].hops)].ends,'h:mm a')}}</div>
																	<div  *ngIf="showSecondLegOrNot(flight.option.flight_option)" >{{ getDisplayDateTimeForFlights(flight.option.flight_option.legs[flight.option.flight_option.legs.length-1].hops[flight.option.flight_option.legs[flight.option.flight_option.legs.length-1].hops.length-1].ends,'h:mm a')}}</div>
																</div>
																<div class="origin-destination1">
																	<div [style.color]="getColorByBookingStatus(getUserBookingStatus(flight.tripStatus,flight.type, flight))" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div *ngIf="flight.type =='hotel' &&  showBookedOptionInTripList(flight,data.data)">
													<div [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'upcoming',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
														<div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
															<div class="deviding-container">
																<div class="booking-details">
																	<div class="booking-details-inner">
																		<div class="booking-img">
																			<img src="assets/images/hotel-circle.png" />
																		</div>
																		<div class="booking-text">
																			<p style="margin-right: 2px;">{{'bookingHistory.CheckIn' | translate}}</p>
																			<p class="font-bold">{{getDisplayDate(flight.option.hotel_option.checkInDate, 'EE MMM d, yyyy')}}</p>
																			<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
																		</div>
																	</div>
																</div>
																<div class="origin-destination hotel">
																	<div class="destination-hotel">
																		<p class="font-bold1 primary-text">{{flight.option.hotel_option.hotelRooms[0].hotelRoomName}}</p>
																		<span style="white-space: nowrap;">{{flight.option.hotel_option.stay}}-{{flight.option.hotel_option.stay
																			> 1 ? ('bookingHistory.Nights' | translate)
																			:('bookingHistory.Night' | translate)}}</span>
																	</div>
																</div>
															</div>
															<div class="deviding-container">
																<div class="booking-description">
																	<p class="font-bold1 primary-text">{{flight.option.hotel_option.hotelName}}</p>
																</div>	
																<div class="flight-timing">
																	<p>{{flight.option.hotel_option.address}}</p>
																</div>
																<div class="origin-destination1">
																	<div [style.color]="getColorByBookingStatus(getUserBookingStatus(flight.tripStatus,flight.type, flight))" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
																</div>
															</div>
														</div>
													</div>	
												</div>
												<div *ngIf="flight.type =='cars' &&  showBookedOptionInTripList(flight,data.data)">
													<div [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'upcoming',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
														<div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
															<div class="deviding-container">
																<div class="booking-details">
																	<div class="booking-details-inner">
																		<div class="booking-img">
																			<img src="assets/images/car-circle.png" />
																		</div>
																		<div class="booking-text">
																			<p style="margin-right: 2px;">{{'bookingHistory.Pick-update'| translate}} </p>
																			<p *ngIf="flight.option.car_option.pickUpDate!==null" class="font-bold">
																				{{getDisplayDateTimeForFlights(flight.option.car_option.pickUpDate, 'EE MMM d, yyyy')}}</p>
																			<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
																		</div>
																	</div>
																</div>
																<div class="origin-destination hotel">
																	<div class="destination-hotel">
																		<p class="font-bold1 primary-text">
																			{{flight.option.car_option.description}}</p>
																		<p>{{flight.option.car_option.numberOfDay}}-{{flight.option.car_option.numberOfDay
																			> 1 ? ('bookingHistory.Days'| translate):('bookingHistory.Day'| translate) }}</p>
																	</div>
																	<div class="hotel-nights-count">
																		<span class="font-bold">{{flight.option.partnerName}}</span>
																	</div>
					
																</div>
															</div>
															<div class="deviding-container">
																<div class="booking-description">
																	<p class="font-bold1 primary-text">{{flight.option.car_option.partnerName}}</p>
																</div>
																<div class="flight-timing">
																	<p>{{flight.option.car_option.pickUpLocation}}</p>
																</div>
																<div class="origin-destination1">
																	<div  [style.color]="getColorByBookingStatus(getUserBookingStatus(flight.tripStatus,flight.type, flight))" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
										</div>
									<div class="view-details-div">
										<div class="inner-view-details-div">
											<div>
												<span class="font-bold" style="color: #000;font-size: 16px;font-style: normal;font-weight: 700;margin-right: 5px;" >Trip:</span>  
												<span style="color: #000;font-size: 14px;font-style: normal;font-weight: 400;">{{findTrip(data.data[0])}}</span>
											</div>
											<div *ngIf="getAllTrevelers(data.data[0]) ">
												<span class="font-bold" style="color: #000;font-size: 16px;font-style: normal;font-weight: 700;margin-right: 5px;">Traveler:</span> 
												<span style="color: #000;font-size: 14px;font-style: normal;font-weight: 400;">{{getAllTrevelers(data.data[0])}}</span>
											</div>
										</div>
										<div>
											<div class="booking-view-button">
													<a [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'upcoming',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
														<button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(data.data[0],false)">{{'bookingHistory.ViewDetail'
													| translate}}</button>
													</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="empty-container" *ngIf="!this.showAllBookings && isEmpty && this.viewMode==='tab1'">
							<div class="empty-img">
								<img src="assets/images/no-flight-found.png" />
							</div>
                            <div class="empty-text">{{'bookingHistory.Youdonthaveanyupcomingtrips' | translate}}</div>
							<div class="empty-button">
								<!--         <button class="btn primary-button">{{'bookingHistory.BOOKNOW' | translate}}</button>-->
							</div>
						</div>
					</div>
					<div id="tab2" class="tab-content-item" *ngSwitchCase="'tab2'">
						<app-loader *ngIf="(this.appLoader || this.updateLoader)" style="position: relative;top: 20px;bottom: 10px;" [spinnerStyle]="true"></app-loader>
						<div class="" style="background: #fff !important;display: flex;">

							<ul style="display: flex;margin-top:10px;margin-bottom: 10px;">
                                <li class="{{ viewMode1 == 'tab11' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab11'" (click)="showMYTrip1('myself')">{{'bookingHistory.BookedForMyself' | translate}}
								<li>
                                        <li class="{{ viewMode1 == 'tab12' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab12'" (click)="showMYTrip1('others')">{{'bookingHistory.BookedForOthers' | translate}}</li>
							</ul>

						</div>
						<div  *ngIf=" !this.appLoader" class="filter-row trip-search-div" style="margin-top: 30px;">
							<div class="trips-count">Showing {{this.flightList2.length}} Trips</div>
							<div *ngIf="this.originalBookinngList.length >0" class="col-auto">
								<input [(ngModel)]="empNameSearchValue" type='text' class="search-input search-box" placeholder="{{ 'activeTraveler.SearchbynameConfirmation' | translate}}" (input)="filterData()" />
							</div>
						</div>
						<div class="booking-container" *ngIf="!isEmpty2">
							<div  *ngIf="this.showAllBookings && !this.appLoader" class="filter-row trip-search-div" style="margin-top: 30px;">
								<div class="trips-count">Showing {{this.flightList.length}} Trips</div>
								<div *ngIf="this.originalBookinngList.length >0" class="col-auto">
									<input [(ngModel)]="empNameSearchValue" type='text' class="search-input search-box" placeholder="{{ 'activeTraveler.SearchbynameConfirmation' | translate}}" (input)="filterData()" />
								</div>
								  
						</div>
							
							<div *uiScroll="let data of flightsDatasourceForpastTrips;let i = index" [style.minHeight]="'122px'">
								<div class="result-card-box" [style.minHeight]="'106px'" >
										<div *ngFor=" let flight of data.data" >
												<div *ngIf="flight.type=='flight' && showBookedOptionInTripList(flight,data.data)">
													<div [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'past',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
														<div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(data.data[0],false)">
															<div class="deviding-container">
																<div class="booking-details">
																	<div class="booking-details-inner">
																		<div class="booking-img">
																			<img src="assets/images/flight-circle.png" />
																		</div>
																		<div class="booking-text">
																			<p>Depart</p>
																			<p class="font-bold">
																				{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts, 'EE MMM d, yyyy')}}
																			</p>
																		</div>
																	</div>
																</div>
																<div class="show-destination">
																	<div>
																		<p class="font-bold1 primary-text">{{flight.option.flight_option.legs[0].hops[0].from}}</p>
																	</div>
																	<span class="destination-devider">-</span>
																	<div>
																		<p class="font-bold1 primary-text">{{flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to}}</p>
																	</div>
																</div>
															</div>
															<div class="deviding-container">
																<div class="booking-description">
																	<p class="font-bold1 primary-text">{{getAirlineFullName(flight.option.flight_option.legs[0].hops[0].carrier)}}</p>
																</div>
																<div class="flight-timing">
																	<div>{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts,'h:mm a')}}</div>
																	<span>-</span>
																	<div>{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[getLastIndex(flight.option.flight_option.legs[0].hops)].ends,'h:mm a')}}</div>
																</div>
																<div class="origin-destination1">
																	<div [style.color]="getColorByBookingStatus(getUserBookingStatus(flight.tripStatus,flight.type, flight))" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div *ngIf="flight.type =='hotel' && showBookedOptionInTripList(flight,data.data)">
													<div [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'past',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
														<div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(data.data[0],false)">
															<div class="deviding-container">
																<div class="booking-details">
																	<div class="booking-details-inner">
																		<div class="booking-img">
																			<img src="assets/images/hotel-circle.png" />
																		</div>
																		<div class="booking-text">
																			<p style="margin-right: 2px;">{{'bookingHistory.CheckIn' | translate}}</p>
																			<p class="font-bold">{{getDisplayDate(flight.option.hotel_option.checkInDate, 'EE MMM d, yyyy')}}</p>
																			<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
																		</div>
																	</div>
																</div>
																<div class="origin-destination hotel">
																	<div class="destination-hotel">
																		<p class="font-bold1 primary-text">{{flight.option.hotel_option.hotelRooms[0].hotelRoomName}}</p>
																		<span style="white-space: nowrap;">{{flight.option.hotel_option.stay}}-{{flight.option.hotel_option.stay
																			> 1 ? ('bookingHistory.Nights' | translate)
																			:('bookingHistory.Night' | translate)}}</span>
																	</div>
																</div>
															</div>
															<div class="deviding-container">
																<div class="booking-description">
																	<p class="font-bold1 primary-text">{{flight.option.hotel_option.hotelName}}</p>
																</div>	
																<div class="flight-timing">
																	<p>{{flight.option.hotel_option.address}}</p>
																</div>
																<div class="origin-destination1">
																	<div [style.color]="getColorByBookingStatus(getUserBookingStatus(flight.tripStatus,flight.type, flight))" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div *ngIf="flight.type =='cars' && showBookedOptionInTripList(flight,data.data)">
													<div [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'past',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
														<div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(data.data[0],false)">
															<div class="deviding-container">
																<div class="booking-details">
																	<div class="booking-details-inner">
																		<div class="booking-img">
																			<img src="assets/images/car-circle.png" />
																		</div>
																		<div class="booking-text">
																			<p style="margin-right: 2px;">{{'bookingHistory.Pick-update'| translate}} </p>
																			<p *ngIf="flight.option.car_option.pickUpDate!==null" class="font-bold">
																				{{getDisplayDateTimeForFlights(flight.option.car_option.pickUpDate, 'EE MMM d, yyyy')}}</p>
																			<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
																		</div>
																	</div>
																</div>
																<div class="origin-destination hotel">
																	<div class="destination-hotel">
																		<p class="font-bold1 primary-text">
																			{{flight.option.car_option.description}}</p>
																		<p>{{flight.option.car_option.numberOfDay}}-{{flight.option.car_option.numberOfDay
																			> 1 ? ('bookingHistory.Days'| translate):('bookingHistory.Day'| translate) }}</p>
																	</div>
																	<div class="hotel-nights-count">
																		<span class="font-bold">{{flight.option.partnerName}}</span>
																	</div>
					
																</div>
															</div>
															<div class="deviding-container">
																<div class="booking-description">
																	<p class="font-bold1 primary-text">{{flight.option.car_option.partnerName}}</p>
																</div>
																<div class="flight-timing">
																	<p>{{flight.option.car_option.pickUpLocation}}</p>
																</div>
																<div class="origin-destination1">
																	<div  [style.color]="getColorByBookingStatus(getUserBookingStatus(flight.tripStatus,flight.type, flight))" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
										</div>
									<div class="view-details-div">
										<div class="inner-view-details-div">
											<div>
												<span class="font-bold" style="color: #000;font-size: 16px;font-style: normal;font-weight: 700;margin-right: 5px;" >Trip:</span>  
												<span style="color: #000;font-size: 14px;font-style: normal;font-weight: 400;">{{findTrip(data.data[0])}}</span>
											</div>
											<div *ngIf="getAllTrevelers(data.data[0])">
												<span class="font-bold" style="color: #000;font-size: 16px;font-style: normal;font-weight: 700;margin-right: 5px;">Traveler:</span> 
												<span style="color: #000;font-size: 14px;font-style: normal;font-weight: 400;padding-right: 15px;">{{getAllTrevelers(data.data[0])}}</span>
											</div>
										</div>
										<div>
											<div class="booking-view-button">
													<a [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'past',ticketid:data.data[0].ticketid,tripid:data.data[0].tripid,transactionid:data.data[0].option.selectTransId,tripSessionId:data.data[0].tripSessionId}" routerLinkActive="active">
												<button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(data.data[0],false)">{{'bookingHistory.ViewDetail'
													| translate}}</button>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							</div>
						</div>
						<div class="empty-container" *ngIf="!this.showAllBookings && isEmpty2 &&  this.viewMode==='tab2'">
							<div class="empty-img">
								<img src="assets/images/no-flight-found.png" />
							</div>
                            <div class="empty-text">{{'bookingHistory.Youdonthaveanypasttrips' | translate}}</div>
							<div class="empty-button">
								<!--  <button class="btn primary-button">{{'bookingHistory.BOOKNOW' | translate}}</button>-->
							</div>
						</div>
					</div>
				</div>
			
			
			<div class="booking-history-detail" *ngIf="detailView">

				<div class="top-strip booking-history-detail-heading"  *ngIf="bookingHistoryDetailResp">
                    <a class="booking-history-heading-link" href="javascript:void(0)" attr.data-track="BackToTrips" (click)="backToList()"> <img attr.data-track="BackToTrips" src="assets/images/hotel/backarrow.svg" alt="">
					</a>
					<h4 attr.data-track="BackToTrips" *ngIf="!this.searchService.showAllBookings"> {{'bookingHistory.BacktoTrips' | translate}}</h4>
					<h4 attr.data-track="BackToTrips" *ngIf="this.searchService.showAllBookings"> {{'bookingHistory.BacktoBookings' | translate}}</h4>
				</div>

				<div class="booking-history-detail-content">
					<div class="booking-history-detail-content-inner">
						<div class="booking-history-detail-content-left">
							
							<div *ngFor="let item of detailList;let i=index">
								<div class="booking-history-upper-header" *ngIf="i == 0">
									<div class="font-bold" >
										<span *ngIf="getTripName()">{{getTrevelerName()}}{{getTripName()}}</span>
									</div>
								</div>
								<app-trip-details-card 
                                        [details]="item" 
                                        [tripDeatilsIndex]="i"
                                        [detailList]="this.detailList" 
                                        [newtripDetails]="getTripDetails()" 
                                        [showDetailsActionButtons]="true"
                                        [getFullDetailsResponse]="this.getDetailsResponse()"
										[NewuserAccountInfoObj]="this.userAccountInfoObj"
										[bookingType]="this.bookingType"
										(emitGetDetailView)="getDetailViewEmitter($event)"
										(emitDetectChanges)="detectChanges()"
										(emitCallTripsApi)="callTripsApi()"
										(emitAfterBokkingCancel)="afterCancelBooking($event)"
										(emitHandleBookingHistoryListResponse)="handleBookingHistoryListResponse($event)"
                                        ></app-trip-details-card>								
							</div>
							
						</div>
					</div>
				</div>

				<div class="trip-tag-box" *ngIf="bookingHistoryDetailResp" >
					<div *ngIf="tagset && tagset.length > 0 && this.detailTransaction && this.detailTransaction.projectTagId != ''"  class="single-trip-box">
							<app-dropdown class="trip-tags-elem" [dropDownopen]="dropDownopen" [reportComponet]="reportComponet" [tagset]="tagset" [selectedTag]="selectedTag" [tagShow]="tagShow" [changing]="changingValue" [newTagSetsArray]="tagsSetData.tags" (goBackDropDownOptions)="handleResponseFromDropdwn($event)" [isTripDetails]="true"></app-dropdown>
					</div>

					<div style="padding-bottom: 15px;" *ngIf="getTravellersData().travellers">
						<div style="text-align: start;">
							<div style="font-size: 21px;color: black;margin-bottom: 5px;">Travelers</div>
							<ng-container *ngFor="let item of  getTravellersData().travellers;let in = index">
								<div style="color: #6e6c6b;font-size: 16px;font-style: normal;font-weight: 700;margin-left: 5px;padding: 5px 0px;">{{item.firstName}} {{item.lastName}}</div>
							</ng-container>
						</div>	
						

					</div>
				</div>
				<div style="width: 100%;background-color: white;padding: 0px 40px;" *ngIf="bookingHistoryDetailResp">
					<div style="font-size: 21px;color: black;margin-bottom: 5px;" *ngIf="getTravellersData().emergencyContact"><span>{{getTravellersData().travellers[0].firstName}} {{getTravellersData().travellers[0].lastName}}'s</span> emergency contact:</div>
						<div *ngIf="getTravellersData().emergencyContact" style="color: #6e6c6b;font-size: 16px;font-style: normal;font-weight: 700;margin-left: 5px;padding-bottom: 10px;">
							<p>{{getTravellersData().emergencyContact.name}} ({{getTravellersData().emergencyContact.relationship}})</p>
							<p>{{getTravellersData().emergencyContact.contactNumber.displayText}}</p>
						</div>
				</div>
				<div  class="total-price-box" *ngIf="bookingHistoryDetailResp">
					<div  class="total-price-box-inner-div">
						<div  class="total-price-label">{{'bookingHistory.TotalPrice' |translate}}</div>
						<div  class="price-div">{{getTotalPrice() | currency : getCurrencySymbol(getCurrencyByItem()) :'code': '1.2-2'}}</div>
					</div>
				</div>
				

				

			</div>

</div>
			
					
				
			
			
	<ng-template #addExpensifyModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel">
				{{'bookingHistory.Expense' | translate}}</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body">
			<div class="input-field">
				<form method="post" id="expensifyForm" class="expensifyForm">
					<div class="row">

						<div style="width:100%;margin-top:10px;padding-left:10px;padding: 0 8px;">
							<label style="color:gray;margin-top:0px;margin-bottom: 0px;"> {{'bookingHistory.Selectyourexpensingtool' | translate}}:</label>
							<div class="formControl99" id="expennsify">
                                <ng-select #expense [items]="expenseProviders" bindLabel="" dropdownPosition="bottom" bindValue="id" [searchable]="false" [clearable]="false" [formControl]="expensifyEmailControlDD" class="booking-history-expense-form">
									<ng-template ng-header-tmp>
										<div class="selectox-header">
											<span> {{'bookingHistory.Expensingtool' | translate}}</span>
											<span class="selectBox-remove" (click)="expense.toggle()"><span
													class="material-icons">clear</span></span>
										</div>
									</ng-template>
									<ng-template ng-label-tmp let-item="item">
										<img class="expense-image" src="{{item.url}}" />
									</ng-template>
									<ng-template ng-option-tmp let-item="item" let-index="index">
										<img class="expense-image" src="{{item.url}}" />
									</ng-template>
								</ng-select>
							</div>
						</div>
						<div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
							<div class="input-box">
                                <input [formControl]="expensifyEmailControl" style="max-width: 100% !important;" class="input-textfield input-textfield-lg" id="expensifyLoginEmail" name="expensifyLoginEmail" placeholder="{{this.getPlaceholderText()}}" type="text">
                            </div>
                            <div *ngIf="expensifyEmailControl.hasError('required') && (expensifyEmailControl.touched || expensifyEmailControl.dirty)" class="error">{{'bookingHistory.Thisfieldisrequired' | translate}}
                            </div>
                            <div *ngIf="expensifyEmailControl.hasError('pattern') && (expensifyEmailControl.touched || expensifyEmailControl.dirty)" class="error">{{'bookingHistory.Pleaseenteravalidemail' | translate}}
							</div>
						</div>
					</div>
				</form>
			</div>
			<div *ngIf="this.errorMsg">
				<span class="text-danger">{{'bookingHistory.Pleaseselectanyofcheckboxdefineabove'| translate}}</span>
			</div>
			<div class="modal-form-button">
                <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="onExpensifyAdd()">{{'bookingHistory.SendReceipttoExpense' | translate}}</button>
				<button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'| translate}}</button>
			</div>
		</div>
	</ng-template>


	<ng-template #requestChangeModalFlight let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
				{{'bookingHistory.RebookAndCancel' | translate}}</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body requestModalBody">
            <div class="input-field">
				<label style="font-size: 16px;font-family:'apercu-r';text-align: left;
				margin-left: 13px;">{{'bookingHistory.Pleasenoteonceyourebookthisreservationwillbecancelledanditcannotbeundone.'
					| translate}}</label>
		
			</div>
			
			<div class="modal-form-button" style="margin-bottom: 30px;">
                <button class="btn primary-button" (click)="onRequestChangeForFlight()" [disabled]="this.checked">{{'bookingHistory.PROCEEDTOREBOOK'
					| translate}}</button>
			</div>
		</div>
		<div class="modal-footer">
			
		</div>
	</ng-template>


	<ng-template #requestCancelModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
				{{'bookingHistory.Cancelyour' | translate}} {{getHeading()}}?</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body requestModalBody" style="text-align: left !important;">
			<div *ngFor="let item of selectedDetail;let i=index;">
				<div class="input-field" style="font-size: 15px;float: left;padding-left: 12px;text-align: left;">
                    <label *ngIf="i===0 && getHeading()!=='approval request'">{{getCarCancelMsg(item.type,item)}}</label>
				</div>
				<div *ngIf="item.type =='flight'" style="text-align: left;">
					<ng-container *ngFor="let flight of item.option.flight_option.legs;">
						<label style="float: left;padding-left: 12px;margin-top:10px;">
							{{getAirlineFullName(flight.hops[0].ocarrier)}}:{{getDisplayDateTimeForFlights(flight.hops[0].starts,'EE, MMM d')}} 
							{{'bookingHistory.from' | translate}}{{flight.hops[0].from}}
							{{'bookingHistory.to' | translate}}{{flight.hops[flight.hops.length-1].to}}
						</label>
					</ng-container>
				</div>
				<div *ngIf="item.type =='hotel'">
					<label style="float: left;padding-left: 12px;margin-top:10px;">{{'bookingHistory.Hotel' | translate}}:
						{{item.option.hotel_option.hotelName}},{{item.option.hotel_option.address}}
					</label>
					<label style="float: left;padding-left: 12px;margin-top:10px;">{{'bookingHistory.CheckIn' |
						translate}}:
						{{getDisplayDate(item.option.hotel_option.checkInDate,'MMM d')}} ; {{'bookingHistory.CheckOut' |
						translate}}:
						{{getDisplayDate(item.option.hotel_option.checkOutDate,'MMM d')}}</label>
				</div>
				<div *ngIf="item.type =='cars'">
					<label style="float: left;padding-left: 12px;margin-top:10px;"> {{'bookingHistory.Pick-Up' | translate}}:
						{{getDisplayDateTimeForFlights(item.option.car_option.pickUpDate,
						'MMM d')}}, {{item.option.car_option.pickUpTime}} - {{item.option.car_option.pickUpLocation}}
					</label>
					<label style="float: left;padding-left: 12px;margin-top:10px;"> {{'bookingHistory.Drop-off' | translate}}:
						{{getDisplayDateTimeForFlights(item.option.car_option.dropOffDate,
						'MMM d')}}, {{item.option.car_option.dropOffTime}} - {{item.option.car_option.dropOffLocation}}
					</label>
				</div>
			</div>
			<div class="modal-form-button">
                <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="cancelRequest()"><span style="margin-right: 4px;">{{'bookingHistory.Confirm' | translate}}</span> <span
						*ngIf="getHeading()!=='approval request'" >{{getHeading()}}</span> {{'bookingHistory.Cancellation' | translate}}</button>
				<button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'
					| translate}}</button>
			</div>
		</div>
        <div class="modal-footer" style="text-align: center;padding-top:15px;padding-bottom:25px;display:block !important;color:gray;">
			{{'bookingHistory.Pleasenoteonce' | translate}} {{getHeading1()}} {{'bookingHistory.iscancelleditcannotbeundone.' | translate}}
		</div>
	</ng-template>

	<ng-template #requestFlightCancelModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
				{{'bookingHistory.Cancelyour' | translate}} {{getHeading()}}?</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body requestModalBody">
            <div *ngIf="getHeading()!=='approval request'" style="font-size: 15px;float: left;padding-left: 12px;text-align: left;">
				{{getCancelMsg()}}</div>
			<div *ngFor="let flight of selectedDetail;let i=index;">
				<div *ngIf="flight.type =='flight'" style="text-align: left;">
					<ng-container *ngFor="let item of flight.option.flight_option.legs;let flightIndex = index">
						<label style="float: left;padding-left: 12px;margin-top:10px;">
							{{'bookingHistory.flight' | translate}} {{flightIndex+1}} : {{getDisplayDateTimeForFlights(item.hops[0].starts,'MMM d')}} {{'bookingHistory.from' | translate}}
							{{item.hops[0].from}}
							({{getAirportCity(item.hops[0].from)}})
							{{'bookingHistory.to' | translate}}
							{{item.hops[item.hops.length-1].to}}
							({{getAirportCity(item.hops[item.hops.length-1].to)}})
						</label>
					</ng-container>
				</div>
			</div>
			<div class="modal-form-button">
                <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="cancelRequest()"><span style="margin-right: 4px;">{{'bookingHistory.Confirm' | translate}}</span><span
						*ngIf="getHeading()!=='approval request'" >{{getHeading()}}</span> {{'bookingHistory.Cancellation' | translate}}</button>
				<button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'
					| translate}}</button>
			</div>
		</div>
        <div class="modal-footer" style="text-align: center;padding-top:15px;padding-bottom:25px;display:block !important;color:gray;">
			{{'bookingHistory.Pleasenoteonceflightiscancelleditcannotbeundone.' | translate}}

		</div>
	</ng-template>

	<ng-template #requestChangeModal let-modal>
			<div class="modal-header">
				<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
					{{'bookingHistory.RequestChanges' | translate}}</h5>
				<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
					<i class="material-icons">close</i>
				</button>
			</div>
			<div class="modal-body requestModalBody">
            <div class="input-field">
					<label>{{'bookingHistory.PleasetelluswhatchangeyouwouldlikeforyourTrip'
						| translate}}</label>
					<div *ngFor="let item of detailList">
						<div *ngIf="item.type =='flight'">
                        <label class="font-bold">{{getAirlineFullName(item.option.flight_option.legs[0].hops[0].ocarrier)}}:{{getDisplayDateTimeForFlights(item.option.flight_option.legs[0].hops[0].starts,
								'EE, MMM d')}} {{'bookingHistory.from' | translate}}
								{{item.option.flight_option.legs[0].hops[0].from}}
								{{'bookingHistory.to' | translate}}
								{{item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to}}
							</label>
						</div>
						<div *ngIf="item.type =='hotel'">
							<label class="font-bold"> {{'bookingHistory.CheckIn' | translate}} {{getDisplayDate(item.option.hotel_option.checkInDate ,
								'EE, MMM d')}}-{{item.option.hotel_option.hotelName}},{{item.option.hotel_option.address}}
							</label>
						</div>
						<div *ngIf="item.type =='cars'">
							<label class="font-bold">
								{{'bookingHistory.Pick-Up' | translate}}:{{getDisplayDateTimeForFlights(item.option.car_option.pickUpDate,
								'EE, MMM
								d')}}-{{item.option.car_option.description}},{{item.option.car_option.pickUpLocation}}
							</label>
						</div>
					</div>
					<div style="position: relative;float: left;
					width: 100%;">
							<span  class="showNumber">
									{{messageForChange.length}}/255
								  </span>
                    <textarea placeholder="{{'bookingHistory.Adddetailsforyourchange' | translate}}" [(ngModel)]="messageForChange" maxlength="255" (focus)="getChecked()" style="position: relative;padding-right: 55px;padding-top: 20px;" class="modal-textarea input-textfield"></textarea>
							  </div>
				</div>
				<div *ngIf="showChangeRequestError">
					<span class="text-danger">{{'bookingHistory.youmusthavetowritethechangemessage'
						| translate}} </span>
				</div>
				<div class="modal-form-button">
                <button class="btn primary-button" (click)="onRequestChange(detailList)" [disabled]="this.checked">{{'bookingHistory.SubmitChangeRequest'
						| translate}}</button>
				</div>
			</div>
			<div class="modal-footer">
				<p>
                <span>{{'bookingHistory.Note' | translate}}</span> {{'bookingHistory.Sendingarequestwillnotimpactormodifyyourreservationwithoutfurtheraction.YouwillreceiveanemailfromTripwiththebestoptionsandapplicablechangefeeorfaredifferencesforyoutorespondtobeforeachangeisexecuted'
					| translate}}
				</p>
			</div>
	</ng-template>

	<ng-template #addTripitModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel">
				{{'bookingHistory.AddtoTripIt' | translate}}</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body">
			<div *ngFor="let option of expensifyType">
				<div class="header-inner">
					<div class="header-left">
                        <input id="{{option.name}}" value="{{option.value}}" class="mdl-radio__outer-circle" type="radio" name="{{option.name}}" [(ngModel)]="radioSelect" (change)="setTripItUrl(option.value)">
					</div>
					<div class="header-right">
						<span class="mdl-radio__label"> {{option.name | translate}}</span>
					</div>
				</div>
			</div>
			<div *ngIf="this.showRadioError">
				<span class="text-danger">{{'bookingHistory.youmusthavetoselectoneoftheabove'
					| translate}}</span>
			</div>
			<div class="modal-form-button">
				<button class="btn primary-button" (click)="proceedtoTripIt()">{{'bookingHistory.AddtoTripIt'
					| translate}}</button>
			</div>
		</div>
	</ng-template>

	<ng-template #addCalendarModal let-modal style="max-width: 310px !important;">
        <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel">
                {{'bookingHistory.AddtoCalender' | translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
        </div>
        <div class="modal-body" (click)="onSmartModelCancel('calendarOptionModal')">
            <div [hidden]="!this.modalShow1">
                <ngx-new-modal [flightUrl]="this.calenderUrl" [hotelUrl]="this.calenderUrlHotel" [selectOption]="this.selectOptionForModal" [calUrls]="this.calUrls" [template]="this.calenderTemplate"></ngx-new-modal>
            </div>
        </div>
    </ng-template>
	<ng-template #tripDetails let-modal>
        <div class="modal-header" style="padding: 0px !important;
        padding-right: 5px !important;">
                <button type="button" class="close" data-dismiss="modal"  style="color: #fff !important;" (click)="onCancel()">
                    <i class="material-icons">close</i>
                </button>
            </div>
                        <div class="modal-body" style="background-color: #FFF !important;">
                                <div class="flight-row detailed" style="background-color: #FFF !important;box-shadow: none !important;">
                                        <div class="flight-row-left" style="width: 100%;" >
                                                <ng-container *ngIf="tripSelectedToshowOnpopUp.type==='flight'">
                                                        <div class="date-duration" style="justify-content: unset;padding-left: 0px !important;">
                                                              <label class="date" style="margin-right: 4px;">{{'option.Traveltime' |
                                                                    translate}}
                                                                    </label>
                                                              <span class="date">  {{ transformMinute( this.tripSelectedToshowOnpopUp.duration)}}</span>
                                                                 <span class="dotBlack"></span>
                                                               
                                                              <span class="block flight-airport" style="margin-top: 0px;margin-left: 10px;color: black" data-toggle="tooltip"
                                                                
                                                                 >{{this.tripSelectedToshowOnpopUp.stops}}</span>
                                                           
                                                          
                                                        </div>
                                                     <div class="flight-schedule">
                                                        <ng-container *ngFor="let hop of this.tripSelectedToshowOnpopUp.hops; let hopIndex = index">
                                                           <div class="flight-box">
                                                              <div class="flight-box-left" style="max-width: 63px;">
                                                                 <img
                                                                    onerror="this.onerror = null; this.src = 'https://s3.amazonaws.com/images.biztravel.ai/template/default.gif';"
                                                                    [src]="hop.imageUrl" />
                                                              </div>
                                                              <div class="flight-box-right">
                                                                    <div class="row" style="margin-top: 10px;">
                                                                    <div class="col-6" style="text-align: left;">
                                                                            <div class="date">{{hop.carrier}} {{hop.flightNumber}}</div>
                                                                    </div>
                                                                    <div  class="col-6"  style="color:#817E7B;text-align: left;">
                                                                          <p> {{hop.fareClassName}}
                                                                                {{getBrandText(hop.brandClass)}}</p>
                                                                       </div>
                                                                    </div>
                                                              
                                                                 <div class="row" style="margin-top: 10px;">
                                                                    <div class="col-6" style="text-align: left;">
                                                                          <div
                                                                          class="date">{{  getAirLinesName(hop.carrier)}}</div>
                                                                       <div style="margin-top: 10px;"
                                                                       class="block flight-time">{{ getDisplayDateTimeForFlights(hop.starts,'h:mm a')}} <span
                                                                       class="extra-time1"></span></div>
                                                                       <div class="flight-timings-lineDetail"></div>
                                                                       <div class="date" style="font-size: 14px;margin-top: 10px;">Departure</div>
                                                                       <div class="detailcity" style="text-align: left;line-height: 18px"
                                                                       >{{hop.from}} - {{ getAirportName(hop.from)}}</div>
                                                                       <div class="dateDetails" style="text-align: left;">{{hop.starts  | date: 'dd MMM yyyy'}}
                                                                          </div> 
                                                                       </div>
                                                                       
                                                                       <div class="col-6"style="text-align: left;" >
                                                                             <div
                                                                             class="date">{{  transformMinute(hop.duration)}}</div>
                                                                       <div style="margin-top: 10px;"
                                                                       class="block flight-time">{{ getDisplayDateTimeForFlights(hop.ends,'h:mm a')}}  <span class="extra-time"></span></div>
                                                                       <div class="date" style="font-size: 14px;margin-top: 10px;">Arrival</div>
                                                                       <div class="detailcity" style="line-height: 18px"
                                                                       >{{hop.to}} - {{getAirportName(hop.to)}}</div>
                                                                       <div class="dateDetails">{{hop.ends  | date: 'dd MMM yyyy'}}
                                                                          </div> 
                                                                       </div>
                                                                      
                                                                   </div>
                                                                  
                                                               
                                                               
                                                              </div>
                                                           </div>
                                                           <div class="flight-layover" *ngIf="this.tripSelectedToshowOnpopUp.hops[hopIndex+1] && this.tripSelectedToshowOnpopUp.hops[hopIndex+1].duration" style="justify-content: center;">
                                                                 <span
                                                                 class="flight-layover-right date">{{ getLayoverTime( this.tripSelectedToshowOnpopUp.hops[hopIndex+1].starts , this.tripSelectedToshowOnpopUp.hops[hopIndex].ends)}}</span> <span class="dotBlack" style="margin-right: 4px;margin-left: 4px;;"></span>
                                                              <span class="flight-layover-left">{{'flightChart.Layoverin' | translate}}
                                                                 {{getAirportName(hop.to)}}</span>
                                                              
                                                           </div>
                                                        </ng-container>
                                                     </div>
                                                  </ng-container>
                                                  <ng-container *ngIf="tripSelectedToshowOnpopUp.type==='car'">
                                                        <div class="date-duration" style="justify-content: unset;">
                                                              <label class="date" style="margin-right: 4px;">{{tripSelectedToshowOnpopUp.description}}
                                                                    </label>
                                                            
                                                          
                                                        </div>
                                                     <div class="flight-schedule">
                                                        <ng-container>
                                                           <div class="flight-box">
                                                              <div class="flight-box-left" style="max-width: 63px;">
                                                                  <div>
                                                                    <img style="width:130px !important;" *ngIf="carUrl(tripSelectedToshowOnpopUp)"
                                                                    src="{{carUrl(tripSelectedToshowOnpopUp)}}"
                                                                    onerror="this.src='https://s3.amazonaws.com/images.biztravel.ai/ota/carrentals/vehicle_notavailable_S.jpg' " />
                                                                  </div>
                                                                  <div> <img
                                                                    style="margin-top:5px;height:auto!important;width:100px !important;border-radius: 6px"
                                                                    *ngIf="tripSelectedToshowOnpopUp.partnerLogo" src="{{ tripSelectedToshowOnpopUp.partnerLogo }}" />
                                                            </div>
                                                              </div>
                                                              <div class="flight-box-right">
                                                              
                                                                 <div class="row" style="margin-top: 10px;">
                                                                    <div class="col-6" style="text-align: left;">
                                                                          <div
                                                                          class="date" style="font-size: 14px;white-space: nowrap;">{{tripSelectedToshowOnpopUp.carMake}}</div>
                                                                       
                                                                       <div class="date" style="font-size: 14px;margin-top: 10px;">{{tripSelectedToshowOnpopUp.pickUpLocationType}} {{'carresult.Pick-up' | translate }}</div>
                                                                       <div class="detailcity" style="text-align: left;line-height: 18px"
                                                                       >{{tripSelectedToshowOnpopUp.pickUpLocation}}</div>
                                                                       <div class="dateDetails" style="text-align: left;">{{
                                                                        getLocationType(tripSelectedToshowOnpopUp.locationInformation) }}
                                                                          </div> 
                                                                          <div style="color:#9C4AF6;margin-top: 10px;">
                                                                            {{getPerDayPrice(tripSelectedToshowOnpopUp) | currency : getCurrencySymbol(tripSelectedToshowOnpopUp.currency)
                                                                                : 'code' : "1.0-0"}} {{'carresult.avgday' | translate }}
                                                                          </div>
                                                                          <div style="margin-top: 10px;">
                                                                            <li >
                                                                                <img
                                                                                *ngIf="tripSelectedToshowOnpopUp.policy"
                                                                                src="../../../assets/images/hotel/policy-active.svg">
                                                                             
                                                                                <img
                                                                                *ngIf="!tripSelectedToshowOnpopUp.policy"
                                                                                src="../../../assets/images/hotel/policy.svg">
                          
                          
                                                                             <span style="position: relative;top:
                                                                             2px;left:3px;">{{'flightChart.Policy' | translate}}</span>
                          
                                                                          </li>
                                                                          </div>
                                                                       </div>
                                                                       
                                                                       <div class="col-6"style="text-align: left;" >
                                                                        <div
                                                                          class="date"></div>
                                                                       <div class="date" style="font-size: 14px;margin-top: 30px;">{{tripSelectedToshowOnpopUp.dropLocationType}} {{'carresult.Drop-off' | translate }}</div>
                                                                       <div class="detailcity" style="line-height: 18px"
                                                                       ><span
                                                                       *ngIf="tripSelectedToshowOnpopUp.pickUpLocation !== tripSelectedToshowOnpopUp.dropOffLocation">
                                                                       {{tripSelectedToshowOnpopUp.dropOffLocation}}</span><span
                                                                       *ngIf="tripSelectedToshowOnpopUp.pickUpLocation === tripSelectedToshowOnpopUp.dropOffLocation"> {{'option.Sameaspick-up' | translate}}</span></div>
                                                                      
                                                                       </div>
                                                                      
                                                                   </div>
                                                                  <hr *ngIf="tripSelectedToshowOnpopUp.type==='car' && this.isMobile1">
                                                                   <div *ngIf="tripSelectedToshowOnpopUp.type==='car' && this.isMobile1" style="text-align: left;">
                                                                        <div *ngIf="tripSelectedToshowOnpopUp.milage"
                                                                        class="hotel-distance-address">
                                                                        <i class="fa fa-tachometer" aria-hidden="true"></i><span
                                                                            style="margin-left:4px;">{{'carresult.Unlimitedmileage' | translate}}</span>
                                                                    </div>
                                                                    <div *ngIf="tripSelectedToshowOnpopUp.flights.automaticTransmission"
                                                                        class="hotel-distance-address">
                                                                        <span class="inlineblock icon-margin "><img class="door"
                                                                                src="assets/images/transmission_icon.png"></span><span
                                                                            style="margin-left:4px;">{{'carresult.Automatictransmission' | translate}}</span>
                                                                    </div>
                                                                    <div *ngIf="tripSelectedToshowOnpopUp.doors"
                                                                                class="duration-section car-duration" style="display: block !important;">
                                                                                <span class="inlineblock icon-margin "><img class="door"
                                                                                        src="assets/images/car_gate.svg"></span>
                                                                                <span class="inlineblock"
                                                                                    style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.doors}}</span>
                                                                            </div>
                        
                                                                            <div *ngIf="tripSelectedToshowOnpopUp.passengers"
                                                                                class="duration-section car-duration" style="display: block !important;">
                                                                                <span class="inlineblock icon-margin "><i class="fa fa-user"
                                                                                        aria-hidden="true"></i></span>
                                                                                <span class="inlineblock"
                                                                                    style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.passengers}}</span>
                                                                            </div>
                        
                                                                            <div *ngIf="tripSelectedToshowOnpopUp.bags"
                                                                                class="duration-section walk-duration" style="display: block !important;">
                                                                                <span class="inlineblock icon-margin"><i class="fa fa-suitcase"
                                                                                        aria-hidden="true"></i></span>
                                                                                <span class="inlineblock "
                                                                                    style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.bags}}</span>
                                                                            </div>
                                                                     </div>
                                                               
                                                              </div>
                                                           </div>
                                                          
                                                        </ng-container>
                                                     </div>
                                                  </ng-container>
                                                  <ng-container *ngIf="tripSelectedToshowOnpopUp.type==='hotel'">
                                                    <div class="date-duration" style="justify-content: unset;padding-left: 1px !important;">
                                                          <label class="date" style="margin-right: 4px;text-align: left;">{{tripSelectedToshowOnpopUp.hotelRooms[0].hotelRoomName}}
                                                                </label>
                                                        
                                                      
                                                    </div>
                                                 <div class="flight-schedule">
                                                    <ng-container>
                                                       <div class="flight-box">
                                                          <div class="flight-box-left" style="min-width: 63px;max-width: 63px;">
                                                            <img onerror="this.onerror=null;this.src='assets/images/hotel/hotel.png'"
                                                            *ngIf="tripSelectedToshowOnpopUp.thumbnailImage"
                                                            src="{{tripSelectedToshowOnpopUp.thumbnailImage.url}}" />
                                                        <img *ngIf="!tripSelectedToshowOnpopUp.thumbnailImage"
                                                            src="assets/images/hotel/hotel.png" />
                                                          </div>
                                                          <div class="flight-box-right">
                                                          
                                                             <div class="row" style="margin-top: -4px;">
                                                                <div class="col-12" style="text-align: left;">
                                                                      <div
                                                                      class="date" style="">{{tripSelectedToshowOnpopUp.hotelName}}</div>
                                                                      <div class="date">{{tripSelectedToshowOnpopUp.distanceInMilesFromMeeting | number : '.2-2'}}
                                                                            {{'hotelSelect.milesfromyourdestination' | translate}}</div>
                                                                   
                                                                   <div class="date" style="font-size: 14px;margin-top: 10px;"> 
                                                                   </div>
                                                                   <div class="detailcity" style="text-align: left;line-height: 18px"
                                                                   >{{tripSelectedToshowOnpopUp.address}}</div>
                                                                   <div class="dateDetails" style="text-align: left;margin-top: 10px;"> <div>
                                                                    <span class="distance-img-text" style="margin-left: -3px !important;">
                                                                        <span class="inlineblock_m" style="margin-right: 10px;">
                                                                            <img class="distance-time-img"
                                                                                src="assets/images/emailflow/car-distance.png"
                                                                                alt="" />
                                                                            <span class="distance-time-text">{{tripSelectedToshowOnpopUp.distance}}
                                                                                {{'option.min' | translate}}</span>
                                                                        </span>
                                                                        <span *ngIf="tripSelectedToshowOnpopUp.walkingDistance < 30"
                                                                            class="inlineblock_m">
                                                                            <img class="distance-time-img"
                                                                                src="assets/images/emailflow/walk-distance.png"
                                                                                alt="" />
                                                                            <span
                                                                                class="distance-time-text">{{tripSelectedToshowOnpopUp.walkingDistance}}
                                                                                {{'option.min' | translate}}</span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                      </div> 
                                                                      <div *ngIf="!tripSelectedToshowOnpopUp.loyaltyPointsSupported"
                                                                      style="color:#f93d30; width:100%;text-align: left;margin-top:15px;"> <img
                                                                          src="assets/images/flight-list/gen-negative.svg"> {{'option.Noloyaltyrewards' | translate}}
                                                                  </div>
                                                                  <div class="dateDetails" style="margin-top: 15px;color: #817E7B !important;">
                                                                    {{'option.Cancellationpolicy' | translate}}: {{getCancellationPolicy(tripSelectedToshowOnpopUp)}}
                                                                  </div>
                                                                      
                                                                      <div style="margin-top: 10px;">
                                                                        <li>
                                                                          
                                                                            <img
                                                                           
                                                                            src="assets/images/hotel/policy{{tripSelectedToshowOnpopUp.inPolicy ? '-active' : ''}}.svg">
                      
                      
                                                                         <span style="position: relative;top:
                                                                         2px;left:3px;">{{'flightChart.Policy' | translate}}</span>
                      
                                                                      </li>
                                                                      </div>
                                                                   </div>
                                                                   
                                                                   
                                                               </div>
                                                              
                                                           
                                                           
                                                          </div>
                                                       </div>
                                                      
                                                    </ng-container>
                                                 </div>
                                              </ng-container>
                                            </div>
                                <div [hidden]="tripSelectedToshowOnpopUp.type==='car' && this.isMobile1"  *ngIf="false" class="flight-row-right " [ngStyle]="getDetailsStyleModal()" style="padding-right:6px !important;background-color: #FFF !important;box-shadow: none!important;align-items:  baseline !important;">
                                        <div class="flight-box" style="padding-top: 0px !important;padding-right: 0px !important;">
                                                <div *ngIf="false && this.isMobile1" class="flight-box-left" style="width: 93px;">
                                                    </div>
                                                    <div class="flight-box-right">
                                        <div *ngIf="false"class="flight-feature-list">
                                        <!-- <div *ngIf="tripSelectedToshowOnpopUp.type==='flight'"class="flight-feature-list"> -->
                                             
                                                <ul>
                                                      <li *ngIf="getCorporateFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0])" style="white-space: nowrap;">
                                                            <i class="fa fa-star" style="margin-right: 4px;color: #3CBF9A"></i> 
                                                           
                                                            <span style="white-space: break-spaces;">{{'hotelResult.Corporaterate' | translate}}</span>
                                                         </li>
                                                     
                                                   <!-- <li *ngIf="getSeatFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) !== 'NOTFOUND'" style="white-space: nowrap;">
                                                         <img
                                                         *ngIf="getSeatFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'INCLUDED'" 
                                                         src="../../../assets/images/seat-green.svg"
                                                         >
                                                        
                                                         <img
                                                         *ngIf="getSeatFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'NOTOFFERED' || getSeatFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" 
                                                         src="../../../assets/images/seat-red.svg"
                                                         >
                                                      <span style="white-space:break-spaces;">{{'flightChart.Seatchoice' | translate}}</span>
                                                   </li> -->
                                                  
                                                   <li *ngIf="isMixed(this.tripSelectedToshowOnpopUp.hops[0])" class="not-active  "
                                                      style="flex: 0 0 auto; min-width:50px;">
                                                      <i class="fa fa-exclamation-triangle  fare-attr-red" 
                                                         aria-hidden="true"></i>
                                                      <span style=" color: rgb(249, 61, 48);">{{'flightChart.Mixedclass' | translate}}</span>
                                                   </li>
                                                   <li *ngIf="getCarryOnBagFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) !== 'NOTFOUND'">
                                                         <img
                                                         *ngIf="getCarryOnBagFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'INCLUDED'" 
                                                         src="../../../assets/images/carry-on-green.svg"
                                                         >
                                                       
                                                         <img
                                                         *ngIf="getCarryOnBagFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'NOTOFFERED' || getCarryOnBagFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" 
                                                         src="../../../assets/images/carry-on-red.svg"
                                                         >
                                                      <span>{{'flightChart.Carryonbag' | translate}}</span>
                                                   </li>
                                                   <li *ngIf="getChangesFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) !== 'NOTFOUND'">
                                                        <img
                                                        *ngIf="getChangesFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'INCLUDED'" 
                                                        src="../../../assets/images/changes-green.svg"
                                                        >
                                                       
                                                        <img
                                                        *ngIf="getChangesFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'NOTOFFERED' || getChangesFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" 
                                                        src="../../../assets/images/changes-red.svg"
                                                        >
                                                     <span>{{'flightChart.Changes' | translate}}</span>
                                                  </li>
                                                   <li *ngIf="getBaggageDetails(this.tripSelectedToshowOnpopUp.hops[0])" class="not-active"
                                                      style="flex: 0 0 auto; min-width:50px;white-space: nowrap;">
                                                      <i class="fa fa-suitcase  fare-attr-red" aria-hidden="true"
                                                         [ngStyle]="{'color': isBaggageAvailable(this.tripSelectedToshowOnpopUp.hops[0]) ? '#27c198':'#9B9B9B'}"></i>
                                                      <span style="white-space: break-spaces;">{{getBaggageDetails(this.tripSelectedToshowOnpopUp.hops[0])}}</span>
                                                   </li>
                                                   <li  >
                                                        <img
                                                        *ngIf="this.tripSelectedToshowOnpopUp.policy == true"
                                                        src="../../../assets/images/policy-green.svg"
                                                        >
                                                        <img
                                                        *ngIf="this.tripSelectedToshowOnpopUp.policy == false"
                                                           src="../../../assets/images/policy-red.svg"
                                                           >
                                                          
                                                     
  
  
                                                     <span>{{'flightChart.Policy' | translate}}</span>
  
                                                  </li>
                                                   <li *ngIf="getRefundAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) !== 'NOTFOUND'">
                                                         <img
                                                         *ngIf="getRefundAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'INCLUDED'" 
                                                         src="../../../assets/images/refund-green.svg"
                                                         >
                                                       
                                                         <img
                                                         *ngIf="getRefundAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'NOTOFFERED' || getRefundAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" 
                                                         src="../../../assets/images/refund-red.svg"
                                                         >
                                                      <span>{{'flightChart.Refundable' | translate}}</span>
                                                   </li>
                                                  
                                                  
                                                   <li *ngIf="getWifiAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) !== 'NOTFOUND'"
                                                   style="flex: 0 0 auto; min-width:80px;">
                                                   <img
                                                   *ngIf="getWifiAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'INCLUDED'" 
                                                   src="../../../assets/images/wifi-green.svg"
                                                   >
                                                  
                                                   <img
                                                   *ngIf="getWifiAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'NOTOFFERED' || getWifiAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" 
                                                   src="../../../assets/images/wifi-red.svg"
                                                   >
                                                   <span>{{'flightChart.WiFi' | translate}}<label *ngIf="getWifiAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" style="margin-left: 4px;">{{'flightChart.atcost' | translate}}</label></span>
                                                </li>
                                                   <li *ngIf="getMealFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) !== 'NOTFOUND'">
                                                         <img
                                                         *ngIf="getMealFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'INCLUDED'" 
                                                         src="../../../assets/images/meal-green.svg"
                                                         >
                                                        
                                                         <img
                                                         *ngIf="getMealFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'NOTOFFERED' || getMealFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" 
                                                         src="../../../assets/images/meal-red.svg"
                                                         >
                                                      <span>{{'flightChart.Meal' | translate}}</span>
                                                   </li>
                                                   <li *ngIf="getLegroomFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) !== 'NOTFOUND'"  style="flex: 0 0 auto; min-width:50px;white-space: nowrap;">
                                                        <img
                                                        *ngIf="getLegroomFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'INCLUDED'" 
                                                        src="../../../assets/images/leg-space-green.svg"
                                                        >
                                                        
                                                        <img
                                                        *ngIf="getLegroomFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'NOTOFFERED' || getLegroomFareAttributeMoreOptions(this.tripSelectedToshowOnpopUp.hops[0]) === 'AVAILABLEFORCHARGE'" 
                                                        src="../../../assets/images/leg-space-red.svg"
                                                        >
                                                        <span style="white-space: break-spaces;">{{'flightChart.legRoom' | translate}}</span>
                                                     </li>
                                                  
                                                </ul>
                                             </div>
                                             <div *ngIf="tripSelectedToshowOnpopUp.type==='car' && !this.isMobile1" [ngStyle]="{'margin-left': this.isMobile1 ?  '30px' : '0px;'}"style="text-align: left;">
                                                <div *ngIf="tripSelectedToshowOnpopUp.milage"
                                                class="hotel-distance-address">
                                                <i class="fa fa-tachometer" aria-hidden="true"></i><span
                                                    style="margin-left:4px;">{{'carresult.Unlimitedmileage' | translate}}</span>
                                            </div>
                                            <div *ngIf="tripSelectedToshowOnpopUp.automaticTransmission"
                                                class="hotel-distance-address">
                                                <span class="inlineblock icon-margin "><img class="door"
                                                        src="assets/images/transmission_icon.png"></span><span
                                                    style="margin-left:4px;">{{'carresult.Automatictransmission' | translate}}</span>
                                            </div>
                                            <div *ngIf="tripSelectedToshowOnpopUp.doors"
                                                        class="duration-section car-duration" style="display: block !important;">
                                                        <span class="inlineblock icon-margin "><img class="door"
                                                                src="assets/images/car_gate.svg"></span>
                                                        <span class="inlineblock"
                                                            style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.doors}}</span>
                                                    </div>

                                                    <div *ngIf="tripSelectedToshowOnpopUp.passengers"
                                                        class="duration-section car-duration" style="display: block !important;">
                                                        <span class="inlineblock icon-margin "><i class="fa fa-user"
                                                                aria-hidden="true"></i></span>
                                                        <span class="inlineblock"
                                                            style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.passengers}}</span>
                                                    </div>

                                                    <div *ngIf="tripSelectedToshowOnpopUp.bags"
                                                        class="duration-section walk-duration" style="display: block !important;">
                                                        <span class="inlineblock icon-margin"><i class="fa fa-suitcase"
                                                                aria-hidden="true"></i></span>
                                                        <span class="inlineblock "
                                                            style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.bags}}</span>
                                                    </div>
                                             </div>
                                             <div *ngIf="tripSelectedToshowOnpopUp.type==='hotel'">
                                                <div class="hotel-amenities">
                                                   
                                                    <ul>
                                                            <li class="inlineblock_m hotel-rating-stars">
                                                                    <span
                                                                        *ngFor="let starType of getRatingStarsMap(tripSelectedToshowOnpopUp.starRating); let i = index;"
                                                                        class="star">
                                                                        <img *ngIf="starType == 'full'" src="assets/images/hotel/star-filled.svg" />
                                                                        <img *ngIf="starType == 'half'" src="assets/images/hotel/halfstar.png" />
                                                                        <img *ngIf="starType == 'none'" src="assets/images/hotel/star.svg" />
                                                                    </span>
                                                                    <!-- <span><img src="assets/images/hotel/star-filled.svg" alt="star-filled" /></span>
                                                                    <span><img src="assets/images/hotel/star-filled.svg" alt="star-filled" /></span>
                                                                    <span><img src="assets/images/hotel/star-filled.svg" alt="star-filled" /></span>
                                                                    <span><img src="assets/images/hotel/star.svg" alt="star" /></span>
                                                                    <span><img src="assets/images/hotel/star.svg" alt="star" /></span> -->
                                                                    </li>
                                                        <li>
                                                            <img class="inlineblock_m"
                                                                src="assets/images/hotel/local_cafe{{isAmenityAvailable('AMENITY_TYPE_BKFAST') ? '-active' : ''}}.svg"
                                                                alt="" />
                                                            <span class="inlineblock_m">{{'hotelSelect.Breakfast' | translate}}</span>
                                                        </li>
                    
                                                        <li>
                                                            <img class="inlineblock_m"
                                                                src="assets/images/hotel/wifi{{isAmenityAvailable('AMENITY_TYPE_WIFI') ? '-active' : ''}}.svg"
                                                                alt="" />
                                                            <span class="inlineblock_m">{{'hotelSelect.FreeWiFi' | translate}}</span>
                                                        </li>
                    
                                                        <li>
                                                            <img class="inlineblock_m"
                                                                src="assets/images/hotel/fitness-dumbbell{{isAmenityAvailable('AMENITY_TYPE_GYM') ? '-active' : ''}}.svg"
                                                                alt="" />
                                                            <span class="inlineblock_m">{{'hotelSelect.Gym' | translate}}</span>
                                                        </li>
                    
                                                        <li>
                                                            <img class="inlineblock_m"
                                                                src="assets/images/hotel/local_restaurant{{isAmenityAvailable('AMENITY_TYPE_RESTAURANT') ? '-active' : ''}}.svg"
                                                                alt="" />
                                                            <span class="inlineblock_m">{{'hotelSelect.Restaurant' | translate}}</span>
                                                        </li>
                    
                                                        <li>
                                                            <img class="inlineblock_m"
                                                                src="assets/images/hotel/bell-call{{isAmenityAvailable('AMENITY_TYPE_ROOMSERVICE') ? '-active' : ''}}.svg"
                                                                alt="" />
                                                            <span class="inlineblock_m">{{'hotelSelect.Concierge' | translate}}</span>
                                                        </li>
                    
                                                        <li>
                                                            <img class="inlineblock_m"
                                                                src="assets/images/hotel/swimming-pool-person{{isAmenityAvailable('AMENITY_TYPE_POOL') ? '-active' : ''}}.svg"
                                                                alt="" />
                                                            <span class="inlineblock_m">{{'hotelSelect.Pool' | translate}}</span>
                                                        </li>
                    
                                                        <li>
                                                            <img class="inlineblock_m"
                                                                src="assets/images/hotel/bar{{isAmenityAvailable('AMENITY_TYPE_BAR') ? '-active' : ''}}.svg"
                                                                alt="" />
                                                            <span class="inlineblock_m">{{'hotelSelect.Bar' | translate}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                             </div>
                                             </div>
                                             </div>
                                    </div>
                                    </div>
                            </div>
                       
    </ng-template>

	
	<app-navigation></app-navigation>