import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { SearchActionType } from './enum/search-action.type';
import { FlightSearchRequest } from './entity/flight-search-request';
import { FlightLegSearch } from './entity/flight-leg-search';
import { IntervalType } from './enum/interval.type';
import { JourneyDate } from './entity/journey-date';
import { Constants } from './util/constants';
import { DateUtils } from './util/date-utils';
import { FlightSearchResponse } from './entity/flight-search-response';
import { FlightSearchQueryParam } from './entity/flight-search-query-param';
import { FlightQueryParam } from './entity/flight-query-param';
import { FilterService } from './filter.service';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { UserAccountService } from './user-account.service';
import { FlightUtils } from './util/flight-utils';
import { FlightResult } from './entity/flight-result';
import { FlightLegResult } from './entity/flight-leg-result';
import { FlightHopResult } from './entity/flight-hop-result';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { SeatSelect } from './entity/seatSelected';
import { CommonUtils } from './util/common-utils';
import { eventNames } from 'cluster';
import { AlgoTypes } from './enum/algo-types';
import { FilterType } from './enum/filter.type';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from './entity/user-info';

declare var getHotjarUserId: any;
declare var getGAClientID: any;
declare var parseParamsFromURLString: any;
declare var gtag: any;

@Injectable()
export class SearchService {
  chatEnabled = false;
  airportOptions: any;
  type1 = [];
  isSearchPage = false;
  mapSupprted=false;
  perDiemSupported = false;
  changeClick =false;
  showApprovalaInMenu =false;
  public addEntitySupported= false;
  currentTimeZone:any;
  sourceLocationForlatLng:any;
destLocationForlatLng:any
sourceLocationForMultilatLng:any=[];
destLocationForMultilatLng:any=[];
  networkErrorpopupHide=false;
  internetavailable=false;
  showAllBookings =false;
  isEventOutOfPolicy =false;
  showHotelTab=false;
  logoIcon:any;
  selectedEventForBooking:any;
  selectedEventID:any;
  showFlightTab=false;
  messageIndex=0;
  showCarTab=false;
  showTrainsTab = false;
  paymentPage: boolean;
  maxAllowedHotelStay:any;
  bookingDone = false;
  pastTrip =false
  hotelbookingDone = false;
  searchPopMsg=[];
  currentMessage='';
  searchCallComplete =false;
  multiTripHotelDelete =false;
  multiTripCarDelete =false;
  multiTripFlightDelete =false;
  groupTravelEventsSupported=false;
  tripFeatureEnabled=false;
  creditsFinished =false;
  nonCombo=false;
  editMytripname='false';
  selectedFareIndex=1;
  bookingAndApprovalDone = false;
  infantBookingAllowed=false;
  backToSelectionPage=false;
  travelPurposeMandatory=false;
  ageGroupArray=[];
  searchHeaderCliked = false;
  apiCallafterFlightSelection = false;
  priceChange: string;
  employeeOptions: Array<any>[];
  originalEmployeeOptions: Array<any>[];
  selectedFilter = [];
  selectedFilter1 = [];
  selectedSourceAirports = [];
  selectedDestAirports = [];
  filtereSourceAirports = [];
  filteredDestAirports = [];
  NGS_UI_STAR_MAPPING:any;
  employeeEmail = [];
  employeeList = [];
  travellerList=[];
  selectedLeg = [];
  showRadio = true;
  allFaresPopupShow = false;
  searchStart =false;
  priceWithTravelCreditsZero = false;
  comingFromSelectionPAge=false;
  inputId = '';
  airportSelect = '';
  creditDetails: any;
  typeOfBooking = 'BOOK_FOR_SELF';
  carDestinationCity='';
  multiflightQuery=[];
  multihotelQuery=[];
  multicarQuery=[];
  displayPrice: number;
  previousSelectedCreditAirline=[];
  originalAllTrips:any;
  comingBackFromDetailsPAge=false;
  dateRangeForTripsFilter:any;
  dateDateTypeForTrips:any;
  statusForTrips:any;
  bookingValueForTrips:any;
  empNameSearchValueForTrips='';
  displayCurrency: string;
  comboOutGoingSelectedFlight: FlightResult = new FlightResult();
  selectedSeatArrayForPaymentPage: Array<any>[];
  seatSelectArray1: SeatSelect[];
  seatForMultipleBooking=[];
  exitFromMultiTrip=false;
  tripName='';
  selectedCreditAirline:any;
  airportDestinationOptions: any;
  multipleNoOfLegs = 0;
  noAvailability = [];
  seletedIndex: number;
  filterReset = false;
  multiTripBooking =false;
  tripType='';
  previousDate = false;
  intervalId:any;
  adultCount=0;
  backClick = false;
  tempFlight = [];
  filtered_airlines = [];
  flightId = [];
  outsidePolicyselected = false;
  outgoingSelectedFlight: FlightResult;
  multipleOutgoingSelectedFlight: FlightResult[];
  nonComboSelectedFlight: Array<any> = [];
  nonComboSelectedFlightForForwardArrow: Array<any> = [];
  brandedFareCheckBox = false;
  flight = [];
  processedFlightResults: FlightResult[][];
  inPolicyFlight: Array<any> = [];
  originalFlightsWithIndex: FlightResult[] = [];
  lastBroadcastedSearchResult: FlightSearchResponse;
  public flightSearchQueryParam: any;
  public autoAllFareRequestCounter = 0;
  daterangepickerModel: Array<any> = [];
  public minPrices;
  public overlapBookingsList =[];
  constructor(
    private http: GallopHttpClient,
    public filterService: FilterService,
    private userAccountService: UserAccountService,
    private gallopLocalStorage: GallopLocalStorageService,
    public translateService: TranslateService,
    private toastr: ToastrService,
  ) {
  }

  flightSearchResponse: FlightSearchResponse;
  orgFlightResponse: FlightSearchResponse;
  timeOptions: any[] = Constants.CONSTRAINT_TIME_OPTIONS_FOR_DEPART.slice();
  previousSearch: FlightSearchQueryParam = null;
  airlineOptions: any = [];
  allianceOptions: any = [];

  private travellerListResponseSubject = new BehaviorSubject<Array<UserInfo>>(null);
  travellerListResponseObservable$ = this.travellerListResponseSubject.asObservable();
  private noOfFlightLegsSubject = new BehaviorSubject<number>(1);
  noOfFlightLegs$ = this.noOfFlightLegsSubject.asObservable();

  public flightResponseSubject = new BehaviorSubject<any>(null);
  flightResponse$ = this.flightResponseSubject.asObservable();

  private flightRequestSubject = new BehaviorSubject<any>(null);
  flightRequest$ = this.flightRequestSubject.asObservable();


  private selectedFlightUnSelectionSubject = new BehaviorSubject<any>(null);
  selectedFlightUnSelection$ = this.selectedFlightUnSelectionSubject.asObservable();
  public browserBackOrForwardClickListener = new BehaviorSubject<any>(null);
  public networkPopupListener = new BehaviorSubject<any>(null);

  searchType: SearchActionType;

  public calendarOpenEventListsner = new BehaviorSubject<any>(null);
  public hoverCounter = 0;

  broadcastNoOfFlightLegs(num: number) {
    this.noOfFlightLegsSubject.next(num);
  }

  broadCastFlightResponse(flightSearchResponse: FlightSearchResponse) {
    this.flightResponseSubject.next(flightSearchResponse);
    this.lastBroadcastedSearchResult = flightSearchResponse;
  }

  broadCastFlightRequest(flightSearchQueryParam: FlightSearchQueryParam) {
    this.flightRequestSubject.next(flightSearchQueryParam);
  }

  setPreviousSearch(flightSearchQueryParam: FlightSearchQueryParam) {
    this.previousSearch = flightSearchQueryParam;
  }
emptyIntervalID(){
  if (this.intervalId) {
    clearInterval(this.intervalId);
  }
}
  getPreviousSearch() {
    return this.previousSearch ? this.previousSearch : null;
  }
  setBrandedFareCheckBoxState(checked) {
    this.brandedFareCheckBox = checked;
  }
  getBrandedFareCheckBoxState() {
    return this.brandedFareCheckBox;
  }
  getFaqResults(): Observable<any> {
    return this.http.get(environment.apiFaq);
  }
  showNextMessage() {
    this.currentMessage = this.searchPopMsg[this.messageIndex];

    // Update the message every 5 seconds
    this.intervalId = setInterval(() => {
      this.messageIndex = (this.messageIndex + 1) % this.searchPopMsg.length;
      this.currentMessage =this.searchPopMsg[this.messageIndex];
    }, 5000); 
  }
  getProcessedFlightResultsUptoCurrentLeg(currentLegIndex, inputFlights) {
    if (currentLegIndex < this.multipleOutgoingSelectedFlight.length) {
      var output: FlightResult[][] = [];
      var matchingIndices = [];
      var indexCounter = 0;
      for (let flight of inputFlights[currentLegIndex]) {
        if (flight.flightId === this.multipleOutgoingSelectedFlight[currentLegIndex].flightId) {
          matchingIndices.push(indexCounter);
        }
        indexCounter++;
      }
      var localLegIndex = 0;
      for (let legArray of inputFlights) {
        var outputLegArray = [];
        var outputFlightIndex = 0;
        for (let flight of inputFlights[localLegIndex]) {
          if (matchingIndices.indexOf(outputFlightIndex) !== -1) {
            outputLegArray.push(flight);
          }
          outputFlightIndex++;
        }
        localLegIndex++;
        output.push(outputLegArray);
      }
      return this.getProcessedFlightResultsUptoCurrentLeg(currentLegIndex + 1, output);
    }
    return inputFlights;
  }
  getUniqueFlightNew() {
    var processedFlightResultsTemp = this.getProcessedFlightResultsUptoCurrentLeg(0, this.processedFlightResults);
    let procssedIds: string[] = [];
    let uniqueFlightResults: FlightResult[] = [];
    var flights = processedFlightResultsTemp[this.multipleOutgoingSelectedFlight.length];
    for (let flightResult of flights) {
      if (procssedIds.indexOf(flightResult.flightId) == -1) {
        uniqueFlightResults.push(flightResult);
        procssedIds.push(flightResult.flightId)
      } else {
        let oldIndex: number = procssedIds.indexOf(flightResult.flightId);
        if ((!uniqueFlightResults[oldIndex].legs[0].brandDetails || uniqueFlightResults[oldIndex].legs[0].brandDetails.length == 0)
          &&
          (flightResult.legs[0].brandDetails && flightResult.legs[0].brandDetails.length > 0)) {
          //Swap
          uniqueFlightResults[oldIndex] = flightResult;
        }
      }
    }
    return uniqueFlightResults;
  }
  getUniqueFlight(index) {
    let procssedIds: string[] = [];
    let uniqueFlightResults: FlightResult[] = [];
    var flights = this.processedFlightResults[index];
    if (index !== (this.processedFlightResults.length - 1)) {
      for (let flightResult of flights) {
        if (procssedIds.indexOf(flightResult.flightId) == -1) {
          uniqueFlightResults.push(flightResult);
          procssedIds.push(flightResult.flightId)
        } else {
          uniqueFlightResults.filter(flight => {
            if (flight.flightId === flightResult.flightId) {
              if (!flight.parentIdsOfSameFlight) {
                flight.parentIdsOfSameFlight = [];
              }
              flight.parentIdsOfSameFlight.push(flightResult.parentFlightId);
            }
          });
        }
      }
    } else {
      for (let flightResult of flights) {
        uniqueFlightResults.push(flightResult);
      }
    }
    return uniqueFlightResults;
  }

  setOutgoingSelectedFlight(flight: FlightResult) {
    this.outgoingSelectedFlight = JSON.parse(JSON.stringify(flight));
    if (!flight.flightId || !this.returnFlightSearchResponse) return;
    let flightSearchResponse: FlightSearchResponse = this.filterService.filterAssociatedReturnFlights(flight.flightId, this.returnFlightSearchResponse);
    this.updateAirlineOptions(flightSearchResponse);
    this.filterService.setOriginalFlightSearchResults(flightSearchResponse);
    this.sortResultsAsRecommended(flightSearchResponse);
  }
  setOutgoingMultipleSelectedFlight(flight: FlightResult, index: number, event?) {
    this.outgoingSelectedFlight = JSON.parse(JSON.stringify(flight));
    this.multipleOutgoingSelectedFlight[index - 1] = this.outgoingSelectedFlight;
    if (!this.nonCombo) {
      this.returnFlightSearchResponse.flightsLists[0].flights = this.getUniqueFlightNew();
    } else {
      let procssedIds: string[] = [];
      let uniqueFlightResults: FlightResult[] = [];

      for (let flightResult of this.processedFlightResults[index]) {
        if (procssedIds.indexOf(flightResult.flightId) == -1) {
          uniqueFlightResults.push(flightResult);
          procssedIds.push(flightResult.flightId)
        } else {
          let oldIndex: number = procssedIds.indexOf(flightResult.flightId);
          if ((!uniqueFlightResults[oldIndex].legs[0].brandDetails || uniqueFlightResults[oldIndex].legs[0].brandDetails.length == 0)
            &&
            (flightResult.legs[0].brandDetails && flightResult.legs[0].brandDetails.length > 0)) {
            //Swap
            uniqueFlightResults[oldIndex] = flightResult;
          }
        }
      }
      this.returnFlightSearchResponse.flightsLists[0].flights = uniqueFlightResults;
    } //this.getUniqueFlight(index);
    if (!flight.flightId || !this.returnFlightSearchResponse) return;
    let flightResponse = this.returnFlightSearchResponse;
    if (!this.apiCallafterFlightSelection) {
      if (event) {
        if (event.airlineFilter && event.airlineFilter.length > 0) {
          flightResponse = this.filterService.filterByPreferredAirlines(this.selectedFilter, flightResponse);
        }
        if (event.sourceAirports && event.sourceAirports.length > 0) {
          flightResponse = this.filterService.filterByPreferredAirport(this.selectedSourceAirports, flightResponse);
        }
        if (event.destAirports && event.destAirports.length > 0) {
          flightResponse = this.filterService.filterByPreferredAirport(this.selectedDestAirports, flightResponse);
        }
      }
      this.returnFlightSearchResponse = flightResponse;
    }

    let flightSearchResponse: FlightSearchResponse = this.returnFlightSearchResponse; // this.filterService.filterAssociatedReturnFlights(flight.flightId,this.returnFlightSearchResponse);
    this.updateAirlineOptions(flightSearchResponse);
    this.filterService.setOriginalFlightSearchResults(flightSearchResponse);
    //  if(!event){
    this.applySorting(flightSearchResponse);
    //  }
  }
  letsTrack(params1): void {
    const userid = this.userAccountService.getUserEmail();
    const hjUserId = getHotjarUserId();
    if (hjUserId) {
      params1 += '&hjUserId=' + hjUserId;
    }
    const gaClientId = getGAClientID();
    if (gaClientId) {
      params1 += '&gaClientId=' + gaClientId;
    }
    this.http.get(environment.apiForTrack + params1 + '&userid=' + encodeURIComponent(userid)).subscribe(response => {
    });
    setTimeout(() => {
      this.postOnGA4(params1);
    }, 100);
  }
  postOnGA4(params): void {
    const paramObj = parseParamsFromURLString(params);
    const action = paramObj['ua_action'];
    const label = paramObj['ua_item'];
    paramObj['ua_action'] = undefined;
    paramObj['ua_item'] = undefined;
    paramObj['hjUserId'] = undefined;
    paramObj['gaClientId'] = undefined;
    const paramArr = [];
    Object.keys(paramObj).forEach((key) => {
      if (paramArr.length < 2) {
        const value = paramObj[key];
        let name = key;
        if (key.indexOf('ua_') === 0) {
          name = key.replace('ua_', '');
        }
        if (value) {
          paramArr.push({ 'name': name, 'value': value });
        }
      }
    });
    const eventParams = {};
    eventParams['event_category'] = 'UATrackOnOTA';
    eventParams['event_name'] = action;
    if (label) {
      eventParams['event_label'] = label;
    }
    if (paramArr.length > 0) {
      eventParams[paramArr[0].name] = paramArr[0].value;
    }
    if (paramArr.length > 1) {
      eventParams[paramArr[1].name] = paramArr[1].value;
    }
    gtag('event', action, eventParams);
  }
  resetOutgoingSelectedFlight(index) {
    this.filterService.currLegIndex = 0;

    if (index === 0) {
      this.multipleOutgoingSelectedFlight.splice(index);
      this.seletedIndex = 0;
    } else {
      this.outgoingSelectedFlight = this.multipleOutgoingSelectedFlight[index - 1];
      if (this.multipleOutgoingSelectedFlight && this.multipleOutgoingSelectedFlight.length > 0) {
        var temp = (this.multipleOutgoingSelectedFlight[0].legs.length - index);
        this.seletedIndex = this.multipleOutgoingSelectedFlight.length;
      }
    }
    if (this.returnFlightSearchResponse) {
      //  console.log("return flights111",this.returnFlightSearchResponse.flightsLists[0].flights);
      if (this.nonCombo) {
        let procssedIds: string[] = [];
        let uniqueFlightResults: FlightResult[] = [];

        for (let flightResult of this.processedFlightResults[this.seletedIndex]) {
          if (procssedIds.indexOf(flightResult.flightId) == -1) {
            uniqueFlightResults.push(flightResult);
            procssedIds.push(flightResult.flightId)
          } else {
            let oldIndex: number = procssedIds.indexOf(flightResult.flightId);
            if ((!uniqueFlightResults[oldIndex].legs[0].brandDetails || uniqueFlightResults[oldIndex].legs[0].brandDetails.length == 0)
              &&
              (flightResult.legs[0].brandDetails && flightResult.legs[0].brandDetails.length > 0)) {
              //Swap
              uniqueFlightResults[oldIndex] = flightResult;
            }
          }
          this.returnFlightSearchResponse.flightsLists[0].flights = uniqueFlightResults;
        }
        //   this.returnFlightSearchResponse.flightsLists[0].flights = this.processedFlightResults[this.seletedIndex];
      } else {
        this.returnFlightSearchResponse.flightsLists[0].flights = this.getUniqueFlightNew();
      } //this.getUniqueFlight(index);
      // console.log("return flights",this.returnFlightSearchResponse.flightsLists[0].flights);
      this.updateAirlineOptions(this.returnFlightSearchResponse);
      this.filterService.setOriginalFlightSearchResults(this.returnFlightSearchResponse);
      this.filterService.broadcastResetFiltersResults();
      this.currentSortOptionId = 'recommended';
      this.changeClick = true;
      this.sortResultsAsRecommended(this.returnFlightSearchResponse);
    }
  }
  getOutgoingSelectedFlight() {
    return this.outgoingSelectedFlight;
  }
  updateFlightSearchResponse(flightSearchResponse: FlightSearchResponse) {
    if ((flightSearchResponse.flightsLists &&
      flightSearchResponse.flightsLists[0] && flightSearchResponse.flightsLists[0].flights)) {
      // this.flightSearchResponse= flightSearchResponse;
      this.outgoingSelectedFlight = undefined;
      this.flightSearchResponse = this.processMultipleComboResults(flightSearchResponse);
      this.updateAirlineOptions(this.flightSearchResponse);
      this.filterService.updateOriginalFlightSearchResults(this.flightSearchResponse);
      if(this.multiTripBooking){
        let airportArr =this.flightSearchResponse.airports;
        let airports = JSON.parse(this.gallopLocalStorage.getItem('airports'));
        if(airports){
        airportArr  = Object.assign(airports,airportArr);
        }
        let airlineArr =this.flightSearchResponse.airlineNames;
        let airlines = JSON.parse(this.gallopLocalStorage.getItem('airlineNames'));
        if(airlines){
        airlineArr  = Object.assign(airlines,airlineArr);
        }
        this.gallopLocalStorage.setItem('airports', JSON.stringify(airportArr));
        this.gallopLocalStorage.setItem('airlineNames', JSON.stringify(airlineArr));
      }else{
      this.gallopLocalStorage.setItem('airports', JSON.stringify(this.flightSearchResponse.airports));
      this.gallopLocalStorage.setItem('airlineNames', JSON.stringify(this.flightSearchResponse.airlineNames));
      }
      this.sortResultsAsRecommended(this.flightSearchResponse);
    }
  }
  setFlightSearchResponse(flightSearchResponse: FlightSearchResponse, overrideOriginalSearchRes: boolean, event?) {
    if (overrideOriginalSearchRes && (flightSearchResponse.flightsLists &&
      flightSearchResponse.flightsLists[0] && flightSearchResponse.flightsLists[0].flights)) {
      this.outgoingSelectedFlight = undefined;
      this.originalFlightsWithIndex = undefined;
      this.orgFlightResponse = flightSearchResponse;
      this.flightSearchResponse = this.processMultipleComboResults(flightSearchResponse);
      this.updateAirlineOptions(this.flightSearchResponse);
      this.filterService.setOriginalFlightSearchResults(this.flightSearchResponse);
      if(this.multiTripBooking){
        let airportArr =this.flightSearchResponse.airports;
        let airports = JSON.parse(this.gallopLocalStorage.getItem('airports'));
        if(airports){
          airportArr  = Object.assign(airports,airportArr);
          }
          let airlineArr =this.flightSearchResponse.airlineNames;
          let airlines = JSON.parse(this.gallopLocalStorage.getItem('airlineNames'));
          if(airlines){
          airlineArr  = Object.assign(airlines,airlineArr);
          }
        this.gallopLocalStorage.setItem('airports', JSON.stringify(airportArr));
        this.gallopLocalStorage.setItem('airlineNames', JSON.stringify(airlineArr));
      }else{
      this.gallopLocalStorage.setItem('airports', JSON.stringify(this.flightSearchResponse.airports));
      this.gallopLocalStorage.setItem('airlineNames', JSON.stringify(this.flightSearchResponse.airlineNames));
      }
      if (this.flightSearchResponse.cabinClassNames) {
        this.gallopLocalStorage.setItem('cabinClassNames', JSON.stringify(this.flightSearchResponse.cabinClassNames));
      }

      if (this.flightSearchResponse.flightPolicy) {
        this.gallopLocalStorage.setItem('flightPolicy', JSON.stringify(this.flightSearchResponse.flightPolicy));
      } else {
        this.gallopLocalStorage.removeItem('flightPolicy');
      }
      if (!event && this.seletedIndex === 0) {
        this.applySorting(this.flightSearchResponse);
      }
      if (this.seletedIndex > 0 && !this.backClick) {
        this.setOutgoingMultipleSelectedFlight(this.tempFlight[this.tempFlight.length - 1], this.seletedIndex, event);
        //this.applySorting(this.flightSearchResponse);
        if (event) {
          this.filterService.broadcastGetFiltersResults();
        }
      } else if (event) {
        let flightResponse = this.flightSearchResponse;
        if (event.airlineFilter && event.airlineFilter.length > 0) {
          //flightResponse = this.filterService.filterByPreferredAirlines(this.selectedFilter, flightResponse);
        }
        if (event.sourceAirports && event.sourceAirports.length > 0) {
          // flightResponse = this.filterService.filterByPreferredAirport(this.selectedSourceAirports, flightResponse);
        }
        if (event.destAirports && event.destAirports.length > 0) {
          // flightResponse = this.filterService.filterByPreferredAirport(this.selectedDestAirports, flightResponse);
        }
        this.filterService.broadcastGetFiltersResults();
        // this.applySorting(flightResponse);
      }
    } else {
      this.broadCastFlightResponse(flightSearchResponse);
    }
  }
  public hasReturnFlightSearchResponse(): boolean {
    return (this.processedFlightResults && this.processedFlightResults.length > 1) ? true : false;
  }
  private generateFlightIdForDuplicateRemoval(legs: FlightLegResult[]) {
    let key = '';
    for (let legIndex = 0; legIndex < legs.length; legIndex++) {
      const currLeg: FlightLegResult = legs[legIndex];
      key += this.generateLegFlightId('', -1, currLeg);
      // for (let hopIndex = 0; hopIndex < currLeg.flightHops.length; hopIndex++) {
      //   let currHop: FlightHopResult = currLeg.flightHops[hopIndex];
      //   key += currHop.from + '_' + currHop.to + '_' + currHop.carrier + '_' + currHop.starts + '_' + currHop.ends
      // }
    }
    return key;
  }



  public removeDuplicates(input: FlightResult[]) {
    if (!input || input.length == 0) {
      return input;
    }
    let processedList: FlightResult[] = [];
    let uniqKeySet = {};
    for (let flight of input) {
      let currKey = this.generateFlightIdForDuplicateRemoval(flight.legs);
      if (!uniqKeySet[currKey]) {
        uniqKeySet[currKey] = 1;
        processedList.push(flight);
      }
    }
    return processedList;
  }
  returnFlightSearchResponse: FlightSearchResponse;
   removeZFromDate(isoDateString: string): string {
    return isoDateString.replace(/Z$/, ''); // Remove 'Z' from the end
  }
  checkOOPAccordingToEventConstraints(eventObj, item) {
    const event = eventObj.event;
    const fligtArrivalStr = item.legs[0].flightHops[item.legs[0].flightHops.length-1].ends;
    const fligtArrival = new Date(fligtArrivalStr);
    const eventStart = new Date(event.start.substring(0, 23) + fligtArrivalStr.substring(23));
    const eventEnd = new Date(event.end.substring(0, 23).replace('00:00:00', '23:59:59') + fligtArrivalStr.substring(23));
    if (event.items[0].suggestedStart) {
      // suggested start given so forward leg arrival shoydl be between eventStart and suggested start
      const eventSuggestedLatestArrival = new Date(event.items[0].suggestedStart.substring(0,23) + fligtArrivalStr.substring(23));
      if (fligtArrival < eventStart || fligtArrival > eventSuggestedLatestArrival) {
        return true;
      }
    } else {
      //Suggested start not given so we will just check that flight arrival is within event dates
      if (fligtArrival < eventStart || fligtArrival > eventEnd) {
        return true;
      }
    }

    if (item.legs.length === 2){
      const flightDeparture = new Date(item.legs[1].flightHops[0].starts);
      if (event.items[0].suggestedEnd ) {
        // suggested end given so return leg departure shoydl be between suggested end and eventEnd 
        const suggestedEarliestDeparture = new Date(event.items[0].suggestedEnd.substring(0,23) + fligtArrivalStr.substring(23));
        if (flightDeparture < suggestedEarliestDeparture || flightDeparture > eventEnd) {
          return true;
        }
      } else {
        // suggested end not given so return leg departure shoydl be  within event dates
        if (flightDeparture < eventStart || flightDeparture > eventEnd) {
          return true;
        }
      }
    }
      return false;
  }
  updatePolicyAccordingToEventConstraints(isOutOfPolicyBecauseOfEvent, flightItem) {
    if (isOutOfPolicyBecauseOfEvent) {
      for(const otherFareItem of flightItem.otherFarePriceAttributes) {
        for( const legItem of otherFareItem.legs) {
          legItem.flightHighlights.withinPolicy=false;
          legItem.flightHighlights.outOfPolicyContext.reason = 'NOT_ACCORDING_EVENT_DEF';
          legItem.flightHighlights.withinPolicy=false;
          legItem.flightHighlights.outOfPolicyContext.reason = 'NOT_ACCORDING_EVENT_DEF';
        }
      }
      for( const legItem of flightItem.legs) {
        legItem.flightHighlights.withinPolicy=false;
        legItem.flightHighlights.outOfPolicyContext.reason = 'NOT_ACCORDING_EVENT_DEF';
        legItem.flightHighlights.withinPolicy=false;
        legItem.flightHighlights.outOfPolicyContext.reason = 'NOT_ACCORDING_EVENT_DEF';
      }
  }
  }
  updatePolicyOnAllFlightsAccordingToEventConstraints(flights) {
    if (this.selectedEventID && flights && flights.length > 0) {
      let isSearchOutOfPolicy = false;
      const event = this.userAccountService.eventList.filter(item => item.event.id === this.selectedEventID);
      if (event && event.length > 0 && event[0]
          && event[0].event.id
          && event[0].event.items
          && event[0].event.items.length > 0) {
        const eventObj = event[0];
        const userid = this.userAccountService.getUserEmail();
        if(
          flights[0].legs.length > 2
          ||
          this.isDestinationOutOFEventPolicy(this.flightSearchQueryParam)
          ||
          this.employeeEmail.length > 1
            ||
          (this.employeeEmail.length === 1 && this.employeeEmail[0].email!==userid)) {
            isSearchOutOfPolicy =true;
        }
        if (isSearchOutOfPolicy) {
          for( const flightItem of flights) {
            this.updatePolicyAccordingToEventConstraints(true, flightItem);
          }
        } else {
          for( const flightItem of flights) {
            this.updatePolicyAccordingToEventConstraints(
              this.checkOOPAccordingToEventConstraints(eventObj, flightItem)
              , flightItem);
          }
        }
      }
    }
  }
  private processMultipleComboResults(flightSearchResponse: FlightSearchResponse) {
    if (flightSearchResponse || flightSearchResponse.flightsLists ||
      flightSearchResponse.flightsLists[0] || flightSearchResponse.flightsLists[0].flights) {
      if (!this.nonCombo) {
        this.multipleNoOfLegs = flightSearchResponse.flightsLists[0].flights[0].legs.length;
      } else {
        this.multipleNoOfLegs = flightSearchResponse.flightsLists.length;
      }
      if(flightSearchResponse.flightsLists[0].flights[0].legs.length===1 || (flightSearchResponse.flightsLists[0].flights[0].legs.length > 1 && this.seletedIndex && this.seletedIndex > 0)){
      this.updatePolicyOnAllFlightsAccordingToEventConstraints(flightSearchResponse.flightsLists[0].flights);
      }
      var inPolicyFlights=[];
        for(let item of flightSearchResponse.flightsLists[0].flights){
          for(let item1 of item.otherFarePriceAttributes){
            if(item1.legs[0].flightHighlights.withinPolicy){
              if(item.travelCreditsInfo){
                item1['travelCreditsInfo'] = item.travelCreditsInfo;
              }
              inPolicyFlights.push(item1);
            }
          }
        }
        console.log("inpolicy flights11",inPolicyFlights);
      if (!this.nonCombo && (this.previousSearch && this.previousSearch.classTypeSwitch === AlgoTypes.PODUCTIVITY.toString() && environment.presentAlternateInPolicyOptions && inPolicyFlights) && inPolicyFlights.length > 0) {
        const distinctArray = inPolicyFlights.filter((flight, i, arr) => arr.findIndex(t => t.legs[0].legId === flight.legs[0].legId) === i);
        if (distinctArray.length > 3) {
          distinctArray.splice(3);
         // this.inPolicyFlight = [...distinctArray];
        } else {
         // this.inPolicyFlight = [...distinctArray];
        }
      }
      console.log("inpolicy flights",this.inPolicyFlight)
    }
    this.processedFlightResults = new Array(this.multipleNoOfLegs).fill(null).map(_ => []);
    let outgoingFlightSearchResponse: FlightSearchResponse = JSON.parse(JSON.stringify(flightSearchResponse));
    if (!flightSearchResponse || !flightSearchResponse.flightsLists ||
      !flightSearchResponse.flightsLists[0] || !flightSearchResponse.flightsLists[0].flights) return outgoingFlightSearchResponse;
    let orgIndex: number = 0;
    if (this.nonCombo) {
      for (let i = 0; i < flightSearchResponse.flightsLists.length; i++) {
        for (let flight of flightSearchResponse.flightsLists[i].flights) {
          for (let j = 0; j < flight.legs.length; j++) {
            let processedFlight: FlightResult = JSON.parse(JSON.stringify(flight));
            processedFlight.flightId = this.generateLegFlightId(processedFlight.handlerType, flight.price, flight.legs[j]);
            if (i < (this.processedFlightResults.length - 1)) {
              processedFlight.childFlightId = processedFlight.flightId
            } else {
              processedFlight.parentFlightId = processedFlight.flightId
            }
            processedFlight.recommendedIdx = orgIndex;
            let legs = [];
            legs.push(flight.legs[j]);
            processedFlight.legs = legs;
            this.processedFlightResults[i].push(processedFlight);
          }
          orgIndex++;
        }
      }
    } else {
      for (let flight of flightSearchResponse.flightsLists[0].flights) {
        for (let i = 0; i < flight.legs.length; i++) {
          let processedFlight: FlightResult = JSON.parse(JSON.stringify(flight));
          processedFlight.flightId = this.generateLegFlightId(processedFlight.handlerType, flight.price, flight.legs[i]);
          processedFlight.recommendedIdx = orgIndex;
          let legs = [];
          legs.push(flight.legs[i]);
          processedFlight.legs = legs;
          this.processedFlightResults[i].push(processedFlight);
        }
        let index = 0;
        for (let flight of this.processedFlightResults) {
          this.putParentId(flight, index)
          index = index + 1;
        }
        orgIndex++;
      }
    }

    this.originalFlightsWithIndex = this.processedFlightResults[0];
    let procssedIds: string[] = [];
    let uniqueFlightResults: FlightResult[] = [];

    for (const flightResult of this.originalFlightsWithIndex) {
      if (procssedIds.indexOf(flightResult.flightId) === -1) {
        uniqueFlightResults.push(flightResult);
        procssedIds.push(flightResult.flightId);
      } else {
        // We will only merge flight results that are entirly different flights
        // const oldIndex: number = procssedIds.indexOf(flightResult.flightId);
        // const oldItem = uniqueFlightResults[oldIndex];
        // for(const newItem of flightResult.otherFarePriceAttributes) {
        //   const newItemHopForMaxStar = CommonUtils.getHopForMaxStar(newItem, this);
        //   if (newItemHopForMaxStar) {
        //     const matchedItems = oldItem.otherFarePriceAttributes.find((itemToMatch) => {
        //       const oldItemHopForMaxStar = CommonUtils.getHopForMaxStar(itemToMatch, this);
        //       if (oldItemHopForMaxStar) {
        //         if (newItemHopForMaxStar.ngsStars === oldItemHopForMaxStar.ngsStars) {
        //           return true;
        //         }
        //       }
        //       return false;
        //     });
        //     if (!matchedItems || matchedItems.length === 0) {
        //       oldItem.otherFarePriceAttributes.push(newItem);
        //       if ((!oldItem['allUpsellFares'] || oldItem['allUpsellFares'].length === 0)
        //         &&
        //         (newItem['allUpsellFares'] && newItem['allUpsellFares'].length > 0)) {
        //           oldItem['allUpsellFares'] = newItem['allUpsellFares'];
        //       }
        //     }
        //   }
        // }
      }
    }


    outgoingFlightSearchResponse.flightsLists[0].flights = uniqueFlightResults;
    if (this.processedFlightResults.length > 1) {
      this.returnFlightSearchResponse = JSON.parse(JSON.stringify(flightSearchResponse));
      this.returnFlightSearchResponse.flightsLists[0].flights = this.processedFlightResults[1];
    } else {
      this.returnFlightSearchResponse = undefined;
    }

    return outgoingFlightSearchResponse;
  }
  private putParentId(flight, index) {
    if (index < this.processedFlightResults.length - 1) {
      var i = 0;
      for (let item of flight) {
        this.processedFlightResults[index + 1][i].parentFlightId = this.processedFlightResults[index][i].flightId
        this.processedFlightResults[index][i].childFlightId = this.processedFlightResults[index + 1][i].flightId;
        i++;
      }
    }
  }

  private generateLegFlightId(handler: string, price: number, leg: FlightLegResult) {
    let flightId = '';
    for(let hopCounter = 0; hopCounter < leg.flightHops.length; hopCounter++) {
      flightId += leg.flightHops[hopCounter].from + '_' + leg.flightHops[hopCounter].to 
      +     '_' + leg.flightHops[hopCounter].carrier + '_' + leg.flightHops[hopCounter].starts + '_' + leg.flightHops[hopCounter].ends;
    }
    return flightId;
    // return leg.flightHops[0].from + '_' + leg.flightHops[0].to +'_'
    // + '_' + leg.flightHops[0].starts + '_' + leg.flightHops[leg.flightHops.length - 1].ends;
  }
  search1(msg, type): any {
    return this.http.get(environment.apiForAirportsOnSearchForm + '?query=' + msg + '&type=' + type);
  }
  searchGoogleAutoComplete(msg): any {
    return this.http.get(environment.apiForGoogleAutoCompletePredictions + '?queryInput=' + msg);
  }


  getTravellerList(companyId): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.get(environment.apiForTravellersList + '?companyId=' + companyId);
 }
 emptytravelList(){
  this.travellerListResponseSubject.next(null);
 }
 fetchEmployeesList(companyId: number) {
  this.getTravellerList(companyId).subscribe(res => {
    if (res && res.status==='success') {
      let employeeList = deserialize(res.data);
     this.travellerList = employeeList;
     this.userAccountService.setExpenseApprovalData();
     this.travellerListResponseSubject.next(employeeList);
    } else if (res && res.error_message) {
      this.toastr.error(res.error_message);
    } else {
      this.toastr.error("Apologies! something went wrong, we could'nt retrive employees list. Please try again later or contact support");
    }
  }, error => {
    if (error.status != 403) {
      setTimeout(() => {
        let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
        this.toastr.error(resultErrorMessage);
      }, 100);
    }
  })
}
  search(flightSearchQueryParam: FlightSearchQueryParam, searchActionType: SearchActionType, event?,previousTransactionid?): Observable<any> {
    this.setPreviousSearch(flightSearchQueryParam);
   // this.searchHeaderCliked = true;
    let searchRequest: FlightSearchRequest = this.getSearchRequest(flightSearchQueryParam);
    if (this.minPrices) {
      searchRequest.minPrices = this.minPrices;
    }
    if (event && event.airlineFilter && event.airlineFilter.length > 0) {
      searchRequest.filtered_airlines = event.airlineFilter;
      for (let item of event.airlineFilter) {
        if (this.filtered_airlines.indexOf(item) === -1) {
          this.filtered_airlines.push(item);
        }
      }
    }
    if(this.multiTripBooking && this.previousSelectedCreditAirline.length > 0){
      searchRequest.previousSelectedCreditAirline = this.previousSelectedCreditAirline;
    }
    if (event && event.sourceAirports && event.sourceAirports.length > 0) {
      searchRequest.src_filtered_airports = event.sourceAirports;
      for (let item of event.sourceAirports) {
        if (this.filtereSourceAirports.indexOf(item) === -1) {
          this.filtereSourceAirports.push(item);
        }
      }
    }
    if (event && event.selectedLeg && event.selectedLeg.length > 0) {
      searchRequest.selected_legs = event.selectedLeg;
    }
    if(this.selectedEventID){
      searchRequest.group_travel_event_id = this.selectedEventID;
    }
    if(previousTransactionid){
      searchRequest.previousTransactionid = previousTransactionid;
    }
    if (event && event.destAirports && event.destAirports.length > 0) {
      searchRequest.dest_filtered_airports = event.destAirports;
      for (let item of event.destAirports) {
        if (this.filteredDestAirports.indexOf(item) === -1) {
          this.filteredDestAirports.push(item);
        }
      }
    }
    this.broadcastNoOfFlightLegs(searchRequest.requests.length);
    this.broadCastFlightRequest(flightSearchQueryParam);
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.get(environment.apiSearchFlightUrl
      + '?' + 'action=' + searchActionType + '&query=' + encodeURIComponent(JSON.stringify(searchRequest)) + '&eventid=' + this.selectedEventID
     + '&searchHandler=MYSTIFLY');
  }


  getMappingStars(): Observable<any> {
    return this.http.get(environment.apiForMappingStar);
  }
  getSearchRequest(flightSearchQueryParam: FlightSearchQueryParam): FlightSearchRequest {

    let searchRequest: FlightSearchRequest = new FlightSearchRequest();

    if (flightSearchQueryParam.class) {
      searchRequest.cabinClass = flightSearchQueryParam.class;
    }
    searchRequest.combo = "true";
    if (flightSearchQueryParam.trip) {
      searchRequest.tripType = flightSearchQueryParam.trip.toString();
    }
    searchRequest.algoType = flightSearchQueryParam.classTypeSwitch;
    if (flightSearchQueryParam.adults) searchRequest.travellers.adults = Number.parseInt(flightSearchQueryParam.adults);
    if (flightSearchQueryParam.children) searchRequest.travellers.children = Number.parseInt(flightSearchQueryParam.children);
    if (flightSearchQueryParam.infants) searchRequest.travellers.infants = Number.parseInt(flightSearchQueryParam.infants);

    if (!flightSearchQueryParam.adults) {
      searchRequest.travellers.adults = Number.parseInt(flightSearchQueryParam.passengers);
    }
    if (flightSearchQueryParam.travellerAgeGroup && flightSearchQueryParam.travellerAgeGroup.length > 0) {
      if (!this.multiTripBooking && this.ageGroupArray.length !== flightSearchQueryParam.travellerAgeGroup.length) {
        this.ageGroupArray=[];
        this.adultCount = Number.parseInt(flightSearchQueryParam.adults) + Number.parseInt(flightSearchQueryParam.children);
        this.ageGroupArray = flightSearchQueryParam.travellerAgeGroup;
      }  

    }
    if (flightSearchQueryParam.travellerEmails && flightSearchQueryParam.travellerEmails.length > 0) {
      searchRequest.travellerEmails = flightSearchQueryParam.travellerEmails;
      if (!this.multiTripBooking && this.employeeEmail.length !== flightSearchQueryParam.travellerEmails.length) {
        this.employeeEmail = [];
        for (let i = 0; i < flightSearchQueryParam.travellerEmails.length; i++) {
          let employee1 = { Name: '', Id: -1, Type: '' };
          employee1['email'] = flightSearchQueryParam.travellerEmails[i];
          this.employeeEmail.push(employee1);
        }
      }
    }
    if (flightSearchQueryParam.eventid){
        this.selectedEventID = flightSearchQueryParam.eventid;
    }
   // this.selectEventPolicy(flightSearchQueryParam);
   
  
    searchRequest.requests = [];

    //main formgroup
    searchRequest.requests.push(this.getDepartureLegSearchRequest(flightSearchQueryParam));
    //    searchRequest.requests.push(this.getFlightLegSearchRequest(flightSearchQueryParam));
    if (flightSearchQueryParam.trip == 'ROUNDTRIP') {
      searchRequest.requests.push(this.getArrivalLegSearchRequest(flightSearchQueryParam));
    }

    //formarray
    for (let flight of flightSearchQueryParam.flights) {
      searchRequest.requests.push(this.getFlightLegSearchRequest(flight));
    }

    return searchRequest;
  }
  selectEventPolicy(flightSearchQueryParam){
    if (this.selectedEventID) {
      let event = this.userAccountService.eventList.filter(item => item.event.id === this.selectedEventID);
      if (event && event[0] && event[0].event.id) {
        if (event[0].event.start && event[0].event.end) {
          let firstDate = new Date(event[0].event.start);
          let userid = this.userAccountService.getUserEmail();
          firstDate.setHours(0);
          firstDate.setMinutes(0);
          firstDate.setSeconds(0);
          firstDate.setMilliseconds(0);
          let lastDate = new Date(event[0].event.end);
          lastDate.setHours(23);
          lastDate.setMinutes(59);
          lastDate.setSeconds(59);
          lastDate.setMilliseconds(0);
          if((this.employeeEmail.length > 1) || (this.employeeEmail.length>0 && this.employeeEmail[0].email!==userid) || (new Date(flightSearchQueryParam.departureDate) < firstDate) ||  (new Date(flightSearchQueryParam.departureDate) > lastDate) || (flightSearchQueryParam.arrivalDate && new Date(flightSearchQueryParam.arrivalDate) >lastDate) || (this.isDestinationOutOFEventPolicy(flightSearchQueryParam))){
            this.isEventOutOfPolicy =true;
          
          }
          if(!this.isEventOutOfPolicy && event[0].event.items && event[0].event.items.length > 0 && event[0].event.items[0].suggestedEnd){
           
              let firstDate1 = new Date(event[0].event.items[0].suggestedStart);
              let userid = this.userAccountService.getUserEmail();
              firstDate1.setHours(0);
              firstDate1.setMinutes(0);
              firstDate1.setSeconds(0);
              firstDate1.setMilliseconds(0);
              let lastDate2 = new Date(event[0].event.items[0].suggestedEnd);
              lastDate2.setHours(23);
              lastDate2.setMinutes(59);
              lastDate2.setSeconds(59);
              lastDate2.setMilliseconds(0);
              if((new Date(flightSearchQueryParam.departureDate) < firstDate1) || (new Date(flightSearchQueryParam.arrivalDate) >lastDate2)){
              this.isEventOutOfPolicy =true;
            }

          }
        }
      }
    }
  }
  isDestinationOutOFEventPolicy(flightSearchQueryParam){
    let event = this.userAccountService.eventList.filter(item => item.event.id === this.selectedEventID);
   if(event && event[0].event.items && event[0].event.items.length >0  && event[0].event.items[0].destinationAirports.length > 0){
     let findAirport =  event[0].event.items[0].destinationAirports.find(airport => airport=== flightSearchQueryParam.destination);
     if(!findAirport){
    return   true;
     }else{
     return  false;
     }
   }
 }
  getDepartureLegSearchRequest(flightQueryParam: FlightQueryParam): FlightLegSearch {
    let flightLegSearch: FlightLegSearch = new FlightLegSearch();
    let journeyDate: JourneyDate = new JourneyDate();
    flightLegSearch.origin = flightQueryParam.source;
    flightLegSearch.destination = flightQueryParam.destination;
    journeyDate.intervalType = flightQueryParam.timeTypeFilter;
    let date = flightQueryParam.departureDate;
    let time = flightQueryParam.constraintTime;
    flightLegSearch.journeyDate = journeyDate;
    let dateObj = DateUtils.getDateAsDatePickerObject(date);
    journeyDate.intervalStart = this.getDateByTimeConstraint(dateObj, time, 'start');
    journeyDate.intervalEnd = this.getDateByTimeConstraint(dateObj, time, 'end');
    flightLegSearch.originType = flightQueryParam.originType;
    flightLegSearch.destinationType = flightQueryParam.destinationType;
    return flightLegSearch;
  }
  getArrivalLegSearchRequest(flightQueryParam: FlightQueryParam): FlightLegSearch {
    let flightLegSearch: FlightLegSearch = new FlightLegSearch();
    let journeyDate: JourneyDate = new JourneyDate();
    flightLegSearch.origin = flightQueryParam.destination;
    flightLegSearch.destination = flightQueryParam.source;
    flightLegSearch.originType = flightQueryParam.destinationType;
    flightLegSearch.destinationType = flightQueryParam.originType;

    journeyDate.intervalType = flightQueryParam.timeTypeFilter1;
    let date = flightQueryParam.arrivalDate;
    let time = flightQueryParam.constraintTime1;
    flightLegSearch.journeyDate = journeyDate;
    let dateObj = DateUtils.getDateAsDatePickerObject(date);
    journeyDate.intervalStart = this.getDateByTimeConstraint(dateObj, time, 'start');
    journeyDate.intervalEnd = this.getDateByTimeConstraint(dateObj, time, 'end');
    return flightLegSearch;
  }

  
  getFlightLegSearchRequest(flightQueryParam: FlightQueryParam): FlightLegSearch {

    let flightLegSearch: FlightLegSearch = new FlightLegSearch();

    let journeyDate: JourneyDate = new JourneyDate();

    let date = (flightQueryParam.constraint == IntervalType.DEPARTURE_BASED) ?
      flightQueryParam.departureDate : flightQueryParam.arrivalDate;
    let time = flightQueryParam.constraintTime;

    let dateObj = DateUtils.getDateAsDatePickerObject(date);

    journeyDate.intervalStart = this.getDateByTimeConstraint(dateObj, time, 'start');
    journeyDate.intervalEnd = this.getDateByTimeConstraint(dateObj, time, 'end');

    journeyDate.intervalType = flightQueryParam.constraint;
    flightLegSearch.journeyDate = journeyDate;
    flightLegSearch.originType = flightQueryParam.destinationType;
    flightLegSearch.destinationType = flightQueryParam.originType;
    flightLegSearch.origin = flightQueryParam.source;
    flightLegSearch.destination = flightQueryParam.destination;

    return flightLegSearch;
  }

  getDateByTimeConstraint(searchDateObj: any, timeConstraint: string, type: string): any {

    //let date = new Date(searchDateObj.year,(searchDateObj.month-1),searchDateObj.day);
    return this.setDateHours(searchDateObj, timeConstraint, type);
    //    return DateUtils.getAPIFormattedDateWithTimeZone(date);
  }


  setDateHours(searchDateObj: any, timeConstraint: string, type: string, hour?: number): string {

    if (!hour) { hour = this.getTimeConstraintHour(timeConstraint, type); }

    if (hour == 24) {
      return searchDateObj.year + "-" + (searchDateObj.month) + "-" + searchDateObj.day + "T23:59:00";
    } else {
      if (hour < 10) {
        return searchDateObj.year + "-" + (searchDateObj.month) + "-" + searchDateObj.day + "T0" + hour + ":00:00";
      } else {
        return searchDateObj.year + "-" + (searchDateObj.month) + "-" + searchDateObj.day + "T" + hour + ":00:00";
      }
    }
  }

  getTimeConstraintHour(timeConstraint: string, type: string): number {
    return Number(this.timeOptions.filter(time => (time.titleID.toLowerCase() === timeConstraint.toLowerCase()))[0].hours[type.toLowerCase()]);
  }

  static compareFlightResults(aflight: FlightResult, bflight: FlightResult) {
    if (aflight.displayPrice < bflight.displayPrice) {
      return -1;
    }
    if (aflight.displayPrice > bflight.displayPrice) {
      return 1;
    }
    return 0;
  }
  isMobile: boolean;
  getMultifareMinPriceFlightObj(flight: FlightResult) {
    let effectivePriceObject = flight;
    if(this.isMobile){
      return effectivePriceObject;
     }else if(!this.isMobile && flight.otherFarePriceAttributes
      && flight.otherFarePriceAttributes.length > 0) {
        effectivePriceObject = flight.otherFarePriceAttributes[0];
        for(let counter = 1; counter < flight.otherFarePriceAttributes.length; counter++) {
          if (effectivePriceObject.price > flight.otherFarePriceAttributes[counter].price) {
            effectivePriceObject = flight.otherFarePriceAttributes[counter];
          }
        }
    }
    return effectivePriceObject;
  }
  getPriceAfterCreditApplied(effectivePrice, pricePerPassenger, travelCreditsInfo, currItemCarrrier) {
    if (travelCreditsInfo && travelCreditsInfo.length > 0) {
      for (let counter = 0; counter < travelCreditsInfo.length; counter++) {
        const item = travelCreditsInfo[counter];
        if (item.vendorCode = currItemCarrrier) {
          if (item.displayCreditAmount) {
            if (item.displayCreditAmount <= pricePerPassenger) {
              effectivePrice -= item.displayCreditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          } else {
            if (item.creditAmount <= pricePerPassenger) {
              effectivePrice -= item.creditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
        }
      }
    }
    return effectivePrice;
  }
  priceCompareFunction(a, b){
    const noOfPassenger = this.employeeEmail.length;
    let nonComboprice = 0;
    const selectedIndex = this.seletedIndex;
    let nonComboSelectedFlight = [];
    if (this.nonCombo) {
      for (const item of this.nonComboSelectedFlight) {
        if (item.displayPrice) {
          nonComboprice += item.displayPrice;
        } else {
          nonComboprice += item.price;
        }
      }
      if (this.nonComboSelectedFlight.length > 0) {
        nonComboSelectedFlight = this.nonComboSelectedFlight;
      }
    }

  const aEffective = this.getMultifareMinPriceFlightObj(a);
  const bEffective = this.getMultifareMinPriceFlightObj(b);
  let aPrice = (aEffective.displayPrice)? aEffective.displayPrice
    : ((aEffective.discountedPrice && aEffective.discountedPrice > 0) ? aEffective.discountedPrice : aEffective.price);
  let bPrice = (bEffective.displayPrice)? bEffective.displayPrice
    : ((bEffective.discountedPrice && bEffective.discountedPrice > 0) ? bEffective.discountedPrice : bEffective.price);
  const aPricePerPassenger = ((aPrice + nonComboprice) / noOfPassenger);
  const bPricePerPassenger = ((bPrice + nonComboprice) / noOfPassenger);
  aPrice = this.getPriceAfterCreditApplied(aPrice, aPricePerPassenger, a.travelCreditsInfo, a.legs[0].flightHops[0].carrier);
  bPrice = this.getPriceAfterCreditApplied(bPrice, bPricePerPassenger, b.travelCreditsInfo, b.legs[0].flightHops[0].carrier);
  console.log(aPrice + ' : ' + a.flightId + ', ' + bPrice + ':' + b.flightId);
  return aPrice - bPrice;
}

  sortResultsByPrice(flightSearchResponse: FlightSearchResponse) {
    flightSearchResponse.flightsLists[0].flights 
      = flightSearchResponse.flightsLists[0].flights.sort((a,b) =>this.priceCompareFunction(a,b));

/*
    if (!this.creditsFinished && credits && credits.length > 0 && flightSearchResponse.flightsLists[0].flights.length > 0 && flightSearchResponse.flightsLists[0].flights[0].displayPrice) {
      flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
        let aEffective = this.getMultifareMinPriceFlightObj(a);
        let bEffective = this.getMultifareMinPriceFlightObj(b);
        let aPrice = (aEffective.discountedPrice && aEffective.discountedPrice > 0) ? aEffective.discountedPrice : aEffective.displayPrice;
        let bPrice = (bEffective.discountedPrice && bEffective.discountedPrice > 0) ? bEffective.discountedPrice : bEffective.displayPrice;
        if (nonComboSelectedFlight[selectedIndex - 1].legs[0].flightHops[0].carrier === aEffective.legs[0].flightHops[0].carrier) {
          var creditDetails = a.travelCreditsInfo;
          let effectivePrice = (aPrice);
          const pricePerPassenger = ((aPrice + nonComboprice) / noOfPassenger);
          for (let counter = 0; counter < credits.length; counter++) {
            const item = credits[counter];
            if (item.displayCreditAmount <= pricePerPassenger) {
              effectivePrice -= item.displayCreditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          aPrice = effectivePrice
        }
        if (nonComboSelectedFlight[selectedIndex - 1].legs[0].flightHops[0].carrier === bEffective.legs[0].flightHops[0].carrier) {
          var creditDetails = bEffective.travelCreditsInfo;
          let effectivePrice = (bPrice);
          const pricePerPassenger = ((bPrice + nonComboprice) / noOfPassenger);
          for (let counter = 0; counter < credits.length; counter++) {
            const item = credits[counter];
            if (item.displayCreditAmount <= pricePerPassenger) {
              effectivePrice -= item.displayCreditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          bPrice = effectivePrice;
        }
        if (aPrice < bPrice) return -1;
        else if (aPrice > bPrice) return 1;
        else return 0;
      });
    } else if (flightSearchResponse.flightsLists[0].flights.length > 0 && flightSearchResponse.flightsLists[0].flights[0].displayPrice) {
      flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
        let aEffective = this.getMultifareMinPriceFlightObj(a);
        let bEffective = this.getMultifareMinPriceFlightObj(b);

        let aPrice = (aEffective.discountedPrice && aEffective.discountedPrice > 0) ? aEffective.discountedPrice : aEffective.displayPrice;
        let bPrice = (bEffective.discountedPrice && bEffective.discountedPrice > 0) ? bEffective.discountedPrice : bEffective.displayPrice;
        if (a.travelCreditsInfo && a.travelCreditsInfo.length > 0) {
          var creditDetails = a.travelCreditsInfo;
          let effectivePrice = aPrice;
          const pricePerPassenger = (aPrice / noOfPassenger);
          for (let counter = 0; counter < creditDetails.length; counter++) {
            const item = creditDetails[counter];
            if (item.displayCreditAmount <= pricePerPassenger) {
              effectivePrice -= item.displayCreditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          aPrice = effectivePrice
        }
        if (b.travelCreditsInfo && b.travelCreditsInfo.length > 0) {
          var creditDetails = b.travelCreditsInfo;
          let effectivePrice = bPrice;
          const pricePerPassenger = bPrice / noOfPassenger;
          for (let counter = 0; counter < creditDetails.length; counter++) {
            const item = creditDetails[counter];
            if (item.displayCreditAmount <= pricePerPassenger) {
              effectivePrice -= item.displayCreditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          bPrice = effectivePrice;
        }
        if (aPrice < bPrice) return -1;
        else if (aPrice > bPrice) return 1;
        else return 0;
      });
    }
    else if (!this.creditsFinished && credits && credits.length > 0) {
      flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
        let aEffective = this.getMultifareMinPriceFlightObj(a);
        let bEffective = this.getMultifareMinPriceFlightObj(b);
        let aPrice = (aEffective.discountedPrice && aEffective.discountedPrice > 0) ? aEffective.discountedPrice : aEffective.price;
        let bPrice = (bEffective.discountedPrice && bEffective.discountedPrice > 0) ? bEffective.discountedPrice : bEffective.price;
        if (nonComboSelectedFlight[selectedIndex - 1].legs[0].flightHops[0].carrier === a.legs[0].flightHops[0].carrier) {
          var creditDetails = a.travelCreditsInfo;
          let effectivePrice = (aPrice);
          const pricePerPassenger = ((aPrice + nonComboprice) / noOfPassenger);
          for (let counter = 0; counter < credits.length; counter++) {
            const item = credits[counter];
            if (item.creditAmount <= pricePerPassenger) {
              effectivePrice -= item.creditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          aPrice = effectivePrice
        }
        if (nonComboSelectedFlight[selectedIndex - 1].legs[0].flightHops[0].carrier === b.legs[0].flightHops[0].carrier) {
          var creditDetails = b.travelCreditsInfo;
          let effectivePrice = (bPrice);
          const pricePerPassenger = ((bPrice + nonComboprice) / noOfPassenger);
          for (let counter = 0; counter < credits.length; counter++) {
            const item = credits[counter];
            if (item.creditAmount <= pricePerPassenger) {
              effectivePrice -= item.creditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          bPrice = effectivePrice;
        }
        if (aPrice < bPrice) return -1;
        else if (aPrice > bPrice) return 1;
        else return 0;
      });
    } else {
      flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
        let aEffective = this.getMultifareMinPriceFlightObj(a);
        let bEffective = this.getMultifareMinPriceFlightObj(b);

        let aPrice = (aEffective.discountedPrice && aEffective.discountedPrice > 0) ? aEffective.discountedPrice : aEffective.price;
        let bPrice = (bEffective.discountedPrice && bEffective.discountedPrice > 0) ? bEffective.discountedPrice : bEffective.price;
        if (a.travelCreditsInfo && a.travelCreditsInfo.length > 0) {
          var creditDetails = a.travelCreditsInfo;
          let effectivePrice = aPrice;
          const pricePerPassenger = (aPrice / noOfPassenger);
          for (let counter = 0; counter < creditDetails.length; counter++) {
            const item = creditDetails[counter];
            if (item.creditAmount <= pricePerPassenger) {
              effectivePrice -= item.creditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          aPrice = effectivePrice
        }
        if (b.travelCreditsInfo && b.travelCreditsInfo.length > 0) {
          var creditDetails = b.travelCreditsInfo;
          let effectivePrice = bPrice;
          const pricePerPassenger = bPrice / noOfPassenger;
          for (let counter = 0; counter < creditDetails.length; counter++) {
            const item = creditDetails[counter];
            if (item.creditAmount <= pricePerPassenger) {
              effectivePrice -= item.creditAmount;
            } else {
              effectivePrice -= pricePerPassenger;
            }
          }
          bPrice = effectivePrice;
        }
        if (aPrice < bPrice) return -1;
        else if (aPrice > bPrice) return 1;
        else return 0;
      });
    }
*/
    this.setFlightSearchResponse(flightSearchResponse, false);
  }

  sortResultsByDeparture(flightSearchResponse: FlightSearchResponse) {
    flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
      if (a.legs[0].flightHops[0].starts < b.legs[0].flightHops[0].starts) return -1;
      else if (a.legs[0].flightHops[0].starts > b.legs[0].flightHops[0].starts) return 1;
      else return 0;
    });

    this.setFlightSearchResponse(flightSearchResponse, false);
  }

  sortResultsByArival(flightSearchResponse: FlightSearchResponse) {
    flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
      if (a.legs[0].flightHops[a.legs[0].flightHops.length - 1].ends < b.legs[0].flightHops[b.legs[0].flightHops.length - 1].ends) return -1;
      else if (a.legs[0].flightHops[a.legs[0].flightHops.length - 1].ends > b.legs[0].flightHops[b.legs[0].flightHops.length - 1].ends) return 1;
      else return 0;
    });
    this.setFlightSearchResponse(flightSearchResponse, false);
  }

  sortResultsByDuration(flightSearchResponse: FlightSearchResponse) {
    flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
      if (FlightUtils.getFlightLegDurationInMin(a, 0) < FlightUtils.getFlightLegDurationInMin(b, 0)) return -1;
      else if (FlightUtils.getFlightLegDurationInMin(a, 0) > FlightUtils.getFlightLegDurationInMin(b, 0)) return 1;
      else return 0;
    });
    this.setFlightSearchResponse(flightSearchResponse, false);
  }


  currentSortOptionId = 'recommended';

  public applySorting(flightSearchResponse) {
    setTimeout(() => {
      if (this.currentSortOptionId === 'price') {
        this.sortResultsByPrice(flightSearchResponse);
      } else if (this.currentSortOptionId === 'duration') {
        this.sortResultsByDuration(flightSearchResponse);
      } else if (this.currentSortOptionId === 'arrivalTime') {
        this.sortResultsByArival(flightSearchResponse);
      } else if (this.currentSortOptionId === 'departureTime') {
        this.sortResultsByDeparture(flightSearchResponse);
      } else if (this.currentSortOptionId === 'recommended') {
        this.sortResultsAsRecommendedWithFilterService(flightSearchResponse, this.filterService);
      }
    }, 200);

  }
  sortResultsAsRecommended(flightSearchResponse: FlightSearchResponse) {
    this.sortResultsAsRecommendedWithFilterService(flightSearchResponse, null);
  }

  sortResultsAsRecommendedWithFilterService(flightSearchResponse: FlightSearchResponse, filterService: FilterService) {
    if (flightSearchResponse
      && flightSearchResponse.flightsLists
      && flightSearchResponse.flightsLists.length > 0
      && flightSearchResponse.flightsLists[0].flights
      && flightSearchResponse.flightsLists[0].flights.length > 0) {
      flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
        let weightDiff = 0;
        if (filterService && filterService.appliedFilterList && filterService.appliedFilterList.includes(FilterType.AIRLINE)) {
          const airline0 = a.legs[0].flightHops[0].carrier.toUpperCase();
          const airline1 = b.legs[0].flightHops[0].carrier.toUpperCase();
          const o_airline0 = a.legs[0].flightHops[0].operatingCarrier.toUpperCase();
          const o_airline1 = b.legs[0].flightHops[0].operatingCarrier.toUpperCase();
          let weight0 = 0;
          let weight1 = 0;
          if (filterService.filter_airlines.includes(airline0)) {
            weight0 += 100;
          }
          // if (filterService.filter_airlines.includes(o_airline0)) {
          //   weight0 += 50;
          // }
          if (filterService.filter_airlines.includes(airline1)) {
            weight1 += 100;
          }
          // if (filterService.filter_airlines.includes(o_airline1)) {
          //   weight1 += 50;
          // }
          weightDiff = weight1 - weight0;
          if (weightDiff !== 0) {
            return weightDiff;
          }
        }
        if (a.recommendedIdx < b.recommendedIdx) {
          return -1;
        } else if (a.recommendedIdx > b.recommendedIdx) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    this.setFlightSearchResponse(flightSearchResponse, false);
  }

  updateAirlineOptions(flightSearchResponse: FlightSearchResponse) {
    if (flightSearchResponse && flightSearchResponse.flightsLists.length > 0) {
      this.airlineOptions = [];
      this.allianceOptions = [];
      this.airportOptions = [];
      this.airportDestinationOptions = [];
      let airlineList = []
      for (let flight of flightSearchResponse.flightsLists[0].flights) {
        let carrier = flight.legs[0].flightHops[0].carrier;
        if (airlineList.indexOf(carrier) == -1) {
          let airline = { 'id': '', 'value': '' };
          airline.id = '' + carrier;
          airline.value = '' + flightSearchResponse.airlineNames[carrier];
          airlineList.push(carrier);
          this.airlineOptions.push(airline);
        }
      }
      if (this.airlineOptions.length > 0) {
        this.airlineOptions = this.airlineOptions.sort(function (a, b) {
          if (a.value.toLowerCase() > b.value.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
      }
      let airportsDeparture = []
      let airportsDestination = []
      for (let flight of flightSearchResponse.flightsLists[0].flights) {
        let fromAirport = flight.legs[0].flightHops[0].from;
        if (airportsDeparture.indexOf(fromAirport) == -1) {
          let airline = { 'id': '', 'value': '' };
          airline.id = fromAirport;
          airline.value = flightSearchResponse.airports[fromAirport].name + ' (' + fromAirport + ')';
          this.airportOptions.push(airline);
          airportsDeparture.push(fromAirport);
        }
        let toAirport = flight.legs[0].flightHops[flight.legs[0].flightHops.length - 1].to;
        if (airportsDestination.indexOf(toAirport) == -1) {
          let airline = { 'id': '', 'value': '' };
          airline.id = toAirport;
          airline.value = flightSearchResponse.airports[toAirport].name + ' (' + toAirport + ')';
          this.airportDestinationOptions.push(airline);
          airportsDestination.push(toAirport);
        }
        // }
      }

      // Object.entries(flightSearchResponse.flightsLists[0].flights).forEach((flight) => {
      let allianceList = [];
      for (let flight of flightSearchResponse.flightsLists[0].flights) {
        for (let flightHop of flight.legs[0].flightHops) {
          if (!flightHop.allianceName) continue;
          let allianceId = flightHop.allianceName.replace(' ', '').toLowerCase();
          if (allianceList.indexOf(allianceId) == -1) {
            let airline = { 'id': '', 'value': '' };
            airline.id = allianceId;
            airline.value = flightHop.allianceName;
            this.allianceOptions.push(airline);
            allianceList.push(allianceId);
          }
        }
      }
      // });
    }
  }

  getAirportName(code: string): string {

    if (this.flightSearchResponse && this.flightSearchResponse.airports[code]) {
      return this.flightSearchResponse.airports[code].name;
    } else {
      let airports;
      if (this.gallopLocalStorage.getItem('airports')) {
        airports = JSON.parse(this.gallopLocalStorage.getItem('airports'));
      }
      return airports ? (airports[code] ? airports[code].name : code) : code;
    }
  }

  getAirlineName(code: string): string {
    if (this.flightSearchResponse && this.flightSearchResponse.airlineNames[code]) {
      return this.flightSearchResponse.airlineNames[code];
    } else {
      let airlineNames;
      if (this.gallopLocalStorage.getItem('airlineNames')) {
        airlineNames = JSON.parse(this.gallopLocalStorage.getItem('airlineNames'));
      }
      return airlineNames ? (airlineNames[code] ? airlineNames[code] : '') : '';
    }
  }
  getTravellerName(email){
   if(this.travellerList && this.travellerList.length > 0){
     let employee  = this.travellerList.filter(item => item.userId === email);
     if (employee && employee[0] && employee[0].userName) {
         return employee[0].userName;
     }
     return email;
   }
   return email;
 }
  sendSearchDataForTrain(searchData): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForSendTrainSearchData+ '?userid=' + userid,searchData);
  }
  getStationListDataForTrain(): Observable<any> {
    return this.http.get('assets/trainSearchData.json'); 
  }

}
