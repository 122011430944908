import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientConfiguration } from '../client-config.service';
import { UserAccountService } from '../user-account.service';
import { Constants } from '../util/constants';
import { SearchService } from '../search.service';
import { DeviceDetailsService } from '../device-details.service';

declare var removeNavigationComponentPadding: any;
declare var openZSIQChatWidow: any;
declare var getZohoSIQChatOfflineStatus: any;
declare var setZSIQNameAndMail_CBK: any;

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  njoySpecificBuild: boolean;
  contactNumber= Constants.GALLOP_HELP_CONTACT_NO;
  @Input() searchPopClicked = false;
  @Output() closeHelpComponent = new EventEmitter();
  isMobile = false;
  
  constructor(public userAccountInfoService: UserAccountService,
    private clientConfig: ClientConfiguration,
    private deviceDetailsService: DeviceDetailsService,
    private searchService: SearchService
  ) {
    this.njoySpecificBuild = this.userAccountInfoService.isItNjoyBuild();
  }

  changeStyle() {
    if (this.searchPopClicked) {
      if(window.screen.width < 600){
        return { 'border': 'none', 'height': 'max-content', 'box-shadow': 'none' };
      } else {
        return { 'border': 'none', 'height': '70vh;', 'box-shadow': 'none' };
      }
    } else {
      return { 'border': '1px solid #E1E1E1', 'height': 'calc(100vh - 75px)', 'box-shadow': '0 0 20px 0 #edfafc' }
    }
  }
  ngOnInit() {
    this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    setTimeout(() => {
      removeNavigationComponentPadding();
    }, 500);

  }
  getPhoneNo(): any {
    return Constants.GALLOP_HELP_CONTACT_NO;
  }
  getNetherlandPhone(): any{
    return Constants.GALLOP_HELP_CONTACT_NO_NETHERLANDS;
  }
  getEmailId(): any {
    if (this.clientConfig.agentEmailId && this.clientConfig.agentEmailId.trim().length > 0) {
      return this.clientConfig.agentEmailId;
    }
    return 'trip@routespring.com';
  }
  openZSIQChatWidowClick() {
    if (this.userAccountInfoService.getAccountInfo() != null) {
      const userInfo = this.userAccountInfoService.getAccountInfo().userInfo;
      if (userInfo != null) {
        setZSIQNameAndMail_CBK(userInfo.fullName, userInfo.email);
      }
    }
    openZSIQChatWidow();
  }
  isZohoSIQChatOffline() {
    if (!this.searchService.chatEnabled || getZohoSIQChatOfflineStatus()) {
      return true;
    }
    return false;
  }
}
