import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonUtils } from '../util/common-utils';
import { DateUtils } from '../util/date-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserCredentialsService } from '../user-credentials.service';
import { FareAttributes } from 'src/app/entity/fare-attributes';
import { TranslateService } from '@ngx-translate/core';
import { EmailQuoteOptionsService } from '../email-quote-options.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BookingHistoryService } from '../booking-history.service';
import { CancelSuccessComponent } from '../cancel-success/cancel-success.component';
import { BookingService } from '../booking.service';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { FormControl, Validators, Form, ValidationErrors, AbstractControl } from '@angular/forms';
import { Constants } from '../util/constants';
import { Title } from '@angular/platform-browser';
import { BookedOption } from '../util/bookingdata';
import { DatePipe } from '@angular/common';
import { type } from 'os';
import { Subscription } from 'rxjs';
import { DeviceDetailsService } from '../device-details.service';
import { UserAccountService } from '../user-account.service';
import { SeeDetailsComponent } from '../see-details/see-details.component';
@Component({
  selector: 'app-trip-details-card',
  templateUrl: './trip-details-card.component.html',
  styleUrls: ['./trip-details-card.component.scss']
})
export class TripDetailsCardComponent implements OnInit {
  @Input() details;
  @Input() detailList;
  @Input() newtripDetails;
  @Input() tripDeatilsIndex;
  @Input() NewuserAccountInfoObj;
  @Input() NewbsModalRef;
  @Input() NewnjoySpecificBuild;
  @Input() showDetailsActionButtons = false;
  @Input() IsMobile;
  @Input() getFullDetailsResponse;
  @Input() forReports = false;
  @Input() forApproval = false;
  @Input() bookingType;
  @Output() emitGetDetailView = new EventEmitter<any>();
  @Output() emitDetectChanges = new EventEmitter<any>();
  @Output() emitCallTripsApi = new EventEmitter<any>();
  @Output() emitHandleBookingHistoryListResponse = new EventEmitter<any>();
  @Output() emitAfterBokkingCancel = new EventEmitter<any>();

  tripDetails;
  cardType;
  detail;
  allowAddToExpensify;
  userAccountInfoObj;
  detailTransaction;
  bsModalRef: BsModalRef;
  calenderUrlHotel;
  calenderUrl;
  authFormUrl;
  cardDetails:any;
  selectedDetail;
  selectOptionForModal;
  calUrls;
  CalenderTemplate;
  invoiceUrl;
  njoySpecificBuild;
  tripSelectedToshowOnpopUp: any;
  airlines;
  airports;
  airplaneData;
  sendRequestProgress = false;
  calenderTemplate;
  modalShow;
  modalShow1;
  selectOption: any;
  checked: boolean;
  messageForChange: any;
  showChangeRequestError = false;
  traveler: any;
  expensifyEmailControl: FormControl;
  expensifyEmailControlDD: FormControl;
  expenseProviders = [];
  expensifyEmail: any;
  errorMsg = false;
  expensify_add = [];
  expensify_add_hotel = [];
  hideChanges = false;
  refreshingData = false;
  detailView = false;
  fareList: any[];
  length1: number;
  tripItUrl: string;
  tripItHotelUrl: string;
  flightPresent: boolean;
  hotelPresent: boolean;
  ticketid: any;
  tripid: any;
  selectTransId: any;
  tripSessionId: any;
  flightList: any[];
  flightList2: any[];
  gallopCash = 0;
  billingItem: any;
  selectedLegIndexForPopUp: any;
  paymentSelectedIndex: any;
  transaction: any;
  iscreditAppled = false
  deviceSubscription1: Subscription;
  isMobile1
  constructor(
    private modalService: BsModalService,
    private userCredentials: UserCredentialsService,
    public translateService: TranslateService,
    public ngxSmartModalService: NgxSmartModalService,
    private userAccountService: UserAccountService,
    private bookingHistoryService: BookingHistoryService,
    private bookingService: BookingService,
    private searchService: SearchService,
    private router: Router,
    private titleService: Title,
    public deviceDetailsService: DeviceDetailsService,
  ) { }


  ngOnInit(): void {
    
    
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.tripDetails = this.newtripDetails[this.tripDeatilsIndex];
    this.cardType = this.tripDetails.detail[0].type
    if(this.cardType && this.cardType==='hotel' && this.tripDetails && this.tripDetails.authFormUrl){
      this.authFormUrl = this.tripDetails.authFormUrl;
      this.cardDetails = this.tripDetails.cardDetails;
    }
    this.detail =  this.tripDetails.detail[0];
    if (this.bookingType && this.bookingType === 'past') {
      this.hideChanges = true;
    } else {
      this.hideChanges = false;
    }
    if(this.forApproval){
      if(this.getFullDetailsResponse.transactionList){
        const NewTransactionList = this.getFullDetailsResponse.transactionList;
        const findTransaction = NewTransactionList.find(e => e.trip_id == this.detail.tripid);
        this.transaction = findTransaction;
      };
    }else{
      this.transaction = this.tripDetails.transaction;
    }

    if(this.transaction){
      let travel_credit = this.transaction.data.find(e=> e.transactionEventType == "travel_credit")
      if(travel_credit){
        this.iscreditAppled = true;
      }
    }

    this.bsModalRef = this.NewbsModalRef;
    this.airlines = this.getFullDetailsResponse.airlineNames;
    this.airports = this.getFullDetailsResponse.airports;
    if (this.getFullDetailsResponse.travellersData) {
      this.traveler = [this.getFullDetailsResponse.travellersData.travellers];
    } else {
      this.traveler = [this.getFullDetailsResponse.transactionList];
    }
    if (this.NewuserAccountInfoObj) {
      this.messageForChange = '';
      this.userAccountInfoObj = this.NewuserAccountInfoObj;
      this.expensifyEmailControl = new FormControl(this.expensifyEmail, Validators.compose([this.expensifyEmailValidator.bind(this), Validators.pattern(Constants.RGEX_EMAIL)]));
      this.expenseProviders = CommonUtils.getActiveExpenseProviders(this.userAccountInfoObj);
      if (this.expenseProviders && this.expenseProviders.length > 0) {
        this.expensifyEmailControlDD = new FormControl(this.expenseProviders[0].id);
      } else {
        this.expensifyEmailControlDD = new FormControl();
      }
      if (this.userAccountInfoObj.userInfo.expensifyId
        && this.userAccountInfoObj.userInfo.expensifyId.trim().length > 0) {
        this.expensifyEmail = this.userAccountInfoObj.userInfo.expensifyId;
        this.expensifyEmailControl.setValue(this.expensifyEmail);

      } else {
        this.expensifyEmail = this.userAccountInfoObj.userInfo.email;
        this.expensifyEmailControl.setValue(this.expensifyEmail);
      }
    }
    this.deviceSubscription1 = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile1 = isMobile;
    });
  }

  

  expensifyEmailValidator(control: AbstractControl): ValidationErrors | null {
    let result = null;
    if ((!control.value || control.value.trim().length === 0)) {
      return { 'required': true };
    }
    return result;
  };

  getPlaceholderText() {
    return CommonUtils.getExpensefieldPlaceholderText(this.expensifyEmailControlDD.value, this.translateService);
  };

  

  showActionButtons() {
    let detail = this.detail;
    let showButtons = true;
    if (detail.option.status === 'cancelled' || detail.option.status === 'voided') {
      showButtons = false;
    }
    return showButtons;
  }
  carUrl(car) {
    if (car.images) {
      return car.images.S;
    }
  }
  getFlightIconURL(flightCode: string) {
    return CommonUtils.getAirlineImageUrl(flightCode);
  }
  getHotelPhoneNo(hotel): any {
    if (hotel.option.hotel_option.phoneNumber && hotel.option.hotel_option.phoneNumber.length !== 0) {
      if(hotel.option.hotel_option.phoneNumber[0].displayText){
      return hotel.option.hotel_option.phoneNumber[0].displayText;
      }else{
        return hotel.option.hotel_option.phoneNumber[0].number;  
      }
    }
  }
  getDisplayDate(dateString: string, format: string): string {
    return DateUtils.getFormattedDateWithoutTimeZoneFromFormat(new Date(dateString), format);
  }
  getDisplayDateForFlights(dateString: string, format: string): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(dateString.split('T')[0]), format, 'UTC');
  }
  getDisplayDateTimeForFlights(dateString: string, format: string): string {
    return DateUtils.getFormattedDateForGivenTimeZone(dateString, format);
  }
  getFlightLegDuration(flight, legIndex: number): any {
    let durationInMins = this.getFlightLegDurationInMin(flight, legIndex);
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }
  getFlightLegDurationInMin(flight, legIndex: number): any {
    if (!flight && !flight.option.flight_option.legs[legIndex]) return 0;
    let startDate = new Date(flight.option.flight_option.legs[legIndex].hops[0].starts);
    let endDate = new Date(flight.option.flight_option.legs[legIndex].hops[flight.option.flight_option.legs[legIndex].hops.length - 1].ends);

    return DateUtils.getDateDiffInMinutes(endDate, startDate);
  }
  getDuration(flight, legIndex: number) {
    return this.getFlightLegDuration(flight, legIndex);
  }
  getLastIndex(hops) {
    if (hops.length > 0) {
      return hops.length - 1;
    }
  }
  getPaymentDetailsByDetails(i) {
    let transaction = this.transaction.data[0].paymentSourceDesc;
    return transaction;
  }
  getCurrencySymbol(item): string {
    if (item.displayCurrency) {
      return CommonUtils.getCurrencySymbol(item.displayCurrency);
    } else {
      return CommonUtils.getCurrencySymbol(item.currency);
    }
  }
  getLayOverDuration(hops,i){
    
    let endsDate = new Date(hops[i].ends);
    let startDate = new Date(hops[i + 1].starts)
    let durationInMins = DateUtils.getDateDiffInMinutes(startDate, endsDate);
    let durationInHrsAndMin = DateUtils.getDurationAsHrsMinObj(durationInMins);
    return durationInHrsAndMin.hrs + " hr " + durationInHrsAndMin.mins + " min ";
  }
  getFlightInfoForCalender(item) {
    let obj = {
      from: '',
      to: '',
      starts: '',
      ends: '',
    }
    let lasthopIndex = item.hops.length - 1;
    obj.from = item.hops[0].from;
    obj.to = item.hops[lasthopIndex].to;
    obj.starts = this.getDisplayDateForFlights(item.hops[0].starts, 'EEEE, MMM d, yyyy')
    obj.ends = this.getDisplayDateForFlights(item.hops[lasthopIndex].ends, 'EEEE, MMM d, yyyy')

    return obj;
  }

  getConfirmationNo(): string {
    let confirmNo = '';
    const item = this.detail
    this.detailTransaction = this.transaction;

    if (this.detailTransaction && this.detailTransaction.approvalStatus === 'pending') {
      return 'PENDING APPROVAL';
    }
    const optionStatus = item.option.status;
    if ('cancelled' === optionStatus.toLowerCase()
      || 'refund' == optionStatus.toLowerCase()
      || 'voided' == optionStatus.toLowerCase() ) {
      return optionStatus.toLowerCase();
    }
    if (item.type === 'flight') {
      if (item.option.flight_option.cancellationRequestReceived) { return 'CNCL under review'; };
      for (let leg of item.option.flight_option.legs) {
        for (let hop of leg.hops) {
          if (hop.pnr) {
            if (!confirmNo.match(hop.pnr)) {
              confirmNo = confirmNo + hop.pnr + ',';
            };
          };
        };
      };

      var trim = confirmNo.replace(/(^,)|(,$)/g, "");
      return trim;
    } else if (item.type === 'hotel') {
      if (item.option.hotel_option.cancellationRequestReceived) { return 'CNCL under review'; };
      return item.option.hotel_option.bookingId;
    } else {
      if (item.option.car_option.cancellationRequestReceived) { return 'CNCL under review'; };
      return item.option.car_option.bookingId;

    }
  }
  showCalenderButton() {
    const tripDetail = this.tripDetails;
    let calenderUrl = tripDetail.calendarUrl;
    return (calenderUrl && calenderUrl.trim().length > 0);
  }
  showTripItButton() {
    const tripDetail = this.tripDetails;
    const type = this.cardType;
    let tripUrl = tripDetail.tripItUrl;
    if (type == 'flight' || type == "hotel") {
      return (tripUrl && tripUrl.trim().length > 0);
    } else {
      return false;
    }
  }

  showExpensifyButton() {
    return false;
    // let tripDetail = this.tripDetails;
    // let type = this.cardType;
    // this.allowAddToExpensify = tripDetail.canExpensify;
    // if (this.userAccountInfoObj && this.userAccountInfoObj.companySettings
    //   && this.userAccountInfoObj.companySettings.expensifySupported) {
    //   this.allowAddToExpensify = this.allowAddToExpensify && this.userAccountInfoObj.companySettings.expensifySupported;
    // } else {
    //   this.allowAddToExpensify = false;
    // }

    // if (this.allowAddToExpensify && (type == "flight" || type == "hotel")) {
    //   return true;
    // }
    // return false;
  }

  showDownloadInvoiceButton() {
    const tripDetail = this.tripDetails;
    let tripTransaction = tripDetail.transaction;
    let invoiceUrl = tripDetail.invoiceUrl;
    if (tripTransaction && tripTransaction.approvalStatus === 'pending') {
      return false;
    }
    return invoiceUrl && invoiceUrl.length > 0;
  }

  showExpenseButtons(i) {
    let tripDetail = this.tripDetails;
    let detail = tripDetail.detail;
    this.detailTransaction = tripDetail.transaction;
    if (this.detailTransaction && this.detailTransaction.approvalStatus === 'pending') {
      return false;
    }

    let showButtons = true;
    let status = '';
    if (detail) {
      for (let item of detail) {
        if (item.type === 'flight') {
          status = this.getConfirmationNo();
        } else if (item.type === 'hotel') {
          status = item.option.hotel_option.bookingId;
        } else {
          status = item.option.car_option.bookingId;
        }
        if (status && status.toLowerCase() === 'pending') {
          showButtons = false;
        }
      }
    }
    return showButtons;
  }
  getCardNumberDetails(number){
  var ccNum = number;
                var digits = ccNum.length;
                ccNum = ccNum.split("");
                ccNum.splice(digits - 4, 0, ' ');
                ccNum.splice(digits - 8, 0, ' ');
                ccNum.splice(digits - 12, 0, ' ');
                ccNum = ccNum.join("");
                return ccNum;
}
  showRebookButton(i) {
    const tripDetail = this.tripDetails;
    let showButtons = true;
    this.detailTransaction = this.transaction;

    if (this.detailTransaction && this.detailTransaction.approvalStatus === 'pending') {
      return true;
    }
    if (this.detailList[i]) {
    //  for (let item of this.detailList) {
        if (this.detailList[i].type === 'flight') {
          let bookingTime = new Date(this.detailList[i].option.flight_option.legs[this.detailList[i].option.flight_option.legs.length - 1].hops[this.detailList[i].option.flight_option.legs[this.detailList[i].option.flight_option.legs.length - 1].hops.length - 1].ends);
          let currentTime = new Date();
          if (currentTime.getTime() > (bookingTime.getTime())) {
            showButtons = false;
          } else {
            showButtons = true;
          }

        }else  if (this.detailList[i].type === 'hotel') {
          let bookingTime = new Date(this.detailList[i].option.hotel_option.checkInDate);
          let currentTime = new Date();
          if (currentTime.getTime() > (bookingTime.getTime())) {
            showButtons = false;
          } else {
            showButtons = true;
          }

        }else  if (this.detailList[i].type === 'cars') {
          let bookingTime = new Date(this.detailList[i].option.car_option.pickupDateTimeISO);
          let currentTime = new Date();
          if (currentTime.getTime() > (bookingTime.getTime())) {
            showButtons = false;
          } else {
            showButtons = true;
          }

        }
     // }
    }
    return showButtons;
  }
  showRequestChangeButton(i) {
    const tripDetail = this.tripDetails;
    let showButtons = true;
    if (this.detailList[i]) {
    //  for (let item of this.detailList) {
        if (this.detailList[i].type === 'flight') {
          let bookingTime = new Date(this.detailList[i].option.flight_option.legs[this.detailList[i].option.flight_option.legs.length - 1].hops[this.detailList[i].option.flight_option.legs[this.detailList[i].option.flight_option.legs.length - 1].hops.length - 1].ends);
          let currentTime = new Date();
          if (currentTime.getTime() > (bookingTime.getTime())) {
            showButtons = false;
          } else {
            showButtons = true;
          }

        }else  if (this.detailList[i].type === 'hotel') {
          let bookingTime = new Date(this.detailList[i].option.hotel_option.checkOutDate);
          let currentTime = new Date();
          if (currentTime.getTime() > (bookingTime.getTime())) {
            showButtons = false;
          } else {
            showButtons = true;
          }

        }else  if (this.detailList[i].type === 'cars') {
          let bookingTime = new Date(this.detailList[i].option.car_option.dropOffDateTimeISO);
          let currentTime = new Date();
          if (currentTime.getTime() > (bookingTime.getTime())) {
            showButtons = false;
          } else {
            showButtons = true;
          }

        }
     // }
    }
    return showButtons;
  }
  showChangesFlag() {
    const tripDetail = this.tripDetails;
    const item = this.detail;
    const status = item.option.status;
    const type = item.type;
    let cancellationRequestReceivedFlag = false;
    if (type === 'flight') {
      cancellationRequestReceivedFlag = item.option.flight_option.cancellationRequestReceived;
    }
    if (type === 'hotel') {
      cancellationRequestReceivedFlag = item.option.hotel_option.cancellationRequestReceived;
    }
    if (type === 'cars') {
      cancellationRequestReceivedFlag = item.option.car_option.cancellationRequestReceived;
    }
    if (cancellationRequestReceivedFlag) {
      return false;
    }
    if (status === 'ticketed' || status === 'selected' || status === 'booked' || status === 'expensed') {
      return true;
    }
    return false;
  };

  

  goToCalendar(addCalendarModal) {
    this.bsModalRef = this.modalService.show(addCalendarModal);
    let tripDetail = this.tripDetails;
    let detail = this.detail;
    let type = this.cardType;
    let finalLink = []
    


    if (type == "hotel") {
      this.calenderUrlHotel = tripDetail.calendarUrl;

    } else if (type == "flight") {
      this.calenderUrl = tripDetail.calendarUrl;
    };


    this.selectedDetail = tripDetail.detail;
    this.selectOptionForModal = type
    if (tripDetail.calendarUrls && tripDetail.calendarUrls.length > 0) {
      this.calUrls = {};
      for (let urlCounter = 0; urlCounter < tripDetail.calendarUrls.length; urlCounter++) {
        const currTuple = tripDetail.calendarUrls[urlCounter];
        let detailArray = {
          link_name: currTuple.name,
          details: []
        };
        if (type == "flight") {
          let url_value = currTuple.value.split("||");
          if (url_value.length > 1) {
            for (let i = 0; i < url_value.length; i++) {
              const flightDetails = detail.option.flight_option.legs[i];
              const e = url_value[i];
              let obj = {
                detail: this.getFlightInfoForCalender(flightDetails),
                url: e,

              }
              detailArray.details.push(obj)

            }
            finalLink.push(detailArray)
          } else {
            let url_value = currTuple.value.split("||")[0];
            this.calUrls[currTuple.name] = url_value;
            let obj = {
              url: url_value,
              link_name: currTuple.name
            };
            finalLink.push(obj);
          }
        } else {
          let url_value = currTuple.value.split("||")[0];
          this.calUrls[currTuple.name] = url_value;
          let obj = {
            url: url_value,
            link_name: currTuple.name
          };
          finalLink.push(obj);
        }

      }
    }

    this.calenderTemplate = finalLink;
    console.log(finalLink);
    setTimeout(() => {
      this.getOpenModal()
    }, 50);

  };


  getOpenModal() {

    this.modalShow = false;
    this.modalShow1 = true;
    this.selectOptionForModal = this.selectOption;
    this.ngxSmartModalService.getModal('calendarOptionModal').open();

  }

  downloadInvoices() {

    let tripDetail = this.tripDetails;
    this.invoiceUrl = [tripDetail.invoiceUrl];

    if (this.invoiceUrl) {
      let count = 1;
      for (let url of this.invoiceUrl) {
        url = url + '&selectedLanguage=' + this.userCredentials.getLang();
        setTimeout(() => {
          if (this.njoySpecificBuild) {
            window.location.href = url + '&dummy=/TripItAPI/';
          } else {
            window.open(url, "_blank");
          }
        }, 500 * count);
        count++;
      }
    }
  }
  downloadAuthForm() {

  

    if (this.authFormUrl) {
      
     
       let  url = this.authFormUrl + '&selectedLanguage=' + this.userCredentials.getLang();
        setTimeout(() => {
          if (this.njoySpecificBuild) {
            window.location.href = url + '&dummy=/TripItAPI/';
          } else {
            window.open(url, "_blank");
          }
        }, 500);
        
      
    }
  }
  bsModalRefForUpcomingTrip:BsModalRef;
  openCardDetailsModal(modal){
    this.bsModalRef = this.modalService.show(modal);
  }
  openModal(modal, type, item,selectedLegIndex?) {
    if (type == "flight") {
      this.airplaneData = this.detail;
      this.tripSelectedToshowOnpopUp = item;
      this.tripSelectedToshowOnpopUp['type'] = type;
      this.selectedLegIndexForPopUp = selectedLegIndex;
    } else {
      this.tripSelectedToshowOnpopUp = item;
      this.tripSelectedToshowOnpopUp['type'] = type;
    }


    // if(option){
    // let policy = option.getWithinPolicy();
    // this.tripSelectedToshowOnpopUp['policy'] = policy;
    // }
    this.bsModalRefForUpcomingTrip = this.modalService.show(SeeDetailsComponent, {
      initialState: {
       airlines:this.airlines,
       selectedLegIndexForPopUp :this.selectedLegIndexForPopUp,
       airports:this.airports,
       airplaneData:this.airplaneData,
       tripSelectedToshowOnpopUp:this.tripSelectedToshowOnpopUp,
       isMobile:this.isMobile1,
       comingFromSearchPage:false,
       detailsList:this.detailList[this.tripDeatilsIndex].option
      }, 
    });
   // this.bsModalRef = this.modalService.show(modal);
    console.log("triptypeselected", this.tripSelectedToshowOnpopUp);
  }
  getDetailsStyleModal() {
    if (this.isMobile1) {
      return { 'justify-content': 'center', 'border-left': 'none' }
    } else {
      return { 'justify-content': 'start', 'border-left': '2px solid #e3e3e3' }
    }

  }

  getLocationType(item) {
    if (item && item !== '') {
      if (item === 'Terminal') {
        return 'At airport terminal'
      } else {
        return 'Outside airport - shuttle from airport terminal'
      }
    }
  }
  getBrandText(brandClass: string) {
    if (brandClass && brandClass.trim().length > 0) {
      return '(' + brandClass + ')';
    }
    return '';
  }
  getCorporateFareAttributeMoreOptions(item) {
    if (item.corporateRate) {
      return item.corporateRate;
    }
  }
  getSeatFareAttributeMoreOptions(item) {
    console.log("getSeatFareAttributeMoreOptions", item);
    return this.getCurrentFlightFareAttributesForMoreOptions(item).seat;
  }
  getCurrentFlightFareAttributesForMoreOptions(item): FareAttributes {
    console.log("-----------------", item)
    return item.fareAttributes;
  }
  isMixed(): boolean {
    return this.tripSelectedToshowOnpopUp.hops[0].flightHighlights.mixedClass;
  }
  // getAirLinesName(airPortName){
  //   // console.log(this.airlines[airPortCode]);
  //   var airLineNameToReturn =airPortName;
  // if(this.airlines[airPortName] != undefined){
  //   airLineNameToReturn =this.airlines[airPortName];
  // }
  // return airLineNameToReturn;
  // }
  // getAirportName(airportCode){
  //   return this.airports[airportCode].name;
  // }
  transformMinute(value: number): string {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    return hours + ' hrs ' + minutes + ' mins';
  }
  getPerDayPrice(car) {
    if (car.displayPrice) {
      var subTotal = (car.displayPrice / car.numberOfDay);
      return subTotal;
    } else {
      var subTotal = (car.price / car.numberOfDay);
      return subTotal;
    }
  }
  public onCancel(): void {
    this.bsModalRef.hide();
  }
  getCancellationPolicy(hotelOption) {
    var cancellationPolicy = '';
    if (hotelOption.cancellationPolicyInfo
      && hotelOption.cancellationPolicyInfo != null
      && hotelOption.cancellationPolicyInfo.trim().length > 0) {
      cancellationPolicy = hotelOption.cancellationPolicyInfo.toLowerCase();
    }
    if (hotelOption.hotelRateDetail
      && hotelOption.hotelRateDetail.cancelInfo
      && hotelOption.hotelRateDetail.cancelInfo.text
      && hotelOption.hotelRateDetail.cancelInfo.text.length > 0) {
      cancellationPolicy = hotelOption.hotelRateDetail.cancelInfo.text[0];
    }
    if (hotelOption.hotelRooms && hotelOption.hotelRooms != null
      && hotelOption.hotelRooms.length > 0
      && hotelOption.hotelRooms[0].hotelRates
      && hotelOption.hotelRooms[0].hotelRates != null
      && hotelOption.hotelRooms[0].hotelRates.length > 0
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies != null
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies.length > 0
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].cancellationDate) {
      let dateStr = new Date(DateUtils.getFormattedDateWithoutTimeZone(
        hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].cancellationDate
      )).toDateString().split(' ');
      cancellationPolicy = this.translateService.instant('optionSelection.CancellationPolicy').toString()
        + hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].amountAfterCancellation
        + this.translateService.instant('optionSelection.spacefeeafterspace').toString()
        + dateStr[1] + ' ' + dateStr[2];
    }
    return cancellationPolicy;
  }
  getLayoverTime(time1, time2) {
    const earlierTime = new Date(time2);
    const laterTime = new Date(time1);

    const timeDifference = laterTime.getTime() - earlierTime.getTime();
    const differnceInMinutes = timeDifference / 60000;
    let timeToSend;
    if (differnceInMinutes > 60) {
      timeToSend = this.transformMinute(differnceInMinutes);
    }
    else {
      timeToSend = differnceInMinutes + ' mins';
    }
    return timeToSend;
  }
  getColorByBookingStatus(status) {
    if (status == 'Booked') {
      return 'black';
    } else {
      return 'red';
    }
  }
  log(val) {
    console.log('-------------------------------------------------', val)
  }
  getTripDetails() {
    return this.tripDetails
  }
  getRatingStarsMap(hotelStars) {
    return EmailQuoteOptionsService.getHotelRatingStarsMap(hotelStars);
  }
  isAmenityAvailable(type: string) {
    if (this.tripSelectedToshowOnpopUp && this.tripSelectedToshowOnpopUp.amenities) {
      return this.tripSelectedToshowOnpopUp.amenities[type];
    }
    return false;
  }
  getStopsUIText(item, legIndex: number) {
    let stopsText = this.translateService.instant('flightChart.nonstop');
    if (item.length > 0) {
      if (item.length == 1) {
        stopsText = this.translateService.instant('flightSelected.1stop');
      } else {
        stopsText = item.length - 1 + this.translateService.instant('flightSelected.spacestops');
      }

      let stopAirports = undefined;
      for (let layover of item) {
        if (stopAirports) {
          stopAirports = stopAirports + ', ' + layover.to;
        } else {
          stopAirports = layover.to;
        }
      }
      stopsText = stopsText;
    }
    return ' ' + stopsText;
  }

  getHopDuration(durationInMins) {
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }
  getDisplayFlightDate(dateString: string, format: string): string {
    return DateUtils.getFormattedDateForGivenTimeZone(dateString, format);
  }

  getDurationOfhop(hop) {
    let d = DateUtils.getDurationAsHrsMinObj(hop.duration);
    return d.hrs + ' hr ' + d.mins + ' min';
  }
  getClassBytype(type) {
    if (type == "flight") {
      return 'flight-row detailed flight-details'
    } else {
      return 'flight-row detailed'
    }

  }

  onModelCancel() {
    if (this.sendRequestProgress) {
      return;
    }
    this.bsModalRef.hide();
  }

  goToTripItLink() {
    let tripDetail = this.tripDetails;
    let type = this.cardType;

    if (type == "flight" || type == "hotel") {
       if (this.njoySpecificBuild) {
            window.location.href = tripDetail.tripItUrl + '&dummy=/TripItAPI/';
          } else {
            window.open(tripDetail.tripItUrl, "_blank");
          }
    };
  }
  showModal(roomGalleryModal, i) {
    let tripDetail = this.tripDetails;
    let type = this.cardType;

    if (type == 'flight') {
      this.expensify_add.push('flight');
    } else {
      this.expensify_add_hotel.push('hotel');
    }
    this.selectedDetail = tripDetail.detail;
    console.log(this.selectedDetail)
    this.checked = false;
    this.bsModalRef = this.modalService.show(roomGalleryModal, { class: 'historyModal' });
  }

  onRequestChange(item: any) {
    if (this.messageForChange) {
      this.showChangeRequestError = false;
      this.checked = true; let ticketid = item[this.tripDeatilsIndex].ticketid;
      let tripid = item[this.tripDeatilsIndex].tripid; let selectTransId = item[this.tripDeatilsIndex].option.selectTransId;
      this.bookingHistoryService.getPostOnChange(ticketid, tripid, selectTransId, this.messageForChange).subscribe(data => { this.bsModalRef.hide(); this.bsModalRef = this.modalService.show(CancelSuccessComponent, { initialState: { message: this.translateService.instant('bookingHistory.Yourchangerequesthasbeensentsuccessfully'), }, }) });
    } else {
      this.showChangeRequestError = true;
    }
  }

  
cancelFailModal;
  showCancelModal(modal1, modal2, modal3,modal4?) {
    let type = this.cardType;
    let detail = this.detail;
    if(modal4){
    this.cancelFailModal =modal4;
    }
    this.selectedDetail = [detail];
    console.log(this.selectedDetail);
    this.selectOptionForModal = type
    let roomGalleryModal;
    this.detailTransaction = this.transaction;
    if (this.transaction.approvalStatus == "pending") {
      roomGalleryModal = modal3
    } else {
      if (type == 'flight') { roomGalleryModal = modal2; }
      else { roomGalleryModal = modal1; }
    }
    this.bsModalRef = this.modalService.show(roomGalleryModal, { class: 'historyModal', backdrop: true, keyboard: !false, ignoreBackdropClick: true },);
  }

  getHeading() {
    if (this.detailTransaction.approvalStatus === 'pending') {
      return this.translateService.instant('bookingHistory.approvalrequest');
    } else {
      if (this.selectOptionForModal === 'cars') {
        return this.translateService.instant('bookingHistory.car');
      } else if (this.selectOptionForModal === 'hotel') {
        return this.translateService.instant('bookingHistory.hotel');
      } else if (this.selectOptionForModal === 'flight') {
        return this.translateService.instant('bookingHistory.flight');
      };
    };
  };

  getHeading1() {
    if (this.selectOptionForModal === 'cars') {
      return this.translateService.instant('bookingHistory.car');
    } else if (this.selectOptionForModal === 'hotel') {
      return this.translateService.instant('bookingHistory.hotel');
    } else if (this.selectOptionForModal === 'flight') {
      return this.translateService.instant('bookingHistory.flight');
    };
  };

  getCancelMsg() {
    let bookingTime = new Date(this.detailTransaction.created);
    let currentTime = new Date();
    if (currentTime.getTime() < (bookingTime.getTime() + (24 * 3600 * 1000))) {
      return this.translateService.instant('bookingHistory.Yourreservationisstilleligibleforfullrefund.');
    } else {
      return this.translateService.instant('bookingHistory.YourreservationmayNOTbeeligibleforrefund.Youwillreceiveanemailwithdetailsoftravelfunds.');
    }
  }
  getCarCancelMsg(type, item) {
    if (type === 'cars') {
      let bookingTime = new Date(item.option.car_option.pickupDateTimeISO);
      let currentTime = new Date();
      if (currentTime.getTime() < (bookingTime.getTime())) {
        return this.translateService.instant('bookingHistory.Yourreservationisstilleligibleforfullrefund.');
      } else {
        return this.translateService.instant('bookingHistory.YourreservationmayNOTbeeligibleforrefund.Youwillreceiveanemailwithdetailsoftravelfunds.');
      }
    } else {
      return this.translateService.instant('bookingHistory.YourreservationmayNOTbeeligibleforrefund.Youwillreceiveanemailwithdetailsoftravelfunds.');
    }
  }

  getAirportCity(code) {
    return code && this.airports[code] && this.airports[code]['name'] ? this.airports[code].name : code;
  }

  onExpensifyClicked(option, event) {
    if (event.target.checked) {
      if (option == 'flight') {
        this.expensify_add.push(option);
        this.errorMsg = false;
      } else {
        this.expensify_add_hotel.push(option);
        this.errorMsg = false;
      }
    } else {
      if (this.expensify_add.length > 0 || this.expensify_add_hotel.length > 0) {
        this.deleteMsg(option);
      }
    }
  }

  deleteMsg(msg) {
    if (msg == 'flight') {
      const index: number = this.expensify_add.indexOf(msg);
      if (index !== -1) {
        this.expensify_add.splice(index, 1);
      }
    } else {
      const index: number = this.expensify_add_hotel.indexOf(msg);
      if (index !== -1) {
        this.expensify_add_hotel.splice(index, 1);
      }
    }
  }
  onExpensifyAdd() {

    const item = this.selectedDetail;
    if (this.expensifyEmailControl.valid && this.expensify_add.length > 0 || this.expensify_add_hotel.length > 0) {
      let expensifyType = '';
      this.sendRequestProgress = true;
      this.errorMsg = false;
      if (this.expensify_add.length > 0 && this.expensify_add_hotel.length > 0) {
        expensifyType = 'All';
      } else {
        if (this.expensify_add.length > 0) {
          expensifyType = 'flight';
        } else if (this.expensify_add_hotel.length > 0) {
          expensifyType = 'hotel';
        }
      }
      let ticketid = item[0].ticketid;
      let tripid = item[0].tripid;
      let selectTransId = item[0].option.selectTransId;
      this.bookingHistoryService.getPostExpensifyAdd(ticketid, tripid, selectTransId, expensifyType,
        this.expensifyEmailControl.value, this.expensifyEmailControlDD.value).subscribe(data => {
          this.bsModalRef.hide();
          this.sendRequestProgress = false;
          this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
            initialState: {
              message: this.translateService.instant('bookingHistory.Expensesentsuccessfully'),
            },
          });
        });
    } else if (this.expensify_add.length == 0 && this.expensify_add_hotel.length == 0) {
      this.errorMsg = true;
    }
  }

  isExpensifyChecked(option) {
    if (option == 'flight') {
      return this.expensify_add.indexOf(option) > -1;
    } else {
      return this.expensify_add_hotel.indexOf(option) > -1;
    }
  }
        getAirlineFullName(code) {
          let airlineFullName = null;
          airlineFullName = this.airlines[code];
          return (airlineFullName === '' || airlineFullName === null || airlineFullName === undefined) ? code : airlineFullName;
        }
        getChecked() {
          this.checked = false;
        }
        onRequestChangeForFlight() {
          const item = this.selectedDetail;
          this.bookingService.previousBooking = item[0];
          this.bookingService.previousTransaction =  this.transaction;
          if(item[0].tripSessionId){
            this.bookingService.oldTripSessionId = item[0].tripSessionId;
          }
          if(item.length >1){
          for(let i=1;i<item.length;i++){
            this.bookingService.previousBooking.option.flight_option.legs.push(item[i].option.flight_option.legs[0]);
          }
        }
          this.bookingService.airlines = this.airlines;
          this.bookingService.airports = this.airports;
          this.searchService.employeeEmail =[];
          this.searchService.previousSearch = null;
          this.searchService.searchStart =false;
          this.searchService.previousSearch = undefined;
          this.bookingService.rebookingTravlleremail = this.traveler[0];
          this.bookingService.rebookingTravllername = this.traveler[0][0].firstName +" "+ this.traveler[0][0].lastName;
          this.searchService.tripName = item[0].tripSessionName;
          this.searchService.editMytripname = 'true';
            console.log("item ",item);
            let type='';
            if(item[0].type==='cars'){
              type='car'
            }else  if(item[0].type==='flight'){
              type='flight'
            }else  if(item[0].type==='hotel'){
              type='hotel'
            }
            this.bookingService.previousTransactionId = item[0].option.selectTransId;
            if(this.bsModalRef){
              this.bsModalRef.hide();
            }
            this.router.navigate(["search"],
              {
                queryParams:
                {
                  type: type,
                },
                replaceUrl: true
              }
            );
          };

  cancelApprovalRequest() {
    const item = this.selectedDetail;
    this.sendRequestProgress = true;
    let msg = this.translateService.instant('bookingHistory.Yourcancellationisunderprocess.Youwillshortlyreceiveanemailswithdetailsoffunds.')
    let msg1 = this.translateService.instant('bookingHistory.Yourapprovalrequesthasbeensuccessfullycancelled.')
    let ticketid = item[0].ticketid;
    let tripid = item[0].tripid;
    let selectTransId = item[0].option.selectTransId;
    let tripSessionId = item[0].tripSessionId;
    this.bookingHistoryService.bookingCancelApproval(tripSessionId).subscribe(data => {

      this.bsModalRef.hide();
      this.sendRequestProgress = false;
      if (data && data.status) {
        this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
          initialState: {
            message: (this.detailTransaction.approvalStatus === 'pending') ? msg1 : msg,
            message2: 'AllowedToProceed'
          }, backdrop: true, keyboard: false, ignoreBackdropClick: true
        })
        this.bsModalRef.content.acceptClickSubject.subscribe(result => {
          if (result && data.status) {
            let bookeOption = { ticketid: '', tripid: '', option: { selectTransId: '' }, tripSessionId: '' }
            bookeOption.ticketid = ticketid;
            bookeOption.tripid = tripid;
            bookeOption.tripSessionId = tripSessionId;
            bookeOption.option.selectTransId = selectTransId;
            if (this.bookingType === 'past') {
              this.hideChanges = true;
            } else {
              this.hideChanges = false;
            }
            this.refreshingData = true;
            if (this.detailTransaction.approvalStatus == 'pending') {
              // this.detailView = true;
              // this.getDetailView(bookeOption, this.hideChanges);
              this.flightList = [];
              this.flightList2 = [];
              setTimeout(() => {
                if (this.searchService.showAllBookings) {
                 
                  if(this.searchService.showAllBookings){
                    // this.searchService.comingBackFromDetailsPAge =true;
                    this.router.navigate(["search"],
                              {
                                 queryParams:
                                {
                                   type: 'allbookings',
                                 },
                                 replaceUrl: false
                               }
                             );
                             this.callTripsApi();
                   // history.go(-1);
                    }
                  this.detectChanges();
                } else {
                  this.bookingHistoryService.requestBookingHistoryList().subscribe(resp => {
                    this.handleBookingHistoryListResponse(resp);
                  });
                }
              }, 200);


            } else {
              this.flightList = [];
              this.flightList2 = [];
              if (this.bsModalRef) {
                this.bsModalRef.hide();
              }
              if (this.searchService.showAllBookings) {
                if(this.searchService.showAllBookings){
                  // this.searchService.comingBackFromDetailsPAge =true;
                  this.router.navigate(["search"],
                            {
                               queryParams:
                              {
                                 type: 'allbookings',
                               },
                               replaceUrl: false
                             }
                           );
                 // history.go(-1);
                  }
              } else {
                this.router.navigate(["bookingHistory"],
                  {
                    queryParams:
                    {
                      type: 'list',
                    },
                    replaceUrl: false
                  }
                );
              }


              //  this.titleService.setTitle('Bookings');
              this.titleService.setTitle(this.translateService.instant('search.Bookings'));
            }
            this.detectChanges();
          } else if (result) {
            this.bsModalRef.hide();
          }

        });
      } else {

        if (data && !data.success && data.error_message) {

          this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
            initialState: {
              message: data.error_message,
              message2: 'AllowedToProceed'
            }, backdrop: true, keyboard: false, ignoreBackdropClick: false
          })
        }
      }
    })

  };

  cancelRequest() {
    const item = this.selectedDetail;
    this.sendRequestProgress = true;
    let msg = this.translateService.instant('bookingHistory.Yourcancellationisunderprocess.Youwillshortlyreceiveanemailswithdetailsoffunds.')
    let msg1 = this.translateService.instant('bookingHistory.Yourapprovalrequesthasbeensuccessfullycancelled.')
    let ticketid = item[0].ticketid;
    let tripid = item[0].tripid;
    let selectTransId = item[0].option.selectTransId;
    this.bookingHistoryService.getPost(ticketid, tripid, selectTransId).subscribe(data => {
      this.bsModalRef.hide();
      this.sendRequestProgress = false;
      if (data && data.success) {
        this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
          initialState: {
            message: (this.detailTransaction.approvalStatus === 'pending') ? msg1 : msg,
            message2: 'AllowedToProceed'
          }, backdrop: true, keyboard: false, ignoreBackdropClick: true
        })
        this.bsModalRef.content.acceptClickSubject.subscribe(res => {
          if (res) {
            let bookeOption = { ticketid: '', tripid: '', option: { selectTransId: '' } }
            bookeOption.ticketid = ticketid;
            bookeOption.tripid = tripid;
            bookeOption.option.selectTransId = selectTransId;
            this.hideChanges = false;
            this.refreshingData = true;
            this.flightList = [];
            this.flightList2 = [];
            if (this.bsModalRef) {
              this.bsModalRef.hide();
            }
            
           this.emitAfterBokkingCancel.emit(bookeOption);
          } else if (res) {
            this.bsModalRef.hide();
          }
        })
      } else {
        if (data && !data.success && data.error_message) {
          if(item && item[0].type==='flight' && data.error_code && data.error_code==='CANCEL_FAIL_REASON_USER_CHECKED_IN'){
            this.bsModalRef = this.modalService.show(this.cancelFailModal);
           
          }else{
          this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
            initialState: {
              message: data.error_message,
              message2: 'AllowedToProceed'
            }, backdrop: true, keyboard: false, ignoreBackdropClick: false
          });
        };
      }
      };
    });


  }
  onModelFailFlightCancel(){
    const item = this.selectedDetail;
    let ticketid = item[0].ticketid;
    let tripid = item[0].tripid;
    let selectTransId = item[0].option.selectTransId;
    let bookeOption = { ticketid: '', tripid: '', option: { selectTransId: '' } }
    bookeOption.ticketid = ticketid;
    bookeOption.tripid = tripid;
    bookeOption.option.selectTransId = selectTransId;
    this.hideChanges = false;
    this.refreshingData = true;
    this.flightList = [];
    this.flightList2 = [];
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    
   this.emitAfterBokkingCancel.emit(bookeOption);
  }
  cancelRequest1() {
    const item = this.selectedDetail;
    this.sendRequestProgress = true;
    let msg = this.translateService.instant('bookingHistory.Yourcancellationisunderprocess.Youwillshortlyreceiveanemailswithdetailsoffunds.')
    let msg1 = this.translateService.instant('bookingHistory.Yourapprovalrequesthasbeensuccessfullycancelled.')
    let ticketid = item[0].ticketid;
    let tripid = item[0].tripid;
    let selectTransId = item[0].option.selectTransId;
    this.bookingHistoryService.getPost(ticketid, tripid, selectTransId).subscribe(data => {

      this.bsModalRef.hide();
      this.sendRequestProgress = false;
      if (data && data.success) {
        this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
          initialState: {
            message: (this.detailTransaction.approvalStatus === 'pending') ? msg1 : msg,
            message2: 'AllowedToProceed'
          }, backdrop: true, keyboard: false, ignoreBackdropClick: true
        })
        this.bsModalRef.content.acceptClickSubject.subscribe(result => {
          if (result && data.success) {
            let bookeOption = { ticketid: '', tripid: '', option: { selectTransId: '' } }
            bookeOption.ticketid = ticketid;
            bookeOption.tripid = tripid;
            bookeOption.option.selectTransId = selectTransId;
            if (this.bookingType === 'past') {
              this.hideChanges = true;
            } else {
              this.hideChanges = false;
            }
            this.refreshingData = true;

            if (this.detailTransaction.approvalStatus !== 'pending') {
              // this.bookingHistoryService.requestBookingHistoryList().subscribe(resp => {
              //   this.handleBookingHistoryListResponse(resp);
              // });
              this.flightList = [];
              this.flightList2 = [];
              if (this.bsModalRef) {
                this.bsModalRef.hide();
              }
              this.router.navigate(["bookingHistory"],
                {
                  queryParams:
                  {
                    type: 'list',
                  },
                  replaceUrl: false
                }
              );
              if (this.searchService.showAllBookings) {
                history.go(-1);
              } else {
                this.router.navigate(["bookingHistory"],
                  {
                    queryParams:
                    {
                      type: 'list',
                    },
                    replaceUrl: false
                  }
                );
              }


              //  this.titleService.setTitle('Bookings');
              this.titleService.setTitle(this.translateService.instant('search.Bookings'));

              // this.detailView = true;
              // this.getDetailView(bookeOption, this.hideChanges);
              // this.flightList = [];
              // this.flightList2 = [];
              // setTimeout(() => {
              //   if(this.searchService.showAllBookings){
              //    this.callTripsApi();
              //    history.go(-1);
              //     this.detectChanges();
              //   } else {
              //   this.bookingHistoryService.requestBookingHistoryList().subscribe(resp => {
              //     this.handleBookingHistoryListResponse(resp);
              //   });
              // }
              // }, 200);


            } else {
              this.flightList = [];
              this.flightList2 = [];
              this.bookingHistoryService.requestBookingHistoryList().subscribe(resp => {
                this.handleBookingHistoryListResponse(resp);
              });
              if (this.bsModalRef) {
                this.bsModalRef.hide();
              }
              if (this.searchService.showAllBookings) {

                history.go(-1);
              } else {
                this.router.navigate(["bookingHistory"],
                  {
                    queryParams:
                    {
                      type: 'list',
                    },
                    replaceUrl: false
                  }
                );
              }


              //  this.titleService.setTitle('Bookings');
              this.titleService.setTitle(this.translateService.instant('search.Bookings'));
            }
            this.detectChanges();
          } else if (result) {
            this.bsModalRef.hide();
          }

        });
      } else {
        if (data && !data.success && data.error_message) {
          this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
            initialState: {
              message: data.error_message,
              message2: 'AllowedToProceed'
            }, backdrop: true, keyboard: false, ignoreBackdropClick: false
          })
        }
      }
    })
  }

  getDetailView(bookeOption, hideChanges) {
    let obj = {
      'bookeOption': bookeOption,
      'hideChanges': hideChanges
    }
    this.emitGetDetailView.emit(obj);
  };

  detectChanges() {
    this.emitDetectChanges.emit();
  };

  callTripsApi() {
    this.emitCallTripsApi.emit();
  };

  handleBookingHistoryListResponse(resp) {
    this.emitHandleBookingHistoryListResponse.emit(resp);
  };

  showDownloadInvoiceForCancelApproval(item) {
    let option = '';
    if(item.option.status == "cancelled"|| item.option.status == "voided" ){
      option = 'true';
    }else{
      if (item.type === 'flight') {
        option = item.option.flight_option.cancellationRequestReceived;
      } else if (item.type === 'hotel') {
        option = item.option.hotel_option.cancellationRequestReceived;
      } else {
        option = item.option.car_option.cancellationRequestReceived;
      };
    }

    


    if(this.forReports && this.getConfirmationNo().toLowerCase() === 'pending'){
      option = 'false';
    };

    if(this.forReports && this.transaction.approvalStatus == "pending"){
      option = 'true';
    }
    if (option) {
      return false;
    } else {
      return true;
    }
  };

  getNumberOfStops(item){
    return parseInt(item);
  }
  getTotalFare(): number {
    const data = this.detail;
    const fare = this.transaction.data;
    this.gallopCash = this.transaction.gallopCashUsed;
    return CommonUtils.getTotalFare(fare, data, this.gallopCash, this.traveler);
  }

  checkTransactionEventType() {
    const fare = this.transaction.data;
    for (let e of fare) {
      if (e && e.transactionEventType == "travel_credit" && e.event_type == 'other') {
        return true;
      }
    }

    return false;
  }

  openPaymentdetailsModal(modal, option?) {
    this.bsModalRef = this.modalService.show(modal);
    let tripDetail = this.tripDetails;
    let detail = this.detail;
    let type = this.cardType;
    const fare = this.transaction.data;
    this.gallopCash = tripDetail.transaction.gallopCashUsed;
    this.billingItem = detail;
    this.detailTransaction = this.transaction
    this.fareList = fare;
    // this.goBackEmitterForModal.emit(true);
  }
  getCreditPrice(fare) {
    if(fare.displayPrice){
      return fare.displayPrice;
    }else{
    return fare.price;
    }
  }
  

  getTotalAmount(item) {
    let total = 0;
    if (item.credit && item.credit[0]) {
      if (item.credit[0].displayCreditAmount) {
        if (item.credit[0].displayCreditAmount > Number(item.price)) {
          total = 0;
        } else {
          total = (Number(item.price) - item.credit[0].displayCreditAmount);
        }
      } else {
        if (item.credit[0].creditAmount > Number(item.price)) {
          total = 0;
        } else {
          total = (Number(item.price) - item.credit[0].creditAmount);
        }
      }
    } else {
      total = item.price;
    }
    return total;
  }



  removeUSD(text){
    text.substring(0, 3)
  }

  getPerNightCost(item) {
    let night = item.option.hotel_option.stay;
    if(item.option.hotel_option.hotelRateDetail && item.option.hotel_option.displayCurrency){
      if (item.option.hotel_option.hotelRateDetail && item.option.hotel_option.hotelRateDetail.displayBase) {
        if (this.showTaxes()) {
          return ((item.option.hotel_option.displayPrice - this.getTax(item)) / night);
        } else {
          return this.detailTransaction.data[0].displayPrice / night;
        }
      } else {
        return (item.option.hotel_option.displayPrice / night);
      }
    }else{
    if (item.option.hotel_option.hotelRateDetail && item.option.hotel_option.hotelRateDetail.base) {
      if (this.showTaxes()) {
        return ((item.option.hotel_option.originalPrice - this.getTax(item)) / night);
      } else {
        return this.detailTransaction.data[0].price / night;
      }
    } else {
      return (item.option.hotel_option.originalPrice / night);
    }
  }
  }
  getTax(item) {
    var numOfRooms = 1;
    if (item.option && item.option.hotel_option && item.option.hotel_option.hotelRooms
      && item.option.hotel_option.hotelRooms.length > 0) {
      numOfRooms = item.option.hotel_option.hotelRooms.length;
    }
    if(item.option.hotel_option.hotelRateDetail.displayBase){
      if (item.option.hotel_option.hotelRateDetail.displayBase) {
        let base = item.option.hotel_option.hotelRateDetail.displayBase;
        let total = item.option.hotel_option.hotelRateDetail.displayTotal;
        return (total - base) * numOfRooms;
      }
    }else{
    if (item.option.hotel_option.hotelRateDetail.base) {
      let base = item.option.hotel_option.hotelRateDetail.base.substring(3);
      let total = item.option.hotel_option.hotelRateDetail.total.substring(3);
      return (total - base) * numOfRooms;
    }
  }
    return 0;
  }
  getTaxForCar(item) {
    if(item.option.car_option.displayBasePrice){
      if (item.option.car_option.displayBasePrice) {
        let base = item.option.car_option.displayBasePrice;
        let total = item.option.car_option.displayPrice;
        return (total - base);
      } 
    }else{
    if (item.option.car_option.basePrice) {
      let base = item.option.car_option.basePrice;
      let total = item.option.car_option.price;
      return (total - base);
    }
  }
  }
  getCarPrice(price,tax1) {
    let tax 
    tax = Math.abs(tax1);
    if (tax !== null && tax > -1) {
      var subTotal = (price - parseFloat(tax))
      if (subTotal > tax) {
        return subTotal;
      } else {
       // let selectCar = JSON.parse(this.gallopLocalStorage.getItem("selectedCarForBooking"));
        if (price) {
          subTotal = price;
        }
        return subTotal;
      }
    }

  }
  // getCarPrice(item){
  //   if(item.displayBasePrice){
  //     return item.displayBasePrice
  //   }else{
  //     return item.basePrice
  //   }
  // }
  getResortFee(item) {
    if (item.option.hotel_option.hotelRateDetail.displayResortFee){
      return item.option.hotel_option.hotelRateDetail.displayResortFee;
    }else{
    if (item.option.hotel_option.hotelRateDetail.resortFee)
      return item.option.hotel_option.hotelRateDetail.resortFee.substring(3);
    }
  }
  showTaxes() {
    if (this.showHotelPriceBreakUp()) {
      if (this.billingItem.option.hotel_option.originalPrice === this.detailTransaction.data[0].price) {
        return true;
      }
    }
    return false;
  }

  showHotelPriceBreakUp() {
    if (this.detailTransaction && this.detailTransaction.data && this.detailTransaction.data.length > 0) {
      var hotelTransactionCount = 0;
      for (let transactionData of this.detailTransaction.data) {
        if (transactionData.event_type.toLowerCase() === 'hotel') {
          hotelTransactionCount++;
        }
      }
      if (hotelTransactionCount === 1) {
        return true;
      }
    }
    return false;
  }

  getPriceItem(item){
    if(item.displayPrice){
      return item.displayPrice;
    }else{
      return item.price;
    }
  }
  getTotalPassengerFromEmail(data) {
    var noOfGuest = 0;
    for (let item of data.option.hotel_option.hotelRooms) {
      noOfGuest = noOfGuest + item.hotelRates[0].roomOccupancyDTO.numberOfAdult;
    }
    return noOfGuest;
  }

  getOptionsByIndex(item){
    if(item.type == 'flight'){ 
      return item.option.flight_option;
    }else if(item.type == 'hotel'){
      return item.option.hotel_option;
    }else if(item.type == 'cars'){
      return item.option.car_option;
    }
  }

  getCreditAmount(creditDetails,amount) {
    let total =0;
    if(creditDetails.displayCreditAmount){
      if (amount > creditDetails.displayCreditAmount) {
        return total = creditDetails.displayCreditAmount;
      } else{
        return total = amount;
      }
    }else{
    if (amount > creditDetails.creditAmount) {
      return total = creditDetails.creditAmount;
    } else{
      return total = amount;
    }
  }
  }

  getRoomSubtotal(item,price): any {
    var subTotal = (price - this.getTax(item));
    return subTotal;
  }

  checkSeat(seat){
    let removedSpacesText = seat.split(" ").join("");
    if(removedSpacesText.length == 0){
      return false;
    }else{
      return true;
    }

  }


  getGrossFare(){
    if(this.transaction){
      
      let trip_event =  this.transaction.data.find(a => a.transactionEventType == 'trip_event' )
      if(trip_event){ return this.getCreditPrice(trip_event) }
    }
  }


  getApprovalStatus(){
    if(this.transaction.approvalStatus){
      const status = this.transaction.approvalStatus;
      if(status == 'approved'){
        return this.translateService.instant('dashboard.Approved').toString();
      }else if(status == 'rejected'){
        return this.translateService.instant('dashboard.Disapproved').toString();
      }else if(status == 'expired'){
        return this.translateService.instant('dashboard.Expired').toString();
      }else{
        return false;
      }
    }else{
      return false;
    };
  }


  getLayoverDuration(i){
    let detail = this.detail;
    if(detail.option.flight_option && detail.option.flight_option.layoverInfo && detail.option.flight_option.layoverInfo.length > 0 && detail.option.flight_option.layoverInfo[i] && detail.option.flight_option.layoverInfo[i][0]){
      let layoverInfo = detail.option.flight_option.layoverInfo[i]
      return `${this.getAllLayoverTimeHour(layoverInfo)}h ${this.getAllLayoverTime(layoverInfo)}m layover`;

    }else{
      return false;
    }
   
  }


  getAllLayoverTimeHour(layover){
    let time=0;
    for(let item1 of layover){time =  time + (item1.duration.hrs*60) + (item1.duration.mins);}
    time = (time/60);
    return (~~time);
  }
  getAllLayoverTime(layover){
    let time=0; 
    for(let item1 of layover){time = time+ (item1.duration.hrs*60)+(item1.duration.mins)}
    time = (time % 60);
    return time;
  }

  getAllHopes(i){
    let detail = this.detail;
    let hops = detail.option.flight_option.legs[i].hops;
    let string = '';
    hops.forEach((e,ind) => {
      let comma = hops.length -1 == ind ? "" : ","; 
      string += `${e.carrier + e.flightNumber + comma} `;
    })
    return string;
  }

  checkIfMixedClass(){
  

    if(this.detail && this.detail.option && this.detail.option.flight_option){
      let legs = this.detail.option.flight_option.legs;
      let hopsType = ''
      let isMixedClass = false;

        for(let legsItem of legs){
          const hops = legsItem.hops;
            for(let hopsItem of hops){
              if(hopsType != ''){
                if(hopsType != hopsItem.type){
                  isMixedClass =   true;
                }
              }else{
                hopsType = hopsItem.type;
              }
            }
        }
      return isMixedClass;
    }
   
  }
  rebookAndCancelButtonCheckForPendingApproval(){
    if(this.detailTransaction.approvalStatus &&  this.detailTransaction.approvalStatus == 'pending'){
      if(this.detailList.length ==1){ return true;}
      else{ return false;}
    }
      return true;
  }
  checkForPriceChangeInPendingApprovalHotel(){
    if(this.forApproval){
      const data = this.detail;
      if( Object.keys(data).includes('oldPrice') && data.oldPrice != null && data.oldPrice != undefined){
       return true;
      }
     }
     return false;
    }
 
}
