import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchRequestComponent } from './search-request/search-request.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { PaymentComponent } from './payment/payment.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { ItineraryGuard } from './itinerary/itinerary.guard';
import { SearchResultGuard } from './search-result/search-result.guard';
import { NotFoundGuard } from './error/not-found.guard';
import { AppLoaderComponent } from './email-booking-flow/app-loader/app-loader.component';
import { LoginComponent } from './login/login.component';
import { SupportComponent } from './support/support.component';
import { CustomPreloadingStrategy } from './custom-preloading-strategy';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/search'
  },
  {
    path: 'search',
    pathMatch: 'full',
    component: SearchRequestComponent
  },
  {
    path: 'admin',
    pathMatch: 'full',
    loadChildren: () => import('./dashboard-wrapper/dashboard-wrapper.module').then(m => m.DashboardWrapperModule)
  },
  {
    path: 'approval',
    pathMatch: 'full',
    loadChildren: () => import('./app-approval/app-approval.module').then(m => m.AppApprovalModule)
  },
  {
    path: 'approvalRequest',
    pathMatch: 'full',
    loadChildren: () => import('./approval-request/approval-request-routing.module').then(m => m.AppApprovalRequestRoutingModule)
  },
  {
    path: 'carResult',
    pathMatch: 'full',
    loadChildren: () => import('./car-result/car-result.module').then(m => m.CarResultModule),
    data: { preload: true }
  },
  {
    path: 'emailAction',
    pathMatch: 'full',
    loadChildren: () => import('./reset/reset.module').then(m => m.ResetModule),
  },
  {
    path: 'account',
    pathMatch: 'full',
    component: UserAccountComponent
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'logout',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'support',
    pathMatch: 'full',
    component: SupportComponent
  },

  {
    path: 'hotels',
    pathMatch: 'full',
    loadChildren: () => import('./hotel-result/hotel-result.module').then(m => m.HotelResultModule),
    data: { preload: true }
  },
  {
    path: 'hotelSelection',
    pathMatch: 'full',
    loadChildren: () => import('./hotel-selection/hotel-selection.module').then(m => m.HotelSelectionModule),
    data: { preload: true }
  },
  {
    path: 'cards',
    pathMatch: 'full',
    loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule),
    data: { preload: true }
  },
  {
    path: 'bookingHistory',
    pathMatch: 'full',
    loadChildren: () => import('./booking-history/booking-history.module').then(m => m.BookingHistoryModule),
    data: { preload: true }
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
    data: { preload: true }
  },
  {
    path: 'addToSlack',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
    data: { preload: true }
  },
  {
    path: 'slack_callback',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
    data: { preload: true }
  },

  {
    path: 'flights',
    loadChildren: () => import('./search-result/search-result.module').then(m => m.SearchResultModule),
    canActivate: [SearchResultGuard],
    data: { preload: true }
  },
  {
    path: 'itinerary',
    component: ItineraryComponent,
    canActivate: [ItineraryGuard]
  },
  {
    path: 'emailflow',
    loadChildren: () => import('./email-booking-flow/email-booking-flow.module').then(m => m.EmailBookingFlowModule),
    data: { preload: true }
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [ItineraryGuard]
  },
  {
    path: 'errors/404',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
    data: { preload: true }
  },
  {
    path: 'loader',
    component: AppLoaderComponent
  },
  {
    path: 'changeLanguage',
    component: AppLoaderComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
    data: { preload: true }
  }
];

@NgModule({
  providers: [CustomPreloadingStrategy],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', preloadingStrategy: CustomPreloadingStrategy })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

