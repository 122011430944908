import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { Location } from '@angular/common';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { FilterService } from '../filter.service';
import { TranslateService } from '@ngx-translate/core';
declare var sendCriticalErrorToRollBar:any;
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  public static POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH = 'NETWORK_ERROR_ON_FLIGHTSEARCH';
  public static POPUP_ID_NETWORK_ERROR_ON_HOTELSEARCH = 'NETWORK_ERROR_ON_HOTELSEARCH';
  public static POPUP_ID_NETWORK_ERROR_ON_BOOK = 'NETWORK_ERROR_ON_BOOK';
  public static POPUP_ID_NETWORK_ERROR_ON_ROOMLIST = 'NETWORK_ERROR_ON_ROOMLIST';
  public static POPUP_ID_NETWORK_ERROR_ON_APPROVAL_REQUEST = 'NETWORK_ERROR_ON_APPROVAL_REQUEST';
  public static POPUP_ID_NETWORK_ERROR_ON_BOOKPOLLING = 'NETWORK_ERROR_ON_BOOKPOLLING';
  public static POPUP_ID_NETWORK_ERROR_ON_BOOKINPROGRESS = 'NETWORK_ERROR_ON_BOOKINPROGRESS';
  public static POPUP_ID_NETWORK_ERROR_ON_ALLFARES = 'NETWORK_ERROR_ON_ALLFARES';






  // bsModalRef: BsModalRef;

  @Input() popUpTitle = '';
  @Input() bodyMsg = '';
  @Input() msgChange=false;
  @Input() link = this.translate.instant('result.OK');
  @Input() message ='';
  @Input() popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH;
  internetRestored=false;
  constructor(
    private modalService: BsModalService,
    public router: Router,
    public bsModalRefforNetworkError: BsModalRef,
    private filterService: FilterService,
    private translate: TranslateService,
    private gallopLocalStorage: GallopLocalStorageService,
    private location: Location,
  ) {

  }
  @HostListener("window:online", ["$event"])
  offlineEvent(event) {
    this.processOfflineEvent(event);
  }
  processOfflineEvent(event){
    if (event.type == "online") {
    }
  }
  ngOnInit() {
    if(this.msgChange){
     // this.popUpTitle = '';
      this.bodyMsg = this.translate.instant('result.RatelimitexceededPleasetryafterfewminutes')
    }else{
      this.popUpTitle = this.translate.instant('result.ConnectionLost');;
      this.bodyMsg = this.translate.instant('result.Sorrywelosttheconnection') +
  this.translate.instant('result.Pleasetryagain');
    }
    sendCriticalErrorToRollBar(this.message);
  }

  onPopUPCTAClick() {
    if (this.bsModalRefforNetworkError) {
    //  sendCriticalErrorToRollBar(this.message);
    //  this.closeAllModals();
      this.bsModalRefforNetworkError.hide();
      this.bsModalRefforNetworkError=undefined;
    }
  }
  onModelCancel() {
    if(this.bsModalRefforNetworkError){
    //  sendCriticalErrorToRollBar(this.message);
     // this.closeAllModals();
      this.bsModalRefforNetworkError.hide();
      this.bsModalRefforNetworkError=undefined;
    }
  }
  private closeAllModals() {
    for (let i = this.modalService.getModalsCount(); i >= 0; i--) {
     
    }
  }
  onModelCancel1() {
   if(this.bsModalRefforNetworkError){
     this.bsModalRefforNetworkError.hide();
   }
    if(this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ALLFARES){
      
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH) {
      this.router.navigate(['/search']);
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_HOTELSEARCH) {
      this.router.navigate(['/search'],
        {
          queryParams:
          {
            type: 'hotel',
          },
          replaceUrl: true
        });
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ROOMLIST) {
      let hotelSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
      if (hotelSearchQueryParam) {
        this.router.navigate(["hotels"],
          {
            queryParams:
            {
              query: encodeURIComponent(JSON.stringify(hotelSearchQueryParam)),
              step: 0,
              resultFound: 1
            },
            replaceUrl: true
          });
      }
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOK) {
      this.router.navigate(['/search']);
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_APPROVAL_REQUEST) {
      this.router.navigate(['/search']);
    }
  }

}
