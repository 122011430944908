<div>
    <div class="card-div shadow1"  *ngIf="true" [ngStyle]="changeStyle()">
        <div class="heading">
        <h1 class="support-text" >{{ 'help.Support' | translate }}</h1>
        <span class="help">  {{ 'help.Werehereforyou' | translate }}</span>
        </div>
        <div
            style="margin-right:2px !important;margin-left:2px !important;margin-bottom:10px !important;margin-top:10px !important;">
            <ng-container>
                <div class="supportBox">
                <div  class="support-item" style="display: inline-block;">
                    <button  class="btn button-normal" style="float:left;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                <path d="M9.71053 5.1374e-08C9.94642 -6.58618e-05 10.178 0.0632946 10.381 0.183448C10.584 0.303601 10.751 0.476128 10.8645 0.682967L10.9316 0.827719L13.5632 7.40736C13.6691 7.67207 13.6863 7.96405 13.612 8.23935C13.5377 8.51465 13.3761 8.75837 13.1513 8.93383L13.0197 9.02332L10.8171 10.3445L10.9 10.48C11.9484 12.1447 13.3566 13.5531 15.0211 14.6015L15.1553 14.6831L16.4776 12.4829C16.6242 12.2382 16.8462 12.0478 17.1104 11.9403C17.3745 11.8328 17.6664 11.814 17.9421 11.8868L18.0934 11.9381L24.6724 14.57C24.8914 14.6574 25.083 14.802 25.227 14.9889C25.3709 15.1757 25.4621 15.3978 25.4908 15.6319L25.5 15.7911V21.0548C25.5 23.2353 23.7329 25.0026 21.4737 25C10.1855 24.3144 1.18684 15.3148 0.5 3.94778C0.499944 2.94082 0.884638 1.9719 1.57537 1.23926C2.26611 0.506623 3.21067 0.065655 4.21579 0.00657984L4.44737 5.1374e-08H9.71053Z" fill="#8F48F7"/>
                              </svg>
                        <br>
                        <span  class="view"> Phone</span>
                    </button>
                    <a data-rel="external" class="phone-number" href="{{'tel:'+this.getPhoneNo()}}" >
                        <div  class="support-item-detail" style="padding-left: 10px;padding-top: 15px;"> {{getPhoneNo()}} (USA)</div>
                    </a>
                    <a data-rel="external"  class="phone-number" href="{{'tel:'+this.getNetherlandPhone()}}" >
                        <div  class="support-item-detail" style="padding-left: 10px;padding-top: 10px;">{{getNetherlandPhone()}} (Netherlands, Europe)</div>
                    </a>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path d="M1.5 12.5L7 7L1.5 1.5" stroke="#6A6078" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="supportBox">
                <div class="support-item">
                    <a [href]="contactNumber | safeurl: 'sms:'"  style="width: 100%;display:inline-block;"> <button
                            class="btn button-normal" style="float:left;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                <path d="M20.5 0C21.8261 0 23.0979 0.532342 24.0355 1.47992C24.9732 2.42749 25.5 3.71268 25.5 5.05275V15.1583C25.5 16.4983 24.9732 17.7835 24.0355 18.7311C23.0979 19.6787 21.8261 20.211 20.5 20.211H14.595L8.6425 23.8199C8.46324 23.9286 8.25985 23.9902 8.05097 23.9989C7.84208 24.0077 7.63436 23.9633 7.44682 23.8699C7.25928 23.7766 7.09792 23.6371 6.97749 23.4644C6.85707 23.2917 6.78143 23.0913 6.7575 22.8814L6.75 22.7374V20.211H5.5C4.2172 20.211 2.98346 19.7128 2.05399 18.8193C1.12451 17.9259 0.57039 16.7056 0.50625 15.4109L0.5 15.1583V5.05275C0.5 3.71268 1.02678 2.42749 1.96447 1.47992C2.90215 0.532342 4.17392 0 5.5 0H20.5ZM15.5 11.3687H8C7.66848 11.3687 7.35054 11.5018 7.11612 11.7387C6.8817 11.9756 6.75 12.2969 6.75 12.6319C6.75 12.9669 6.8817 13.2882 7.11612 13.5251C7.35054 13.762 7.66848 13.8951 8 13.8951H15.5C15.8315 13.8951 16.1495 13.762 16.3839 13.5251C16.6183 13.2882 16.75 12.9669 16.75 12.6319C16.75 12.2969 16.6183 11.9756 16.3839 11.7387C16.1495 11.5018 15.8315 11.3687 15.5 11.3687ZM18 6.31594H8C7.66848 6.31594 7.35054 6.44902 7.11612 6.68592C6.8817 6.92281 6.75 7.24411 6.75 7.57913C6.75 7.91414 6.8817 8.23544 7.11612 8.47233C7.35054 8.70923 7.66848 8.84231 8 8.84231H18C18.3315 8.84231 18.6495 8.70923 18.8839 8.47233C19.1183 8.23544 19.25 7.91414 19.25 7.57913C19.25 7.24411 19.1183 6.92281 18.8839 6.68592C18.6495 6.44902 18.3315 6.31594 18 6.31594Z" fill="#8F48F7"/>
                              </svg><br><span
                                class="view"> {{ 'support.Text' | translate }}</span></button>
                        <div class="support-item-detail" style="margin-left: 15px;"> {{getPhoneNo()}} (USA)</div>
                    </a>
                    
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path d="M1.5 12.5L7 7L1.5 1.5" stroke="#6A6078" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="supportBox">
                <div class="support-item">
                    <a href="{{'mailto:'+ getEmailId()}}" data-rel="external"
                        style="width: 100%;display:inline-block;text-transform: lowercase;"> <button style="float:left;"
                            class="btn button-normal"><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                <g clip-path="url(#clip0_890_3032)">
                                  <path d="M31.1693 10.6748V24.0836C31.1693 25.1676 30.7551 26.2107 30.0115 26.9994C29.2678 27.7881 28.2508 28.2629 27.1686 28.3265L26.9193 28.3336H7.08594C6.00189 28.3336 4.95879 27.9194 4.17007 27.1757C3.38134 26.432 2.90662 25.4151 2.84302 24.3329L2.83594 24.0836V10.6748L16.2164 19.5956L16.3807 19.6891C16.5744 19.7837 16.7871 19.8329 17.0026 19.8329C17.2182 19.8329 17.4309 19.7837 17.6245 19.6891L17.7889 19.5956L31.1693 10.6748Z" fill="#8F48F7"/>
                                  <path d="M26.9139 5.66699C28.4439 5.66699 29.7854 6.47449 30.5334 7.68858L16.9972 16.7127L3.46094 7.68858C3.81614 7.11166 4.30416 6.62806 4.88427 6.27812C5.46439 5.92817 6.11978 5.72204 6.79577 5.67691L7.08052 5.66699H26.9139Z" fill="#8F48F7"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_890_3032">
                                    <rect width="34" height="34" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg><br><span
                                class="view"> {{ 'support.Email' | translate }}</span></button>
                        <div class="support-item-detail" style="margin-left: 15px;" > {{getEmailId()}}</div>
                    </a>
                    
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path d="M1.5 12.5L7 7L1.5 1.5" stroke="#6A6078" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="supportBox" *ngIf="!this.isZohoSIQChatOffline()" >
                <div  class="support-item" (click) = "openZSIQChatWidowClick();">
                    <div style="width: 100%;display:inline-block;text-transform: none;cursor:pointer"> <button style="float:left;"
                            class="btn button-normal"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M26.9983 16.8889L22.6649 12.5556H12.5538C12.1707 12.5556 11.8033 12.4034 11.5324 12.1325C11.2616 11.8616 11.1094 11.4942 11.1094 11.1111V2.44444C11.1094 2.06135 11.2616 1.69395 11.5324 1.42307C11.8033 1.15218 12.1707 1 12.5538 1H25.5538C25.9369 1 26.3043 1.15218 26.5752 1.42307C26.8461 1.69395 26.9983 2.06135 26.9983 2.44444V16.8889Z" fill="#8F48F7" stroke="#8F48F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.8889 18.3336V21.2224C16.8889 21.6055 16.7367 21.9729 16.4658 22.2438C16.1949 22.5147 15.8275 22.6669 15.4444 22.6669H5.33333L1 27.0002V12.5558C1 12.1727 1.15218 11.8053 1.42307 11.5344C1.69395 11.2635 2.06135 11.1113 2.44444 11.1113H5.33333" stroke="#8F48F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              <br><span
                                class="view">Chat</span></button>
                        <div class="support-item-detail" style="margin-left: 15px;"> {{ 'support.ChatWithUs' | translate }}</div>
                       
                    </div>
                    
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path d="M1.5 12.5L7 7L1.5 1.5" stroke="#6A6078" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
            </div>


            </ng-container>
        </div>
    </div>
</div>
<app-navigation></app-navigation>