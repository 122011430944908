
import { FlightLegResult } from './flight-leg-result';
import { FlightScores } from './flight-scores';
import { FareAttributes } from './fare-attributes'
export class FlightResult {

  public price: number;
  public discountedPrice: number;
  public currency: string;
  public id: string;
  public scores: FlightScores;
  public fareBreakup: any;
  public legs: FlightLegResult[];
  public passportRequired: boolean;
  public handlerType: string;
  public flightId: string;
  public displayPrice:number;
  public displayCurrency:string;
  public parentFlightId: string;
  public childFlightId: string;
  public recommendedIdx: number;
  public fareAttributes: FareAttributes;
  public zipCodeRequired: boolean;
  public travelCreditsInfo: any;
  public serviceFee: number;
  public returnLuggageOptions: any;
  public luggageOptions: any;
  public parentIdsOfSameFlight: string[] = [];
  public ngsStars: number;
  otherFarePriceAttributes: any;
  fareWithAccountCode: any;
  duplicateBooking: Array<any>;
  public constructor(fields?: any) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
